// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Js_json = require("rescript/lib/js/js_json.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Icon = require("./Generator_Icon.bs.js");
var Generator_TextureFrame = require("../modules/Generator_TextureFrame.bs.js");

function next(rotation) {
  switch (rotation) {
    case /* Rot0 */0 :
        return /* Rot90 */1;
    case /* Rot90 */1 :
        return /* Rot180 */2;
    case /* Rot180 */2 :
        return /* Rot270 */3;
    case /* Rot270 */3 :
        return /* Rot0 */0;
    
  }
}

function toDegrees(rotation) {
  switch (rotation) {
    case /* Rot0 */0 :
        return 0.0;
    case /* Rot90 */1 :
        return 90.0;
    case /* Rot180 */2 :
        return 180.0;
    case /* Rot270 */3 :
        return 270.0;
    
  }
}

var Rotation = {
  next: next,
  toDegrees: toDegrees
};

var encode = Js_json.serializeExn;

function decode(json) {
  if (json.length > 0) {
    return Js_json.deserializeUnsafe(json);
  }
  
}

var encodeArray = Js_json.serializeExn;

function decodeArray(json) {
  if (json.length > 0) {
    return Js_json.deserializeUnsafe(json);
  } else {
    return [];
  }
}

var SelectedTexture = {
  encode: encode,
  decode: decode,
  encodeArray: encodeArray,
  decodeArray: decodeArray
};

function px(n) {
  return n.toString() + "px";
}

function deg(n) {
  return n.toString() + "deg";
}

function makeBackgroundImage(url) {
  return "url(" + url + ")";
}

function makeBackgroundPosition(x, y) {
  return x.toString() + "px " + (y.toString() + "px");
}

function makeBackgroundSize(x, y) {
  return x.toString() + "px " + (y.toString() + "px");
}

function makeBorder(size, style, color) {
  return size.toString() + "px " + style + " " + color;
}

function makeMargin(t, r, b, l) {
  return t.toString() + "px " + (r.toString() + "px") + " " + (b.toString() + "px") + " " + (l.toString() + "px");
}

function makePadding(t, r, b, l) {
  return t.toString() + "px " + (r.toString() + "px") + " " + (b.toString() + "px") + " " + (l.toString() + "px");
}

var bgGray200 = "rgb(229 231 235)";

var bgGray400 = "rgb(156 163 175)";

function makeTileBaseStyle(isSelected, tileSize) {
  var borderColor = isSelected ? bgGray400 : bgGray200;
  return {
          border: makeBorder(4, "solid", borderColor),
          height: (tileSize + 8 | 0).toString() + "px",
          width: (tileSize + 8 | 0).toString() + "px"
        };
}

function makeTileStyle(textureDef, frame, isSelected, isHover, tileSize) {
  var match = frame.rectangle;
  var widthScale = tileSize / match[2];
  var heightScale = tileSize / match[3];
  var baseStyle = makeTileBaseStyle(isSelected || isHover, tileSize);
  var backgroundStyle = Object.assign({}, {
        backgroundImage: makeBackgroundImage(textureDef.url),
        backgroundPosition: makeBackgroundPosition(-(match[0] * widthScale | 0) | 0, -(match[1] * heightScale | 0) | 0),
        backgroundRepeat: "no-repeat",
        backgroundSize: makeBackgroundSize(textureDef.standardWidth * widthScale | 0, textureDef.standardHeight * heightScale | 0)
      }, {
        imageRendering: "pixelated"
      });
  return Object.assign({}, baseStyle, backgroundStyle);
}

function Generator_TexturePicker$TileButton(Props) {
  var textureDef = Props.textureDef;
  var frame = Props.frame;
  var isSelected = Props.isSelected;
  var onClick = Props.onClick;
  var match = React.useState(function () {
        return false;
      });
  var setIsHover = match[1];
  var label = Generator_TextureFrame.makeFrameLabel(frame);
  var tileStyle = makeTileStyle(textureDef, frame, isSelected, match[0], 32);
  var buttonStyle = {
    margin: makeMargin(0, 4, 4, 0)
  };
  var style = Object.assign({}, tileStyle, buttonStyle);
  return React.createElement("button", {
              style: style,
              title: label,
              onClick: onClick,
              onMouseEnter: (function (param) {
                  Curry._1(setIsHover, (function (param) {
                          return true;
                        }));
                }),
              onMouseLeave: (function (param) {
                  Curry._1(setIsHover, (function (param) {
                          return false;
                        }));
                })
            });
}

var TileButton = {
  make: Generator_TexturePicker$TileButton
};

function Generator_TexturePicker$Search(Props) {
  var value = Props.value;
  var onChange = Props.onChange;
  var onClear = Props.onClear;
  var onInputChange = function (e) {
    var target = e.target;
    var value = target.value;
    var tmp;
    if (value !== undefined) {
      var value$1 = Caml_option.valFromOption(value);
      tmp = value$1.length === 0 ? undefined : Caml_option.some(value$1);
    } else {
      tmp = undefined;
    }
    Curry._1(onChange, tmp);
  };
  var value$1 = value !== undefined ? value : "";
  return React.createElement("div", {
              className: "relative flex items-center mb-4"
            }, React.createElement("input", {
                  className: "border w-full p-2",
                  placeholder: "Search ...",
                  value: value$1,
                  onChange: onInputChange
                }), React.createElement("button", {
                  className: "absolute right-2",
                  onClick: (function (param) {
                      Curry._1(onClear, undefined);
                    })
                }, React.createElement(Generator_Icon.X.make, {
                      color: "Gray500"
                    })));
}

var Search = {
  make: Generator_TexturePicker$Search
};

function Generator_TexturePicker$Preview(Props) {
  var textureDef = Props.textureDef;
  var frame = Props.frame;
  var rotation = Props.rotation;
  var tmp;
  if (frame !== undefined) {
    var rotationDegrees = toDegrees(rotation);
    var tileStyle = makeTileStyle(textureDef, frame, false, false, 128);
    var rotationStyle = {
      transform: "rotate(" + (rotationDegrees.toString() + "deg") + ")"
    };
    var style = Object.assign({}, tileStyle, rotationStyle);
    tmp = React.createElement(React.Fragment, undefined, React.createElement("div", {
              style: style
            }), React.createElement("div", {
              className: "text-center text-gray-500 p-2 pt-0"
            }, Generator_TextureFrame.makeFrameLabel(frame)));
  } else {
    tmp = React.createElement("div", {
          style: makeTileBaseStyle(false, 128)
        });
  }
  return React.createElement("div", {
              className: "flex flex-col items-center",
              style: {
                width: "148px"
              }
            }, tmp);
}

var Preview = {
  make: Generator_TexturePicker$Preview
};

function Generator_TexturePicker$RotationButton(Props) {
  var rotation = Props.rotation;
  var onClick = Props.onClick;
  var icon;
  switch (rotation) {
    case /* Rot0 */0 :
        icon = React.createElement(Generator_Icon.ArrowUp.make, {
              color: "White"
            });
        break;
    case /* Rot90 */1 :
        icon = React.createElement(Generator_Icon.ArrowRight.make, {
              color: "White"
            });
        break;
    case /* Rot180 */2 :
        icon = React.createElement(Generator_Icon.ArrowDown.make, {
              color: "White"
            });
        break;
    case /* Rot270 */3 :
        icon = React.createElement(Generator_Icon.ArrowLeft.make, {
              color: "White"
            });
        break;
    
  }
  return React.createElement("button", {
              className: "bg-blue-500 rounded p-2 w-10 h-10",
              onClick: onClick
            }, icon);
}

var RotationButton = {
  make: Generator_TexturePicker$RotationButton
};

function Generator_TexturePicker(Props) {
  var textureDef = Props.textureDef;
  var frames = Props.frames;
  var onSelect = Props.onSelect;
  var enableRotationOpt = Props.enableRotation;
  var enableRotation = enableRotationOpt !== undefined ? enableRotationOpt : false;
  var match = React.useState(function () {
        
      });
  var setSearch = match[1];
  var search = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setSelectedFrame = match$1[1];
  var selectedFrame = match$1[0];
  var match$2 = React.useState(function () {
        return /* Rot0 */0;
      });
  var setRotation = match$2[1];
  var rotation = match$2[0];
  var framesFiltered = search !== undefined ? Belt_Array.keep(frames, (function (frame) {
            return frame.name.indexOf(search) >= 0;
          })) : frames;
  return React.createElement("div", undefined, React.createElement(Generator_TexturePicker$Search, {
                  value: search,
                  onChange: (function (value) {
                      Curry._1(setSearch, (function (param) {
                              return value;
                            }));
                    }),
                  onClear: (function (param) {
                      Curry._1(setSearch, (function (param) {
                              
                            }));
                    })
                }), React.createElement("div", {
                  className: "flex xitems-center"
                }, React.createElement("div", {
                      className: "overflow-y-auto h-60 w-full"
                    }, Belt_Array.map(framesFiltered, (function (frame) {
                            var isSelected = Belt_Option.mapWithDefault(selectedFrame, false, (function (selectedFrame) {
                                    return frame.id === selectedFrame.id;
                                  }));
                            return React.createElement(Generator_TexturePicker$TileButton, {
                                        textureDef: textureDef,
                                        frame: frame,
                                        isSelected: isSelected,
                                        onClick: (function (param) {
                                            Curry._1(setSelectedFrame, (function (param) {
                                                    return frame;
                                                  }));
                                            Curry._1(onSelect, {
                                                  textureDefId: textureDef.id,
                                                  frame: frame,
                                                  rotation: rotation
                                                });
                                          }),
                                        key: frame.id
                                      });
                          }))), React.createElement("div", undefined, React.createElement(Generator_TexturePicker$Preview, {
                          textureDef: textureDef,
                          frame: selectedFrame,
                          rotation: rotation
                        }), enableRotation ? React.createElement("div", {
                            className: "flex justify-center"
                          }, React.createElement(Generator_TexturePicker$RotationButton, {
                                rotation: rotation,
                                onClick: (function (param) {
                                    var nextRotation = next(rotation);
                                    Curry._1(setRotation, (function (param) {
                                            return nextRotation;
                                          }));
                                    if (selectedFrame !== undefined) {
                                      return Curry._1(onSelect, {
                                                  textureDefId: textureDef.id,
                                                  frame: selectedFrame,
                                                  rotation: nextRotation
                                                });
                                    }
                                    
                                  })
                              })) : null)));
}

var TextureFrame;

var Icon;

var bgGray100 = "rgb(243 244 246)";

var bgGray300 = "rgb(209 213 219)";

var borderSize = 4;

var make = Generator_TexturePicker;

exports.TextureFrame = TextureFrame;
exports.Icon = Icon;
exports.Rotation = Rotation;
exports.SelectedTexture = SelectedTexture;
exports.px = px;
exports.deg = deg;
exports.makeBackgroundImage = makeBackgroundImage;
exports.makeBackgroundPosition = makeBackgroundPosition;
exports.makeBackgroundSize = makeBackgroundSize;
exports.makeBorder = makeBorder;
exports.makeMargin = makeMargin;
exports.makePadding = makePadding;
exports.bgGray100 = bgGray100;
exports.bgGray200 = bgGray200;
exports.bgGray300 = bgGray300;
exports.bgGray400 = bgGray400;
exports.borderSize = borderSize;
exports.makeTileBaseStyle = makeTileBaseStyle;
exports.makeTileStyle = makeTileStyle;
exports.TileButton = TileButton;
exports.Search = Search;
exports.Preview = Preview;
exports.RotationButton = RotationButton;
exports.make = make;
/* react Not a pure module */
