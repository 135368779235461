// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var texture_url = require("./Texture_minecraft_1_18_1_blocks.png");

var texture = {
  id: "minecraft-1.18.1-blocks",
  url: texture_url,
  standardWidth: 512,
  standardHeight: 1536
};

var tiles = [
  {
    name: "acacia_door_bottom",
    x: 0,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_door_top",
    x: 16,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_leaves",
    x: 32,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_log",
    x: 48,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_log_top",
    x: 64,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_planks",
    x: 80,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_sapling",
    x: 96,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_trapdoor",
    x: 112,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "activator_rail",
    x: 128,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "activator_rail_on",
    x: 144,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "allium",
    x: 160,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "amethyst_block",
    x: 176,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "amethyst_cluster",
    x: 192,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "ancient_debris_side",
    x: 208,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "ancient_debris_top",
    x: 224,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "andesite",
    x: 240,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "anvil",
    x: 256,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "anvil_top",
    x: 272,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "attached_melon_stem",
    x: 288,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "attached_pumpkin_stem",
    x: 304,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "azalea_leaves",
    x: 320,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "azalea_plant",
    x: 336,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "azalea_side",
    x: 352,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "azalea_top",
    x: 368,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "azure_bluet",
    x: 384,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bamboo_large_leaves",
    x: 400,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bamboo_singleleaf",
    x: 416,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bamboo_small_leaves",
    x: 432,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bamboo_stage0",
    x: 448,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bamboo_stalk",
    x: 464,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "barrel_bottom",
    x: 480,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "barrel_side",
    x: 496,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "barrel_top",
    x: 0,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "barrel_top_open",
    x: 16,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "basalt_side",
    x: 32,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "basalt_top",
    x: 48,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beacon",
    x: 64,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bedrock",
    x: 80,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bee_nest_bottom",
    x: 96,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bee_nest_front",
    x: 112,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bee_nest_front_honey",
    x: 128,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bee_nest_side",
    x: 144,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bee_nest_top",
    x: 160,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beehive_end",
    x: 176,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beehive_front",
    x: 192,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beehive_front_honey",
    x: 208,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beehive_side",
    x: 224,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beetroots_stage0",
    x: 240,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beetroots_stage1",
    x: 256,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beetroots_stage2",
    x: 272,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "beetroots_stage3",
    x: 288,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bell_bottom",
    x: 304,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bell_side",
    x: 320,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "bell_top",
    x: 336,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "big_dripleaf_side",
    x: 352,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "big_dripleaf_stem",
    x: 368,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "big_dripleaf_tip",
    x: 384,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "big_dripleaf_top",
    x: 400,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_door_bottom",
    x: 416,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_door_top",
    x: 432,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_leaves",
    x: 448,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_log",
    x: 464,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_log_top",
    x: 480,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_planks",
    x: 496,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "birch_sapling",
    x: 0,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "birch_trapdoor",
    x: 16,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_candle",
    x: 32,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_candle_lit",
    x: 48,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_concrete",
    x: 64,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_concrete_powder",
    x: 80,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_glazed_terracotta",
    x: 96,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_shulker_box",
    x: 112,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_stained_glass",
    x: 128,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_stained_glass_pane_top",
    x: 144,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_terracotta",
    x: 160,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "black_wool",
    x: 176,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blackstone",
    x: 192,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blackstone_top",
    x: 208,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blast_furnace_front",
    x: 224,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blast_furnace_front_on",
    x: 112,
    y: 384,
    width: 16,
    height: 32
  },
  {
    name: "blast_furnace_side",
    x: 240,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blast_furnace_top",
    x: 256,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_candle",
    x: 272,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_candle_lit",
    x: 288,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_concrete",
    x: 304,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_concrete_powder",
    x: 320,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_glazed_terracotta",
    x: 336,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_ice",
    x: 352,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_orchid",
    x: 368,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_shulker_box",
    x: 384,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_stained_glass",
    x: 400,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_stained_glass_pane_top",
    x: 416,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_terracotta",
    x: 432,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "blue_wool",
    x: 448,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "bone_block_side",
    x: 464,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "bone_block_top",
    x: 480,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "bookshelf",
    x: 496,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "brain_coral",
    x: 0,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brain_coral_block",
    x: 16,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brain_coral_fan",
    x: 32,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brewing_stand",
    x: 48,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brewing_stand_base",
    x: 64,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "bricks",
    x: 80,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_candle",
    x: 96,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_candle_lit",
    x: 112,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_concrete",
    x: 128,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_concrete_powder",
    x: 144,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_glazed_terracotta",
    x: 160,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_mushroom",
    x: 176,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_mushroom_block",
    x: 192,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_shulker_box",
    x: 208,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_stained_glass",
    x: 224,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_stained_glass_pane_top",
    x: 240,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_terracotta",
    x: 256,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "brown_wool",
    x: 272,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "bubble_coral",
    x: 288,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "bubble_coral_block",
    x: 304,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "bubble_coral_fan",
    x: 320,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "budding_amethyst",
    x: 336,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cactus_bottom",
    x: 352,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cactus_side",
    x: 368,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cactus_top",
    x: 384,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cake_bottom",
    x: 400,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cake_inner",
    x: 416,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cake_side",
    x: 432,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cake_top",
    x: 448,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "calcite",
    x: 464,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "campfire_fire",
    x: 496,
    y: 384,
    width: 16,
    height: 128
  },
  {
    name: "campfire_log",
    x: 480,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "campfire_log_lit",
    x: 208,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "candle",
    x: 496,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "candle_lit",
    x: 0,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage0",
    x: 16,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage1",
    x: 32,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage2",
    x: 48,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage3",
    x: 64,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cartography_table_side1",
    x: 80,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cartography_table_side2",
    x: 96,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cartography_table_side3",
    x: 112,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cartography_table_top",
    x: 128,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "carved_pumpkin",
    x: 144,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_bottom",
    x: 160,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_inner",
    x: 176,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_side",
    x: 192,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_top",
    x: 208,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cave_vines",
    x: 224,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cave_vines_lit",
    x: 240,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cave_vines_plant",
    x: 256,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cave_vines_plant_lit",
    x: 272,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chain",
    x: 288,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chain_command_block_back",
    x: 224,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "chain_command_block_conditional",
    x: 240,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "chain_command_block_front",
    x: 256,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "chain_command_block_side",
    x: 272,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "chipped_anvil_top",
    x: 304,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_deepslate",
    x: 320,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_nether_bricks",
    x: 336,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_polished_blackstone",
    x: 352,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_quartz_block",
    x: 368,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_quartz_block_top",
    x: 384,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_red_sandstone",
    x: 400,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_sandstone",
    x: 416,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chiseled_stone_bricks",
    x: 432,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chorus_flower",
    x: 448,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chorus_flower_dead",
    x: 464,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "chorus_plant",
    x: 480,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "clay",
    x: 496,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "coal_block",
    x: 0,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "coal_ore",
    x: 16,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "coarse_dirt",
    x: 32,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cobbled_deepslate",
    x: 48,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cobblestone",
    x: 64,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cobweb",
    x: 80,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_stage0",
    x: 96,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_stage1",
    x: 112,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_stage2",
    x: 128,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "command_block_back",
    x: 288,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "command_block_conditional",
    x: 304,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "command_block_front",
    x: 320,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "command_block_side",
    x: 336,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "comparator",
    x: 144,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "comparator_on",
    x: 160,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "composter_bottom",
    x: 176,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "composter_compost",
    x: 192,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "composter_ready",
    x: 208,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "composter_side",
    x: 224,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "composter_top",
    x: 240,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "conduit",
    x: 256,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "copper_block",
    x: 272,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "copper_ore",
    x: 288,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cornflower",
    x: 304,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cracked_deepslate_bricks",
    x: 320,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cracked_deepslate_tiles",
    x: 336,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cracked_nether_bricks",
    x: 352,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cracked_polished_blackstone_bricks",
    x: 368,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "cracked_stone_bricks",
    x: 384,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crafting_table_front",
    x: 400,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crafting_table_side",
    x: 416,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crafting_table_top",
    x: 432,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crimson_door_bottom",
    x: 448,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crimson_door_top",
    x: 464,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crimson_fungus",
    x: 480,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crimson_nylium",
    x: 496,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "crimson_nylium_side",
    x: 0,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "crimson_planks",
    x: 16,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "crimson_roots",
    x: 32,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "crimson_roots_pot",
    x: 48,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "crimson_stem",
    x: 448,
    y: 384,
    width: 16,
    height: 80
  },
  {
    name: "crimson_stem_top",
    x: 64,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "crimson_trapdoor",
    x: 80,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "crying_obsidian",
    x: 96,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cut_copper",
    x: 112,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cut_red_sandstone",
    x: 128,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cut_sandstone",
    x: 144,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_candle",
    x: 160,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_candle_lit",
    x: 176,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_concrete",
    x: 192,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_concrete_powder",
    x: 208,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_glazed_terracotta",
    x: 224,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_shulker_box",
    x: 240,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_stained_glass",
    x: 256,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_stained_glass_pane_top",
    x: 272,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_terracotta",
    x: 288,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "cyan_wool",
    x: 304,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "damaged_anvil_top",
    x: 320,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dandelion",
    x: 336,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_door_bottom",
    x: 352,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_door_top",
    x: 368,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_leaves",
    x: 384,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_log",
    x: 400,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_log_top",
    x: 416,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_planks",
    x: 432,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_sapling",
    x: 448,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_trapdoor",
    x: 464,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "dark_prismarine",
    x: 480,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "daylight_detector_inverted_top",
    x: 496,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "daylight_detector_side",
    x: 0,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "daylight_detector_top",
    x: 16,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_brain_coral",
    x: 32,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_brain_coral_block",
    x: 48,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_brain_coral_fan",
    x: 64,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_bubble_coral",
    x: 80,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_bubble_coral_block",
    x: 96,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_bubble_coral_fan",
    x: 112,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_bush",
    x: 128,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_fire_coral",
    x: 144,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_fire_coral_block",
    x: 160,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_fire_coral_fan",
    x: 176,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_horn_coral",
    x: 192,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_horn_coral_block",
    x: 208,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_horn_coral_fan",
    x: 224,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_tube_coral",
    x: 240,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_tube_coral_block",
    x: 256,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "dead_tube_coral_fan",
    x: 272,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "debug",
    x: 288,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "debug2",
    x: 304,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate",
    x: 320,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_bricks",
    x: 336,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_coal_ore",
    x: 352,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_copper_ore",
    x: 368,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_diamond_ore",
    x: 384,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_emerald_ore",
    x: 400,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_gold_ore",
    x: 416,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_iron_ore",
    x: 432,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_lapis_ore",
    x: 448,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_redstone_ore",
    x: 464,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_tiles",
    x: 480,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "deepslate_top",
    x: 496,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_0",
    x: 0,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_1",
    x: 16,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_2",
    x: 32,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_3",
    x: 48,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_4",
    x: 64,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_5",
    x: 80,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_6",
    x: 96,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_7",
    x: 112,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_8",
    x: 128,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_9",
    x: 144,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "detector_rail",
    x: 160,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "detector_rail_on",
    x: 176,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "diamond_block",
    x: 192,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "diamond_ore",
    x: 208,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "diorite",
    x: 224,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dirt",
    x: 240,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dirt_path_side",
    x: 256,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dirt_path_top",
    x: 272,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dispenser_front",
    x: 288,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dispenser_front_vertical",
    x: 304,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dragon_egg",
    x: 320,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dried_kelp_bottom",
    x: 336,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dried_kelp_side",
    x: 352,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dried_kelp_top",
    x: 368,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dripstone_block",
    x: 384,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dropper_front",
    x: 400,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "dropper_front_vertical",
    x: 416,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "emerald_block",
    x: 432,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "emerald_ore",
    x: 448,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "enchanting_table_bottom",
    x: 464,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "enchanting_table_side",
    x: 480,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "enchanting_table_top",
    x: 496,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "end_portal_frame_eye",
    x: 0,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "end_portal_frame_side",
    x: 16,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "end_portal_frame_top",
    x: 32,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "end_rod",
    x: 48,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "end_stone",
    x: 64,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "end_stone_bricks",
    x: 80,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "exposed_copper",
    x: 96,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "exposed_cut_copper",
    x: 112,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "farmland",
    x: 128,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "farmland_moist",
    x: 144,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fern",
    x: 160,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fire_0",
    x: 144,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "fire_1",
    x: 160,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "fire_coral",
    x: 176,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fire_coral_block",
    x: 192,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fire_coral_fan",
    x: 208,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fletching_table_front",
    x: 224,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fletching_table_side",
    x: 240,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "fletching_table_top",
    x: 256,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "flower_pot",
    x: 272,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "flowering_azalea_leaves",
    x: 288,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "flowering_azalea_side",
    x: 304,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "flowering_azalea_top",
    x: 320,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "frosted_ice_0",
    x: 336,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "frosted_ice_1",
    x: 352,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "frosted_ice_2",
    x: 368,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "frosted_ice_3",
    x: 384,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "furnace_front",
    x: 400,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "furnace_front_on",
    x: 416,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "furnace_side",
    x: 432,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "furnace_top",
    x: 448,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "gilded_blackstone",
    x: 464,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "glass",
    x: 480,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top",
    x: 496,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "glow_item_frame",
    x: 0,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "glow_lichen",
    x: 16,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "glowstone",
    x: 32,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gold_block",
    x: 48,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gold_ore",
    x: 64,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "granite",
    x: 80,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "grass",
    x: 96,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "grass_block_side",
    x: 112,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "grass_block_side_overlay",
    x: 128,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "grass_block_snow",
    x: 144,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "grass_block_top",
    x: 160,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gravel",
    x: 176,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_candle",
    x: 192,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_candle_lit",
    x: 208,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_concrete",
    x: 224,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_concrete_powder",
    x: 240,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_glazed_terracotta",
    x: 256,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_shulker_box",
    x: 272,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_stained_glass",
    x: 288,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_stained_glass_pane_top",
    x: 304,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_terracotta",
    x: 320,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "gray_wool",
    x: 336,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_candle",
    x: 352,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_candle_lit",
    x: 368,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_concrete",
    x: 384,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_concrete_powder",
    x: 400,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_glazed_terracotta",
    x: 416,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_shulker_box",
    x: 432,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_stained_glass",
    x: 448,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_stained_glass_pane_top",
    x: 464,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_terracotta",
    x: 480,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "green_wool",
    x: 496,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "grindstone_pivot",
    x: 0,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "grindstone_round",
    x: 16,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "grindstone_side",
    x: 32,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "hanging_roots",
    x: 48,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "hay_block_side",
    x: 64,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "hay_block_top",
    x: 80,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "honey_block_bottom",
    x: 96,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "honey_block_side",
    x: 112,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "honey_block_top",
    x: 128,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "honeycomb_block",
    x: 144,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "hopper_inside",
    x: 160,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "hopper_outside",
    x: 176,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "hopper_top",
    x: 192,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "horn_coral",
    x: 208,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "horn_coral_block",
    x: 224,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "horn_coral_fan",
    x: 240,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "ice",
    x: 256,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "iron_bars",
    x: 272,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "iron_block",
    x: 288,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "iron_door_bottom",
    x: 304,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "iron_door_top",
    x: 320,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "iron_ore",
    x: 336,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "iron_trapdoor",
    x: 352,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "item_frame",
    x: 368,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jack_o_lantern",
    x: 384,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jigsaw_bottom",
    x: 400,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jigsaw_lock",
    x: 416,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jigsaw_side",
    x: 432,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jigsaw_top",
    x: 448,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jukebox_side",
    x: 464,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jukebox_top",
    x: 480,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jungle_door_bottom",
    x: 496,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "jungle_door_top",
    x: 0,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "jungle_leaves",
    x: 16,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "jungle_log",
    x: 32,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "jungle_log_top",
    x: 48,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "jungle_planks",
    x: 64,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "jungle_sapling",
    x: 80,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "jungle_trapdoor",
    x: 96,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "kelp",
    x: 96,
    y: 512,
    width: 16,
    height: 320
  },
  {
    name: "kelp_plant",
    x: 112,
    y: 512,
    width: 16,
    height: 320
  },
  {
    name: "ladder",
    x: 112,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lantern",
    x: 128,
    y: 384,
    width: 16,
    height: 48
  },
  {
    name: "lapis_block",
    x: 128,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lapis_ore",
    x: 144,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "large_amethyst_bud",
    x: 160,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "large_fern_bottom",
    x: 176,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "large_fern_top",
    x: 192,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lava_flow",
    x: 176,
    y: 512,
    width: 32,
    height: 512
  },
  {
    name: "lava_still",
    x: 128,
    y: 512,
    width: 16,
    height: 320
  },
  {
    name: "lectern_base",
    x: 208,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lectern_front",
    x: 224,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lectern_sides",
    x: 240,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lectern_top",
    x: 256,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "lever",
    x: 272,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_candle",
    x: 288,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_candle_lit",
    x: 304,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_concrete",
    x: 320,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_concrete_powder",
    x: 336,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_glazed_terracotta",
    x: 352,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_shulker_box",
    x: 368,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_stained_glass",
    x: 384,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_stained_glass_pane_top",
    x: 400,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_terracotta",
    x: 416,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_wool",
    x: 432,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_candle",
    x: 448,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_candle_lit",
    x: 464,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_concrete",
    x: 480,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_concrete_powder",
    x: 496,
    y: 192,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_glazed_terracotta",
    x: 0,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_shulker_box",
    x: 16,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_stained_glass",
    x: 32,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_stained_glass_pane_top",
    x: 48,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_terracotta",
    x: 64,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_wool",
    x: 80,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lightning_rod",
    x: 96,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lightning_rod_on",
    x: 112,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lilac_bottom",
    x: 128,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lilac_top",
    x: 144,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lily_of_the_valley",
    x: 160,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lily_pad",
    x: 176,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_candle",
    x: 192,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_candle_lit",
    x: 208,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_concrete",
    x: 224,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_concrete_powder",
    x: 240,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_glazed_terracotta",
    x: 256,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_shulker_box",
    x: 272,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_stained_glass",
    x: 288,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_stained_glass_pane_top",
    x: 304,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_terracotta",
    x: 320,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lime_wool",
    x: 336,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lodestone_side",
    x: 352,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "lodestone_top",
    x: 368,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "loom_bottom",
    x: 384,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "loom_front",
    x: 400,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "loom_side",
    x: 416,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "loom_top",
    x: 432,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "magenta_candle",
    x: 448,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "magenta_candle_lit",
    x: 464,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "magenta_concrete",
    x: 480,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "magenta_concrete_powder",
    x: 496,
    y: 208,
    width: 16,
    height: 16
  },
  {
    name: "magenta_glazed_terracotta",
    x: 0,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "magenta_shulker_box",
    x: 16,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "magenta_stained_glass",
    x: 32,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "magenta_stained_glass_pane_top",
    x: 48,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "magenta_terracotta",
    x: 64,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "magenta_wool",
    x: 80,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "magma",
    x: 144,
    y: 384,
    width: 16,
    height: 48
  },
  {
    name: "medium_amethyst_bud",
    x: 96,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "melon_side",
    x: 112,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "melon_stem",
    x: 128,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "melon_top",
    x: 144,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "moss_block",
    x: 160,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "mossy_cobblestone",
    x: 176,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "mossy_stone_bricks",
    x: 192,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_block_inside",
    x: 208,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_stem",
    x: 224,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "mycelium_side",
    x: 240,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "mycelium_top",
    x: 256,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_bricks",
    x: 272,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_gold_ore",
    x: 288,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_portal",
    x: 208,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "nether_quartz_ore",
    x: 304,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_sprouts",
    x: 320,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_block",
    x: 336,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_stage0",
    x: 352,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_stage1",
    x: 368,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_stage2",
    x: 384,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "netherite_block",
    x: 400,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "netherrack",
    x: 416,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "note_block",
    x: 432,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "oak_door_bottom",
    x: 448,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "oak_door_top",
    x: 464,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "oak_leaves",
    x: 480,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "oak_log",
    x: 496,
    y: 224,
    width: 16,
    height: 16
  },
  {
    name: "oak_log_top",
    x: 0,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "oak_planks",
    x: 16,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "oak_sapling",
    x: 32,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "oak_trapdoor",
    x: 48,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "observer_back",
    x: 64,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "observer_back_on",
    x: 80,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "observer_front",
    x: 96,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "observer_side",
    x: 112,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "observer_top",
    x: 128,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "obsidian",
    x: 144,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_candle",
    x: 160,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_candle_lit",
    x: 176,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_concrete",
    x: 192,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_concrete_powder",
    x: 208,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_glazed_terracotta",
    x: 224,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_shulker_box",
    x: 240,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_stained_glass",
    x: 256,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_stained_glass_pane_top",
    x: 272,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_terracotta",
    x: 288,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_tulip",
    x: 304,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "orange_wool",
    x: 320,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "oxeye_daisy",
    x: 336,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "oxidized_copper",
    x: 352,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "oxidized_cut_copper",
    x: 368,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "packed_ice",
    x: 384,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "peony_bottom",
    x: 400,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "peony_top",
    x: 416,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "pink_candle",
    x: 432,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "pink_candle_lit",
    x: 448,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "pink_concrete",
    x: 464,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "pink_concrete_powder",
    x: 480,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "pink_glazed_terracotta",
    x: 496,
    y: 240,
    width: 16,
    height: 16
  },
  {
    name: "pink_shulker_box",
    x: 0,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pink_stained_glass",
    x: 16,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pink_stained_glass_pane_top",
    x: 32,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pink_terracotta",
    x: 48,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pink_tulip",
    x: 64,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pink_wool",
    x: 80,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "piston_bottom",
    x: 96,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "piston_inner",
    x: 112,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "piston_side",
    x: 128,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "piston_top",
    x: 144,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "piston_top_sticky",
    x: 160,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "podzol_side",
    x: 176,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "podzol_top",
    x: 192,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_down_base",
    x: 208,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_down_frustum",
    x: 224,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_down_middle",
    x: 240,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_down_tip",
    x: 256,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_down_tip_merge",
    x: 272,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_up_base",
    x: 288,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_up_frustum",
    x: 304,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_up_middle",
    x: 320,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_up_tip",
    x: 336,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "pointed_dripstone_up_tip_merge",
    x: 352,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_andesite",
    x: 368,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_basalt_side",
    x: 384,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_basalt_top",
    x: 400,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_blackstone",
    x: 416,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_blackstone_bricks",
    x: 432,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_deepslate",
    x: 448,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_diorite",
    x: 464,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "polished_granite",
    x: 480,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "poppy",
    x: 496,
    y: 256,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage0",
    x: 0,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage1",
    x: 16,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage2",
    x: 32,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage3",
    x: 48,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potted_azalea_bush_plant",
    x: 64,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potted_azalea_bush_side",
    x: 80,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potted_azalea_bush_top",
    x: 96,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potted_flowering_azalea_bush_plant",
    x: 112,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potted_flowering_azalea_bush_side",
    x: 128,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "potted_flowering_azalea_bush_top",
    x: 144,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "powder_snow",
    x: 160,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "powered_rail",
    x: 176,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "powered_rail_on",
    x: 192,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "prismarine",
    x: 352,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "prismarine_bricks",
    x: 208,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_side",
    x: 224,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_stem",
    x: 240,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_top",
    x: 256,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_candle",
    x: 272,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_candle_lit",
    x: 288,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_concrete",
    x: 304,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_concrete_powder",
    x: 320,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_glazed_terracotta",
    x: 336,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_shulker_box",
    x: 352,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_stained_glass",
    x: 368,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_stained_glass_pane_top",
    x: 384,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_terracotta",
    x: 400,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purple_wool",
    x: 416,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purpur_block",
    x: 432,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purpur_pillar",
    x: 448,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "purpur_pillar_top",
    x: 464,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_bottom",
    x: 480,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_side",
    x: 496,
    y: 272,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_top",
    x: 0,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "quartz_bricks",
    x: 16,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "quartz_pillar",
    x: 32,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "quartz_pillar_top",
    x: 48,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "rail",
    x: 64,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "rail_corner",
    x: 80,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "raw_copper_block",
    x: 96,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "raw_gold_block",
    x: 112,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "raw_iron_block",
    x: 128,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_candle",
    x: 144,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_candle_lit",
    x: 160,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_concrete",
    x: 176,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_concrete_powder",
    x: 192,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_glazed_terracotta",
    x: 208,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_mushroom",
    x: 224,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_mushroom_block",
    x: 240,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_nether_bricks",
    x: 256,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_sand",
    x: 272,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_sandstone",
    x: 288,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_sandstone_bottom",
    x: 304,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_sandstone_top",
    x: 320,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_shulker_box",
    x: 336,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_stained_glass",
    x: 352,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_stained_glass_pane_top",
    x: 368,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_terracotta",
    x: 384,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_tulip",
    x: 400,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "red_wool",
    x: 416,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "redstone_block",
    x: 432,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_dot",
    x: 448,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_line0",
    x: 464,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_line1",
    x: 480,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_overlay",
    x: 496,
    y: 288,
    width: 16,
    height: 16
  },
  {
    name: "redstone_lamp",
    x: 0,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "redstone_lamp_on",
    x: 16,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "redstone_ore",
    x: 32,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "redstone_torch",
    x: 48,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "redstone_torch_off",
    x: 64,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "repeater",
    x: 80,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "repeater_on",
    x: 96,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "repeating_command_block_back",
    x: 368,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "repeating_command_block_conditional",
    x: 384,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "repeating_command_block_front",
    x: 400,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "repeating_command_block_side",
    x: 416,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "respawn_anchor_bottom",
    x: 112,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "respawn_anchor_side0",
    x: 128,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "respawn_anchor_side1",
    x: 144,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "respawn_anchor_side2",
    x: 160,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "respawn_anchor_side3",
    x: 176,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "respawn_anchor_side4",
    x: 192,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "respawn_anchor_top",
    x: 224,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "respawn_anchor_top_off",
    x: 208,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "rooted_dirt",
    x: 224,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "rose_bush_bottom",
    x: 240,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "rose_bush_top",
    x: 256,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sand",
    x: 272,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sandstone",
    x: 288,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_bottom",
    x: 304,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_top",
    x: 320,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "scaffolding_bottom",
    x: 336,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "scaffolding_side",
    x: 352,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "scaffolding_top",
    x: 368,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sculk_sensor_bottom",
    x: 384,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sculk_sensor_side",
    x: 400,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sculk_sensor_tendril_active",
    x: 16,
    y: 512,
    width: 16,
    height: 256
  },
  {
    name: "sculk_sensor_tendril_inactive",
    x: 32,
    y: 512,
    width: 16,
    height: 256
  },
  {
    name: "sculk_sensor_top",
    x: 416,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "sea_lantern",
    x: 464,
    y: 384,
    width: 16,
    height: 80
  },
  {
    name: "sea_pickle",
    x: 432,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "seagrass",
    x: 48,
    y: 512,
    width: 16,
    height: 288
  },
  {
    name: "shroomlight",
    x: 448,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "shulker_box",
    x: 464,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "slime_block",
    x: 480,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "small_amethyst_bud",
    x: 496,
    y: 304,
    width: 16,
    height: 16
  },
  {
    name: "small_dripleaf_side",
    x: 0,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "small_dripleaf_stem_bottom",
    x: 16,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "small_dripleaf_stem_top",
    x: 32,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "small_dripleaf_top",
    x: 48,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smithing_table_bottom",
    x: 64,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smithing_table_front",
    x: 80,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smithing_table_side",
    x: 96,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smithing_table_top",
    x: 112,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smoker_bottom",
    x: 128,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smoker_front",
    x: 144,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smoker_front_on",
    x: 160,
    y: 384,
    width: 16,
    height: 48
  },
  {
    name: "smoker_side",
    x: 160,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smoker_top",
    x: 176,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smooth_basalt",
    x: 192,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smooth_stone",
    x: 208,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "smooth_stone_slab_side",
    x: 224,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "snow",
    x: 240,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "soul_campfire_fire",
    x: 0,
    y: 512,
    width: 16,
    height: 128
  },
  {
    name: "soul_campfire_log_lit",
    x: 432,
    y: 384,
    width: 16,
    height: 64
  },
  {
    name: "soul_fire_0",
    x: 240,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "soul_fire_1",
    x: 256,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "soul_lantern",
    x: 176,
    y: 384,
    width: 16,
    height: 48
  },
  {
    name: "soul_sand",
    x: 256,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "soul_soil",
    x: 272,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "soul_torch",
    x: 288,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spawner",
    x: 304,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "sponge",
    x: 320,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spore_blossom",
    x: 336,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spore_blossom_base",
    x: 352,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_door_bottom",
    x: 368,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_door_top",
    x: 384,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_leaves",
    x: 400,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_log",
    x: 416,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_log_top",
    x: 432,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_planks",
    x: 448,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_sapling",
    x: 464,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "spruce_trapdoor",
    x: 480,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "stone",
    x: 496,
    y: 320,
    width: 16,
    height: 16
  },
  {
    name: "stone_bricks",
    x: 0,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stonecutter_bottom",
    x: 16,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stonecutter_saw",
    x: 192,
    y: 384,
    width: 16,
    height: 48
  },
  {
    name: "stonecutter_side",
    x: 32,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stonecutter_top",
    x: 48,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_acacia_log",
    x: 64,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_acacia_log_top",
    x: 80,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_birch_log",
    x: 96,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_birch_log_top",
    x: 112,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_crimson_stem",
    x: 128,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_crimson_stem_top",
    x: 144,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_dark_oak_log",
    x: 160,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_dark_oak_log_top",
    x: 176,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_jungle_log",
    x: 192,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_jungle_log_top",
    x: 208,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_oak_log",
    x: 224,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_oak_log_top",
    x: 240,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_spruce_log",
    x: 256,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_spruce_log_top",
    x: 272,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_warped_stem",
    x: 288,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "stripped_warped_stem_top",
    x: 304,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "structure_block",
    x: 320,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "structure_block_corner",
    x: 336,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "structure_block_data",
    x: 352,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "structure_block_load",
    x: 368,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "structure_block_save",
    x: 384,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sugar_cane",
    x: 400,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sunflower_back",
    x: 416,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sunflower_bottom",
    x: 432,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sunflower_front",
    x: 448,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sunflower_top",
    x: 464,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sweet_berry_bush_stage0",
    x: 480,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sweet_berry_bush_stage1",
    x: 496,
    y: 336,
    width: 16,
    height: 16
  },
  {
    name: "sweet_berry_bush_stage2",
    x: 0,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "sweet_berry_bush_stage3",
    x: 16,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tall_grass_bottom",
    x: 32,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tall_grass_top",
    x: 48,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tall_seagrass_bottom",
    x: 64,
    y: 512,
    width: 16,
    height: 304
  },
  {
    name: "tall_seagrass_top",
    x: 80,
    y: 512,
    width: 16,
    height: 304
  },
  {
    name: "target_side",
    x: 64,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "target_top",
    x: 80,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "terracotta",
    x: 96,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tinted_glass",
    x: 112,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tnt_bottom",
    x: 128,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tnt_side",
    x: 144,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tnt_top",
    x: 160,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "torch",
    x: 176,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tripwire",
    x: 192,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tripwire_hook",
    x: 208,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tube_coral",
    x: 224,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tube_coral_block",
    x: 240,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tube_coral_fan",
    x: 256,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "tuff",
    x: 272,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "turtle_egg",
    x: 288,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "turtle_egg_slightly_cracked",
    x: 304,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "turtle_egg_very_cracked",
    x: 320,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "twisting_vines",
    x: 336,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "twisting_vines_plant",
    x: 352,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "vine",
    x: 368,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_door_bottom",
    x: 384,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_door_top",
    x: 400,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_fungus",
    x: 416,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_nylium",
    x: 432,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_nylium_side",
    x: 448,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_planks",
    x: 464,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_roots",
    x: 480,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_roots_pot",
    x: 496,
    y: 352,
    width: 16,
    height: 16
  },
  {
    name: "warped_stem",
    x: 480,
    y: 384,
    width: 16,
    height: 80
  },
  {
    name: "warped_stem_top",
    x: 0,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "warped_trapdoor",
    x: 16,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "warped_wart_block",
    x: 32,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "water_flow",
    x: 288,
    y: 512,
    width: 32,
    height: 1024
  },
  {
    name: "water_overlay",
    x: 48,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "water_still",
    x: 272,
    y: 512,
    width: 16,
    height: 512
  },
  {
    name: "weathered_copper",
    x: 64,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "weathered_cut_copper",
    x: 80,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "weeping_vines",
    x: 96,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "weeping_vines_plant",
    x: 112,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wet_sponge",
    x: 128,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage0",
    x: 144,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage1",
    x: 160,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage2",
    x: 176,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage3",
    x: 192,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage4",
    x: 208,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage5",
    x: 224,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage6",
    x: 240,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage7",
    x: 256,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_candle",
    x: 272,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_candle_lit",
    x: 288,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_concrete",
    x: 304,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_concrete_powder",
    x: 320,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_glazed_terracotta",
    x: 336,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_shulker_box",
    x: 352,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_stained_glass",
    x: 368,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_stained_glass_pane_top",
    x: 384,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_terracotta",
    x: 400,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_tulip",
    x: 416,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "white_wool",
    x: 432,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "wither_rose",
    x: 448,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "yellow_candle",
    x: 464,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "yellow_candle_lit",
    x: 480,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "yellow_concrete",
    x: 496,
    y: 368,
    width: 16,
    height: 16
  },
  {
    name: "yellow_concrete_powder",
    x: 0,
    y: 384,
    width: 16,
    height: 16
  },
  {
    name: "yellow_glazed_terracotta",
    x: 16,
    y: 384,
    width: 16,
    height: 16
  },
  {
    name: "yellow_shulker_box",
    x: 32,
    y: 384,
    width: 16,
    height: 16
  },
  {
    name: "yellow_stained_glass",
    x: 48,
    y: 384,
    width: 16,
    height: 16
  },
  {
    name: "yellow_stained_glass_pane_top",
    x: 64,
    y: 384,
    width: 16,
    height: 16
  },
  {
    name: "yellow_terracotta",
    x: 80,
    y: 384,
    width: 16,
    height: 16
  },
  {
    name: "yellow_wool",
    x: 96,
    y: 384,
    width: 16,
    height: 16
  }
];

var data = [
  texture,
  tiles
];

exports.texture = texture;
exports.tiles = tiles;
exports.data = data;
/* texture Not a pure module */
