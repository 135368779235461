// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");

function makeFrameLabel(frame) {
  var name = frame.name.replace(/_/g, " ");
  if (frame.frameCount <= 1) {
    return name;
  }
  var sequence = String(frame.frameIndex + 1 | 0);
  return name + (" (Frame " + sequence + ")");
}

function textureToFrames(texture, frameSize) {
  var xMod = Caml_int32.mod_(texture.width, frameSize);
  var yMod = Caml_int32.mod_(texture.height, frameSize);
  if (xMod > 0 || yMod > 0) {
    return ;
  }
  var rows = Caml_int32.div(texture.height, frameSize);
  var cols = Caml_int32.div(texture.width, frameSize);
  var frames = [];
  for(var col = 0; col < cols; ++col){
    for(var row = 0; row < rows; ++row){
      var frameIndex = Math.imul(col, rows) + row | 0;
      var id = texture.name + "-" + String(frameIndex);
      var frame_name = texture.name;
      var frame_rectangle = [
        texture.x + Math.imul(col, frameSize) | 0,
        texture.y + Math.imul(row, frameSize) | 0,
        frameSize,
        frameSize
      ];
      var frame_frameCount = Math.imul(rows, cols);
      var frame = {
        id: id,
        name: frame_name,
        rectangle: frame_rectangle,
        frameIndex: frameIndex,
        frameCount: frame_frameCount
      };
      frames.push(frame);
    }
  }
  return frames;
}

function texturesToFrames(textures, frameSize) {
  return Belt_Array.reduce(textures, [], (function (acc, texture) {
                var frames = textureToFrames(texture, frameSize);
                if (frames !== undefined) {
                  return Belt_Array.concat(acc, frames);
                } else {
                  return acc;
                }
              }));
}

var tilesToFrames = texturesToFrames;

exports.makeFrameLabel = makeFrameLabel;
exports.textureToFrames = textureToFrames;
exports.texturesToFrames = texturesToFrames;
exports.tilesToFrames = tilesToFrames;
/* No side effect */
