// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var texture_url = require("./Texture_minecraft_1_7_10_blocks.png");

var texture = {
  id: "minecraft-1.7.10-blocks",
  url: texture_url,
  standardWidth: 512,
  standardHeight: 1184
};

var tiles = [
  {
    name: "anvil_base",
    x: 0,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "anvil_top_damaged_0",
    x: 16,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "anvil_top_damaged_1",
    x: 32,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "anvil_top_damaged_2",
    x: 48,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beacon",
    x: 64,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed_feet_end",
    x: 80,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed_feet_side",
    x: 96,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed_feet_top",
    x: 112,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed_head_end",
    x: 128,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed_head_side",
    x: 144,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed_head_top",
    x: 160,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bedrock",
    x: 176,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bookshelf",
    x: 192,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brewing_stand",
    x: 208,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brewing_stand_base",
    x: 224,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brick",
    x: 240,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cactus_bottom",
    x: 256,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cactus_side",
    x: 272,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cactus_top",
    x: 288,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cake_bottom",
    x: 304,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cake_inner",
    x: 320,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cake_side",
    x: 336,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cake_top",
    x: 352,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage_0",
    x: 368,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage_1",
    x: 384,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage_2",
    x: 400,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrots_stage_3",
    x: 416,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_bottom",
    x: 432,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_inner",
    x: 448,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_side",
    x: 464,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cauldron_top",
    x: 480,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "clay",
    x: 496,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "coal_block",
    x: 0,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "coal_ore",
    x: 16,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "cobblestone",
    x: 32,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "cobblestone_mossy",
    x: 48,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_stage_0",
    x: 64,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_stage_1",
    x: 80,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_stage_2",
    x: 96,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "command_block",
    x: 112,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "comparator_off",
    x: 128,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "comparator_on",
    x: 144,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "crafting_table_front",
    x: 160,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "crafting_table_side",
    x: 176,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "crafting_table_top",
    x: 192,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "daylight_detector_side",
    x: 208,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "daylight_detector_top",
    x: 224,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "deadbush",
    x: 240,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_0",
    x: 256,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_1",
    x: 272,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_2",
    x: 288,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_3",
    x: 304,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_4",
    x: 320,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_5",
    x: 336,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_6",
    x: 352,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_7",
    x: 368,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_8",
    x: 384,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "destroy_stage_9",
    x: 400,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_block",
    x: 416,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_ore",
    x: 432,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dirt",
    x: 448,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dirt_podzol_side",
    x: 464,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dirt_podzol_top",
    x: 480,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dispenser_front_horizontal",
    x: 496,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dispenser_front_vertical",
    x: 0,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "door_iron_lower",
    x: 16,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "door_iron_upper",
    x: 32,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "door_wood_lower",
    x: 48,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "door_wood_upper",
    x: 64,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_fern_bottom",
    x: 80,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_fern_top",
    x: 96,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_grass_bottom",
    x: 112,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_grass_top",
    x: 128,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_paeonia_bottom",
    x: 144,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_paeonia_top",
    x: 160,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_rose_bottom",
    x: 176,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_rose_top",
    x: 192,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_sunflower_back",
    x: 208,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_sunflower_bottom",
    x: 224,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_sunflower_front",
    x: 240,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_sunflower_top",
    x: 256,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_syringa_bottom",
    x: 272,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "double_plant_syringa_top",
    x: 288,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dragon_egg",
    x: 304,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dropper_front_horizontal",
    x: 320,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dropper_front_vertical",
    x: 336,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "emerald_block",
    x: 352,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "emerald_ore",
    x: 368,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "enchanting_table_bottom",
    x: 384,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "enchanting_table_side",
    x: 400,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "enchanting_table_top",
    x: 416,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "end_stone",
    x: 432,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "endframe_eye",
    x: 448,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "endframe_side",
    x: 464,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "endframe_top",
    x: 480,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "farmland_dry",
    x: 496,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "farmland_wet",
    x: 0,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "fern",
    x: 16,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "fire_layer_0",
    x: 368,
    y: 160,
    width: 16,
    height: 512
  },
  {
    name: "fire_layer_1",
    x: 384,
    y: 160,
    width: 16,
    height: 512
  },
  {
    name: "flower_allium",
    x: 32,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_blue_orchid",
    x: 48,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_dandelion",
    x: 64,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_houstonia",
    x: 80,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_oxeye_daisy",
    x: 96,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_paeonia",
    x: 112,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_pot",
    x: 128,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_rose",
    x: 144,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_tulip_orange",
    x: 160,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_tulip_pink",
    x: 176,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_tulip_red",
    x: 192,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "flower_tulip_white",
    x: 208,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "furnace_front_off",
    x: 224,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "furnace_front_on",
    x: 240,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "furnace_side",
    x: 256,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "furnace_top",
    x: 272,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass",
    x: 288,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_black",
    x: 304,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_blue",
    x: 320,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_brown",
    x: 336,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_cyan",
    x: 352,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_gray",
    x: 368,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_green",
    x: 384,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_light_blue",
    x: 400,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_lime",
    x: 416,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_magenta",
    x: 432,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_orange",
    x: 448,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top",
    x: 464,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_black",
    x: 480,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_blue",
    x: 496,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_brown",
    x: 0,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_cyan",
    x: 16,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_gray",
    x: 32,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_green",
    x: 48,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_light_blue",
    x: 64,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_lime",
    x: 80,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_magenta",
    x: 96,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_orange",
    x: 112,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_pink",
    x: 128,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_purple",
    x: 144,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_red",
    x: 160,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_silver",
    x: 176,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_white",
    x: 192,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pane_top_yellow",
    x: 208,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_pink",
    x: 224,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_purple",
    x: 240,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_red",
    x: 256,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_silver",
    x: 272,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_white",
    x: 288,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glass_yellow",
    x: 304,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "glowstone",
    x: 320,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "gold_block",
    x: 336,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "gold_ore",
    x: 352,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "grass_side",
    x: 368,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "grass_side_overlay",
    x: 384,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "grass_side_snowed",
    x: 400,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "grass_top",
    x: 416,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "gravel",
    x: 432,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay",
    x: 448,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_black",
    x: 464,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_blue",
    x: 480,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_brown",
    x: 496,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_cyan",
    x: 0,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_gray",
    x: 16,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_green",
    x: 32,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_light_blue",
    x: 48,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_lime",
    x: 64,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_magenta",
    x: 80,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_orange",
    x: 96,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_pink",
    x: 112,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_purple",
    x: 128,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_red",
    x: 144,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_silver",
    x: 160,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_white",
    x: 176,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hardened_clay_stained_yellow",
    x: 192,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hay_block_side",
    x: 208,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hay_block_top",
    x: 224,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hopper_inside",
    x: 240,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hopper_outside",
    x: 256,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "hopper_top",
    x: 272,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "ice",
    x: 288,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "ice_packed",
    x: 304,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "iron_bars",
    x: 320,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "iron_block",
    x: 336,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "iron_ore",
    x: 352,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "itemframe_background",
    x: 368,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "jukebox_side",
    x: 384,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "jukebox_top",
    x: 400,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "ladder",
    x: 416,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "lapis_block",
    x: 432,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "lapis_ore",
    x: 448,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "lava_flow",
    x: 400,
    y: 160,
    width: 32,
    height: 512
  },
  {
    name: "lava_still",
    x: 352,
    y: 160,
    width: 16,
    height: 320
  },
  {
    name: "leaves_acacia",
    x: 464,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "leaves_acacia_opaque",
    x: 480,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "leaves_big_oak",
    x: 496,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "leaves_big_oak_opaque",
    x: 0,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_birch",
    x: 16,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_birch_opaque",
    x: 32,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_jungle",
    x: 48,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_jungle_opaque",
    x: 64,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_oak",
    x: 80,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_oak_opaque",
    x: 96,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_spruce",
    x: 112,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "leaves_spruce_opaque",
    x: 128,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "lever",
    x: 144,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_acacia",
    x: 160,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_acacia_top",
    x: 176,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_big_oak",
    x: 192,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_big_oak_top",
    x: 208,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_birch",
    x: 224,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_birch_top",
    x: 240,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_jungle",
    x: 256,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_jungle_top",
    x: 272,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_oak",
    x: 288,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_oak_top",
    x: 304,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_spruce",
    x: 320,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "log_spruce_top",
    x: 336,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "melon_side",
    x: 352,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "melon_stem_connected",
    x: 368,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "melon_stem_disconnected",
    x: 384,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "melon_top",
    x: 400,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mob_spawner",
    x: 416,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_block_inside",
    x: 432,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_block_skin_brown",
    x: 448,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_block_skin_red",
    x: 464,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_block_skin_stem",
    x: 480,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_brown",
    x: 496,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_red",
    x: 0,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "mycelium_side",
    x: 16,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "mycelium_top",
    x: 32,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "nether_brick",
    x: 48,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_stage_0",
    x: 64,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_stage_1",
    x: 80,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart_stage_2",
    x: 96,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "netherrack",
    x: 112,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "noteblock",
    x: 128,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "obsidian",
    x: 144,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "piston_bottom",
    x: 160,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "piston_inner",
    x: 176,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "piston_side",
    x: 192,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "piston_top_normal",
    x: 208,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "piston_top_sticky",
    x: 224,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "planks_acacia",
    x: 240,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "planks_big_oak",
    x: 256,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "planks_birch",
    x: 272,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "planks_jungle",
    x: 288,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "planks_oak",
    x: 304,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "planks_spruce",
    x: 320,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "portal",
    x: 432,
    y: 160,
    width: 16,
    height: 512
  },
  {
    name: "potatoes_stage_0",
    x: 336,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage_1",
    x: 352,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage_2",
    x: 368,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "potatoes_stage_3",
    x: 384,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_face_off",
    x: 400,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_face_on",
    x: 416,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_side",
    x: 432,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_stem_connected",
    x: 448,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_stem_disconnected",
    x: 464,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_top",
    x: 480,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_bottom",
    x: 496,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_chiseled",
    x: 0,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_chiseled_top",
    x: 16,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_lines",
    x: 32,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_lines_top",
    x: 48,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_side",
    x: 64,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "quartz_block_top",
    x: 80,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "quartz_ore",
    x: 96,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_activator",
    x: 112,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_activator_powered",
    x: 128,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_detector",
    x: 144,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_detector_powered",
    x: 160,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_golden",
    x: 176,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_golden_powered",
    x: 192,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_normal",
    x: 208,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "rail_normal_turned",
    x: 224,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "red_sand",
    x: 240,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_block",
    x: 256,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_cross",
    x: 272,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_cross_overlay",
    x: 288,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_line",
    x: 304,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust_line_overlay",
    x: 320,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_lamp_off",
    x: 336,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_lamp_on",
    x: 352,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_ore",
    x: 368,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_torch_off",
    x: 384,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "redstone_torch_on",
    x: 400,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "reeds",
    x: 416,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "repeater_off",
    x: 432,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "repeater_on",
    x: 448,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "sand",
    x: 464,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_bottom",
    x: 480,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_carved",
    x: 496,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_normal",
    x: 0,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_smooth",
    x: 16,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sandstone_top",
    x: 32,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sapling_acacia",
    x: 48,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sapling_birch",
    x: 64,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sapling_jungle",
    x: 80,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sapling_oak",
    x: 96,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sapling_roofed_oak",
    x: 112,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sapling_spruce",
    x: 128,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "snow",
    x: 144,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "soul_sand",
    x: 160,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sponge",
    x: 176,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stone",
    x: 192,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stone_slab_side",
    x: 208,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stone_slab_top",
    x: 224,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stonebrick",
    x: 240,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stonebrick_carved",
    x: 256,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stonebrick_cracked",
    x: 272,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "stonebrick_mossy",
    x: 288,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "tallgrass",
    x: 304,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "tnt_bottom",
    x: 320,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "tnt_side",
    x: 336,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "tnt_top",
    x: 352,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "torch_on",
    x: 368,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "trapdoor",
    x: 384,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "trip_wire",
    x: 400,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "trip_wire_source",
    x: 416,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "vine",
    x: 432,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "water_flow",
    x: 464,
    y: 160,
    width: 32,
    height: 1024
  },
  {
    name: "water_still",
    x: 448,
    y: 160,
    width: 16,
    height: 512
  },
  {
    name: "waterlily",
    x: 448,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "web",
    x: 464,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_0",
    x: 480,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_1",
    x: 496,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_2",
    x: 0,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_3",
    x: 16,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_4",
    x: 32,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_5",
    x: 48,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_6",
    x: 64,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat_stage_7",
    x: 80,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_black",
    x: 96,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_blue",
    x: 112,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_brown",
    x: 128,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_cyan",
    x: 144,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_gray",
    x: 160,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_green",
    x: 176,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_light_blue",
    x: 192,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_lime",
    x: 208,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_magenta",
    x: 224,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_orange",
    x: 240,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_pink",
    x: 256,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_purple",
    x: 272,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_red",
    x: 288,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_silver",
    x: 304,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_white",
    x: 320,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wool_colored_yellow",
    x: 336,
    y: 160,
    width: 16,
    height: 16
  }
];

var data = [
  texture,
  tiles
];

exports.texture = texture;
exports.tiles = tiles;
exports.data = data;
/* texture Not a pure module */
