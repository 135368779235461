// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-horse";

var name = "Minecraft Horse";

var history = ["11 Jul 2021 NinjolasNJM - Initial script finished."];

var thumbnail = {
  url: require("./thumbnail/v3-thumbnail-256.jpeg")
};

var imageIds = [
  "Foreground-Horse",
  "Foreground-Mule",
  "Folds-Horse",
  "Folds-Mule",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Black Horse",
    url: requireTexture("horse_black"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Brown Horse",
    url: requireTexture("horse_brown"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Chestnut Horse",
    url: requireTexture("horse_chestnut"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Creamy Horse",
    url: requireTexture("horse_creamy"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Dark Brown Horse",
    url: requireTexture("horse_darkbrown"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Gray Horse",
    url: requireTexture("horse_gray"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "White Horse",
    url: requireTexture("horse_white"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Skeleton Horse",
    url: requireTexture("horse_skeleton"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Zombie Horse",
    url: requireTexture("horse_zombie"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Donkey",
    url: requireTexture("donkey"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Mule",
    url: requireTexture("mule"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Black Dots",
    url: requireTexture("horse_markings_blackdots"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "White",
    url: requireTexture("horse_markings_white"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "White Dots",
    url: requireTexture("horse_markings_whitedots"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "White Field",
    url: requireTexture("horse_markings_whitefield"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Leather",
    url: requireTexture("horse_armor_leather"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Gold",
    url: requireTexture("horse_armor_gold"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Iron",
    url: requireTexture("horse_armor_iron"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Diamond",
    url: requireTexture("horse_armor_diamond"),
    standardWidth: 64,
    standardHeight: 64
  }
];

function script(param) {
  Generator.defineTextureInput("Horse", {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Black Horse",
          "Brown Horse",
          "Chestnut Horse",
          "Creamy Horse",
          "Dark Brown Horse",
          "Gray Horse",
          "White Horse",
          "Skeleton Horse",
          "Zombie Horse",
          "Donkey",
          "Mule"
        ]
      });
  Generator.defineTextureInput("Markings", {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Black Dots",
          "White",
          "White Dots",
          "White Field"
        ]
      });
  Generator.defineTextureInput("Armor", {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Leather",
          "Gold",
          "Iron",
          "Diamond"
        ]
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  Generator.defineBooleanInput("Donkey / Mule Model", false);
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var muleModel = Generator.getBooleanInputValue("Donkey / Mule Model");
  var drawHorse = function (texture) {
    Generator.drawTexture(texture, [
          7,
          13,
          6,
          7
        ], [
          76,
          20,
          48,
          56
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          13,
          13,
          6,
          7
        ], [
          76,
          116,
          48,
          56
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          0,
          20,
          7,
          5
        ], [
          20,
          76,
          56,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          7,
          20,
          6,
          5
        ], [
          76,
          76,
          48,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          13,
          20,
          7,
          5
        ], [
          124,
          76,
          56,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          20,
          20,
          6,
          5
        ], [
          180,
          76,
          48,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          25,
          4,
          5
        ], [
          180,
          142,
          32,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          9,
          25,
          4,
          5
        ], [
          180,
          222,
          32,
          40
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          0,
          30,
          5,
          5
        ], [
          140,
          182,
          40,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          30,
          4,
          5
        ], [
          180,
          182,
          32,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          9,
          30,
          5,
          5
        ], [
          212,
          182,
          40,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          14,
          30,
          4,
          5
        ], [
          252,
          182,
          32,
          40
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          7,
          35,
          4,
          7
        ], [
          80,
          232,
          32,
          56
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          11,
          35,
          4,
          7
        ], [
          80,
          384,
          32,
          56
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          0,
          42,
          7,
          12
        ], [
          24,
          288,
          56,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          7,
          42,
          4,
          12
        ], [
          80,
          288,
          32,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          11,
          42,
          7,
          12
        ], [
          112,
          288,
          56,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          18,
          42,
          4,
          12
        ], [
          168,
          288,
          32,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          58,
          36,
          2,
          2
        ], [
          337,
          16,
          16,
          16
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          38,
          2,
          16
        ], [
          353,
          32,
          16,
          128
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          58,
          38,
          2,
          16
        ], [
          369,
          32,
          16,
          128
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          60,
          38,
          2,
          16
        ], [
          321,
          32,
          16,
          128
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          62,
          38,
          2,
          16
        ], [
          337,
          32,
          16,
          128
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          46,
          36,
          3,
          4
        ], [
          256,
          348,
          24,
          32
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture(texture, [
          42,
          40,
          4,
          14
        ], [
          280,
          380,
          32,
          112
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          46,
          40,
          3,
          14
        ], [
          312,
          380,
          24,
          112
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          49,
          40,
          4,
          14
        ], [
          224,
          380,
          32,
          112
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          53,
          40,
          3,
          14
        ], [
          256,
          380,
          24,
          112
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    var horseEars = function (ox, oy) {
      Generator.drawTexture(texture, [
            20,
            16,
            2,
            1
          ], [
            ox + 8 | 0,
            oy + 40 | 0,
            16,
            8
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            22,
            16,
            2,
            1
          ], [
            ox + 8 | 0,
            oy + 64 | 0,
            16,
            8
          ], "Vertical", undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            19,
            17,
            1,
            2
          ], [
            ox + 0 | 0,
            oy + 48 | 0,
            8,
            16
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            20,
            17,
            2,
            2
          ], [
            ox + 8 | 0,
            oy + 48 | 0,
            16,
            16
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            22,
            17,
            1,
            2
          ], [
            ox + 24 | 0,
            oy + 48 | 0,
            8,
            16
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            23,
            17,
            2,
            2
          ], [
            ox + 32 | 0,
            oy + 48 | 0,
            16,
            16
          ], undefined, undefined, undefined, undefined, undefined, undefined);
    };
    var muleEars = function (ox, oy) {
      Generator.drawTexture(texture, [
            1,
            12,
            2,
            1
          ], [
            ox + 8 | 0,
            oy + 0 | 0,
            16,
            8
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            3,
            12,
            2,
            1
          ], [
            ox + 8 | 0,
            oy + 64 | 0,
            16,
            8
          ], "Vertical", undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            0,
            13,
            1,
            7
          ], [
            ox + 0 | 0,
            oy + 8 | 0,
            8,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            1,
            13,
            2,
            7
          ], [
            ox + 8 | 0,
            oy + 8 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            3,
            13,
            1,
            7
          ], [
            ox + 24 | 0,
            oy + 8 | 0,
            8,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture(texture, [
            4,
            13,
            2,
            7
          ], [
            ox + 32 | 0,
            oy + 8 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
    };
    if (muleModel) {
      muleEars(332, 249);
    } else {
      horseEars(332, 249);
    }
    if (muleModel) {
      muleEars(256, 249);
    } else {
      horseEars(256, 249);
    }
    Generator.drawTexture(texture, [
          22,
          32,
          10,
          22
        ], [
          120,
          532,
          80,
          176
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture(texture, [
          32,
          32,
          10,
          22
        ], [
          280,
          532,
          80,
          176
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          0,
          54,
          22,
          10
        ], [
          152,
          580,
          176,
          80
        ], undefined, undefined, undefined, -90.0, undefined, undefined);
    Generator.drawTexture(texture, [
          22,
          54,
          10,
          10
        ], [
          120,
          452,
          80,
          80
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture(texture, [
          32,
          54,
          22,
          10
        ], [
          -8,
          580,
          176,
          80
        ], undefined, undefined, undefined, 90.0, undefined, undefined);
    Generator.drawTexture(texture, [
          54,
          54,
          10,
          10
        ], [
          120,
          708,
          80,
          80
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          21,
          4,
          4
        ], [
          477,
          40,
          32,
          32
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          21,
          4,
          4
        ], [
          477,
          160,
          32,
          32
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture(texture, [
          48,
          25,
          4,
          11
        ], [
          509,
          72,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          25,
          4,
          11
        ], [
          477,
          72,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          25,
          4,
          11
        ], [
          445,
          72,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          60,
          25,
          4,
          11
        ], [
          413,
          72,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          21,
          4,
          4
        ], [
          445,
          238,
          32,
          32
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          21,
          4,
          4
        ], [
          445,
          358,
          32,
          32
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          48,
          25,
          4,
          11
        ], [
          413,
          270,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          25,
          4,
          11
        ], [
          445,
          270,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          25,
          4,
          11
        ], [
          477,
          270,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          60,
          25,
          4,
          11
        ], [
          509,
          270,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          21,
          4,
          4
        ], [
          477,
          436,
          32,
          32
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          21,
          4,
          4
        ], [
          477,
          556,
          32,
          32
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture(texture, [
          48,
          25,
          4,
          11
        ], [
          509,
          468,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          25,
          4,
          11
        ], [
          477,
          468,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          25,
          4,
          11
        ], [
          445,
          468,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          60,
          25,
          4,
          11
        ], [
          413,
          468,
          32,
          88
        ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          21,
          4,
          4
        ], [
          445,
          634,
          32,
          32
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          21,
          4,
          4
        ], [
          445,
          754,
          32,
          32
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          48,
          25,
          4,
          11
        ], [
          413,
          666,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          52,
          25,
          4,
          11
        ], [
          445,
          666,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          56,
          25,
          4,
          11
        ], [
          477,
          666,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          60,
          25,
          4,
          11
        ], [
          509,
          666,
          32,
          88
        ], undefined, undefined, undefined, undefined, undefined, undefined);
  };
  drawHorse("Horse");
  drawHorse("Markings");
  drawHorse("Armor");
  if (muleModel) {
    Generator.drawImage("Foreground-Mule", [
          0,
          0
        ]);
  } else {
    Generator.drawImage("Foreground-Horse", [
          0,
          0
        ]);
  }
  if (showFolds) {
    if (muleModel) {
      Generator.drawImage("Folds-Mule", [
            0,
            0
          ]);
    } else {
      Generator.drawImage("Folds-Horse", [
            0,
            0
          ]);
    }
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
