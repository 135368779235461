// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Belt_SortArray = require("rescript/lib/js/belt_SortArray.js");
var Generator_Icon = require("../../builder/views/Generator_Icon.bs.js");
var Generator_Texture = require("../../builder/modules/Generator_Texture.bs.js");
var MinecraftBlock_Face = require("./MinecraftBlock_Face.bs.js");
var MinecraftBlock_Tints = require("./MinecraftBlock_Tints.bs.js");
var MinecraftBlock_Textures = require("./MinecraftBlock_Textures.bs.js");

function makeOptions(tints) {
  return Belt_Array.concatMany(Belt_Array.mapWithIndex(tints, (function (index1, param) {
                    var color = param.color;
                    return Belt_Array.mapWithIndex(param.biomes, (function (index2, biome) {
                                  var key = index1.toString() + "-" + index2.toString();
                                  return React.createElement("option", {
                                              key: key,
                                              value: color
                                            }, biome);
                                }));
                  })));
}

function isValidTint(tint) {
  return Generator_Texture.hexToRGB(tint) !== undefined;
}

function normalizeTint(tint) {
  var tint$1 = tint.trim();
  if (tint$1.length === 0) {
    return ;
  }
  var tint$2 = tint$1.startsWith("#") ? tint$1 : "#" + tint$1;
  if (isValidTint(tint$2)) {
    return tint$2;
  }
  
}

function MinecraftBlock_TexturePicker$TintSelector(Props) {
  var onChange = Props.onChange;
  var match = React.useState(function () {
        return /* NoTint */0;
      });
  var setSelectedTint = match[1];
  var selectedTint = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setCustomTint = match$1[1];
  var onSelectChange = function (e) {
    var target = e.target;
    var value = target.value;
    var tint;
    if (value !== undefined) {
      var value$1 = Caml_option.valFromOption(value);
      switch (value$1) {
        case "Custom" :
            tint = /* CustomTint */1;
            break;
        case "None" :
            tint = /* NoTint */0;
            break;
        default:
          tint = /* HexTint */{
            _0: value$1
          };
      }
    } else {
      tint = /* NoTint */0;
    }
    Curry._1(setSelectedTint, (function (param) {
            return tint;
          }));
    if (typeof tint === "number") {
      return Curry._1(onChange, undefined);
    } else {
      return Curry._1(onChange, tint._0);
    }
  };
  var onInputChange = function (e) {
    var target = e.target;
    var tint = target.value;
    var tint$1 = tint !== undefined ? normalizeTint(Caml_option.valFromOption(tint)) : undefined;
    Curry._1(setCustomTint, (function (param) {
            return tint$1;
          }));
    if (tint$1 !== undefined) {
      return Curry._1(onChange, tint$1);
    }
    
  };
  var color = typeof selectedTint === "number" ? (
      selectedTint !== 0 ? match$1[0] : undefined
    ) : selectedTint._0;
  return React.createElement("div", {
              className: "flex"
            }, React.createElement("select", {
                  className: "border border-gray-300 rounded text-gray-600 h-8 pl-5 pr-10 mr-4 bg-white hover:border-gray-400 focus:outline-none appearance-none",
                  placeholder: "Tint",
                  onChange: onSelectChange
                }, React.createElement("option", {
                      value: "None"
                    }, "No tint"), React.createElement("option", {
                      value: "Custom"
                    }, "Custom tint"), React.createElement("optgroup", {
                      key: "grass",
                      label: "Grass"
                    }, makeOptions(MinecraftBlock_Tints.tints.grass)), React.createElement("optgroup", {
                      key: "foliage",
                      label: "Foliage"
                    }, makeOptions(MinecraftBlock_Tints.tints.foliage)), React.createElement("optgroup", {
                      key: "water",
                      label: "Water"
                    }, makeOptions(MinecraftBlock_Tints.tints.water))), typeof selectedTint === "number" && selectedTint !== 0 ? React.createElement("div", undefined, React.createElement("span", undefined, "#"), React.createElement("input", {
                        className: "border border-gray-300 rounded text-gray-600 h-8 px-5 mr-4 bg-white",
                        onChange: onInputChange
                      })) : null, color !== undefined ? React.createElement("div", undefined, React.createElement("div", {
                        className: "border w-8 h-8",
                        style: {
                          backgroundColor: color
                        }
                      })) : null);
}

var TintSelector = {
  makeOptions: makeOptions,
  isValidTint: isValidTint,
  normalizeTint: normalizeTint,
  make: MinecraftBlock_TexturePicker$TintSelector
};

function px(n) {
  return "" + n.toString() + "px";
}

function deg(n) {
  return "" + n.toString() + "deg";
}

function makeTileStyle(textureDef, frameIndex, size, width, height) {
  var y = Math.imul(Caml_int32.div(frameIndex, MinecraftBlock_Textures.textureSize), -size | 0);
  return Object.assign({}, {
              backgroundImage: "url(" + textureDef.url + ")",
              backgroundPosition: "0px " + px(y) + "",
              backgroundRepeat: "no-repeat",
              height: px(height),
              width: px(width),
              backgroundSize: "cover"
            }, {
              imageRendering: "pixelated"
            });
}

function makePreviewTileStyle(textureDef, textureFrame, rotation) {
  var base = {
    backgroundColor: "white",
    height: px(128),
    width: px(128),
    transform: "rotate(" + deg(Math.imul(rotation, 90)) + ")"
  };
  if (textureFrame !== undefined) {
    return Object.assign({}, base, makeTileStyle(textureDef, textureFrame.frameIndex, 128, 128, 128));
  } else {
    return base;
  }
}

function makeGridTileStyle(textureDef, frameIndex) {
  var width = 40;
  return Object.assign({}, makeTileStyle(textureDef, frameIndex, 32, width, width), {
              borderWidth: px(4),
              display: "inline-block",
              marginRight: px(6),
              marginBottom: px(6)
            });
}

function makeGridTileClassName(isSelected) {
  if (isSelected) {
    return "border-blue-600";
  } else {
    return "border-gray-300 hover:border-gray-400";
  }
}

function MinecraftBlock_TexturePicker$GridTile(Props) {
  var textureDef = Props.textureDef;
  var textureFrame = Props.textureFrame;
  var isSelected = Props.isSelected;
  var onClick = Props.onClick;
  var textureId = textureFrame.textureId;
  var className = makeGridTileClassName(isSelected);
  var style = makeGridTileStyle(textureDef, textureFrame.frameIndex);
  var title = MinecraftBlock_Textures.findTextureFrameCount(textureFrame.versionId, textureId) > 1 ? "" + textureId + " (Frame " + textureFrame.frame.toString() + ")" : textureId;
  return React.createElement("button", {
              className: className,
              style: style,
              title: title,
              onClick: (function (param) {
                  Curry._1(onClick, undefined);
                })
            }, React.createElement("span", {
                  dangerouslySetInnerHTML: {
                    __html: "&nbsp;"
                  }
                }));
}

var GridTile = {
  make: MinecraftBlock_TexturePicker$GridTile
};

function MinecraftBlock_TexturePicker$PreviewTile(Props) {
  var textureDef = Props.textureDef;
  var textureFrame = Props.textureFrame;
  var rotation = Props.rotation;
  var style = makePreviewTileStyle(textureDef, textureFrame, rotation);
  return React.createElement("div", {
              style: style
            });
}

var PreviewTile = {
  make: MinecraftBlock_TexturePicker$PreviewTile
};

function getBlend(tint) {
  if (tint !== undefined) {
    return {
            NAME: "MultiplyHex",
            VAL: Caml_option.valFromOption(tint)
          };
  } else {
    return "None";
  }
}

function MinecraftBlock_TexturePicker$GridTileSingle(Props) {
  var textureDef = Props.textureDef;
  var textureSingle = Props.textureSingle;
  var textureIsSelected = Props.textureIsSelected;
  var onClick = Props.onClick;
  var textureFrame = textureSingle.textureFrame;
  var textureId = textureSingle.textureId;
  var isSelected = Curry._2(textureIsSelected, textureId, textureFrame.frame);
  var key = textureId + ":" + textureFrame.frame.toString();
  return React.createElement(MinecraftBlock_TexturePicker$GridTile, {
              textureDef: textureDef,
              textureFrame: textureFrame,
              isSelected: isSelected,
              onClick: (function (param) {
                  Curry._1(onClick, textureFrame);
                }),
              key: key
            });
}

var GridTileSingle = {
  make: MinecraftBlock_TexturePicker$GridTileSingle
};

function MinecraftBlock_TexturePicker$GridTileMultiple(Props) {
  var textureDef = Props.textureDef;
  var textureMultiple = Props.textureMultiple;
  var textureIsSelected = Props.textureIsSelected;
  var onClick = Props.onClick;
  var match = React.useState(function () {
        return true;
      });
  var textureFrames = textureMultiple.textureFrames;
  var textureId = textureMultiple.textureId;
  var setIsExpanded = match[1];
  var isExpanded = match[0];
  if (isExpanded) {
    return React.createElement(React.Fragment, undefined, Belt_Array.map(textureFrames, (function (textureFrame) {
                      var isSelected = Curry._2(textureIsSelected, textureId, textureFrame.frame);
                      var key = textureId + ":" + textureFrame.frame.toString();
                      return React.createElement(MinecraftBlock_TexturePicker$GridTile, {
                                  textureDef: textureDef,
                                  textureFrame: textureFrame,
                                  isSelected: isSelected,
                                  onClick: (function (param) {
                                      Curry._1(onClick, textureFrame);
                                    }),
                                  key: key
                                });
                    })));
  }
  var textureFrame = textureFrames[0];
  var isSelected = Curry._2(textureIsSelected, textureId, textureFrame.frame);
  var key = textureId + ":" + textureFrame.frame.toString();
  return React.createElement(React.Fragment, undefined, React.createElement(MinecraftBlock_TexturePicker$GridTile, {
                  textureDef: textureDef,
                  textureFrame: textureFrame,
                  isSelected: isSelected,
                  onClick: (function (param) {
                      Curry._1(onClick, textureFrame);
                    }),
                  key: key
                }), React.createElement("button", {
                  className: "border border-transparent hover:bg-gray-200 inline-flex items-center justify-center",
                  style: {
                    borderWidth: "4px",
                    height: "40px",
                    marginRight: "6px",
                    marginBottom: "6px",
                    width: "40px"
                  },
                  onClick: (function (param) {
                      Curry._1(setIsExpanded, (function (param) {
                              return !isExpanded;
                            }));
                    })
                }, React.createElement(Generator_Icon.ChevronDoubleRight.make, {
                      size: "Small",
                      color: "Gray500"
                    })));
}

var GridTileMultiple = {
  make: MinecraftBlock_TexturePicker$GridTileMultiple
};

function MinecraftBlock_TexturePicker(Props) {
  var versionId = Props.versionId;
  var onChange = Props.onChange;
  var match = React.useState(function () {
        
      });
  var setSelectedTextureFrame = match[1];
  var selectedTextureFrame = match[0];
  var match$1 = React.useState(function () {
        return 0;
      });
  var setRotation = match$1[1];
  var rotation = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setSearch = match$2[1];
  var search = match$2[0];
  var match$3 = React.useState(function () {
        
      });
  var setTint = match$3[1];
  var tint = match$3[0];
  var textureFrames = MinecraftBlock_Textures.findTextureFrames(versionId);
  var textureDef = MinecraftBlock_Textures.findTextureDef(versionId);
  var textureIsSelected = function (textureId, frame) {
    if (selectedTextureFrame !== undefined && textureId === selectedTextureFrame.textureId) {
      return frame === selectedTextureFrame.frame;
    } else {
      return false;
    }
  };
  if (textureDef === undefined) {
    return null;
  }
  if (textureFrames === undefined) {
    return null;
  }
  var onSearchChange = function (e) {
    var target = e.target;
    var value = target.value;
    var value$1;
    if (value !== undefined) {
      var value$2 = Caml_option.valFromOption(value);
      value$1 = value$2.length === 0 ? undefined : Caml_option.some(value$2);
    } else {
      value$1 = undefined;
    }
    Curry._1(setSearch, (function (param) {
            return value$1;
          }));
  };
  var textureIds = Belt_SortArray.stableSortBy(Belt_Array.reduce(textureFrames, [], (function (acc, frame) {
              var found = Belt_Array.some(acc, (function (textureId) {
                      return textureId === frame.textureId;
                    }));
              if (found) {
                return acc;
              } else {
                return Belt_Array.concat(acc, [frame.textureId]);
              }
            })), (function (textureId1, textureId2) {
          return textureId1.localeCompare(textureId2) | 0;
        }));
  var groups = Belt_Array.reduce(textureIds, [], (function (acc, textureId) {
          var frames = Belt_Array.keep(textureFrames, (function (frame) {
                  return frame.textureId === textureId;
                }));
          var frameCount = frames.length;
          if (frameCount === 0) {
            return acc;
          }
          if (frameCount === 1) {
            var frame = frames[0];
            var group = {
              TAG: /* TextureSingle */0,
              _0: {
                textureId: textureId,
                textureFrame: frame
              }
            };
            return Belt_Array.concat(acc, [group]);
          }
          var group$1 = {
            TAG: /* TextureMultiple */1,
            _0: {
              textureId: textureId,
              textureFrames: frames
            }
          };
          return Belt_Array.concat(acc, [group$1]);
        }));
  var groups$1 = search !== undefined ? Belt_Array.keep(groups, (function (group) {
            return group._0.textureId.indexOf(search) >= 0;
          })) : groups;
  var onRotate = function (param) {
    var rotation$1 = rotation + 1 | 0;
    var rotation$2 = rotation$1 > 3 ? 0 : rotation$1;
    Curry._1(setRotation, (function (param) {
            return rotation$2;
          }));
    if (selectedTextureFrame === undefined) {
      return ;
    }
    var faceTexture_versionId = selectedTextureFrame.versionId;
    var faceTexture_textureId = selectedTextureFrame.textureId;
    var faceTexture_frame = selectedTextureFrame.frame;
    var faceTexture_blend = getBlend(tint);
    var faceTexture = {
      versionId: faceTexture_versionId,
      textureId: faceTexture_textureId,
      frame: faceTexture_frame,
      rot: rotation$2,
      blend: faceTexture_blend
    };
    Curry._1(onChange, MinecraftBlock_Face.encodeFaceTexture(faceTexture));
  };
  var onTintChange = function (tint) {
    Curry._1(setTint, (function (param) {
            return tint;
          }));
    if (selectedTextureFrame === undefined) {
      return ;
    }
    var faceTexture_versionId = selectedTextureFrame.versionId;
    var faceTexture_textureId = selectedTextureFrame.textureId;
    var faceTexture_frame = selectedTextureFrame.frame;
    var faceTexture_blend = getBlend(tint);
    var faceTexture = {
      versionId: faceTexture_versionId,
      textureId: faceTexture_textureId,
      frame: faceTexture_frame,
      rot: rotation,
      blend: faceTexture_blend
    };
    Curry._1(onChange, MinecraftBlock_Face.encodeFaceTexture(faceTexture));
  };
  var onClickGridTile = function (textureFrame) {
    Curry._1(setSelectedTextureFrame, (function (param) {
            return textureFrame;
          }));
    Curry._1(setRotation, (function (param) {
            return 0;
          }));
    var faceTexture_versionId = textureFrame.versionId;
    var faceTexture_textureId = textureFrame.textureId;
    var faceTexture_frame = textureFrame.frame;
    var faceTexture_blend = getBlend(tint);
    var faceTexture = {
      versionId: faceTexture_versionId,
      textureId: faceTexture_textureId,
      frame: faceTexture_frame,
      rot: 0,
      blend: faceTexture_blend
    };
    Curry._1(onChange, MinecraftBlock_Face.encodeFaceTexture(faceTexture));
  };
  return React.createElement("div", {
              className: "mb-4"
            }, React.createElement("div", {
                  className: "font-bold"
                }, "Block Texture"), React.createElement("div", undefined, React.createElement("input", {
                      className: "p-2 mb-2 w-full",
                      placeholder: "Search",
                      onChange: onSearchChange
                    })), React.createElement("div", {
                  className: "flex w-full"
                }, React.createElement("div", {
                      className: "w-full"
                    }, React.createElement("div", {
                          className: "h-44 overflow-y-scroll"
                        }, Belt_Array.map(groups$1, (function (group) {
                                if (group.TAG === /* TextureSingle */0) {
                                  var textureSingle = group._0;
                                  return React.createElement(MinecraftBlock_TexturePicker$GridTileSingle, {
                                              textureDef: textureDef,
                                              textureSingle: textureSingle,
                                              textureIsSelected: textureIsSelected,
                                              onClick: onClickGridTile,
                                              key: textureSingle.textureId
                                            });
                                }
                                var textureMultiple = group._0;
                                return React.createElement(MinecraftBlock_TexturePicker$GridTileMultiple, {
                                            textureDef: textureDef,
                                            textureMultiple: textureMultiple,
                                            textureIsSelected: textureIsSelected,
                                            onClick: onClickGridTile,
                                            key: textureMultiple.textureId
                                          });
                              })))), React.createElement("div", {
                      className: "flex flex-col w-40 items-center px-8"
                    }, React.createElement(MinecraftBlock_TexturePicker$PreviewTile, {
                          textureDef: textureDef,
                          textureFrame: selectedTextureFrame,
                          rotation: rotation
                        }), React.createElement("div", undefined, React.createElement("button", {
                              className: "bg-blue-500 rounded text-white my-4 py-1 px-4 text-center",
                              onClick: onRotate
                            }, "Rotate")))), React.createElement("div", {
                  className: "mb-2"
                }, React.createElement(MinecraftBlock_TexturePicker$TintSelector, {
                      onChange: onTintChange
                    })));
}

var Builder;

var Icon;

var Textures;

var Face;

var Tints;

var make = MinecraftBlock_TexturePicker;

exports.Builder = Builder;
exports.Icon = Icon;
exports.Textures = Textures;
exports.Face = Face;
exports.Tints = Tints;
exports.TintSelector = TintSelector;
exports.px = px;
exports.deg = deg;
exports.makeTileStyle = makeTileStyle;
exports.makePreviewTileStyle = makePreviewTileStyle;
exports.makeGridTileStyle = makeGridTileStyle;
exports.makeGridTileClassName = makeGridTileClassName;
exports.GridTile = GridTile;
exports.PreviewTile = PreviewTile;
exports.getBlend = getBlend;
exports.GridTileSingle = GridTileSingle;
exports.GridTileMultiple = GridTileMultiple;
exports.make = make;
/* react Not a pure module */
