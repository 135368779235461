// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "minecraft-allay-character";

var name = "Minecraft Allay Character";

var history = ["1 May 2022 PaperDoggy - Initial script developed."];

var video = {
  url: "https://www.youtube.com/embed/vG-mXWu0OlA?rel=0"
};

var thumbnail = {
  url: require("./thumbnail/thumbnail-256.jpeg")
};

var images = [{
    id: "Overlay",
    url: require("./images/OverlayAllay.png")
  }];

var textures = [
  {
    id: "Skin1",
    url: require("./textures/Skin1.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Skin2",
    url: require("./textures/Skin2.png"),
    standardWidth: 64,
    standardHeight: 64
  }
];

var steve = TextureMap.MinecraftCharacter.steve;

var alex = TextureMap.MinecraftCharacter.alex;

function drawHead(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.head.front, [
        ox,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.right, [
        ox - 51 | 0,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.left, [
        ox + 51 | 0,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.back, [
        ox + 102 | 0,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.top, [
        ox,
        oy - 51 | 0,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.bottom, [
        ox,
        oy + 51 | 0,
        50,
        50
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.front, [
        ox,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.right, [
        ox - 51 | 0,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.left, [
        ox + 51 | 0,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.back, [
        ox + 102 | 0,
        oy,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.top, [
        ox,
        oy - 51 | 0,
        50,
        50
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.bottom, [
        ox,
        oy + 51 | 0,
        50,
        50
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
}

function drawBody(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.body.front, [
        ox,
        oy,
        30,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.right, [
        ox - 21 | 0,
        oy,
        20,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.left, [
        ox + 31 | 0,
        oy,
        20,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.back, [
        (ox + 31 | 0) + 21 | 0,
        oy,
        30,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.top, [
        ox,
        oy - 21 | 0,
        30,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.front, [
        ((ox - 21 | 0) - 21 | 0) - 31 | 0,
        oy,
        30,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.right, [
        (ox - 21 | 0) - 21 | 0,
        oy,
        20,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.left, [
        (((ox - 21 | 0) - 21 | 0) - 31 | 0) - 21 | 0,
        oy,
        20,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.back, [
        ((((ox - 21 | 0) - 21 | 0) - 31 | 0) - 21 | 0) - 31 | 0,
        oy,
        30,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.top, [
        ((ox - 21 | 0) - 21 | 0) - 31 | 0,
        oy - 21 | 0,
        30,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.front, [
        ox,
        oy,
        30,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.right, [
        ox - 21 | 0,
        oy,
        20,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.left, [
        ox + 31 | 0,
        oy,
        20,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.back, [
        (ox + 31 | 0) + 21 | 0,
        oy,
        30,
        30
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.top, [
        ox,
        oy - 21 | 0,
        30,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.front, [
        ((ox - 21 | 0) - 21 | 0) - 31 | 0,
        oy,
        30,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.right, [
        (ox - 21 | 0) - 21 | 0,
        oy,
        20,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.left, [
        (((ox - 21 | 0) - 21 | 0) - 31 | 0) - 21 | 0,
        oy,
        20,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.back, [
        ((((ox - 21 | 0) - 21 | 0) - 31 | 0) - 21 | 0) - 31 | 0,
        oy,
        30,
        30
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.top, [
        ((ox - 21 | 0) - 21 | 0) - 31 | 0,
        oy - 21 | 0,
        30,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawRightArm(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.rightArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.back, [
        (ox - 11 | 0) - 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.back, [
        (ox - 11 | 0) - 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawLeftArm(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.leftArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.back, [
        (ox + 11 | 0) + 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.back, [
        (ox + 11 | 0) + 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawRightArmAlex(ox, oy, texture) {
  Generator.drawTexture(texture, alex.base.rightArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.back, [
        (ox - 11 | 0) - 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.back, [
        (ox - 11 | 0) - 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawLeftArmAlex(ox, oy, texture) {
  Generator.drawTexture(texture, alex.base.leftArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.back, [
        (ox + 11 | 0) + 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.front, [
        ox,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.right, [
        ox - 21 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.left, [
        ox + 11 | 0,
        oy,
        20,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.back, [
        (ox + 11 | 0) + 21 | 0,
        oy,
        10,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.top, [
        ox,
        oy - 21 | 0,
        10,
        20
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.bottom, [
        ox,
        oy + 41 | 0,
        10,
        20
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawLegs(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.body.front, [
        ox,
        oy,
        30,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.right, [
        ox - 21 | 0,
        oy,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.left, [
        ox + 31 | 0,
        oy,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.back, [
        (ox + 31 | 0) + 21 | 0,
        oy,
        30,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.front, [
        ((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        30,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.right, [
        (((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.left, [
        (((ox + 31 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.back, [
        ((ox + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        30,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.front, [
        ox,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.right, [
        ox - 21 | 0,
        oy + 25 | 0,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.back, [
        ((ox + 31 | 0) + 21 | 0) + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.front, [
        ox + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.left, [
        ox + 31 | 0,
        oy + 25 | 0,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.back, [
        (ox + 31 | 0) + 21 | 0,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.front, [
        (((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 21 | 0) + 31 | 0) + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.right, [
        (((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy + 25 | 0,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.back, [
        ((ox + 31 | 0) + 21 | 0) + 31 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.front, [
        ((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.left, [
        (((ox + 31 | 0) + 21 | 0) + 31 | 0) + 31 | 0,
        oy + 25 | 0,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.back, [
        (((ox + 31 | 0) + 21 | 0) + 31 | 0) + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.front, [
        ox,
        oy,
        30,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.right, [
        ox - 21 | 0,
        oy,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.left, [
        ox + 31 | 0,
        oy,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.back, [
        (ox + 31 | 0) + 21 | 0,
        oy,
        30,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.front, [
        ((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        30,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.right, [
        (((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.left, [
        (((ox + 31 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.back, [
        ((ox + 31 | 0) + 21 | 0) + 31 | 0,
        oy,
        30,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.front, [
        ox,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.right, [
        ox - 21 | 0,
        oy + 25 | 0,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.back, [
        ((ox + 31 | 0) + 21 | 0) + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.front, [
        ox + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.left, [
        ox + 31 | 0,
        oy + 25 | 0,
        20,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.back, [
        (ox + 31 | 0) + 21 | 0,
        oy + 25 | 0,
        15,
        25
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.front, [
        (((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 21 | 0) + 31 | 0) + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.right, [
        (((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy + 25 | 0,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.back, [
        ((ox + 31 | 0) + 21 | 0) + 31 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.front, [
        ((((ox + 31 | 0) + 21 | 0) + 31 | 0) + 21 | 0) + 31 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.left, [
        (((ox + 31 | 0) + 21 | 0) + 31 | 0) + 31 | 0,
        oy + 25 | 0,
        20,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.back, [
        (((ox + 31 | 0) + 21 | 0) + 31 | 0) + 15 | 0,
        oy + 25 | 0,
        15,
        25
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

var Drawing = {
  drawHead: drawHead,
  drawBody: drawBody,
  drawRightArm: drawRightArm,
  drawLeftArm: drawLeftArm,
  drawRightArmAlex: drawRightArmAlex,
  drawLeftArmAlex: drawLeftArmAlex,
  drawLegs: drawLegs
};

function script(param) {
  Generator.defineSelectInput("Character 1 skin model type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin1", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  var alexModel1 = Generator.getSelectInputValue("Character 1 skin model type") === "Alex";
  Generator.defineSelectInput("Character 2 skin model type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin2", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  var alexModel2 = Generator.getSelectInputValue("Character 2 skin model type") === "Alex";
  drawHead(62, 63, "Skin1");
  drawBody(422, 84, "Skin1");
  if (alexModel1) {
    drawRightArmAlex(186, 198, "Skin1");
    drawLeftArmAlex(48, 198, "Skin1");
  } else {
    drawRightArm(186, 198, "Skin1");
    drawLeftArm(48, 198, "Skin1");
  }
  drawLegs(279, 197, "Skin1");
  drawHead(62, 424, "Skin2");
  drawBody(422, 445, "Skin2");
  if (alexModel2) {
    drawRightArmAlex(186, 559, "Skin2");
    drawLeftArmAlex(48, 559, "Skin2");
  } else {
    drawRightArm(186, 559, "Skin2");
    drawLeftArm(48, 559, "Skin2");
  }
  drawLegs(278, 559, "Skin2");
  Generator.drawImage("Overlay", [
        0,
        0
      ]);
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.video = video;
exports.thumbnail = thumbnail;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.Drawing = Drawing;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
