// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var texture_url = require("./minecraft-1.18.1.png");

var texture = {
  id: "minecraft-1.18.1",
  url: texture_url,
  standardWidth: 16,
  standardHeight: 20080
};

var version = {
  id: "minecraft-1.18.1",
  textures: [
    {
      id: "acacia_door_bottom",
      frames: 1,
      index: 0
    },
    {
      id: "acacia_door_top",
      frames: 1,
      index: 16
    },
    {
      id: "acacia_leaves",
      frames: 1,
      index: 32
    },
    {
      id: "acacia_log",
      frames: 1,
      index: 48
    },
    {
      id: "acacia_log_top",
      frames: 1,
      index: 64
    },
    {
      id: "acacia_planks",
      frames: 1,
      index: 80
    },
    {
      id: "acacia_sapling",
      frames: 1,
      index: 96
    },
    {
      id: "acacia_trapdoor",
      frames: 1,
      index: 112
    },
    {
      id: "activator_rail",
      frames: 1,
      index: 128
    },
    {
      id: "activator_rail_on",
      frames: 1,
      index: 144
    },
    {
      id: "allium",
      frames: 1,
      index: 160
    },
    {
      id: "amethyst_block",
      frames: 1,
      index: 176
    },
    {
      id: "amethyst_cluster",
      frames: 1,
      index: 192
    },
    {
      id: "ancient_debris_side",
      frames: 1,
      index: 208
    },
    {
      id: "ancient_debris_top",
      frames: 1,
      index: 224
    },
    {
      id: "andesite",
      frames: 1,
      index: 240
    },
    {
      id: "anvil",
      frames: 1,
      index: 256
    },
    {
      id: "anvil_top",
      frames: 1,
      index: 272
    },
    {
      id: "attached_melon_stem",
      frames: 1,
      index: 288
    },
    {
      id: "attached_pumpkin_stem",
      frames: 1,
      index: 304
    },
    {
      id: "azalea_leaves",
      frames: 1,
      index: 320
    },
    {
      id: "azalea_plant",
      frames: 1,
      index: 336
    },
    {
      id: "azalea_side",
      frames: 1,
      index: 352
    },
    {
      id: "azalea_top",
      frames: 1,
      index: 368
    },
    {
      id: "azure_bluet",
      frames: 1,
      index: 384
    },
    {
      id: "bamboo_large_leaves",
      frames: 1,
      index: 400
    },
    {
      id: "bamboo_singleleaf",
      frames: 1,
      index: 416
    },
    {
      id: "bamboo_small_leaves",
      frames: 1,
      index: 432
    },
    {
      id: "bamboo_stage0",
      frames: 1,
      index: 448
    },
    {
      id: "bamboo_stalk",
      frames: 1,
      index: 464
    },
    {
      id: "barrel_bottom",
      frames: 1,
      index: 480
    },
    {
      id: "barrel_side",
      frames: 1,
      index: 496
    },
    {
      id: "barrel_top",
      frames: 1,
      index: 512
    },
    {
      id: "barrel_top_open",
      frames: 1,
      index: 528
    },
    {
      id: "basalt_side",
      frames: 1,
      index: 544
    },
    {
      id: "basalt_top",
      frames: 1,
      index: 560
    },
    {
      id: "beacon",
      frames: 1,
      index: 576
    },
    {
      id: "bedrock",
      frames: 1,
      index: 592
    },
    {
      id: "bee_nest_bottom",
      frames: 1,
      index: 608
    },
    {
      id: "bee_nest_front",
      frames: 1,
      index: 624
    },
    {
      id: "bee_nest_front_honey",
      frames: 1,
      index: 640
    },
    {
      id: "bee_nest_side",
      frames: 1,
      index: 656
    },
    {
      id: "bee_nest_top",
      frames: 1,
      index: 672
    },
    {
      id: "beehive_end",
      frames: 1,
      index: 688
    },
    {
      id: "beehive_front",
      frames: 1,
      index: 704
    },
    {
      id: "beehive_front_honey",
      frames: 1,
      index: 720
    },
    {
      id: "beehive_side",
      frames: 1,
      index: 736
    },
    {
      id: "beetroots_stage0",
      frames: 1,
      index: 752
    },
    {
      id: "beetroots_stage1",
      frames: 1,
      index: 768
    },
    {
      id: "beetroots_stage2",
      frames: 1,
      index: 784
    },
    {
      id: "beetroots_stage3",
      frames: 1,
      index: 800
    },
    {
      id: "bell_bottom",
      frames: 1,
      index: 816
    },
    {
      id: "bell_side",
      frames: 1,
      index: 832
    },
    {
      id: "bell_top",
      frames: 1,
      index: 848
    },
    {
      id: "big_dripleaf_side",
      frames: 1,
      index: 864
    },
    {
      id: "big_dripleaf_stem",
      frames: 1,
      index: 880
    },
    {
      id: "big_dripleaf_tip",
      frames: 1,
      index: 896
    },
    {
      id: "big_dripleaf_top",
      frames: 1,
      index: 912
    },
    {
      id: "birch_door_bottom",
      frames: 1,
      index: 928
    },
    {
      id: "birch_door_top",
      frames: 1,
      index: 944
    },
    {
      id: "birch_leaves",
      frames: 1,
      index: 960
    },
    {
      id: "birch_log",
      frames: 1,
      index: 976
    },
    {
      id: "birch_log_top",
      frames: 1,
      index: 992
    },
    {
      id: "birch_planks",
      frames: 1,
      index: 1008
    },
    {
      id: "birch_sapling",
      frames: 1,
      index: 1024
    },
    {
      id: "birch_trapdoor",
      frames: 1,
      index: 1040
    },
    {
      id: "black_candle",
      frames: 1,
      index: 1056
    },
    {
      id: "black_candle_lit",
      frames: 1,
      index: 1072
    },
    {
      id: "black_concrete",
      frames: 1,
      index: 1088
    },
    {
      id: "black_concrete_powder",
      frames: 1,
      index: 1104
    },
    {
      id: "black_glazed_terracotta",
      frames: 1,
      index: 1120
    },
    {
      id: "black_shulker_box",
      frames: 1,
      index: 1136
    },
    {
      id: "black_stained_glass",
      frames: 1,
      index: 1152
    },
    {
      id: "black_stained_glass_pane_top",
      frames: 1,
      index: 1168
    },
    {
      id: "black_terracotta",
      frames: 1,
      index: 1184
    },
    {
      id: "black_wool",
      frames: 1,
      index: 1200
    },
    {
      id: "blackstone",
      frames: 1,
      index: 1216
    },
    {
      id: "blackstone_top",
      frames: 1,
      index: 1232
    },
    {
      id: "blast_furnace_front",
      frames: 1,
      index: 1248
    },
    {
      id: "blast_furnace_front_on",
      frames: 2,
      index: 1264
    },
    {
      id: "blast_furnace_side",
      frames: 1,
      index: 1296
    },
    {
      id: "blast_furnace_top",
      frames: 1,
      index: 1312
    },
    {
      id: "blue_candle",
      frames: 1,
      index: 1328
    },
    {
      id: "blue_candle_lit",
      frames: 1,
      index: 1344
    },
    {
      id: "blue_concrete",
      frames: 1,
      index: 1360
    },
    {
      id: "blue_concrete_powder",
      frames: 1,
      index: 1376
    },
    {
      id: "blue_glazed_terracotta",
      frames: 1,
      index: 1392
    },
    {
      id: "blue_ice",
      frames: 1,
      index: 1408
    },
    {
      id: "blue_orchid",
      frames: 1,
      index: 1424
    },
    {
      id: "blue_shulker_box",
      frames: 1,
      index: 1440
    },
    {
      id: "blue_stained_glass",
      frames: 1,
      index: 1456
    },
    {
      id: "blue_stained_glass_pane_top",
      frames: 1,
      index: 1472
    },
    {
      id: "blue_terracotta",
      frames: 1,
      index: 1488
    },
    {
      id: "blue_wool",
      frames: 1,
      index: 1504
    },
    {
      id: "bone_block_side",
      frames: 1,
      index: 1520
    },
    {
      id: "bone_block_top",
      frames: 1,
      index: 1536
    },
    {
      id: "bookshelf",
      frames: 1,
      index: 1552
    },
    {
      id: "brain_coral",
      frames: 1,
      index: 1568
    },
    {
      id: "brain_coral_block",
      frames: 1,
      index: 1584
    },
    {
      id: "brain_coral_fan",
      frames: 1,
      index: 1600
    },
    {
      id: "brewing_stand",
      frames: 1,
      index: 1616
    },
    {
      id: "brewing_stand_base",
      frames: 1,
      index: 1632
    },
    {
      id: "bricks",
      frames: 1,
      index: 1648
    },
    {
      id: "brown_candle",
      frames: 1,
      index: 1664
    },
    {
      id: "brown_candle_lit",
      frames: 1,
      index: 1680
    },
    {
      id: "brown_concrete",
      frames: 1,
      index: 1696
    },
    {
      id: "brown_concrete_powder",
      frames: 1,
      index: 1712
    },
    {
      id: "brown_glazed_terracotta",
      frames: 1,
      index: 1728
    },
    {
      id: "brown_mushroom",
      frames: 1,
      index: 1744
    },
    {
      id: "brown_mushroom_block",
      frames: 1,
      index: 1760
    },
    {
      id: "brown_shulker_box",
      frames: 1,
      index: 1776
    },
    {
      id: "brown_stained_glass",
      frames: 1,
      index: 1792
    },
    {
      id: "brown_stained_glass_pane_top",
      frames: 1,
      index: 1808
    },
    {
      id: "brown_terracotta",
      frames: 1,
      index: 1824
    },
    {
      id: "brown_wool",
      frames: 1,
      index: 1840
    },
    {
      id: "bubble_coral",
      frames: 1,
      index: 1856
    },
    {
      id: "bubble_coral_block",
      frames: 1,
      index: 1872
    },
    {
      id: "bubble_coral_fan",
      frames: 1,
      index: 1888
    },
    {
      id: "budding_amethyst",
      frames: 1,
      index: 1904
    },
    {
      id: "cactus_bottom",
      frames: 1,
      index: 1920
    },
    {
      id: "cactus_side",
      frames: 1,
      index: 1936
    },
    {
      id: "cactus_top",
      frames: 1,
      index: 1952
    },
    {
      id: "cake_bottom",
      frames: 1,
      index: 1968
    },
    {
      id: "cake_inner",
      frames: 1,
      index: 1984
    },
    {
      id: "cake_side",
      frames: 1,
      index: 2000
    },
    {
      id: "cake_top",
      frames: 1,
      index: 2016
    },
    {
      id: "calcite",
      frames: 1,
      index: 2032
    },
    {
      id: "campfire_fire",
      frames: 8,
      index: 2048
    },
    {
      id: "campfire_log",
      frames: 1,
      index: 2176
    },
    {
      id: "campfire_log_lit",
      frames: 4,
      index: 2192
    },
    {
      id: "candle",
      frames: 1,
      index: 2256
    },
    {
      id: "candle_lit",
      frames: 1,
      index: 2272
    },
    {
      id: "carrots_stage0",
      frames: 1,
      index: 2288
    },
    {
      id: "carrots_stage1",
      frames: 1,
      index: 2304
    },
    {
      id: "carrots_stage2",
      frames: 1,
      index: 2320
    },
    {
      id: "carrots_stage3",
      frames: 1,
      index: 2336
    },
    {
      id: "cartography_table_side1",
      frames: 1,
      index: 2352
    },
    {
      id: "cartography_table_side2",
      frames: 1,
      index: 2368
    },
    {
      id: "cartography_table_side3",
      frames: 1,
      index: 2384
    },
    {
      id: "cartography_table_top",
      frames: 1,
      index: 2400
    },
    {
      id: "carved_pumpkin",
      frames: 1,
      index: 2416
    },
    {
      id: "cauldron_bottom",
      frames: 1,
      index: 2432
    },
    {
      id: "cauldron_inner",
      frames: 1,
      index: 2448
    },
    {
      id: "cauldron_side",
      frames: 1,
      index: 2464
    },
    {
      id: "cauldron_top",
      frames: 1,
      index: 2480
    },
    {
      id: "cave_vines",
      frames: 1,
      index: 2496
    },
    {
      id: "cave_vines_lit",
      frames: 1,
      index: 2512
    },
    {
      id: "cave_vines_plant",
      frames: 1,
      index: 2528
    },
    {
      id: "cave_vines_plant_lit",
      frames: 1,
      index: 2544
    },
    {
      id: "chain",
      frames: 1,
      index: 2560
    },
    {
      id: "chain_command_block_back",
      frames: 4,
      index: 2576
    },
    {
      id: "chain_command_block_conditional",
      frames: 4,
      index: 2640
    },
    {
      id: "chain_command_block_front",
      frames: 4,
      index: 2704
    },
    {
      id: "chain_command_block_side",
      frames: 4,
      index: 2768
    },
    {
      id: "chipped_anvil_top",
      frames: 1,
      index: 2832
    },
    {
      id: "chiseled_deepslate",
      frames: 1,
      index: 2848
    },
    {
      id: "chiseled_nether_bricks",
      frames: 1,
      index: 2864
    },
    {
      id: "chiseled_polished_blackstone",
      frames: 1,
      index: 2880
    },
    {
      id: "chiseled_quartz_block",
      frames: 1,
      index: 2896
    },
    {
      id: "chiseled_quartz_block_top",
      frames: 1,
      index: 2912
    },
    {
      id: "chiseled_red_sandstone",
      frames: 1,
      index: 2928
    },
    {
      id: "chiseled_sandstone",
      frames: 1,
      index: 2944
    },
    {
      id: "chiseled_stone_bricks",
      frames: 1,
      index: 2960
    },
    {
      id: "chorus_flower",
      frames: 1,
      index: 2976
    },
    {
      id: "chorus_flower_dead",
      frames: 1,
      index: 2992
    },
    {
      id: "chorus_plant",
      frames: 1,
      index: 3008
    },
    {
      id: "clay",
      frames: 1,
      index: 3024
    },
    {
      id: "coal_block",
      frames: 1,
      index: 3040
    },
    {
      id: "coal_ore",
      frames: 1,
      index: 3056
    },
    {
      id: "coarse_dirt",
      frames: 1,
      index: 3072
    },
    {
      id: "cobbled_deepslate",
      frames: 1,
      index: 3088
    },
    {
      id: "cobblestone",
      frames: 1,
      index: 3104
    },
    {
      id: "cobweb",
      frames: 1,
      index: 3120
    },
    {
      id: "cocoa_stage0",
      frames: 1,
      index: 3136
    },
    {
      id: "cocoa_stage1",
      frames: 1,
      index: 3152
    },
    {
      id: "cocoa_stage2",
      frames: 1,
      index: 3168
    },
    {
      id: "command_block_back",
      frames: 4,
      index: 3184
    },
    {
      id: "command_block_conditional",
      frames: 4,
      index: 3248
    },
    {
      id: "command_block_front",
      frames: 4,
      index: 3312
    },
    {
      id: "command_block_side",
      frames: 4,
      index: 3376
    },
    {
      id: "comparator",
      frames: 1,
      index: 3440
    },
    {
      id: "comparator_on",
      frames: 1,
      index: 3456
    },
    {
      id: "composter_bottom",
      frames: 1,
      index: 3472
    },
    {
      id: "composter_compost",
      frames: 1,
      index: 3488
    },
    {
      id: "composter_ready",
      frames: 1,
      index: 3504
    },
    {
      id: "composter_side",
      frames: 1,
      index: 3520
    },
    {
      id: "composter_top",
      frames: 1,
      index: 3536
    },
    {
      id: "conduit",
      frames: 1,
      index: 3552
    },
    {
      id: "copper_block",
      frames: 1,
      index: 3568
    },
    {
      id: "copper_ore",
      frames: 1,
      index: 3584
    },
    {
      id: "cornflower",
      frames: 1,
      index: 3600
    },
    {
      id: "cracked_deepslate_bricks",
      frames: 1,
      index: 3616
    },
    {
      id: "cracked_deepslate_tiles",
      frames: 1,
      index: 3632
    },
    {
      id: "cracked_nether_bricks",
      frames: 1,
      index: 3648
    },
    {
      id: "cracked_polished_blackstone_bricks",
      frames: 1,
      index: 3664
    },
    {
      id: "cracked_stone_bricks",
      frames: 1,
      index: 3680
    },
    {
      id: "crafting_table_front",
      frames: 1,
      index: 3696
    },
    {
      id: "crafting_table_side",
      frames: 1,
      index: 3712
    },
    {
      id: "crafting_table_top",
      frames: 1,
      index: 3728
    },
    {
      id: "crimson_door_bottom",
      frames: 1,
      index: 3744
    },
    {
      id: "crimson_door_top",
      frames: 1,
      index: 3760
    },
    {
      id: "crimson_fungus",
      frames: 1,
      index: 3776
    },
    {
      id: "crimson_nylium",
      frames: 1,
      index: 3792
    },
    {
      id: "crimson_nylium_side",
      frames: 1,
      index: 3808
    },
    {
      id: "crimson_planks",
      frames: 1,
      index: 3824
    },
    {
      id: "crimson_roots",
      frames: 1,
      index: 3840
    },
    {
      id: "crimson_roots_pot",
      frames: 1,
      index: 3856
    },
    {
      id: "crimson_stem",
      frames: 5,
      index: 3872
    },
    {
      id: "crimson_stem_top",
      frames: 1,
      index: 3952
    },
    {
      id: "crimson_trapdoor",
      frames: 1,
      index: 3968
    },
    {
      id: "crying_obsidian",
      frames: 1,
      index: 3984
    },
    {
      id: "cut_copper",
      frames: 1,
      index: 4000
    },
    {
      id: "cut_red_sandstone",
      frames: 1,
      index: 4016
    },
    {
      id: "cut_sandstone",
      frames: 1,
      index: 4032
    },
    {
      id: "cyan_candle",
      frames: 1,
      index: 4048
    },
    {
      id: "cyan_candle_lit",
      frames: 1,
      index: 4064
    },
    {
      id: "cyan_concrete",
      frames: 1,
      index: 4080
    },
    {
      id: "cyan_concrete_powder",
      frames: 1,
      index: 4096
    },
    {
      id: "cyan_glazed_terracotta",
      frames: 1,
      index: 4112
    },
    {
      id: "cyan_shulker_box",
      frames: 1,
      index: 4128
    },
    {
      id: "cyan_stained_glass",
      frames: 1,
      index: 4144
    },
    {
      id: "cyan_stained_glass_pane_top",
      frames: 1,
      index: 4160
    },
    {
      id: "cyan_terracotta",
      frames: 1,
      index: 4176
    },
    {
      id: "cyan_wool",
      frames: 1,
      index: 4192
    },
    {
      id: "damaged_anvil_top",
      frames: 1,
      index: 4208
    },
    {
      id: "dandelion",
      frames: 1,
      index: 4224
    },
    {
      id: "dark_oak_door_bottom",
      frames: 1,
      index: 4240
    },
    {
      id: "dark_oak_door_top",
      frames: 1,
      index: 4256
    },
    {
      id: "dark_oak_leaves",
      frames: 1,
      index: 4272
    },
    {
      id: "dark_oak_log",
      frames: 1,
      index: 4288
    },
    {
      id: "dark_oak_log_top",
      frames: 1,
      index: 4304
    },
    {
      id: "dark_oak_planks",
      frames: 1,
      index: 4320
    },
    {
      id: "dark_oak_sapling",
      frames: 1,
      index: 4336
    },
    {
      id: "dark_oak_trapdoor",
      frames: 1,
      index: 4352
    },
    {
      id: "dark_prismarine",
      frames: 1,
      index: 4368
    },
    {
      id: "daylight_detector_inverted_top",
      frames: 1,
      index: 4384
    },
    {
      id: "daylight_detector_side",
      frames: 1,
      index: 4400
    },
    {
      id: "daylight_detector_top",
      frames: 1,
      index: 4416
    },
    {
      id: "dead_brain_coral",
      frames: 1,
      index: 4432
    },
    {
      id: "dead_brain_coral_block",
      frames: 1,
      index: 4448
    },
    {
      id: "dead_brain_coral_fan",
      frames: 1,
      index: 4464
    },
    {
      id: "dead_bubble_coral",
      frames: 1,
      index: 4480
    },
    {
      id: "dead_bubble_coral_block",
      frames: 1,
      index: 4496
    },
    {
      id: "dead_bubble_coral_fan",
      frames: 1,
      index: 4512
    },
    {
      id: "dead_bush",
      frames: 1,
      index: 4528
    },
    {
      id: "dead_fire_coral",
      frames: 1,
      index: 4544
    },
    {
      id: "dead_fire_coral_block",
      frames: 1,
      index: 4560
    },
    {
      id: "dead_fire_coral_fan",
      frames: 1,
      index: 4576
    },
    {
      id: "dead_horn_coral",
      frames: 1,
      index: 4592
    },
    {
      id: "dead_horn_coral_block",
      frames: 1,
      index: 4608
    },
    {
      id: "dead_horn_coral_fan",
      frames: 1,
      index: 4624
    },
    {
      id: "dead_tube_coral",
      frames: 1,
      index: 4640
    },
    {
      id: "dead_tube_coral_block",
      frames: 1,
      index: 4656
    },
    {
      id: "dead_tube_coral_fan",
      frames: 1,
      index: 4672
    },
    {
      id: "debug",
      frames: 1,
      index: 4688
    },
    {
      id: "debug2",
      frames: 1,
      index: 4704
    },
    {
      id: "deepslate",
      frames: 1,
      index: 4720
    },
    {
      id: "deepslate_bricks",
      frames: 1,
      index: 4736
    },
    {
      id: "deepslate_coal_ore",
      frames: 1,
      index: 4752
    },
    {
      id: "deepslate_copper_ore",
      frames: 1,
      index: 4768
    },
    {
      id: "deepslate_diamond_ore",
      frames: 1,
      index: 4784
    },
    {
      id: "deepslate_emerald_ore",
      frames: 1,
      index: 4800
    },
    {
      id: "deepslate_gold_ore",
      frames: 1,
      index: 4816
    },
    {
      id: "deepslate_iron_ore",
      frames: 1,
      index: 4832
    },
    {
      id: "deepslate_lapis_ore",
      frames: 1,
      index: 4848
    },
    {
      id: "deepslate_redstone_ore",
      frames: 1,
      index: 4864
    },
    {
      id: "deepslate_tiles",
      frames: 1,
      index: 4880
    },
    {
      id: "deepslate_top",
      frames: 1,
      index: 4896
    },
    {
      id: "destroy_stage_0",
      frames: 1,
      index: 4912
    },
    {
      id: "destroy_stage_1",
      frames: 1,
      index: 4928
    },
    {
      id: "destroy_stage_2",
      frames: 1,
      index: 4944
    },
    {
      id: "destroy_stage_3",
      frames: 1,
      index: 4960
    },
    {
      id: "destroy_stage_4",
      frames: 1,
      index: 4976
    },
    {
      id: "destroy_stage_5",
      frames: 1,
      index: 4992
    },
    {
      id: "destroy_stage_6",
      frames: 1,
      index: 5008
    },
    {
      id: "destroy_stage_7",
      frames: 1,
      index: 5024
    },
    {
      id: "destroy_stage_8",
      frames: 1,
      index: 5040
    },
    {
      id: "destroy_stage_9",
      frames: 1,
      index: 5056
    },
    {
      id: "detector_rail",
      frames: 1,
      index: 5072
    },
    {
      id: "detector_rail_on",
      frames: 1,
      index: 5088
    },
    {
      id: "diamond_block",
      frames: 1,
      index: 5104
    },
    {
      id: "diamond_ore",
      frames: 1,
      index: 5120
    },
    {
      id: "diorite",
      frames: 1,
      index: 5136
    },
    {
      id: "dirt",
      frames: 1,
      index: 5152
    },
    {
      id: "dirt_path_side",
      frames: 1,
      index: 5168
    },
    {
      id: "dirt_path_top",
      frames: 1,
      index: 5184
    },
    {
      id: "dispenser_front",
      frames: 1,
      index: 5200
    },
    {
      id: "dispenser_front_vertical",
      frames: 1,
      index: 5216
    },
    {
      id: "dragon_egg",
      frames: 1,
      index: 5232
    },
    {
      id: "dried_kelp_bottom",
      frames: 1,
      index: 5248
    },
    {
      id: "dried_kelp_side",
      frames: 1,
      index: 5264
    },
    {
      id: "dried_kelp_top",
      frames: 1,
      index: 5280
    },
    {
      id: "dripstone_block",
      frames: 1,
      index: 5296
    },
    {
      id: "dropper_front",
      frames: 1,
      index: 5312
    },
    {
      id: "dropper_front_vertical",
      frames: 1,
      index: 5328
    },
    {
      id: "emerald_block",
      frames: 1,
      index: 5344
    },
    {
      id: "emerald_ore",
      frames: 1,
      index: 5360
    },
    {
      id: "enchanting_table_bottom",
      frames: 1,
      index: 5376
    },
    {
      id: "enchanting_table_side",
      frames: 1,
      index: 5392
    },
    {
      id: "enchanting_table_top",
      frames: 1,
      index: 5408
    },
    {
      id: "end_portal_frame_eye",
      frames: 1,
      index: 5424
    },
    {
      id: "end_portal_frame_side",
      frames: 1,
      index: 5440
    },
    {
      id: "end_portal_frame_top",
      frames: 1,
      index: 5456
    },
    {
      id: "end_rod",
      frames: 1,
      index: 5472
    },
    {
      id: "end_stone",
      frames: 1,
      index: 5488
    },
    {
      id: "end_stone_bricks",
      frames: 1,
      index: 5504
    },
    {
      id: "exposed_copper",
      frames: 1,
      index: 5520
    },
    {
      id: "exposed_cut_copper",
      frames: 1,
      index: 5536
    },
    {
      id: "farmland",
      frames: 1,
      index: 5552
    },
    {
      id: "farmland_moist",
      frames: 1,
      index: 5568
    },
    {
      id: "fern",
      frames: 1,
      index: 5584
    },
    {
      id: "fire_0",
      frames: 32,
      index: 5600
    },
    {
      id: "fire_1",
      frames: 32,
      index: 6112
    },
    {
      id: "fire_coral",
      frames: 1,
      index: 6624
    },
    {
      id: "fire_coral_block",
      frames: 1,
      index: 6640
    },
    {
      id: "fire_coral_fan",
      frames: 1,
      index: 6656
    },
    {
      id: "fletching_table_front",
      frames: 1,
      index: 6672
    },
    {
      id: "fletching_table_side",
      frames: 1,
      index: 6688
    },
    {
      id: "fletching_table_top",
      frames: 1,
      index: 6704
    },
    {
      id: "flower_pot",
      frames: 1,
      index: 6720
    },
    {
      id: "flowering_azalea_leaves",
      frames: 1,
      index: 6736
    },
    {
      id: "flowering_azalea_side",
      frames: 1,
      index: 6752
    },
    {
      id: "flowering_azalea_top",
      frames: 1,
      index: 6768
    },
    {
      id: "frosted_ice_0",
      frames: 1,
      index: 6784
    },
    {
      id: "frosted_ice_1",
      frames: 1,
      index: 6800
    },
    {
      id: "frosted_ice_2",
      frames: 1,
      index: 6816
    },
    {
      id: "frosted_ice_3",
      frames: 1,
      index: 6832
    },
    {
      id: "furnace_front",
      frames: 1,
      index: 6848
    },
    {
      id: "furnace_front_on",
      frames: 1,
      index: 6864
    },
    {
      id: "furnace_side",
      frames: 1,
      index: 6880
    },
    {
      id: "furnace_top",
      frames: 1,
      index: 6896
    },
    {
      id: "gilded_blackstone",
      frames: 1,
      index: 6912
    },
    {
      id: "glass",
      frames: 1,
      index: 6928
    },
    {
      id: "glass_pane_top",
      frames: 1,
      index: 6944
    },
    {
      id: "glow_item_frame",
      frames: 1,
      index: 6960
    },
    {
      id: "glow_lichen",
      frames: 1,
      index: 6976
    },
    {
      id: "glowstone",
      frames: 1,
      index: 6992
    },
    {
      id: "gold_block",
      frames: 1,
      index: 7008
    },
    {
      id: "gold_ore",
      frames: 1,
      index: 7024
    },
    {
      id: "granite",
      frames: 1,
      index: 7040
    },
    {
      id: "grass",
      frames: 1,
      index: 7056
    },
    {
      id: "grass_block_side",
      frames: 1,
      index: 7072
    },
    {
      id: "grass_block_side_overlay",
      frames: 1,
      index: 7088
    },
    {
      id: "grass_block_snow",
      frames: 1,
      index: 7104
    },
    {
      id: "grass_block_top",
      frames: 1,
      index: 7120
    },
    {
      id: "gravel",
      frames: 1,
      index: 7136
    },
    {
      id: "gray_candle",
      frames: 1,
      index: 7152
    },
    {
      id: "gray_candle_lit",
      frames: 1,
      index: 7168
    },
    {
      id: "gray_concrete",
      frames: 1,
      index: 7184
    },
    {
      id: "gray_concrete_powder",
      frames: 1,
      index: 7200
    },
    {
      id: "gray_glazed_terracotta",
      frames: 1,
      index: 7216
    },
    {
      id: "gray_shulker_box",
      frames: 1,
      index: 7232
    },
    {
      id: "gray_stained_glass",
      frames: 1,
      index: 7248
    },
    {
      id: "gray_stained_glass_pane_top",
      frames: 1,
      index: 7264
    },
    {
      id: "gray_terracotta",
      frames: 1,
      index: 7280
    },
    {
      id: "gray_wool",
      frames: 1,
      index: 7296
    },
    {
      id: "green_candle",
      frames: 1,
      index: 7312
    },
    {
      id: "green_candle_lit",
      frames: 1,
      index: 7328
    },
    {
      id: "green_concrete",
      frames: 1,
      index: 7344
    },
    {
      id: "green_concrete_powder",
      frames: 1,
      index: 7360
    },
    {
      id: "green_glazed_terracotta",
      frames: 1,
      index: 7376
    },
    {
      id: "green_shulker_box",
      frames: 1,
      index: 7392
    },
    {
      id: "green_stained_glass",
      frames: 1,
      index: 7408
    },
    {
      id: "green_stained_glass_pane_top",
      frames: 1,
      index: 7424
    },
    {
      id: "green_terracotta",
      frames: 1,
      index: 7440
    },
    {
      id: "green_wool",
      frames: 1,
      index: 7456
    },
    {
      id: "grindstone_pivot",
      frames: 1,
      index: 7472
    },
    {
      id: "grindstone_round",
      frames: 1,
      index: 7488
    },
    {
      id: "grindstone_side",
      frames: 1,
      index: 7504
    },
    {
      id: "hanging_roots",
      frames: 1,
      index: 7520
    },
    {
      id: "hay_block_side",
      frames: 1,
      index: 7536
    },
    {
      id: "hay_block_top",
      frames: 1,
      index: 7552
    },
    {
      id: "honey_block_bottom",
      frames: 1,
      index: 7568
    },
    {
      id: "honey_block_side",
      frames: 1,
      index: 7584
    },
    {
      id: "honey_block_top",
      frames: 1,
      index: 7600
    },
    {
      id: "honeycomb_block",
      frames: 1,
      index: 7616
    },
    {
      id: "hopper_inside",
      frames: 1,
      index: 7632
    },
    {
      id: "hopper_outside",
      frames: 1,
      index: 7648
    },
    {
      id: "hopper_top",
      frames: 1,
      index: 7664
    },
    {
      id: "horn_coral",
      frames: 1,
      index: 7680
    },
    {
      id: "horn_coral_block",
      frames: 1,
      index: 7696
    },
    {
      id: "horn_coral_fan",
      frames: 1,
      index: 7712
    },
    {
      id: "ice",
      frames: 1,
      index: 7728
    },
    {
      id: "iron_bars",
      frames: 1,
      index: 7744
    },
    {
      id: "iron_block",
      frames: 1,
      index: 7760
    },
    {
      id: "iron_door_bottom",
      frames: 1,
      index: 7776
    },
    {
      id: "iron_door_top",
      frames: 1,
      index: 7792
    },
    {
      id: "iron_ore",
      frames: 1,
      index: 7808
    },
    {
      id: "iron_trapdoor",
      frames: 1,
      index: 7824
    },
    {
      id: "item_frame",
      frames: 1,
      index: 7840
    },
    {
      id: "jack_o_lantern",
      frames: 1,
      index: 7856
    },
    {
      id: "jigsaw_bottom",
      frames: 1,
      index: 7872
    },
    {
      id: "jigsaw_lock",
      frames: 1,
      index: 7888
    },
    {
      id: "jigsaw_side",
      frames: 1,
      index: 7904
    },
    {
      id: "jigsaw_top",
      frames: 1,
      index: 7920
    },
    {
      id: "jukebox_side",
      frames: 1,
      index: 7936
    },
    {
      id: "jukebox_top",
      frames: 1,
      index: 7952
    },
    {
      id: "jungle_door_bottom",
      frames: 1,
      index: 7968
    },
    {
      id: "jungle_door_top",
      frames: 1,
      index: 7984
    },
    {
      id: "jungle_leaves",
      frames: 1,
      index: 8000
    },
    {
      id: "jungle_log",
      frames: 1,
      index: 8016
    },
    {
      id: "jungle_log_top",
      frames: 1,
      index: 8032
    },
    {
      id: "jungle_planks",
      frames: 1,
      index: 8048
    },
    {
      id: "jungle_sapling",
      frames: 1,
      index: 8064
    },
    {
      id: "jungle_trapdoor",
      frames: 1,
      index: 8080
    },
    {
      id: "kelp",
      frames: 20,
      index: 8096
    },
    {
      id: "kelp_plant",
      frames: 20,
      index: 8416
    },
    {
      id: "ladder",
      frames: 1,
      index: 8736
    },
    {
      id: "lantern",
      frames: 3,
      index: 8752
    },
    {
      id: "lapis_block",
      frames: 1,
      index: 8800
    },
    {
      id: "lapis_ore",
      frames: 1,
      index: 8816
    },
    {
      id: "large_amethyst_bud",
      frames: 1,
      index: 8832
    },
    {
      id: "large_fern_bottom",
      frames: 1,
      index: 8848
    },
    {
      id: "large_fern_top",
      frames: 1,
      index: 8864
    },
    {
      id: "lava_still",
      frames: 20,
      index: 8880
    },
    {
      id: "lectern_base",
      frames: 1,
      index: 9200
    },
    {
      id: "lectern_front",
      frames: 1,
      index: 9216
    },
    {
      id: "lectern_sides",
      frames: 1,
      index: 9232
    },
    {
      id: "lectern_top",
      frames: 1,
      index: 9248
    },
    {
      id: "lever",
      frames: 1,
      index: 9264
    },
    {
      id: "light_blue_candle",
      frames: 1,
      index: 9280
    },
    {
      id: "light_blue_candle_lit",
      frames: 1,
      index: 9296
    },
    {
      id: "light_blue_concrete",
      frames: 1,
      index: 9312
    },
    {
      id: "light_blue_concrete_powder",
      frames: 1,
      index: 9328
    },
    {
      id: "light_blue_glazed_terracotta",
      frames: 1,
      index: 9344
    },
    {
      id: "light_blue_shulker_box",
      frames: 1,
      index: 9360
    },
    {
      id: "light_blue_stained_glass",
      frames: 1,
      index: 9376
    },
    {
      id: "light_blue_stained_glass_pane_top",
      frames: 1,
      index: 9392
    },
    {
      id: "light_blue_terracotta",
      frames: 1,
      index: 9408
    },
    {
      id: "light_blue_wool",
      frames: 1,
      index: 9424
    },
    {
      id: "light_gray_candle",
      frames: 1,
      index: 9440
    },
    {
      id: "light_gray_candle_lit",
      frames: 1,
      index: 9456
    },
    {
      id: "light_gray_concrete",
      frames: 1,
      index: 9472
    },
    {
      id: "light_gray_concrete_powder",
      frames: 1,
      index: 9488
    },
    {
      id: "light_gray_glazed_terracotta",
      frames: 1,
      index: 9504
    },
    {
      id: "light_gray_shulker_box",
      frames: 1,
      index: 9520
    },
    {
      id: "light_gray_stained_glass",
      frames: 1,
      index: 9536
    },
    {
      id: "light_gray_stained_glass_pane_top",
      frames: 1,
      index: 9552
    },
    {
      id: "light_gray_terracotta",
      frames: 1,
      index: 9568
    },
    {
      id: "light_gray_wool",
      frames: 1,
      index: 9584
    },
    {
      id: "lightning_rod",
      frames: 1,
      index: 9600
    },
    {
      id: "lightning_rod_on",
      frames: 1,
      index: 9616
    },
    {
      id: "lilac_bottom",
      frames: 1,
      index: 9632
    },
    {
      id: "lilac_top",
      frames: 1,
      index: 9648
    },
    {
      id: "lily_of_the_valley",
      frames: 1,
      index: 9664
    },
    {
      id: "lily_pad",
      frames: 1,
      index: 9680
    },
    {
      id: "lime_candle",
      frames: 1,
      index: 9696
    },
    {
      id: "lime_candle_lit",
      frames: 1,
      index: 9712
    },
    {
      id: "lime_concrete",
      frames: 1,
      index: 9728
    },
    {
      id: "lime_concrete_powder",
      frames: 1,
      index: 9744
    },
    {
      id: "lime_glazed_terracotta",
      frames: 1,
      index: 9760
    },
    {
      id: "lime_shulker_box",
      frames: 1,
      index: 9776
    },
    {
      id: "lime_stained_glass",
      frames: 1,
      index: 9792
    },
    {
      id: "lime_stained_glass_pane_top",
      frames: 1,
      index: 9808
    },
    {
      id: "lime_terracotta",
      frames: 1,
      index: 9824
    },
    {
      id: "lime_wool",
      frames: 1,
      index: 9840
    },
    {
      id: "lodestone_side",
      frames: 1,
      index: 9856
    },
    {
      id: "lodestone_top",
      frames: 1,
      index: 9872
    },
    {
      id: "loom_bottom",
      frames: 1,
      index: 9888
    },
    {
      id: "loom_front",
      frames: 1,
      index: 9904
    },
    {
      id: "loom_side",
      frames: 1,
      index: 9920
    },
    {
      id: "loom_top",
      frames: 1,
      index: 9936
    },
    {
      id: "magenta_candle",
      frames: 1,
      index: 9952
    },
    {
      id: "magenta_candle_lit",
      frames: 1,
      index: 9968
    },
    {
      id: "magenta_concrete",
      frames: 1,
      index: 9984
    },
    {
      id: "magenta_concrete_powder",
      frames: 1,
      index: 10000
    },
    {
      id: "magenta_glazed_terracotta",
      frames: 1,
      index: 10016
    },
    {
      id: "magenta_shulker_box",
      frames: 1,
      index: 10032
    },
    {
      id: "magenta_stained_glass",
      frames: 1,
      index: 10048
    },
    {
      id: "magenta_stained_glass_pane_top",
      frames: 1,
      index: 10064
    },
    {
      id: "magenta_terracotta",
      frames: 1,
      index: 10080
    },
    {
      id: "magenta_wool",
      frames: 1,
      index: 10096
    },
    {
      id: "magma",
      frames: 3,
      index: 10112
    },
    {
      id: "medium_amethyst_bud",
      frames: 1,
      index: 10160
    },
    {
      id: "melon_side",
      frames: 1,
      index: 10176
    },
    {
      id: "melon_stem",
      frames: 1,
      index: 10192
    },
    {
      id: "melon_top",
      frames: 1,
      index: 10208
    },
    {
      id: "moss_block",
      frames: 1,
      index: 10224
    },
    {
      id: "mossy_cobblestone",
      frames: 1,
      index: 10240
    },
    {
      id: "mossy_stone_bricks",
      frames: 1,
      index: 10256
    },
    {
      id: "mushroom_block_inside",
      frames: 1,
      index: 10272
    },
    {
      id: "mushroom_stem",
      frames: 1,
      index: 10288
    },
    {
      id: "mycelium_side",
      frames: 1,
      index: 10304
    },
    {
      id: "mycelium_top",
      frames: 1,
      index: 10320
    },
    {
      id: "nether_bricks",
      frames: 1,
      index: 10336
    },
    {
      id: "nether_gold_ore",
      frames: 1,
      index: 10352
    },
    {
      id: "nether_portal",
      frames: 32,
      index: 10368
    },
    {
      id: "nether_quartz_ore",
      frames: 1,
      index: 10880
    },
    {
      id: "nether_sprouts",
      frames: 1,
      index: 10896
    },
    {
      id: "nether_wart_block",
      frames: 1,
      index: 10912
    },
    {
      id: "nether_wart_stage0",
      frames: 1,
      index: 10928
    },
    {
      id: "nether_wart_stage1",
      frames: 1,
      index: 10944
    },
    {
      id: "nether_wart_stage2",
      frames: 1,
      index: 10960
    },
    {
      id: "netherite_block",
      frames: 1,
      index: 10976
    },
    {
      id: "netherrack",
      frames: 1,
      index: 10992
    },
    {
      id: "note_block",
      frames: 1,
      index: 11008
    },
    {
      id: "oak_door_bottom",
      frames: 1,
      index: 11024
    },
    {
      id: "oak_door_top",
      frames: 1,
      index: 11040
    },
    {
      id: "oak_leaves",
      frames: 1,
      index: 11056
    },
    {
      id: "oak_log",
      frames: 1,
      index: 11072
    },
    {
      id: "oak_log_top",
      frames: 1,
      index: 11088
    },
    {
      id: "oak_planks",
      frames: 1,
      index: 11104
    },
    {
      id: "oak_sapling",
      frames: 1,
      index: 11120
    },
    {
      id: "oak_trapdoor",
      frames: 1,
      index: 11136
    },
    {
      id: "observer_back",
      frames: 1,
      index: 11152
    },
    {
      id: "observer_back_on",
      frames: 1,
      index: 11168
    },
    {
      id: "observer_front",
      frames: 1,
      index: 11184
    },
    {
      id: "observer_side",
      frames: 1,
      index: 11200
    },
    {
      id: "observer_top",
      frames: 1,
      index: 11216
    },
    {
      id: "obsidian",
      frames: 1,
      index: 11232
    },
    {
      id: "orange_candle",
      frames: 1,
      index: 11248
    },
    {
      id: "orange_candle_lit",
      frames: 1,
      index: 11264
    },
    {
      id: "orange_concrete",
      frames: 1,
      index: 11280
    },
    {
      id: "orange_concrete_powder",
      frames: 1,
      index: 11296
    },
    {
      id: "orange_glazed_terracotta",
      frames: 1,
      index: 11312
    },
    {
      id: "orange_shulker_box",
      frames: 1,
      index: 11328
    },
    {
      id: "orange_stained_glass",
      frames: 1,
      index: 11344
    },
    {
      id: "orange_stained_glass_pane_top",
      frames: 1,
      index: 11360
    },
    {
      id: "orange_terracotta",
      frames: 1,
      index: 11376
    },
    {
      id: "orange_tulip",
      frames: 1,
      index: 11392
    },
    {
      id: "orange_wool",
      frames: 1,
      index: 11408
    },
    {
      id: "oxeye_daisy",
      frames: 1,
      index: 11424
    },
    {
      id: "oxidized_copper",
      frames: 1,
      index: 11440
    },
    {
      id: "oxidized_cut_copper",
      frames: 1,
      index: 11456
    },
    {
      id: "packed_ice",
      frames: 1,
      index: 11472
    },
    {
      id: "peony_bottom",
      frames: 1,
      index: 11488
    },
    {
      id: "peony_top",
      frames: 1,
      index: 11504
    },
    {
      id: "pink_candle",
      frames: 1,
      index: 11520
    },
    {
      id: "pink_candle_lit",
      frames: 1,
      index: 11536
    },
    {
      id: "pink_concrete",
      frames: 1,
      index: 11552
    },
    {
      id: "pink_concrete_powder",
      frames: 1,
      index: 11568
    },
    {
      id: "pink_glazed_terracotta",
      frames: 1,
      index: 11584
    },
    {
      id: "pink_shulker_box",
      frames: 1,
      index: 11600
    },
    {
      id: "pink_stained_glass",
      frames: 1,
      index: 11616
    },
    {
      id: "pink_stained_glass_pane_top",
      frames: 1,
      index: 11632
    },
    {
      id: "pink_terracotta",
      frames: 1,
      index: 11648
    },
    {
      id: "pink_tulip",
      frames: 1,
      index: 11664
    },
    {
      id: "pink_wool",
      frames: 1,
      index: 11680
    },
    {
      id: "piston_bottom",
      frames: 1,
      index: 11696
    },
    {
      id: "piston_inner",
      frames: 1,
      index: 11712
    },
    {
      id: "piston_side",
      frames: 1,
      index: 11728
    },
    {
      id: "piston_top",
      frames: 1,
      index: 11744
    },
    {
      id: "piston_top_sticky",
      frames: 1,
      index: 11760
    },
    {
      id: "podzol_side",
      frames: 1,
      index: 11776
    },
    {
      id: "podzol_top",
      frames: 1,
      index: 11792
    },
    {
      id: "pointed_dripstone_down_base",
      frames: 1,
      index: 11808
    },
    {
      id: "pointed_dripstone_down_frustum",
      frames: 1,
      index: 11824
    },
    {
      id: "pointed_dripstone_down_middle",
      frames: 1,
      index: 11840
    },
    {
      id: "pointed_dripstone_down_tip",
      frames: 1,
      index: 11856
    },
    {
      id: "pointed_dripstone_down_tip_merge",
      frames: 1,
      index: 11872
    },
    {
      id: "pointed_dripstone_up_base",
      frames: 1,
      index: 11888
    },
    {
      id: "pointed_dripstone_up_frustum",
      frames: 1,
      index: 11904
    },
    {
      id: "pointed_dripstone_up_middle",
      frames: 1,
      index: 11920
    },
    {
      id: "pointed_dripstone_up_tip",
      frames: 1,
      index: 11936
    },
    {
      id: "pointed_dripstone_up_tip_merge",
      frames: 1,
      index: 11952
    },
    {
      id: "polished_andesite",
      frames: 1,
      index: 11968
    },
    {
      id: "polished_basalt_side",
      frames: 1,
      index: 11984
    },
    {
      id: "polished_basalt_top",
      frames: 1,
      index: 12000
    },
    {
      id: "polished_blackstone",
      frames: 1,
      index: 12016
    },
    {
      id: "polished_blackstone_bricks",
      frames: 1,
      index: 12032
    },
    {
      id: "polished_deepslate",
      frames: 1,
      index: 12048
    },
    {
      id: "polished_diorite",
      frames: 1,
      index: 12064
    },
    {
      id: "polished_granite",
      frames: 1,
      index: 12080
    },
    {
      id: "poppy",
      frames: 1,
      index: 12096
    },
    {
      id: "potatoes_stage0",
      frames: 1,
      index: 12112
    },
    {
      id: "potatoes_stage1",
      frames: 1,
      index: 12128
    },
    {
      id: "potatoes_stage2",
      frames: 1,
      index: 12144
    },
    {
      id: "potatoes_stage3",
      frames: 1,
      index: 12160
    },
    {
      id: "potted_azalea_bush_plant",
      frames: 1,
      index: 12176
    },
    {
      id: "potted_azalea_bush_side",
      frames: 1,
      index: 12192
    },
    {
      id: "potted_azalea_bush_top",
      frames: 1,
      index: 12208
    },
    {
      id: "potted_flowering_azalea_bush_plant",
      frames: 1,
      index: 12224
    },
    {
      id: "potted_flowering_azalea_bush_side",
      frames: 1,
      index: 12240
    },
    {
      id: "potted_flowering_azalea_bush_top",
      frames: 1,
      index: 12256
    },
    {
      id: "powder_snow",
      frames: 1,
      index: 12272
    },
    {
      id: "powered_rail",
      frames: 1,
      index: 12288
    },
    {
      id: "powered_rail_on",
      frames: 1,
      index: 12304
    },
    {
      id: "prismarine",
      frames: 4,
      index: 12320
    },
    {
      id: "prismarine_bricks",
      frames: 1,
      index: 12384
    },
    {
      id: "pumpkin_side",
      frames: 1,
      index: 12400
    },
    {
      id: "pumpkin_stem",
      frames: 1,
      index: 12416
    },
    {
      id: "pumpkin_top",
      frames: 1,
      index: 12432
    },
    {
      id: "purple_candle",
      frames: 1,
      index: 12448
    },
    {
      id: "purple_candle_lit",
      frames: 1,
      index: 12464
    },
    {
      id: "purple_concrete",
      frames: 1,
      index: 12480
    },
    {
      id: "purple_concrete_powder",
      frames: 1,
      index: 12496
    },
    {
      id: "purple_glazed_terracotta",
      frames: 1,
      index: 12512
    },
    {
      id: "purple_shulker_box",
      frames: 1,
      index: 12528
    },
    {
      id: "purple_stained_glass",
      frames: 1,
      index: 12544
    },
    {
      id: "purple_stained_glass_pane_top",
      frames: 1,
      index: 12560
    },
    {
      id: "purple_terracotta",
      frames: 1,
      index: 12576
    },
    {
      id: "purple_wool",
      frames: 1,
      index: 12592
    },
    {
      id: "purpur_block",
      frames: 1,
      index: 12608
    },
    {
      id: "purpur_pillar",
      frames: 1,
      index: 12624
    },
    {
      id: "purpur_pillar_top",
      frames: 1,
      index: 12640
    },
    {
      id: "quartz_block_bottom",
      frames: 1,
      index: 12656
    },
    {
      id: "quartz_block_side",
      frames: 1,
      index: 12672
    },
    {
      id: "quartz_block_top",
      frames: 1,
      index: 12688
    },
    {
      id: "quartz_bricks",
      frames: 1,
      index: 12704
    },
    {
      id: "quartz_pillar",
      frames: 1,
      index: 12720
    },
    {
      id: "quartz_pillar_top",
      frames: 1,
      index: 12736
    },
    {
      id: "rail",
      frames: 1,
      index: 12752
    },
    {
      id: "rail_corner",
      frames: 1,
      index: 12768
    },
    {
      id: "raw_copper_block",
      frames: 1,
      index: 12784
    },
    {
      id: "raw_gold_block",
      frames: 1,
      index: 12800
    },
    {
      id: "raw_iron_block",
      frames: 1,
      index: 12816
    },
    {
      id: "red_candle",
      frames: 1,
      index: 12832
    },
    {
      id: "red_candle_lit",
      frames: 1,
      index: 12848
    },
    {
      id: "red_concrete",
      frames: 1,
      index: 12864
    },
    {
      id: "red_concrete_powder",
      frames: 1,
      index: 12880
    },
    {
      id: "red_glazed_terracotta",
      frames: 1,
      index: 12896
    },
    {
      id: "red_mushroom",
      frames: 1,
      index: 12912
    },
    {
      id: "red_mushroom_block",
      frames: 1,
      index: 12928
    },
    {
      id: "red_nether_bricks",
      frames: 1,
      index: 12944
    },
    {
      id: "red_sand",
      frames: 1,
      index: 12960
    },
    {
      id: "red_sandstone",
      frames: 1,
      index: 12976
    },
    {
      id: "red_sandstone_bottom",
      frames: 1,
      index: 12992
    },
    {
      id: "red_sandstone_top",
      frames: 1,
      index: 13008
    },
    {
      id: "red_shulker_box",
      frames: 1,
      index: 13024
    },
    {
      id: "red_stained_glass",
      frames: 1,
      index: 13040
    },
    {
      id: "red_stained_glass_pane_top",
      frames: 1,
      index: 13056
    },
    {
      id: "red_terracotta",
      frames: 1,
      index: 13072
    },
    {
      id: "red_tulip",
      frames: 1,
      index: 13088
    },
    {
      id: "red_wool",
      frames: 1,
      index: 13104
    },
    {
      id: "redstone_block",
      frames: 1,
      index: 13120
    },
    {
      id: "redstone_dust_dot",
      frames: 1,
      index: 13136
    },
    {
      id: "redstone_dust_line0",
      frames: 1,
      index: 13152
    },
    {
      id: "redstone_dust_line1",
      frames: 1,
      index: 13168
    },
    {
      id: "redstone_dust_overlay",
      frames: 1,
      index: 13184
    },
    {
      id: "redstone_lamp",
      frames: 1,
      index: 13200
    },
    {
      id: "redstone_lamp_on",
      frames: 1,
      index: 13216
    },
    {
      id: "redstone_ore",
      frames: 1,
      index: 13232
    },
    {
      id: "redstone_torch",
      frames: 1,
      index: 13248
    },
    {
      id: "redstone_torch_off",
      frames: 1,
      index: 13264
    },
    {
      id: "repeater",
      frames: 1,
      index: 13280
    },
    {
      id: "repeater_on",
      frames: 1,
      index: 13296
    },
    {
      id: "repeating_command_block_back",
      frames: 4,
      index: 13312
    },
    {
      id: "repeating_command_block_conditional",
      frames: 4,
      index: 13376
    },
    {
      id: "repeating_command_block_front",
      frames: 4,
      index: 13440
    },
    {
      id: "repeating_command_block_side",
      frames: 4,
      index: 13504
    },
    {
      id: "respawn_anchor_bottom",
      frames: 1,
      index: 13568
    },
    {
      id: "respawn_anchor_side0",
      frames: 1,
      index: 13584
    },
    {
      id: "respawn_anchor_side1",
      frames: 1,
      index: 13600
    },
    {
      id: "respawn_anchor_side2",
      frames: 1,
      index: 13616
    },
    {
      id: "respawn_anchor_side3",
      frames: 1,
      index: 13632
    },
    {
      id: "respawn_anchor_side4",
      frames: 1,
      index: 13648
    },
    {
      id: "respawn_anchor_top",
      frames: 32,
      index: 13664
    },
    {
      id: "respawn_anchor_top_off",
      frames: 1,
      index: 14176
    },
    {
      id: "rooted_dirt",
      frames: 1,
      index: 14192
    },
    {
      id: "rose_bush_bottom",
      frames: 1,
      index: 14208
    },
    {
      id: "rose_bush_top",
      frames: 1,
      index: 14224
    },
    {
      id: "sand",
      frames: 1,
      index: 14240
    },
    {
      id: "sandstone",
      frames: 1,
      index: 14256
    },
    {
      id: "sandstone_bottom",
      frames: 1,
      index: 14272
    },
    {
      id: "sandstone_top",
      frames: 1,
      index: 14288
    },
    {
      id: "scaffolding_bottom",
      frames: 1,
      index: 14304
    },
    {
      id: "scaffolding_side",
      frames: 1,
      index: 14320
    },
    {
      id: "scaffolding_top",
      frames: 1,
      index: 14336
    },
    {
      id: "sculk_sensor_bottom",
      frames: 1,
      index: 14352
    },
    {
      id: "sculk_sensor_side",
      frames: 1,
      index: 14368
    },
    {
      id: "sculk_sensor_tendril_active",
      frames: 16,
      index: 14384
    },
    {
      id: "sculk_sensor_tendril_inactive",
      frames: 16,
      index: 14640
    },
    {
      id: "sculk_sensor_top",
      frames: 1,
      index: 14896
    },
    {
      id: "sea_lantern",
      frames: 5,
      index: 14912
    },
    {
      id: "sea_pickle",
      frames: 1,
      index: 14992
    },
    {
      id: "seagrass",
      frames: 18,
      index: 15008
    },
    {
      id: "shroomlight",
      frames: 1,
      index: 15296
    },
    {
      id: "shulker_box",
      frames: 1,
      index: 15312
    },
    {
      id: "slime_block",
      frames: 1,
      index: 15328
    },
    {
      id: "small_amethyst_bud",
      frames: 1,
      index: 15344
    },
    {
      id: "small_dripleaf_side",
      frames: 1,
      index: 15360
    },
    {
      id: "small_dripleaf_stem_bottom",
      frames: 1,
      index: 15376
    },
    {
      id: "small_dripleaf_stem_top",
      frames: 1,
      index: 15392
    },
    {
      id: "small_dripleaf_top",
      frames: 1,
      index: 15408
    },
    {
      id: "smithing_table_bottom",
      frames: 1,
      index: 15424
    },
    {
      id: "smithing_table_front",
      frames: 1,
      index: 15440
    },
    {
      id: "smithing_table_side",
      frames: 1,
      index: 15456
    },
    {
      id: "smithing_table_top",
      frames: 1,
      index: 15472
    },
    {
      id: "smoker_bottom",
      frames: 1,
      index: 15488
    },
    {
      id: "smoker_front",
      frames: 1,
      index: 15504
    },
    {
      id: "smoker_front_on",
      frames: 3,
      index: 15520
    },
    {
      id: "smoker_side",
      frames: 1,
      index: 15568
    },
    {
      id: "smoker_top",
      frames: 1,
      index: 15584
    },
    {
      id: "smooth_basalt",
      frames: 1,
      index: 15600
    },
    {
      id: "smooth_stone",
      frames: 1,
      index: 15616
    },
    {
      id: "smooth_stone_slab_side",
      frames: 1,
      index: 15632
    },
    {
      id: "snow",
      frames: 1,
      index: 15648
    },
    {
      id: "soul_campfire_fire",
      frames: 8,
      index: 15664
    },
    {
      id: "soul_campfire_log_lit",
      frames: 4,
      index: 15792
    },
    {
      id: "soul_fire_0",
      frames: 32,
      index: 15856
    },
    {
      id: "soul_fire_1",
      frames: 32,
      index: 16368
    },
    {
      id: "soul_lantern",
      frames: 3,
      index: 16880
    },
    {
      id: "soul_sand",
      frames: 1,
      index: 16928
    },
    {
      id: "soul_soil",
      frames: 1,
      index: 16944
    },
    {
      id: "soul_torch",
      frames: 1,
      index: 16960
    },
    {
      id: "spawner",
      frames: 1,
      index: 16976
    },
    {
      id: "sponge",
      frames: 1,
      index: 16992
    },
    {
      id: "spore_blossom",
      frames: 1,
      index: 17008
    },
    {
      id: "spore_blossom_base",
      frames: 1,
      index: 17024
    },
    {
      id: "spruce_door_bottom",
      frames: 1,
      index: 17040
    },
    {
      id: "spruce_door_top",
      frames: 1,
      index: 17056
    },
    {
      id: "spruce_leaves",
      frames: 1,
      index: 17072
    },
    {
      id: "spruce_log",
      frames: 1,
      index: 17088
    },
    {
      id: "spruce_log_top",
      frames: 1,
      index: 17104
    },
    {
      id: "spruce_planks",
      frames: 1,
      index: 17120
    },
    {
      id: "spruce_sapling",
      frames: 1,
      index: 17136
    },
    {
      id: "spruce_trapdoor",
      frames: 1,
      index: 17152
    },
    {
      id: "stone",
      frames: 1,
      index: 17168
    },
    {
      id: "stone_bricks",
      frames: 1,
      index: 17184
    },
    {
      id: "stonecutter_bottom",
      frames: 1,
      index: 17200
    },
    {
      id: "stonecutter_saw",
      frames: 3,
      index: 17216
    },
    {
      id: "stonecutter_side",
      frames: 1,
      index: 17264
    },
    {
      id: "stonecutter_top",
      frames: 1,
      index: 17280
    },
    {
      id: "stripped_acacia_log",
      frames: 1,
      index: 17296
    },
    {
      id: "stripped_acacia_log_top",
      frames: 1,
      index: 17312
    },
    {
      id: "stripped_birch_log",
      frames: 1,
      index: 17328
    },
    {
      id: "stripped_birch_log_top",
      frames: 1,
      index: 17344
    },
    {
      id: "stripped_crimson_stem",
      frames: 1,
      index: 17360
    },
    {
      id: "stripped_crimson_stem_top",
      frames: 1,
      index: 17376
    },
    {
      id: "stripped_dark_oak_log",
      frames: 1,
      index: 17392
    },
    {
      id: "stripped_dark_oak_log_top",
      frames: 1,
      index: 17408
    },
    {
      id: "stripped_jungle_log",
      frames: 1,
      index: 17424
    },
    {
      id: "stripped_jungle_log_top",
      frames: 1,
      index: 17440
    },
    {
      id: "stripped_oak_log",
      frames: 1,
      index: 17456
    },
    {
      id: "stripped_oak_log_top",
      frames: 1,
      index: 17472
    },
    {
      id: "stripped_spruce_log",
      frames: 1,
      index: 17488
    },
    {
      id: "stripped_spruce_log_top",
      frames: 1,
      index: 17504
    },
    {
      id: "stripped_warped_stem",
      frames: 1,
      index: 17520
    },
    {
      id: "stripped_warped_stem_top",
      frames: 1,
      index: 17536
    },
    {
      id: "structure_block",
      frames: 1,
      index: 17552
    },
    {
      id: "structure_block_corner",
      frames: 1,
      index: 17568
    },
    {
      id: "structure_block_data",
      frames: 1,
      index: 17584
    },
    {
      id: "structure_block_load",
      frames: 1,
      index: 17600
    },
    {
      id: "structure_block_save",
      frames: 1,
      index: 17616
    },
    {
      id: "sugar_cane",
      frames: 1,
      index: 17632
    },
    {
      id: "sunflower_back",
      frames: 1,
      index: 17648
    },
    {
      id: "sunflower_bottom",
      frames: 1,
      index: 17664
    },
    {
      id: "sunflower_front",
      frames: 1,
      index: 17680
    },
    {
      id: "sunflower_top",
      frames: 1,
      index: 17696
    },
    {
      id: "sweet_berry_bush_stage0",
      frames: 1,
      index: 17712
    },
    {
      id: "sweet_berry_bush_stage1",
      frames: 1,
      index: 17728
    },
    {
      id: "sweet_berry_bush_stage2",
      frames: 1,
      index: 17744
    },
    {
      id: "sweet_berry_bush_stage3",
      frames: 1,
      index: 17760
    },
    {
      id: "tall_grass_bottom",
      frames: 1,
      index: 17776
    },
    {
      id: "tall_grass_top",
      frames: 1,
      index: 17792
    },
    {
      id: "tall_seagrass_bottom",
      frames: 19,
      index: 17808
    },
    {
      id: "tall_seagrass_top",
      frames: 19,
      index: 18112
    },
    {
      id: "target_side",
      frames: 1,
      index: 18416
    },
    {
      id: "target_top",
      frames: 1,
      index: 18432
    },
    {
      id: "terracotta",
      frames: 1,
      index: 18448
    },
    {
      id: "tinted_glass",
      frames: 1,
      index: 18464
    },
    {
      id: "tnt_bottom",
      frames: 1,
      index: 18480
    },
    {
      id: "tnt_side",
      frames: 1,
      index: 18496
    },
    {
      id: "tnt_top",
      frames: 1,
      index: 18512
    },
    {
      id: "torch",
      frames: 1,
      index: 18528
    },
    {
      id: "tripwire",
      frames: 1,
      index: 18544
    },
    {
      id: "tripwire_hook",
      frames: 1,
      index: 18560
    },
    {
      id: "tube_coral",
      frames: 1,
      index: 18576
    },
    {
      id: "tube_coral_block",
      frames: 1,
      index: 18592
    },
    {
      id: "tube_coral_fan",
      frames: 1,
      index: 18608
    },
    {
      id: "tuff",
      frames: 1,
      index: 18624
    },
    {
      id: "turtle_egg",
      frames: 1,
      index: 18640
    },
    {
      id: "turtle_egg_slightly_cracked",
      frames: 1,
      index: 18656
    },
    {
      id: "turtle_egg_very_cracked",
      frames: 1,
      index: 18672
    },
    {
      id: "twisting_vines",
      frames: 1,
      index: 18688
    },
    {
      id: "twisting_vines_plant",
      frames: 1,
      index: 18704
    },
    {
      id: "vine",
      frames: 1,
      index: 18720
    },
    {
      id: "warped_door_bottom",
      frames: 1,
      index: 18736
    },
    {
      id: "warped_door_top",
      frames: 1,
      index: 18752
    },
    {
      id: "warped_fungus",
      frames: 1,
      index: 18768
    },
    {
      id: "warped_nylium",
      frames: 1,
      index: 18784
    },
    {
      id: "warped_nylium_side",
      frames: 1,
      index: 18800
    },
    {
      id: "warped_planks",
      frames: 1,
      index: 18816
    },
    {
      id: "warped_roots",
      frames: 1,
      index: 18832
    },
    {
      id: "warped_roots_pot",
      frames: 1,
      index: 18848
    },
    {
      id: "warped_stem",
      frames: 5,
      index: 18864
    },
    {
      id: "warped_stem_top",
      frames: 1,
      index: 18944
    },
    {
      id: "warped_trapdoor",
      frames: 1,
      index: 18960
    },
    {
      id: "warped_wart_block",
      frames: 1,
      index: 18976
    },
    {
      id: "water_overlay",
      frames: 1,
      index: 18992
    },
    {
      id: "water_still",
      frames: 32,
      index: 19008
    },
    {
      id: "weathered_copper",
      frames: 1,
      index: 19520
    },
    {
      id: "weathered_cut_copper",
      frames: 1,
      index: 19536
    },
    {
      id: "weeping_vines",
      frames: 1,
      index: 19552
    },
    {
      id: "weeping_vines_plant",
      frames: 1,
      index: 19568
    },
    {
      id: "wet_sponge",
      frames: 1,
      index: 19584
    },
    {
      id: "wheat_stage0",
      frames: 1,
      index: 19600
    },
    {
      id: "wheat_stage1",
      frames: 1,
      index: 19616
    },
    {
      id: "wheat_stage2",
      frames: 1,
      index: 19632
    },
    {
      id: "wheat_stage3",
      frames: 1,
      index: 19648
    },
    {
      id: "wheat_stage4",
      frames: 1,
      index: 19664
    },
    {
      id: "wheat_stage5",
      frames: 1,
      index: 19680
    },
    {
      id: "wheat_stage6",
      frames: 1,
      index: 19696
    },
    {
      id: "wheat_stage7",
      frames: 1,
      index: 19712
    },
    {
      id: "white_candle",
      frames: 1,
      index: 19728
    },
    {
      id: "white_candle_lit",
      frames: 1,
      index: 19744
    },
    {
      id: "white_concrete",
      frames: 1,
      index: 19760
    },
    {
      id: "white_concrete_powder",
      frames: 1,
      index: 19776
    },
    {
      id: "white_glazed_terracotta",
      frames: 1,
      index: 19792
    },
    {
      id: "white_shulker_box",
      frames: 1,
      index: 19808
    },
    {
      id: "white_stained_glass",
      frames: 1,
      index: 19824
    },
    {
      id: "white_stained_glass_pane_top",
      frames: 1,
      index: 19840
    },
    {
      id: "white_terracotta",
      frames: 1,
      index: 19856
    },
    {
      id: "white_tulip",
      frames: 1,
      index: 19872
    },
    {
      id: "white_wool",
      frames: 1,
      index: 19888
    },
    {
      id: "wither_rose",
      frames: 1,
      index: 19904
    },
    {
      id: "yellow_candle",
      frames: 1,
      index: 19920
    },
    {
      id: "yellow_candle_lit",
      frames: 1,
      index: 19936
    },
    {
      id: "yellow_concrete",
      frames: 1,
      index: 19952
    },
    {
      id: "yellow_concrete_powder",
      frames: 1,
      index: 19968
    },
    {
      id: "yellow_glazed_terracotta",
      frames: 1,
      index: 19984
    },
    {
      id: "yellow_shulker_box",
      frames: 1,
      index: 20000
    },
    {
      id: "yellow_stained_glass",
      frames: 1,
      index: 20016
    },
    {
      id: "yellow_stained_glass_pane_top",
      frames: 1,
      index: 20032
    },
    {
      id: "yellow_terracotta",
      frames: 1,
      index: 20048
    },
    {
      id: "yellow_wool",
      frames: 1,
      index: 20064
    }
  ]
};

var data = [
  texture,
  version
];

exports.texture = texture;
exports.version = version;
exports.data = data;
/* texture Not a pure module */
