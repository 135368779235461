// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "minecraft-mutant-character";

var name = "Mutant Character";

var history = ["Created by PaperDogChannel."];

var video = {
  url: "https://www.youtube.com/embed/DVzumgRinjY?rel=0"
};

var thumbnail = {
  url: require("./thumbnail/thumbnail-256.jpeg")
};

var images = [
  {
    id: "Page1",
    url: require("./images/Page1.png")
  },
  {
    id: "Page2",
    url: require("./images/Page2.png")
  },
  {
    id: "Page3",
    url: require("./images/Page3.png")
  },
  {
    id: "Page4",
    url: require("./images/Page4.png")
  },
  {
    id: "Hole1",
    url: require("./images/Hole1.png")
  },
  {
    id: "Hole2",
    url: require("./images/Hole2.png")
  },
  {
    id: "Hole3",
    url: require("./images/Hole3.png")
  }
];

var textures = [{
    id: "Skin",
    url: require("./textures/Skin.png"),
    standardWidth: 64,
    standardHeight: 64
  }];

function script(param) {
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineSelectInput("Skin style", [
        "Steve",
        "Alex"
      ]);
  var alexModel = Generator.getSelectInputValue("Skin style") === "Alex";
  Generator.usePage("Page 1");
  Generator.drawImage("Page1", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 260,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 2,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 88,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 174,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 2,
        y: 2,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 2,
        y: 174,
        w: 84,
        h: 84
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 260,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 40,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 2,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 48,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 88,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 56,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 174,
        y: 88,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 40,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 2,
        y: 2,
        w: 84,
        h: 84
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 48,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 2,
        y: 174,
        w: 84,
        h: 84
      }, "Vertical", undefined, undefined, undefined);
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 200,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 20,
          w: 3,
          h: 6
        }, {
          x: 2,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 68,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 20,
          w: 3,
          h: 6
        }, {
          x: 134,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 2,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 36,
          w: 4,
          h: 6
        }, {
          x: 200,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 36,
          w: 3,
          h: 6
        }, {
          x: 2,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 36,
          w: 4,
          h: 6
        }, {
          x: 68,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 36,
          w: 3,
          h: 6
        }, {
          x: 134,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 32,
          w: 3,
          h: 4
        }, {
          x: 2,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 488,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 52,
          w: 3,
          h: 6
        }, {
          x: 290,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 356,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 52,
          w: 3,
          h: 6
        }, {
          x: 422,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 290,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 488,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 52,
          w: 3,
          h: 6
        }, {
          x: 290,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 55,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 356,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 59,
          y: 52,
          w: 3,
          h: 6
        }, {
          x: 422,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 290,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 200,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 2,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 68,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 134,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 2,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 36,
          w: 4,
          h: 6
        }, {
          x: 200,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 36,
          w: 4,
          h: 6
        }, {
          x: 2,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 36,
          w: 4,
          h: 6
        }, {
          x: 68,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 36,
          w: 4,
          h: 6
        }, {
          x: 134,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 2,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 488,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 290,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 356,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 422,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 290,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 488,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 290,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 356,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 60,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 422,
          y: 330,
          w: 64,
          h: 168
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 290,
          y: 264,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 20,
        w: 4,
        h: 6
      }, {
        x: 204,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 20,
        w: 4,
        h: 6
      }, {
        x: 6,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 20,
        w: 4,
        h: 6
      }, {
        x: 72,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 20,
        w: 4,
        h: 6
      }, {
        x: 138,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 6,
        y: 529,
        w: 64,
        h: 65
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 36,
        w: 4,
        h: 6
      }, {
        x: 204,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 36,
        w: 4,
        h: 6
      }, {
        x: 6,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 36,
        w: 4,
        h: 6
      }, {
        x: 72,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 36,
        w: 4,
        h: 6
      }, {
        x: 138,
        y: 596,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 32,
        w: 4,
        h: 4
      }, {
        x: 6,
        y: 529,
        w: 64,
        h: 65
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 487,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 289,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 355,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 421,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 289,
        y: 527,
        w: 64,
        h: 65
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 487,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 289,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 355,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 52,
        w: 4,
        h: 6
      }, {
        x: 421,
        y: 594,
        w: 64,
        h: 116
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 289,
        y: 527,
        w: 64,
        h: 65
      }, undefined, undefined, undefined, undefined);
  Generator.usePage("Page 2");
  Generator.drawImage("Page2", [
        0,
        0
      ]);
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 26,
          w: 4,
          h: 6
        }, {
          x: 190,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 26,
          w: 3,
          h: 6
        }, {
          x: 2,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 26,
          w: 4,
          h: 6
        }, {
          x: 65,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 26,
          w: 3,
          h: 6
        }, {
          x: 127,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 2,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 2,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 42,
          w: 4,
          h: 6
        }, {
          x: 190,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 42,
          w: 3,
          h: 6
        }, {
          x: 2,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 42,
          w: 4,
          h: 6
        }, {
          x: 65,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 42,
          w: 3,
          h: 6
        }, {
          x: 127,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 32,
          w: 3,
          h: 4
        }, {
          x: 2,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 32,
          w: 3,
          h: 4
        }, {
          x: 2,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 457,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 58,
          w: 3,
          h: 6
        }, {
          x: 269,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 332,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 58,
          w: 3,
          h: 6
        }, {
          x: 394,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 269,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 269,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 457,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 58,
          w: 3,
          h: 6
        }, {
          x: 269,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 55,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 332,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 59,
          y: 58,
          w: 3,
          h: 6
        }, {
          x: 394,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 269,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 55,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 269,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 26,
          w: 4,
          h: 6
        }, {
          x: 190,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 26,
          w: 4,
          h: 6
        }, {
          x: 2,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 26,
          w: 4,
          h: 6
        }, {
          x: 65,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 26,
          w: 4,
          h: 6
        }, {
          x: 127,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 2,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 2,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 42,
          w: 4,
          h: 6
        }, {
          x: 190,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 42,
          w: 4,
          h: 6
        }, {
          x: 2,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 42,
          w: 4,
          h: 6
        }, {
          x: 65,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 42,
          w: 4,
          h: 6
        }, {
          x: 127,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 2,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 2,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 457,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 269,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 332,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 394,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 269,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 269,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 457,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 269,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 332,
          y: 65,
          w: 60,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 60,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 394,
          y: 65,
          w: 61,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 269,
          y: 2,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 269,
          y: 227,
          w: 61,
          h: 61
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 26,
        w: 4,
        h: 6
      }, {
        x: 191,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 26,
        w: 4,
        h: 6
      }, {
        x: 3,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 26,
        w: 4,
        h: 6
      }, {
        x: 66,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 26,
        w: 4,
        h: 6
      }, {
        x: 128,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 3,
        y: 295,
        w: 61,
        h: 62
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 3,
        y: 471,
        w: 61,
        h: 62
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 42,
        w: 4,
        h: 6
      }, {
        x: 191,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 42,
        w: 4,
        h: 6
      }, {
        x: 3,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 42,
        w: 4,
        h: 6
      }, {
        x: 66,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 42,
        w: 4,
        h: 6
      }, {
        x: 128,
        y: 359,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 32,
        w: 4,
        h: 4
      }, {
        x: 3,
        y: 295,
        w: 61,
        h: 62
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 32,
        w: 4,
        h: 4
      }, {
        x: 3,
        y: 471,
        w: 61,
        h: 62
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 459,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 270,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 333,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 396,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 270,
        y: 293,
        w: 61,
        h: 62
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 270,
        y: 469,
        w: 61,
        h: 62
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 459,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 270,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 333,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 396,
        y: 357,
        w: 61,
        h: 110
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 270,
        y: 293,
        w: 61,
        h: 62
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 270,
        y: 469,
        w: 61,
        h: 62
      }, "Vertical", undefined, undefined, undefined);
  Generator.usePage("Page 3");
  Generator.drawImage("Page3", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 7
      }, {
        x: 131,
        y: 4,
        w: 168,
        h: 126
      }, undefined, 90.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 7
      }, {
        x: 133,
        y: 174,
        w: 252,
        h: 126
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 7
      }, {
        x: 387,
        y: 172,
        w: 168,
        h: 126
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 7
      }, {
        x: 385,
        y: 598,
        w: 252,
        h: 126
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 133,
        y: 4,
        w: 252,
        h: 168
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 26,
        w: 8,
        h: 1
      }, {
        x: 133,
        y: 302,
        w: 252,
        h: 168
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 36,
        w: 4,
        h: 7
      }, {
        x: 131,
        y: 4,
        w: 168,
        h: 126
      }, undefined, 90.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 36,
        w: 8,
        h: 7
      }, {
        x: 133,
        y: 174,
        w: 252,
        h: 126
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 36,
        w: 4,
        h: 7
      }, {
        x: 387,
        y: 172,
        w: 168,
        h: 126
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 36,
        w: 8,
        h: 7
      }, {
        x: 385,
        y: 598,
        w: 252,
        h: 126
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 32,
        w: 8,
        h: 4
      }, {
        x: 133,
        y: 4,
        w: 252,
        h: 168
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 42,
        w: 8,
        h: 1
      }, {
        x: 133,
        y: 302,
        w: 252,
        h: 168
      }, undefined, undefined, undefined, undefined);
  Generator.drawImage("Hole1", [
        216,
        32
      ]);
  Generator.drawImage("Hole2", [
        182,
        320
      ]);
  Generator.usePage("Page 4");
  Generator.drawImage("Page4", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 27,
        w: 4,
        h: 5
      }, {
        x: 434,
        y: 134,
        w: 128,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 27,
        w: 8,
        h: 5
      }, {
        x: 3,
        y: 134,
        w: 150,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 27,
        w: 4,
        h: 5
      }, {
        x: 154,
        y: 134,
        w: 128,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 27,
        w: 8,
        h: 5
      }, {
        x: 283,
        y: 134,
        w: 150,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 3,
        y: 306,
        w: 150,
        h: 128
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 26,
        w: 8,
        h: 1
      }, {
        x: 3,
        y: 5,
        w: 150,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 41,
        w: 4,
        h: 5
      }, {
        x: 434,
        y: 134,
        w: 128,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 41,
        w: 8,
        h: 5
      }, {
        x: 3,
        y: 134,
        w: 150,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 41,
        w: 4,
        h: 5
      }, {
        x: 154,
        y: 134,
        w: 128,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 41,
        w: 8,
        h: 5
      }, {
        x: 283,
        y: 134,
        w: 150,
        h: 171
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 39,
        w: 8,
        h: 9
      }, {
        x: 3,
        y: 306,
        w: 150,
        h: 128
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 42,
        w: 8,
        h: 1
      }, {
        x: 3,
        y: 5,
        w: 150,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawImage("Hole3", [
        3,
        339
      ]);
  Generator.drawImage("Hole3", [
        87,
        339
      ]);
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.video = video;
exports.thumbnail = thumbnail;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
