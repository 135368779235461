// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var MinecraftBlock_Texture_minecraft_1_13_2 = require("./block-textures/MinecraftBlock_Texture_minecraft_1_13_2.bs.js");
var MinecraftBlock_Texture_minecraft_1_18_1 = require("./block-textures/MinecraftBlock_Texture_minecraft_1_18_1.bs.js");

var definitions = [
  MinecraftBlock_Texture_minecraft_1_13_2.data,
  MinecraftBlock_Texture_minecraft_1_18_1.data
];

var versions = Belt_Array.map(definitions, (function (param) {
        return param[1];
      }));

var textures = Belt_Array.map(definitions, (function (param) {
        return param[0];
      }));

var TextureData = {
  definitions: definitions,
  versions: versions,
  textures: textures
};

var versionIds = Belt_Array.reverse(versions.map(function (version) {
          return version.id;
        }));

function findVersion(versionId) {
  return Caml_option.undefined_to_opt(versions.find(function (version) {
                  return version.id === versionId;
                }));
}

function findVersionTexture(versionId, textureId) {
  var version = findVersion(versionId);
  if (version !== undefined) {
    return Caml_option.undefined_to_opt(Caml_option.valFromOption(version).textures.find(function (texture) {
                    return texture.id === textureId;
                  }));
  }
  
}

function findTextureDef(versionId) {
  return Caml_option.undefined_to_opt(textures.find(function (texture) {
                  return texture.id === versionId;
                }));
}

function calculateTextureFrameIndex(textureIndex, frame) {
  return textureIndex + ((frame - 1 | 0) << 4) | 0;
}

function findTextureFrames(versionId) {
  var version = findVersion(versionId);
  if (version !== undefined) {
    return Belt_Array.concatMany(Caml_option.valFromOption(version).textures.map(function (texture) {
                    var textureId = texture.id;
                    var frames = texture.frames;
                    var index = texture.index;
                    return Belt_Array.map(Belt_Array.range(1, frames), (function (frame) {
                                  var frameIndex = calculateTextureFrameIndex(index, frame);
                                  return {
                                          versionId: versionId,
                                          textureId: textureId,
                                          frame: frame,
                                          frameIndex: frameIndex,
                                          blend: "None"
                                        };
                                }));
                  }));
  }
  
}

function findTextureFrameIndex(versionId, textureId, frame) {
  var texture = findVersionTexture(versionId, textureId);
  if (texture === undefined) {
    return ;
  }
  var textureIndex = Caml_option.valFromOption(texture).index;
  return calculateTextureFrameIndex(textureIndex, frame);
}

function findTextureFrameCount(versionId, textureId) {
  var texture = findVersionTexture(versionId, textureId);
  if (texture !== undefined) {
    return Caml_option.valFromOption(texture).frames;
  } else {
    return 0;
  }
}

var Builder;

var textureSize = 16;

exports.Builder = Builder;
exports.TextureData = TextureData;
exports.textureSize = textureSize;
exports.textures = textures;
exports.versionIds = versionIds;
exports.findVersion = findVersion;
exports.findVersionTexture = findVersionTexture;
exports.findTextureDef = findTextureDef;
exports.calculateTextureFrameIndex = calculateTextureFrameIndex;
exports.findTextureFrames = findTextureFrames;
exports.findTextureFrameIndex = findTextureFrameIndex;
exports.findTextureFrameCount = findTextureFrameCount;
/* versions Not a pure module */
