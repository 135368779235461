// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Generator = require("../../builder/modules/Generator.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Markdown = require("../../builder/views/Generator_Markdown.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-cat";

var name = "Minecraft Cat";

var history = [
  "Originally created by Micaias32.",
  "15 Mar 2021 Micaias32 - All cats of 1.14.",
  "03 Feb 2022 NinjolasNJM - Converted to new generator builder, with updated backgrounds, folds and labels, as well as improved texture mappping and collar handling.",
  "06 Aug 2022 M16 - Update thumbnail photo."
];

var thumbnail = {
  url: require("./thumbnail/thumbnail-v2-256.jpeg")
};

var catTexture = requireTexture("white");

var instructions = "\n## How to use the Minecraft Cat Generator?\n\n### Option 1: Use a texture pack or mod Cat skin\n\n* Download your favourite texture pack or mod.\n* Find a cat texture file.\n* Select this file in the generator.\n* Download and print your new Cat papercraft. \n\n## Option 2: Create your own Cat texture file\n\n* Download a sample Cat texture (right click and save):\n  ![Car Texture](" + catTexture + ")\n* Edit this texture in your favourite graphics program.\n* Select this file in the generator.\n* Download and print your new Cat papercraft.\n";

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Cat",
    url: requireTexture("ocelot"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Black",
    url: requireTexture("all_black"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "British Shorthair",
    url: requireTexture("british_shorthair"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Calico",
    url: requireTexture("calico"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Jellie",
    url: requireTexture("jellie"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Ocelot",
    url: requireTexture("ocelot"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Orange Tabby",
    url: requireTexture("red"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Persian",
    url: requireTexture("persian"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Ragdoll",
    url: requireTexture("ragdoll"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Siamese",
    url: requireTexture("siamese"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Tabby",
    url: requireTexture("tabby"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Tuxedo",
    url: requireTexture("black"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "White",
    url: requireTexture("white"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Cat Collar",
    url: requireTexture("cat_collar"),
    standardWidth: 64,
    standardHeight: 32
  }
];

function script(param) {
  Generator.defineTextureInput("Cat", {
        standardWidth: 64,
        standardHeight: 32,
        choices: [
          "Black",
          "British Shorthair",
          "Calico",
          "Jellie",
          "Ocelot",
          "Orange Tabby",
          "Persian",
          "Ragdoll",
          "Siamese",
          "Tabby",
          "Tuxedo",
          "White"
        ]
      });
  Generator.defineTextureInput("Collar", {
        standardWidth: 64,
        standardHeight: 32,
        choices: ["Cat Collar"]
      });
  Generator.defineSelectInput("Collar Color", [
        "Black",
        "Red",
        "Green",
        "Brown",
        "Blue",
        "Purple",
        "Cyan",
        "Light Gray",
        "Gray",
        "Pink",
        "Lime",
        "Yellow",
        "Light Blue",
        "Magenta",
        "Orange",
        "White"
      ]);
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var match = Generator.getSelectInputValue("Collar Color");
  var collarColor;
  switch (match) {
    case "Black" :
        collarColor = "1D1D21";
        break;
    case "Blue" :
        collarColor = "3C44AA";
        break;
    case "Brown" :
        collarColor = "835432";
        break;
    case "Cyan" :
        collarColor = "169C9C";
        break;
    case "Gray" :
        collarColor = "474F52";
        break;
    case "Green" :
        collarColor = "5E7C16";
        break;
    case "Light Blue" :
        collarColor = "3AB3DA";
        break;
    case "Light Gray" :
        collarColor = "9D9D97";
        break;
    case "Lime" :
        collarColor = "80C71F";
        break;
    case "Magenta" :
        collarColor = "C74EBD";
        break;
    case "Orange" :
        collarColor = "F9801D";
        break;
    case "Pink" :
        collarColor = "F38BAA";
        break;
    case "Purple" :
        collarColor = "8932B8";
        break;
    case "Red" :
        collarColor = "B02E26";
        break;
    case "White" :
        collarColor = "F9FFFE";
        break;
    case "Yellow" :
        collarColor = "FED83D";
        break;
    default:
      collarColor = "B02E26";
  }
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var drawCat = function (texture, tint) {
    Generator.drawTexture(texture, [
          0,
          5,
          20,
          4
        ], [
          40,
          73,
          160,
          32
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          0,
          5,
          5
        ], [
          80,
          33,
          40,
          40
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          10,
          0,
          5,
          5
        ], [
          80,
          105,
          40,
          40
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          20,
          6,
          6,
          16
        ], [
          40,
          241,
          48,
          128
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          26,
          6,
          4,
          16
        ], [
          88,
          241,
          32,
          128
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          30,
          6,
          6,
          16
        ], [
          120,
          241,
          48,
          128
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          36,
          6,
          4,
          16
        ], [
          168,
          241,
          32,
          128
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          26,
          0,
          4,
          6
        ], [
          88,
          193,
          32,
          48
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          30,
          0,
          4,
          6
        ], [
          88,
          369,
          32,
          48
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          8,
          17,
          8,
          4
        ], [
          251,
          336,
          64,
          32
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          10,
          13,
          2,
          2
        ], [
          267,
          320,
          16,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          10,
          13,
          2,
          2
        ], [
          267,
          368,
          16,
          16
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          8,
          17,
          8,
          4
        ], [
          340,
          336,
          64,
          32
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          10,
          13,
          2,
          2
        ], [
          356,
          320,
          16,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          10,
          13,
          2,
          2
        ], [
          356,
          368,
          16,
          16
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          40,
          8,
          8,
          4
        ], [
          251,
          248,
          64,
          32
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          42,
          0,
          2,
          2
        ], [
          267,
          232,
          16,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          44,
          0,
          2,
          2
        ], [
          267,
          280,
          16,
          16
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          40,
          8,
          8,
          4
        ], [
          340,
          248,
          64,
          32
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          42,
          0,
          2,
          2
        ], [
          356,
          232,
          16,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          44,
          0,
          2,
          2
        ], [
          356,
          280,
          16,
          16
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          2,
          16,
          1,
          8
        ], [
          469,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          3,
          16,
          1,
          8
        ], [
          477,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          0,
          16,
          1,
          8
        ], [
          485,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          1,
          16,
          1,
          8
        ], [
          493,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          2,
          15,
          1,
          1
        ], [
          477,
          358,
          8,
          8
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          6,
          16,
          1,
          8
        ], [
          541,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          7,
          16,
          1,
          8
        ], [
          549,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          4,
          16,
          1,
          8
        ], [
          557,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          16,
          1,
          8
        ], [
          565,
          294,
          8,
          64
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          6,
          15,
          1,
          1
        ], [
          549,
          358,
          8,
          8
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          2,
          26,
          3,
          2
        ], [
          256,
          80,
          24,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          2,
          25,
          3,
          1
        ], [
          256,
          72,
          24,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          26,
          1,
          2
        ], [
          280,
          80,
          8,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          1,
          26,
          1,
          2
        ], [
          248,
          80,
          8,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          25,
          3,
          1
        ], [
          256,
          96,
          24,
          8
        ], "Vertical", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          6,
          12,
          2,
          1
        ], [
          253,
          161,
          16,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, 90.0, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          8,
          12,
          1,
          1
        ], [
          253,
          177,
          8,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          9,
          12,
          2,
          1
        ], [
          261,
          177,
          16,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, -90.0, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          11,
          12,
          1,
          1
        ], [
          261,
          161,
          8,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, 180.0, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          8,
          10,
          1,
          2
        ], [
          253,
          161,
          8,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          9,
          10,
          1,
          2
        ], [
          269,
          161,
          8,
          16
        ], "Horizontal", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          0,
          12,
          2,
          1
        ], [
          176,
          161,
          16,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, 90.0, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          2,
          12,
          1,
          1
        ], [
          176,
          177,
          8,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          3,
          12,
          2,
          1
        ], [
          184,
          177,
          16,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, -90.0, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          5,
          12,
          1,
          1
        ], [
          184,
          161,
          8,
          8
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, 180.0, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          2,
          10,
          1,
          2
        ], [
          176,
          161,
          8,
          16
        ], undefined, {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
    Generator.drawTexture(texture, [
          3,
          10,
          1,
          2
        ], [
          192,
          161,
          8,
          16
        ], "Horizontal", {
          NAME: "MultiplyHex",
          VAL: tint
        }, undefined, undefined, undefined, undefined);
  };
  drawCat("Cat", "None");
  drawCat("Collar", collarColor);
  Generator.drawImage("Background", [
        0,
        0
      ]);
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator_instructions = Caml_option.some(React.createElement(Generator_Markdown.make, {
          children: instructions
        }));

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: generator_instructions,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.instructions = instructions;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
