// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Dom2 = require("../../bindings/Dom2.bs.js");
var Js_math = require("rescript/lib/js/js_math.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Generator_ImageFactory = require("./Generator_ImageFactory.bs.js");
var Generator_CanvasFactory = require("./Generator_CanvasFactory.bs.js");
var Generator_ImageWithCanvas = require("./Generator_ImageWithCanvas.bs.js");

function make(image, standardWidth, standardHeight) {
  var imageWithCanvas = Generator_ImageWithCanvas.makeFromImage(image);
  return {
          standardWidth: standardWidth,
          standardHeight: standardHeight,
          imageWithCanvas: imageWithCanvas
        };
}

function makeFromUrl(url, standardWidth, standardHeight) {
  return Generator_ImageFactory.makeFromUrl(url).then(function (image) {
              return make(image, standardWidth, standardHeight);
            });
}

function parseHex(hex) {
  var hex$1 = hex.startsWith("#") ? hex.substr(1) : hex;
  if (hex$1.length !== 6) {
    return ;
  }
  var f = parseInt(hex$1, 16);
  if (Number.isNaN(f)) {
    return ;
  } else {
    return f | 0;
  }
}

var shift = (function shift(value, shift) {
      return value >> shift & 255
    });

function hexToRGB(hex) {
  var value = parseHex(hex);
  if (value === undefined) {
    return ;
  }
  var r = shift(value, 16);
  var g = shift(value, 8);
  var b = shift(value, 0);
  return [
          r,
          g,
          b
        ];
}

function blendColors(r1, g1, b1, r2, g2, b2) {
  return [
          r1 * r2 / 255.0 | 0,
          g1 * g2 / 255.0 | 0,
          b1 * b2 / 255.0 | 0
        ];
}

function fit(sw, sh, dw, dh) {
  var wScale = sw / dw;
  var hScale = sh / dh;
  var scale = Math.min(wScale, hScale);
  var match = scale < 1.0 ? [
      dw * scale,
      dh * scale
    ] : [
      dw,
      dh
    ];
  return [
          Js_math.ceil_int(match[0]),
          Js_math.ceil_int(match[1])
        ];
}

function preparePixelationCanvas(sourceCanvas, sx, sy, sw, sh, dw, dh) {
  var match = fit(sw, sh, dw, dh);
  var sh2 = match[1];
  var sw2 = match[0];
  var canvas = Generator_CanvasFactory.make(sw2, sh2);
  var context = Dom2.Canvas.getContext2d(canvas);
  context.imageSmoothingEnabled = false;
  context.drawImage(sourceCanvas, sx, sy, sw, sh, 0, 0, sw2, sh2);
  return canvas;
}

function drawNearestNeighbor(texture, page, sx, sy, sw, sh, dx, dy, dw, dh, options) {
  var match;
  if (options.pixelate) {
    var canvas = preparePixelationCanvas(texture.imageWithCanvas.canvasWithContext.canvas, sx, sy, sw, sh, dw, dh);
    match = [
      canvas,
      0,
      0,
      canvas.width,
      canvas.height,
      dx,
      dy,
      dw,
      dh
    ];
  } else {
    match = [
      texture.imageWithCanvas.canvasWithContext.canvas,
      sx,
      sy,
      sw,
      sh,
      dx,
      dy,
      dw,
      dh
    ];
  }
  var dh$1 = match[8];
  var dw$1 = match[7];
  var sh$1 = match[4];
  var sw$1 = match[3];
  if (!(sw$1 > 0 && sh$1 > 0 && dw$1 > 0 && dh$1 > 0)) {
    return ;
  }
  var imageData = Dom2.Canvas.getContext2d(match[0]).getImageData(match[1], match[2], sw$1, sh$1);
  var pix = imageData.data;
  var tempCanvas = Generator_CanvasFactory.make(dw$1, dh$1);
  var tempContext = Dom2.Canvas.getContext2d(tempCanvas);
  var deltax = dw$1 / sw$1;
  var deltay = dh$1 / sh$1;
  var pixw = Js_math.floor(deltax);
  var pixh = Js_math.floor(deltay);
  var pixw$1 = pixw < deltax ? pixw + 1 | 0 : pixw;
  var pixh$1 = pixh < deltay ? pixh + 1 | 0 : pixh;
  var match$1 = options.blend;
  var blend;
  if (typeof match$1 === "object") {
    if (match$1.NAME === "MultiplyRGB") {
      var match$2 = match$1.VAL;
      blend = [
        match$2[0],
        match$2[1],
        match$2[2]
      ];
    } else {
      blend = hexToRGB(match$1.VAL);
    }
  } else {
    blend = undefined;
  }
  for(var y = 0; y < sh$1; ++y){
    for(var x = 0; x < sw$1; ++x){
      var tx = x * deltax;
      var ty = y * deltay;
      var i = ((Math.imul(y, sw$1) + x | 0) << 2);
      var r = Belt_Option.getWithDefault(pix[i + 0 | 0], 0);
      var g = Belt_Option.getWithDefault(pix[i + 1 | 0], 0);
      var b = Belt_Option.getWithDefault(pix[i + 2 | 0], 0);
      var a = Belt_Option.getWithDefault(pix[i + 3 | 0], 0) / 255.0;
      var match$3 = blend !== undefined ? blendColors(r, g, b, blend[0], blend[1], blend[2]) : [
          r,
          g,
          b
        ];
      Dom2.Context2d.setFillStyleRGBA(tempContext, match$3[0], match$3[1], match$3[2], a);
      tempContext.fillRect(Js_math.floor(tx), Js_math.floor(ty), pixw$1, pixh$1);
    }
  }
  var context = page.canvasWithContext.context;
  context.save();
  context.translate(match[5], match[6]);
  var match$4 = options.rotate;
  if (typeof match$4 === "object") {
    if (match$4.NAME === "Center") {
      var radians = match$4.VAL * Math.PI / 180.0;
      context.translate(dw$1 / 2.0, dh$1 / 2.0);
      context.rotate(radians);
      context.translate(dw$1 / -2.0, dh$1 / -2.0);
    } else {
      var radians$1 = match$4.VAL * Math.PI / 180.0;
      context.rotate(radians$1);
    }
  }
  var match$5 = options.flip;
  if (match$5 === "Horizontal") {
    context.translate(dw$1, 0.0);
    context.scale(-1, 1);
  } else if (match$5 === "None") {
    
  } else {
    context.translate(0.0, dh$1);
    context.scale(1, -1);
  }
  context.drawImage(tempCanvas, 0, 0);
  context.restore();
}

function draw(texture, page, sx, sy, sw, sh, dx, dy, dw, dh, flip, rotate, blend, pixelate, param) {
  if (!(sh > 0 && dh > 0 && sw > 0 && dw > 0)) {
    return ;
  }
  var sourceScaleX = texture.imageWithCanvas.width / texture.standardWidth;
  var sourceScaleY = texture.imageWithCanvas.height / texture.standardHeight;
  var sx$1 = Js_math.floor(sx * sourceScaleX);
  var sy$1 = Js_math.floor(sy * sourceScaleY);
  var sw$1 = Js_math.floor(sw * sourceScaleX);
  var sh$1 = Js_math.floor(sh * sourceScaleY);
  drawNearestNeighbor(texture, page, sx$1, sy$1, sw$1, sh$1, dx, dy, dw, dh, {
        rotate: rotate,
        flip: flip,
        blend: blend,
        pixelate: pixelate
      });
}

exports.make = make;
exports.makeFromUrl = makeFromUrl;
exports.parseHex = parseHex;
exports.shift = shift;
exports.hexToRGB = hexToRGB;
exports.blendColors = blendColors;
exports.fit = fit;
exports.preparePixelationCanvas = preparePixelationCanvas;
exports.drawNearestNeighbor = drawNearestNeighbor;
exports.draw = draw;
/* No side effect */
