// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-golem-character";

var name = "Minecraft Golem Character";

var history = [
  "Originally developed by Wajy.",
  "06 Feb 2015 lostminer: Add user variables.",
  "13 Feb 2015 lostminer: Update to use new version of generator.",
  "19 Sep 2020 NinjolasNJM: Updated to use 1.8+ Skins, fixed bottom textures, and added the ability to choose from multiple flowers and damage cracks.",
  "17 Jul 2021 M16 - Updated generator photo."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Poppy",
    url: requireTexture("Flower-Poppy"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "Rose",
    url: requireTexture("Flower-Rose"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "Cyan Flower",
    url: requireTexture("Flower-Cyan"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "High",
    url: requireTexture("Damage-High"),
    standardWidth: 128,
    standardHeight: 128
  },
  {
    id: "Medium",
    url: requireTexture("Damage-Medium"),
    standardWidth: 128,
    standardHeight: 128
  },
  {
    id: "Low",
    url: requireTexture("Damage-Low"),
    standardWidth: 128,
    standardHeight: 128
  }
];

var steve = TextureMap.MinecraftCharacterLegacy.steve;

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineTextureInput("Flower", {
        standardWidth: 16,
        standardHeight: 16,
        choices: [
          "Poppy",
          "Rose",
          "Cyan Flower"
        ]
      });
  Generator.defineTextureInput("Damage", {
        standardWidth: 128,
        standardHeight: 128,
        choices: [
          "Low",
          "Medium",
          "High"
        ]
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideRightPant = Generator.getBooleanInputValue("Hide Right Pant");
  var hideLeftPant = Generator.getBooleanInputValue("Hide Left Pant");
  var hideRightSleeve = Generator.getBooleanInputValue("Hide Right Sleeve");
  var hideLeftSleeve = Generator.getBooleanInputValue("Hide Left Sleeve");
  Generator.defineRegionInput([
        39,
        19,
        256,
        208
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        120,
        198,
        464,
        272
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        398,
        408,
        176,
        208
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Pant", !hideRightPant);
        }));
  Generator.defineRegionInput([
        398,
        622,
        176,
        208
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Pant", !hideLeftPant);
        }));
  Generator.defineRegionInput([
        29,
        493,
        160,
        336
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Sleeve", !hideRightSleeve);
        }));
  Generator.defineRegionInput([
        216,
        493,
        160,
        336
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Sleeve", !hideLeftSleeve);
        }));
  Generator.drawImage("Background", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 39,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 103,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 167,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 231,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 103,
        y: 19,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 103,
        y: 163,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 57,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 73,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 89,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 105,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 73,
        y: 400,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 73,
        y: 448,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 28,
        w: 8,
        h: 4
      }, {
        x: 373,
        y: 125,
        w: 72,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 28,
        w: 4,
        h: 4
      }, {
        x: 445,
        y: 125,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 28,
        w: 4,
        h: 4
      }, {
        x: 325,
        y: 125,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 28,
        w: 8,
        h: 4
      }, {
        x: 493,
        y: 125,
        w: 72,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 373,
        y: 77,
        w: 72,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 373,
        y: 165,
        w: 72,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 8
      }, {
        x: 208,
        y: 286,
        w: 144,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 8
      }, {
        x: 352,
        y: 286,
        w: 88,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 8
      }, {
        x: 440,
        y: 286,
        w: 144,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 8
      }, {
        x: 120,
        y: 286,
        w: 88,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 208,
        y: 198,
        w: 144,
        h: 88
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 208,
        y: 382,
        w: 144,
        h: 88
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 438,
        y: 448,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 486,
        y: 448,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 526,
        y: 448,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 398,
        y: 448,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 438,
        y: 408,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 438,
        y: 576,
        w: 48,
        h: 40
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 52,
        w: 4,
        h: 12
      }, {
        x: 438,
        y: 662,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 52,
        w: 4,
        h: 12
      }, {
        x: 486,
        y: 662,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 52,
        w: 4,
        h: 12
      }, {
        x: 526,
        y: 662,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 52,
        w: 4,
        h: 12
      }, {
        x: 398,
        y: 662,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 438,
        y: 622,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 438,
        y: 790,
        w: 48,
        h: 40
      }, "Vertical", undefined, undefined, undefined);
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 20,
          w: 3,
          h: 12
        }, {
          x: 77,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 109,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 20,
          w: 3,
          h: 12
        }, {
          x: 157,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 29,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 77,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 77,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 77,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 109,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 157,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 29,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 77,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 77,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
  }
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 52,
          w: 3,
          h: 12
        }, {
          x: 264,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 296,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 52,
          w: 3,
          h: 12
        }, {
          x: 344,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 216,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 264,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 264,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 264,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 296,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 344,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 216,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 264,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 264,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 39,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 167,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 231,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 19,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 163,
          w: 64,
          h: 64
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 57,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 73,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 89,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 105,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 73,
          y: 400,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 73,
          y: 448,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 44,
          w: 8,
          h: 4
        }, {
          x: 373,
          y: 125,
          w: 72,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 44,
          w: 4,
          h: 4
        }, {
          x: 445,
          y: 125,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 44,
          w: 4,
          h: 4
        }, {
          x: 325,
          y: 125,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 44,
          w: 8,
          h: 4
        }, {
          x: 493,
          y: 125,
          w: 72,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 373,
          y: 77,
          w: 72,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 373,
          y: 165,
          w: 72,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 8
        }, {
          x: 208,
          y: 286,
          w: 144,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 8
        }, {
          x: 352,
          y: 286,
          w: 88,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 8
        }, {
          x: 440,
          y: 286,
          w: 144,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 8
        }, {
          x: 120,
          y: 286,
          w: 88,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 208,
          y: 198,
          w: 144,
          h: 88
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 208,
          y: 382,
          w: 144,
          h: 88
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideRightPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 438,
          y: 448,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 486,
          y: 448,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 526,
          y: 448,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 398,
          y: 448,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 438,
          y: 408,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 438,
          y: 576,
          w: 48,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideLeftPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 438,
          y: 662,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 486,
          y: 662,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 526,
          y: 662,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 398,
          y: 662,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 438,
          y: 622,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 438,
          y: 790,
          w: 48,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideRightSleeve) {
    if (alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 36,
            w: 3,
            h: 12
          }, {
            x: 77,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 109,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 36,
            w: 3,
            h: 12
          }, {
            x: 157,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 29,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 77,
            y: 493,
            w: 32,
            h: 48
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 77,
            y: 781,
            w: 32,
            h: 48
          }, "Vertical", undefined, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 77,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 109,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 157,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 29,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 4,
            h: 4
          }, {
            x: 77,
            y: 493,
            w: 32,
            h: 48
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 32,
            w: 4,
            h: 4
          }, {
            x: 77,
            y: 781,
            w: 32,
            h: 48
          }, "Vertical", undefined, undefined, undefined);
    }
  }
  if (!hideLeftSleeve) {
    if (alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: 264,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 296,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 59,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: 344,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 216,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 264,
            y: 493,
            w: 32,
            h: 48
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 264,
            y: 781,
            w: 32,
            h: 48
          }, "Vertical", undefined, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 264,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 56,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 296,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 60,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 344,
            y: 541,
            w: 32,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 216,
            y: 541,
            w: 48,
            h: 240
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 4,
            h: 4
          }, {
            x: 264,
            y: 493,
            w: 32,
            h: 48
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 56,
            y: 48,
            w: 4,
            h: 4
          }, {
            x: 264,
            y: 781,
            w: 32,
            h: 48
          }, "Vertical", undefined, undefined, undefined);
    }
  }
  Generator.drawTextureLegacy("Flower", {
        x: 5,
        y: 5,
        w: 8,
        h: 11
      }, {
        x: 95,
        y: 262,
        w: 64,
        h: 88
      }, undefined, 90.0, undefined, undefined);
  Generator.drawTextureLegacy("Flower", {
        x: 5,
        y: 5,
        w: 8,
        h: 11
      }, {
        x: 95,
        y: 198,
        w: 64,
        h: 88
      }, "Horizontal", 90.0, undefined, undefined);
  var showDamage = Generator.hasTexture("Damage");
  if (showDamage) {
    Generator.drawTextureLegacy("Damage", {
          x: 0,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 39,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 8,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 103,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 16,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 167,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 24,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 231,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 8,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 19,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 16,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 163,
          w: 64,
          h: 64
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 6,
          y: 76,
          w: 9,
          h: 5
        }, {
          x: 373,
          y: 125,
          w: 72,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 15,
          y: 76,
          w: 9,
          h: 5
        }, {
          x: 445,
          y: 125,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 0,
          y: 76,
          w: 6,
          h: 5
        }, {
          x: 325,
          y: 125,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 21,
          y: 76,
          w: 9,
          h: 5
        }, {
          x: 493,
          y: 125,
          w: 72,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 6,
          y: 70,
          w: 9,
          h: 6
        }, {
          x: 373,
          y: 77,
          w: 72,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 15,
          y: 70,
          w: 9,
          h: 6
        }, {
          x: 373,
          y: 165,
          w: 72,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 11,
          y: 51,
          w: 18,
          h: 12
        }, {
          x: 208,
          y: 286,
          w: 144,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 29,
          y: 51,
          w: 11,
          h: 12
        }, {
          x: 352,
          y: 286,
          w: 88,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 40,
          y: 51,
          w: 18,
          h: 12
        }, {
          x: 440,
          y: 286,
          w: 144,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 0,
          y: 51,
          w: 11,
          h: 12
        }, {
          x: 120,
          y: 286,
          w: 89,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 11,
          y: 40,
          w: 18,
          h: 11
        }, {
          x: 208,
          y: 198,
          w: 144,
          h: 88
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 29,
          y: 40,
          w: 18,
          h: 11
        }, {
          x: 208,
          y: 382,
          w: 144,
          h: 88
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 42,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 438,
          y: 448,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 48,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 486,
          y: 448,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 53,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 526,
          y: 448,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 37,
          y: 5,
          w: 5,
          h: 16
        }, {
          x: 398,
          y: 448,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 42,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 408,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 48,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 576,
          w: 48,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 65,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 438,
          y: 662,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 71,
          y: 5,
          w: 5,
          h: 16
        }, {
          x: 486,
          y: 662,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 76,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 526,
          y: 662,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 60,
          y: 5,
          w: 5,
          h: 16
        }, {
          x: 398,
          y: 662,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 65,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 622,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 71,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 790,
          w: 48,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 27,
          w: 4,
          h: 30
        }, {
          x: 77,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 27,
          w: 6,
          h: 30
        }, {
          x: 109,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 76,
          y: 27,
          w: 4,
          h: 30
        }, {
          x: 157,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 60,
          y: 27,
          w: 6,
          h: 30
        }, {
          x: 29,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 21,
          w: 4,
          h: 6
        }, {
          x: 77,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 21,
          w: 4,
          h: 6
        }, {
          x: 77,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 64,
          w: 4,
          h: 30
        }, {
          x: 264,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 64,
          w: 6,
          h: 30
        }, {
          x: 296,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 76,
          y: 64,
          w: 4,
          h: 30
        }, {
          x: 344,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 60,
          y: 64,
          w: 6,
          h: 30
        }, {
          x: 216,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 264,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 21,
          w: 4,
          h: 6
        }, {
          x: 264,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 28,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 57,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 26,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 73,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 24,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 89,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 30,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 105,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 26,
          y: 0,
          w: 2,
          h: 2
        }, {
          x: 73,
          y: 400,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 28,
          y: 0,
          w: 2,
          h: 2
        }, {
          x: 73,
          y: 448,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
