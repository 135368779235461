// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Marked = require("marked");

function Generator_Markdown(Props) {
  var children = Props.children;
  var html = Marked.marked.parse(children);
  return React.createElement("div", {
              className: "prose xmax-w-none",
              dangerouslySetInnerHTML: {
                __html: html
              }
            });
}

var make = Generator_Markdown;

exports.make = make;
/* react Not a pure module */
