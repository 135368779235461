// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Generator = require("../../builder/modules/Generator.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Markdown = require("../../builder/views/Generator_Markdown.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "amogus-bendable";

var name = "Amogus Bendable";

var history = ["Jan 2022 PaperDogChannel - First release."];

var thumbnail = {
  url: require("./thumbnail/thumbnail.jpeg")
};

var video = {
  url: "https://www.youtube.com/embed/0v8_l7J4qWg?rel=0"
};

var amogusImage = require("./instructions/amogus-100.png");

var instructions = "\n\"Amogus\" is a corrupted version of the Among Us game name. In January 2021,\nthe word gained popularity as a catchphrase used in ironic memes, often used\nto replace dialogue in various cartoons. Additionally, Amogus refers to a even\nmore simplified like drawing of a crewmate from Among Us used in these type of memes.\n![Amogus](" + amogusImage + ")\n";

var images = [
  {
    id: "Background",
    url: require("./images/Background.png")
  },
  {
    id: "Folds",
    url: require("./images/Folds.png")
  }
];

var textures = [
  {
    id: "Skin",
    url: require("./textures/Skin.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Colors",
    url: require("./textures/Colors.png"),
    standardWidth: 16,
    standardHeight: 2
  }
];

function script(param) {
  Generator.defineSelectInput("Color", [
        "Red",
        "Black",
        "White",
        "Rose",
        "Blue",
        "Cyan",
        "Yellow",
        "Pink",
        "Purple",
        "Orange",
        "Banana",
        "Coral",
        "Lime",
        "Green",
        "Gray",
        "Maroon",
        "Brown",
        "Tan"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.drawImage("Background", [
        0,
        0
      ]);
  var red = Generator.getSelectInputValue("Color") === "Red";
  var black = Generator.getSelectInputValue("Color") === "Black";
  var white = Generator.getSelectInputValue("Color") === "White";
  var rose = Generator.getSelectInputValue("Color") === "Rose";
  var blue = Generator.getSelectInputValue("Color") === "Blue";
  var cyan = Generator.getSelectInputValue("Color") === "Cyan";
  var yellow = Generator.getSelectInputValue("Color") === "Yellow";
  var pink = Generator.getSelectInputValue("Color") === "Pink";
  var purple = Generator.getSelectInputValue("Color") === "Purple";
  var orange = Generator.getSelectInputValue("Color") === "Orange";
  var banana = Generator.getSelectInputValue("Color") === "Banana";
  var coral = Generator.getSelectInputValue("Color") === "Coral";
  var lime = Generator.getSelectInputValue("Color") === "Lime";
  var green = Generator.getSelectInputValue("Color") === "Green";
  var gray = Generator.getSelectInputValue("Color") === "Gray";
  var maroon = Generator.getSelectInputValue("Color") === "Maroon";
  var brown = Generator.getSelectInputValue("Color") === "Brown";
  var tancolour = Generator.getSelectInputValue("Color") === "Tan";
  if (red) {
    Generator.drawTextureLegacy("Colors", {
          x: 0,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (black) {
    Generator.drawTextureLegacy("Colors", {
          x: 1,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (rose) {
    Generator.drawTextureLegacy("Colors", {
          x: 2,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (blue) {
    Generator.drawTextureLegacy("Colors", {
          x: 3,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (cyan) {
    Generator.drawTextureLegacy("Colors", {
          x: 4,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (yellow) {
    Generator.drawTextureLegacy("Colors", {
          x: 5,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (pink) {
    Generator.drawTextureLegacy("Colors", {
          x: 6,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (purple) {
    Generator.drawTextureLegacy("Colors", {
          x: 7,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (orange) {
    Generator.drawTextureLegacy("Colors", {
          x: 8,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (banana) {
    Generator.drawTextureLegacy("Colors", {
          x: 9,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (coral) {
    Generator.drawTextureLegacy("Colors", {
          x: 10,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (lime) {
    Generator.drawTextureLegacy("Colors", {
          x: 11,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (green) {
    Generator.drawTextureLegacy("Colors", {
          x: 12,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (gray) {
    Generator.drawTextureLegacy("Colors", {
          x: 13,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (maroon) {
    Generator.drawTextureLegacy("Colors", {
          x: 14,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (brown) {
    Generator.drawTextureLegacy("Colors", {
          x: 15,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (tancolour) {
    Generator.drawTextureLegacy("Colors", {
          x: 0,
          y: 1,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  if (white) {
    Generator.drawTextureLegacy("Colors", {
          x: 1,
          y: 1,
          w: 1,
          h: 1
        }, {
          x: 0,
          y: 0,
          w: 600,
          h: 660
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 47,
        y: 229,
        w: 40,
        h: 24
      }, undefined, 0.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 40,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 47,
        y: 229,
        w: 40,
        h: 24
      }, undefined, 0.0, undefined, undefined);
  Generator.drawImage("Folds", [
        0,
        0
      ]);
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator_instructions = Caml_option.some(React.createElement(Generator_Markdown.make, {
          children: instructions
        }));

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: generator_instructions,
  images: images,
  textures: textures,
  script: script
};

var Markdown;

exports.Markdown = Markdown;
exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.video = video;
exports.amogusImage = amogusImage;
exports.instructions = instructions;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
