// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-ultimate-bendable";

var name = "Minecraft Bendable Character";

var history = [
  "Originally Developed by rooterbuster.",
  "06 Feb 2015 lostminer - Add user variables.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "22 Nov 2018 NinjolasNJM - Fixed rotations of tops and bottoms and the placement of arm textures, and made compatible with 1.8+ skins.",
  "30 Aug 2020 NinjolasNJM - Fixed a few more things such as the rotation of the bottom of the head and the bottom of the waist using the wrong textures, changed the tubes and tabs to look better on more skins, and updated the code comments.",
  "06 Jun 2021 NinjolasNJM - Converted to ReScript generator, and fixed the lines on the head."
];

var thumbnail = {
  url: require("./thumbnail/thumbnail-256.jpeg")
};

var video = {
  url: "https://www.youtube.com/embed/CyYn66Zm5n0?rel=0"
};

var imageIds = [
  "Background-Steve",
  "Background-Alex",
  "Colors-Steve",
  "Colors-Alex",
  "Folds-Steve",
  "Folds-Alex",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [{
    id: "Skin",
    url: requireTexture("Skin64x64Steve"),
    standardWidth: 64,
    standardHeight: 64
  }];

function script(param) {
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Color Codes", true);
  Generator.defineBooleanInput("Show Labels", true);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showColorCodes = Generator.getBooleanInputValue("Show Color Codes");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideLeftSleeve = Generator.getBooleanInputValue("Hide Left Sleeve");
  var hideRightSleeve = Generator.getBooleanInputValue("Hide Right Sleeve");
  var hideLeftPant = Generator.getBooleanInputValue("Hide Left Pant");
  var hideRightPant = Generator.getBooleanInputValue("Hide Right Pant");
  Generator.defineRegionInput([
        10,
        534,
        192,
        256
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        35,
        50,
        192,
        328
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        265,
        50,
        128,
        320
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Sleeve", !hideLeftSleeve);
        }));
  Generator.defineRegionInput([
        425,
        426,
        128,
        320
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Sleeve", !hideRightSleeve);
        }));
  Generator.defineRegionInput([
        425,
        10,
        128,
        360
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Pant", !hideLeftPant);
        }));
  Generator.defineRegionInput([
        265,
        386,
        128,
        360
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Pant", !hideRightPant);
        }));
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 790,
        w: 64,
        h: 64
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 726,
        w: 64,
        h: 64
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 662,
        w: 64,
        h: 64
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 598,
        w: 64,
        h: 64
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 10,
        y: 726,
        w: 64,
        h: 64
      }, undefined, 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 726,
        w: 64,
        h: 64
      }, "Vertical", 270.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 36,
        y: 414,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 16,
        w: 24,
        h: 14
      }, {
        x: 35,
        y: 50,
        w: 192,
        h: 112
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 24,
        w: 8,
        h: 6
      }, {
        x: 131,
        y: 204,
        w: 64,
        h: 48
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 24,
        w: 8,
        h: 4
      }, {
        x: 163,
        y: 143,
        w: 64,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 24,
        w: 16,
        h: 8
      }, {
        x: 99,
        y: 282,
        w: 128,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 28,
        w: 8,
        h: 4
      }, {
        x: 35,
        y: 314,
        w: 64,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 20,
        w: 4,
        h: 4
      }, {
        x: 99,
        y: 346,
        w: 32,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 52,
        w: 4,
        h: 4
      }, {
        x: 195,
        y: 346,
        w: 32,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 131,
        y: 380,
        w: 64,
        h: 130
      }, undefined, undefined, undefined, undefined);
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 14,
          h: 8
        }, {
          x: 273,
          y: 82,
          w: 112,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 361,
          y: 58,
          w: 24,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 56,
          w: 3,
          h: 4
        }, {
          x: 328,
          y: 194,
          w: 24,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 329,
          y: 338,
          w: 24,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 48,
          w: 11,
          h: 16
        }, {
          x: 297,
          y: 211,
          w: 88,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 52,
          w: 3,
          h: 12
        }, {
          x: 273,
          y: 243,
          w: 24,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 3,
          h: 4
        }, {
          x: 361,
          y: 138,
          w: 24,
          h: 128
        }, undefined, undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 16,
          h: 8
        }, {
          x: 265,
          y: 82,
          w: 128,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 50,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 328,
          y: 194,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 329,
          y: 338,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 48,
          w: 12,
          h: 16
        }, {
          x: 297,
          y: 211,
          w: 96,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 265,
          y: 243,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 138,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
  }
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 11,
          h: 8
        }, {
          x: 457,
          y: 458,
          w: 88,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 20,
          w: 3,
          h: 12
        }, {
          x: 433,
          y: 458,
          w: 24,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 457,
          y: 458,
          w: 24,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 24,
          w: 3,
          h: 4
        }, {
          x: 513,
          y: 570,
          w: 24,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 465,
          y: 714,
          w: 24,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 16,
          w: 14,
          h: 16
        }, {
          x: 433,
          y: 587,
          w: 112,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 3,
          h: 4
        }, {
          x: 433,
          y: 514,
          w: 24,
          h: 128
        }, undefined, undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 12,
          h: 8
        }, {
          x: 457,
          y: 458,
          w: 96,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 425,
          y: 458,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 457,
          y: 458,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 24,
          w: 4,
          h: 4
        }, {
          x: 521,
          y: 570,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 457,
          y: 714,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 16,
          w: 16,
          h: 16
        }, {
          x: 425,
          y: 587,
          w: 128,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 4,
          h: 4
        }, {
          x: 425,
          y: 514,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 48,
        w: 16,
        h: 16
      }, {
        x: 425,
        y: 41,
        w: 128,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 52,
        w: 4,
        h: 4
      }, {
        x: 489,
        y: 41,
        w: 32,
        h: 32
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 56,
        w: 4,
        h: 4
      }, {
        x: 489,
        y: 194,
        w: 32,
        h: 48
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 48,
        w: 12,
        h: 16
      }, {
        x: 457,
        y: 210,
        w: 96,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 52,
        w: 4,
        h: 12
      }, {
        x: 425,
        y: 242,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 489,
        y: 338,
        w: 32,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 56,
        w: 4,
        h: 4
      }, {
        x: 521,
        y: 138,
        w: 32,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 16,
        w: 16,
        h: 16
      }, {
        x: 297,
        y: 418,
        w: 128,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 265,
        y: 450,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 20,
        w: 4,
        h: 4
      }, {
        x: 361,
        y: 418,
        w: 32,
        h: 32
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 24,
        w: 4,
        h: 4
      }, {
        x: 361,
        y: 570,
        w: 32,
        h: 48
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 16,
        w: 16,
        h: 16
      }, {
        x: 265,
        y: 586,
        w: 128,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 297,
        y: 714,
        w: 32,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 24,
        w: 4,
        h: 4
      }, {
        x: 265,
        y: 514,
        w: 32,
        h: 128
      }, undefined, undefined, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 74,
          y: 790,
          w: 64,
          h: 64
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 74,
          y: 726,
          w: 64,
          h: 64
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 74,
          y: 662,
          w: 64,
          h: 64
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 74,
          y: 598,
          w: 64,
          h: 64
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 10,
          y: 726,
          w: 64,
          h: 64
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 138,
          y: 726,
          w: 64,
          h: 64
        }, "Vertical", 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 36,
          y: 414,
          w: 64,
          h: 96
        }, undefined, undefined, undefined, undefined);
  }
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 32,
          w: 24,
          h: 14
        }, {
          x: 35,
          y: 50,
          w: 192,
          h: 112
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 40,
          w: 8,
          h: 6
        }, {
          x: 131,
          y: 204,
          w: 64,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 40,
          w: 8,
          h: 4
        }, {
          x: 163,
          y: 143,
          w: 64,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 40,
          w: 16,
          h: 8
        }, {
          x: 99,
          y: 282,
          w: 128,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 44,
          w: 8,
          h: 4
        }, {
          x: 35,
          y: 314,
          w: 64,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 36,
          w: 4,
          h: 4
        }, {
          x: 99,
          y: 346,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 52,
          w: 4,
          h: 4
        }, {
          x: 195,
          y: 346,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 131,
          y: 380,
          w: 64,
          h: 130
        }, undefined, undefined, undefined, undefined);
  }
  if (alexModel) {
    if (!hideLeftSleeve) {
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 14,
            h: 8
          }, {
            x: 273,
            y: 82,
            w: 112,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 361,
            y: 58,
            w: 24,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 59,
            y: 56,
            w: 3,
            h: 4
          }, {
            x: 328,
            y: 194,
            w: 24,
            h: 48
          }, undefined, 180.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 329,
            y: 338,
            w: 24,
            h: 32
          }, "Vertical", undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 48,
            w: 11,
            h: 16
          }, {
            x: 297,
            y: 211,
            w: 88,
            h: 128
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 59,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: 273,
            y: 243,
            w: 24,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 36,
            y: 56,
            w: 3,
            h: 4
          }, {
            x: 361,
            y: 138,
            w: 24,
            h: 128
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 56,
            w: 3,
            h: 4
          }, {
            x: 361,
            y: 138,
            w: 24,
            h: 128
          }, undefined, undefined, undefined, undefined);
    }
    
  } else if (!hideLeftSleeve) {
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 52,
          w: 16,
          h: 8
        }, {
          x: 265,
          y: 82,
          w: 128,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 50,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 60,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 328,
          y: 194,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 329,
          y: 338,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 48,
          w: 12,
          h: 16
        }, {
          x: 297,
          y: 211,
          w: 96,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 60,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 265,
          y: 243,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 138,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 138,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
  }
  if (alexModel) {
    if (!hideRightSleeve) {
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 11,
            h: 8
          }, {
            x: 457,
            y: 458,
            w: 88,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 36,
            w: 3,
            h: 12
          }, {
            x: 433,
            y: 458,
            w: 24,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 457,
            y: 458,
            w: 24,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 40,
            w: 3,
            h: 4
          }, {
            x: 513,
            y: 570,
            w: 24,
            h: 48
          }, undefined, 180.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 465,
            y: 714,
            w: 24,
            h: 32
          }, "Vertical", undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 32,
            w: 14,
            h: 16
          }, {
            x: 433,
            y: 587,
            w: 112,
            h: 128
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 24,
            w: 3,
            h: 4
          }, {
            x: 433,
            y: 514,
            w: 24,
            h: 128
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 40,
            w: 3,
            h: 4
          }, {
            x: 433,
            y: 514,
            w: 24,
            h: 128
          }, undefined, undefined, undefined, undefined);
    }
    
  } else if (!hideRightSleeve) {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 36,
          w: 12,
          h: 8
        }, {
          x: 457,
          y: 458,
          w: 96,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 425,
          y: 458,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 457,
          y: 458,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 40,
          w: 4,
          h: 4
        }, {
          x: 521,
          y: 570,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 457,
          y: 714,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 32,
          w: 16,
          h: 16
        }, {
          x: 425,
          y: 587,
          w: 128,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 4,
          h: 4
        }, {
          x: 425,
          y: 514,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 40,
          w: 4,
          h: 4
        }, {
          x: 425,
          y: 514,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
  }
  if (!hideLeftPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 48,
          w: 16,
          h: 16
        }, {
          x: 425,
          y: 41,
          w: 128,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 52,
          w: 4,
          h: 4
        }, {
          x: 489,
          y: 41,
          w: 32,
          h: 32
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 489,
          y: 194,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 489,
          y: 194,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 48,
          w: 12,
          h: 16
        }, {
          x: 457,
          y: 210,
          w: 96,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 425,
          y: 242,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 489,
          y: 338,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 521,
          y: 138,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 521,
          y: 138,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
  }
  if (!hideRightPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 32,
          w: 16,
          h: 16
        }, {
          x: 297,
          y: 418,
          w: 128,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 265,
          y: 450,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 36,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 418,
          w: 32,
          h: 32
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 24,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 570,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 40,
          w: 4,
          h: 4
        }, {
          x: 361,
          y: 570,
          w: 32,
          h: 48
        }, undefined, 180.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 32,
          w: 16,
          h: 16
        }, {
          x: 265,
          y: 586,
          w: 128,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 297,
          y: 714,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 24,
          w: 4,
          h: 4
        }, {
          x: 265,
          y: 514,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 40,
          w: 4,
          h: 4
        }, {
          x: 265,
          y: 514,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
  }
  if (alexModel) {
    Generator.drawImage("Background-Alex", [
          0,
          0
        ]);
  } else {
    Generator.drawImage("Background-Steve", [
          0,
          0
        ]);
  }
  if (showFolds) {
    if (alexModel) {
      Generator.drawImage("Folds-Alex", [
            0,
            0
          ]);
    } else {
      Generator.drawImage("Folds-Steve", [
            0,
            0
          ]);
    }
  }
  if (showColorCodes) {
    if (alexModel) {
      Generator.drawImage("Colors-Alex", [
            0,
            0
          ]);
    } else {
      Generator.drawImage("Colors-Steve", [
            0,
            0
          ]);
    }
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.video = video;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
