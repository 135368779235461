// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Dom2 = require("../../bindings/Dom2.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Jspdf = require("jspdf");
var React = require("react");
var Generator_Buttons = require("./Generator_Buttons.bs.js");
var Generator_PageSize = require("../modules/Generator_PageSize.bs.js");
var PrintHtmlElement = require("print-html-element");
var Generator_ButtonStyles = require("./Generator_ButtonStyles.bs.js");

function px(n) {
  return "" + n.toString() + "px";
}

function scaleInt(value, scale) {
  return Math.round(value * scale) | 0;
}

function scaleRegion(param, actualWidth) {
  var scale = actualWidth / Generator_PageSize.A4.px.width;
  return [
          scaleInt(param[0], scale),
          scaleInt(param[1], scale),
          scaleInt(param[2], scale),
          scaleInt(param[3], scale)
        ];
}

function Generator_Pages$RegionInputs(Props) {
  var model = Props.model;
  var currentPageId = Props.currentPageId;
  var containerWidth = Props.containerWidth;
  var onClick = Props.onClick;
  var regions = model.inputs.reduce((function (acc, input) {
          if (input.TAG === /* RegionInput */2 && input._0 === currentPageId) {
            return acc.concat([[
                          input._1,
                          input._2
                        ]]);
          } else {
            return acc;
          }
        }), []);
  if (regions.length > 0) {
    return React.createElement("div", undefined, regions.map(function (param, i) {
                    var callback = param[1];
                    var match = scaleRegion(param[0], containerWidth);
                    var style = {
                      height: px(match[3]),
                      left: px(match[0] + 1 | 0),
                      top: px(match[1] + 1 | 0),
                      width: px(match[2])
                    };
                    return React.createElement("div", {
                                key: i.toString(),
                                className: "absolute border-4 border-transparent hover:border-blue-500",
                                style: style,
                                onClick: (function (param) {
                                    Curry._1(onClick, callback);
                                  })
                              });
                  }));
  } else {
    return null;
  }
}

var RegionInputs = {
  scaleInt: scaleInt,
  scaleRegion: scaleRegion,
  make: Generator_Pages$RegionInputs
};

function Generator_Pages$SaveAsPDFButton(Props) {
  var size = Props.size;
  var color = Props.color;
  var model = Props.model;
  var onSavePDF = function (param) {
    var doc = new Jspdf.jsPDF({
          orientation: "portrait",
          unit: "mm",
          format: "a4"
        });
    model.pages.forEach(function (page, index) {
          var dataUrl = Dom2.Canvas.toDataUrlAsPng(page.canvasWithContext.canvas);
          if (index > 0) {
            doc.addPage("a4", "portrait");
          }
          doc.addImage(dataUrl, "PNG", 0, 0, Generator_PageSize.A4.mm.width, Generator_PageSize.A4.mm.height);
        });
    var dataUri = doc.output("datauristring");
    window.ReactNativeWebView.postMessage(dataUri);
  };
  return React.createElement(Generator_Buttons.Button.make, {
              state: "Ready",
              onClick: onSavePDF,
              color: color,
              size: size,
              children: "Save as PDF",
              id: "pdf-button"
            });
}

var SaveAsPDFButton = {
  make: Generator_Pages$SaveAsPDFButton
};

function Generator_Pages$SaveAsImageButton(Props) {
  var size = Props.size;
  var color = Props.color;
  var dataUrl = Props.dataUrl;
  var download = Props.download;
  var match = React.useState(function () {
        return "#";
      });
  var setHref = match[1];
  var onClick = function (param) {
    Curry._1(setHref, (function (param) {
            return dataUrl;
          }));
  };
  var className = Generator_ButtonStyles.makeClassName("Ready", color, size, false);
  return React.createElement("a", {
              className: className,
              download: download,
              href: match[0],
              onClick: onClick
            }, "Save as PNG");
}

var SaveAsImageButton = {
  make: Generator_Pages$SaveAsImageButton
};

function Generator_Pages$PrintImageButton(Props) {
  var size = Props.size;
  var color = Props.color;
  var dataUrl = Props.dataUrl;
  var onClick = function ($$event) {
    $$event.preventDefault();
    var image = new Image();
    var onLoad = function (param) {
      image.onload = undefined;
      var styles = "\n          @media print {\n            html, body, img {\n              margin: 0;\n              padding: 0;\n              width: " + (Generator_PageSize.A4.mm.width - 1 | 0).toString() + "mm;\n              height: " + (Generator_PageSize.A4.mm.height - 1 | 0).toString() + "mm;\n            }\n          }\n        ";
      PrintHtmlElement.printElement(image, {
            styles: styles
          });
    };
    image.onload = onLoad;
    image.src = dataUrl;
  };
  var className = Generator_ButtonStyles.makeClassName("Ready", color, size, false);
  return React.createElement("a", {
              className: className,
              href: "#",
              onClick: onClick
            }, "Print");
}

var PrintImageButton = {
  make: Generator_Pages$PrintImageButton
};

function useElementWidthListener(elRef) {
  var match = React.useState(function () {
        
      });
  var setWidth = match[1];
  React.useEffect((function () {
          var updateWidth = function (param) {
            var el = elRef.current;
            if (el == null) {
              return ;
            }
            var width = el.width;
            Curry._1(setWidth, (function (param) {
                    return width;
                  }));
          };
          var onResize = function (param) {
            updateWidth(undefined);
          };
          window.addEventListener("resize", onResize);
          updateWidth(undefined);
          return (function (param) {
                    window.removeEventListener("resize", onResize);
                  });
        }), []);
  return match[0];
}

function Generator_Pages(Props) {
  var generatorDef = Props.generatorDef;
  var model = Props.model;
  var onChange = Props.onChange;
  var containerElRef = React.useRef(null);
  var containerWidth = useElementWidthListener(containerElRef);
  var showPageIds = model.pages.length > 1;
  return React.createElement("div", undefined, model.pages.map(function (page, index) {
                  var dataUrl = Dom2.Canvas.toDataUrlAsPng(page.canvasWithContext.canvas);
                  var fileName = model.pages.length > 1 ? generatorDef.name + " - " + page.id : generatorDef.name;
                  return React.createElement("div", {
                              key: page.id
                            }, showPageIds ? React.createElement("h1", {
                                    className: "font-bold text-2xl mb-4"
                                  }, page.id) : null, React.createElement("div", {
                                  className: "mb-4 flex justify-between items-center",
                                  style: {
                                    maxWidth: px(Generator_PageSize.A4.px.width)
                                  }
                                }, React.createElement("div", undefined, React.createElement("span", {
                                          className: "mr-4"
                                        }, React.createElement(Generator_Pages$PrintImageButton, {
                                              size: "Small",
                                              color: "Blue",
                                              dataUrl: dataUrl
                                            })), React.createElement(Generator_Pages$SaveAsImageButton, {
                                          size: "Small",
                                          color: "Blue",
                                          dataUrl: dataUrl,
                                          download: fileName
                                        })), React.createElement("div", undefined, index === 0 ? React.createElement(Generator_Pages$SaveAsPDFButton, {
                                            size: "Small",
                                            color: "Green",
                                            generatorDef: generatorDef,
                                            model: model
                                          }) : null)), React.createElement("div", {
                                  className: "relative",
                                  style: {
                                    maxWidth: px(Generator_PageSize.A4.px.width + 2 | 0)
                                  }
                                }, React.createElement("img", {
                                      ref: containerElRef,
                                      className: "border shadow-xl mb-8",
                                      style: Object.assign({}, {}, {
                                            imageRendering: "pixelated"
                                          }),
                                      src: dataUrl
                                    }), containerWidth !== undefined ? React.createElement(Generator_Pages$RegionInputs, {
                                        model: model,
                                        currentPageId: page.id,
                                        containerWidth: containerWidth,
                                        onClick: (function (callback) {
                                            Curry._1(callback, undefined);
                                            Curry._1(onChange, undefined);
                                          })
                                      }) : null));
                }));
}

var PageSize;

var Builder;

var Buttons;

var ButtonStyles;

var pageBorderWidth = 1;

var make = Generator_Pages;

exports.PageSize = PageSize;
exports.Builder = Builder;
exports.Buttons = Buttons;
exports.ButtonStyles = ButtonStyles;
exports.px = px;
exports.pageBorderWidth = pageBorderWidth;
exports.RegionInputs = RegionInputs;
exports.SaveAsPDFButton = SaveAsPDFButton;
exports.SaveAsImageButton = SaveAsImageButton;
exports.PrintImageButton = PrintImageButton;
exports.useElementWidthListener = useElementWidthListener;
exports.make = make;
/* jspdf Not a pure module */
