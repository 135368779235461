// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Generator_Icon = require("./Generator_Icon.bs.js");

function Generator_FormInput$Option(Props) {
  var value = Props.value;
  var children = Props.children;
  return React.createElement("option", {
              value: value
            }, children);
}

var $$Option = {
  make: Generator_FormInput$Option
};

function Generator_FormInput$OptGroup(Props) {
  var label = Props.label;
  var children = Props.children;
  return React.createElement("optgroup", {
              label: label
            }, children);
}

var OptGroup = {
  make: Generator_FormInput$OptGroup
};

function Generator_FormInput$Select(Props) {
  var onChange = Props.onChange;
  var value = Props.value;
  var sizeOpt = Props.size;
  var children = Props.children;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var height = size === "Medium" ? "h-8" : "h-12";
  var className = "border border-gray-300 rounded text-gray-600 " + height + " pl-5 pr-10 bg-white hover:border-gray-400 focus:outline-none appearance-none";
  return React.createElement("div", {
              className: "relative inline-flex items-center"
            }, React.createElement("div", {
                  className: "absolute right-0 mr-3 pointer-events-none"
                }, React.createElement(Generator_Icon.DownArrow.make, {})), React.createElement("select", {
                  className: className,
                  value: value,
                  onChange: onChange
                }, children));
}

var Select = {
  make: Generator_FormInput$Select
};

var Icon;

exports.Icon = Icon;
exports.$$Option = $$Option;
exports.OptGroup = OptGroup;
exports.Select = Select;
/* react Not a pure module */
