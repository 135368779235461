// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");

function Generator_Icon$DownArrow(Props) {
  return React.createElement("svg", {
              className: "w-2 h-2",
              viewBox: "0 0 412 232",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M206 171.144L42.678 7.822c-9.763-9.763-25.592-9.763-35.355 0-9.763 9.764-9.763 25.592 0 35.355l181 181c4.88 4.882 11.279 7.323 17.677 7.323s12.796-2.441 17.678-7.322l181-181c9.763-9.764 9.763-25.592 0-35.355-9.763-9.763-25.592-9.763-35.355 0L206 171.144z",
                  fill: "#648299",
                  fillRule: "nonzero"
                }));
}

var DownArrow = {
  make: Generator_Icon$DownArrow
};

function Generator_Icon$Upload(Props) {
  return React.createElement("svg", {
              height: "18",
              width: "18",
              fill: "#FFF",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M0 0h24v24H0z",
                  fill: "none"
                }), React.createElement("path", {
                  d: "M9 16h6v-6h4l-7-7-7 7h4zm-4 2h14v2H5z"
                }));
}

var Upload = {
  make: Generator_Icon$Upload
};

function sizeClass(size) {
  if (size === "Medium") {
    return "w-5 h-5";
  } else {
    return "w-3 h-3";
  }
}

function colorClass(color) {
  if (color === "Black") {
    return "text-black";
  } else if (color === "White") {
    return "text-white";
  } else {
    return "text-gray-500";
  }
}

function makeClassName(size, color) {
  return "inline-block " + sizeClass(size) + " " + colorClass(color);
}

function Generator_Icon$ChevronUp(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5 15l7-7 7 7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronUp = {
  make: Generator_Icon$ChevronUp
};

function Generator_Icon$ChevronDown(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M19 9l-7 7-7-7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronDown = {
  make: Generator_Icon$ChevronDown
};

function Generator_Icon$ChevronRight(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M9 5l7 7-7 7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronRight = {
  make: Generator_Icon$ChevronRight
};

function Generator_Icon$ChevronLeft(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M15 19l-7-7 7-7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronLeft = {
  make: Generator_Icon$ChevronLeft
};

function Generator_Icon$ChevronDoubleUp(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5 11l7-7 7 7M5 19l7-7 7 7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronDoubleUp = {
  make: Generator_Icon$ChevronDoubleUp
};

function Generator_Icon$ChevronDoubleDown(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M19 13l-7 7-7-7m14-8l-7 7-7-7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronDoubleDown = {
  make: Generator_Icon$ChevronDoubleDown
};

function Generator_Icon$ChevronDoubleRight(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M13 5l7 7-7 7M5 5l7 7-7 7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronDoubleRight = {
  make: Generator_Icon$ChevronDoubleRight
};

function Generator_Icon$ChevronDoubleLeft(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M11 19l-7-7 7-7m8 14l-7-7 7-7",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ChevronDoubleLeft = {
  make: Generator_Icon$ChevronDoubleLeft
};

function Generator_Icon$Eye(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M15 12a3 3 0 11-6 0 3 3 0 016 0z",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }), React.createElement("path", {
                  d: "M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var Eye = {
  make: Generator_Icon$Eye
};

function Generator_Icon$EyeOff(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var EyeOff = {
  make: Generator_Icon$EyeOff
};

function Generator_Icon$Menu(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M4 6h16M4 12h16M4 18h16",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var Menu = {
  make: Generator_Icon$Menu
};

function Generator_Icon$ProgressSpin(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color) + " animate-spin",
              fill: "none",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("circle", {
                  className: "opacity-25",
                  cx: "12",
                  cy: "12",
                  r: "10",
                  stroke: "currentColor",
                  strokeWidth: "4"
                }), React.createElement("path", {
                  className: "opacity-75",
                  d: "M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z",
                  fill: "currentColor"
                }));
}

var ProgressSpin = {
  make: Generator_Icon$ProgressSpin
};

function Generator_Icon$Trash(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var Trash = {
  make: Generator_Icon$Trash
};

function Generator_Icon$X(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M6 18L18 6M6 6l12 12",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var X = {
  make: Generator_Icon$X
};

function Generator_Icon$ArrowDown(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M19 14l-7 7m0 0l-7-7m7 7V3",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ArrowDown = {
  make: Generator_Icon$ArrowDown
};

function Generator_Icon$ArrowLeft(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M10 19l-7-7m0 0l7-7m-7 7h18",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ArrowLeft = {
  make: Generator_Icon$ArrowLeft
};

function Generator_Icon$ArrowRight(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M14 5l7 7m0 0l-7 7m7-7H3",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ArrowRight = {
  make: Generator_Icon$ArrowRight
};

function Generator_Icon$ArrowUp(Props) {
  var sizeOpt = Props.size;
  var colorOpt = Props.color;
  var size = sizeOpt !== undefined ? sizeOpt : "Medium";
  var color = colorOpt !== undefined ? colorOpt : "Black";
  return React.createElement("svg", {
              className: makeClassName(size, color),
              fill: "none",
              stroke: "currentColor",
              viewBox: "0 0 24 24",
              xmlns: "http://www.w3.org/2000/svg"
            }, React.createElement("path", {
                  d: "M5 10l7-7m0 0l7 7m-7-7v18",
                  strokeLinecap: "round",
                  strokeLinejoin: "round",
                  strokeWidth: "2"
                }));
}

var ArrowUp = {
  make: Generator_Icon$ArrowUp
};

exports.DownArrow = DownArrow;
exports.Upload = Upload;
exports.sizeClass = sizeClass;
exports.colorClass = colorClass;
exports.makeClassName = makeClassName;
exports.ChevronUp = ChevronUp;
exports.ChevronDown = ChevronDown;
exports.ChevronRight = ChevronRight;
exports.ChevronLeft = ChevronLeft;
exports.ChevronDoubleUp = ChevronDoubleUp;
exports.ChevronDoubleDown = ChevronDoubleDown;
exports.ChevronDoubleRight = ChevronDoubleRight;
exports.ChevronDoubleLeft = ChevronDoubleLeft;
exports.Eye = Eye;
exports.EyeOff = EyeOff;
exports.Menu = Menu;
exports.ProgressSpin = ProgressSpin;
exports.Trash = Trash;
exports.X = X;
exports.ArrowDown = ArrowDown;
exports.ArrowLeft = ArrowLeft;
exports.ArrowRight = ArrowRight;
exports.ArrowUp = ArrowUp;
/* react Not a pure module */
