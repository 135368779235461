// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-squid-character";

var name = "Minecraft Squid Character";

var history = [
  "Originally developed by frownieman.",
  "06 Feb 2015 lostminer - Add user variables.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "18 Mar 2015 frownieman - Added compatibility to 1.8 skins.",
  "29 Sep 2020 NinjolasNJM - Fixed bottom texture rotations, and added the ability to choose which tentacle has which textures."
];

var thumbnail = {
  url: require("./thumbnail/v3-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Folds"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Squid",
    url: requireTexture("Squid"),
    standardWidth: 64,
    standardHeight: 32
  }
];

var steve = TextureMap.MinecraftCharacterLegacy.steve;

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var tent1 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 1")), 5);
  var tent2 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 2")), 7);
  var tent3 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 3")), 3);
  var tent4 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 4")), 3);
  var tent5 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 5")), 3);
  var tent6 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 6")), 1);
  var tent7 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 7")), 1);
  var tent8 = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tentacle 8")), 1);
  var cycleTentacleTypes = function (t) {
    return String(t % 8 + 1 | 0);
  };
  Generator.defineRegionInput([
        67,
        49,
        384,
        192
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        67,
        241,
        384,
        128
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineBooleanInput("Show Folds", true);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var rightArmBase = function (ox, oy) {
    if (alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 16,
            w: 3,
            h: 4
          }, {
            x: ox + 32 | 0,
            y: oy + 176 | 0,
            w: 16,
            h: 16
          }, "Vertical", 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 20,
            w: 4,
            h: 12
          }, {
            x: ox + 0 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 20,
            w: 3,
            h: 12
          }, {
            x: ox + 16 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 20,
            w: 4,
            h: 12
          }, {
            x: ox + 32 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 20,
            w: 3,
            h: 12
          }, {
            x: ox + 48 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      return Generator.drawTextureLegacy("Skin", {
                  x: 44,
                  y: 16,
                  w: 3,
                  h: 4
                }, {
                  x: ox + 48 | 0,
                  y: oy + 0 | 0,
                  w: 16,
                  h: 16
                }, undefined, 90.0, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 16,
            w: 4,
            h: 4
          }, {
            x: ox + 32 | 0,
            y: oy + 176 | 0,
            w: 16,
            h: 16
          }, "Vertical", 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 20,
            w: 16,
            h: 12
          }, {
            x: ox + 0 | 0,
            y: oy + 16 | 0,
            w: 64,
            h: 144
          }, undefined, undefined, undefined, undefined);
      return Generator.drawTextureLegacy("Skin", {
                  x: 44,
                  y: 16,
                  w: 4,
                  h: 4
                }, {
                  x: ox + 48 | 0,
                  y: oy + 0 | 0,
                  w: 16,
                  h: 16
                }, undefined, 90.0, undefined, undefined);
    }
  };
  var leftArmBase = function (ox, oy) {
    if (alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: 39,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: ox + 32 | 0,
            y: oy + 160 | 0,
            w: 16,
            h: 16
          }, "Vertical", undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 32,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: ox + 16 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 36,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: ox + 32 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 39,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: ox + 48 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 43,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: ox + 0 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      return Generator.drawTextureLegacy("Skin", {
                  x: 36,
                  y: 48,
                  w: 3,
                  h: 4
                }, {
                  x: ox + 32 | 0,
                  y: oy + 0 | 0,
                  w: 16,
                  h: 16
                }, undefined, undefined, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 48,
            w: 4,
            h: 4
          }, {
            x: ox + 32 | 0,
            y: oy + 160 | 0,
            w: 16,
            h: 16
          }, "Vertical", undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 32,
            y: 52,
            w: 12,
            h: 12
          }, {
            x: ox + 16 | 0,
            y: oy + 16 | 0,
            w: 48,
            h: 144
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: ox + 0 | 0,
            y: oy + 16 | 0,
            w: 16,
            h: 144
          }, undefined, undefined, undefined, undefined);
      return Generator.drawTextureLegacy("Skin", {
                  x: 36,
                  y: 48,
                  w: 4,
                  h: 4
                }, {
                  x: ox + 32 | 0,
                  y: oy + 0 | 0,
                  w: 16,
                  h: 16
                }, undefined, undefined, undefined, undefined);
    }
  };
  var rightLegBase = function (ox, oy) {
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: ox + 32 | 0,
          y: oy + 176 | 0,
          w: 16,
          h: 16
        }, "Vertical", 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 20,
          w: 16,
          h: 12
        }, {
          x: ox + 0 | 0,
          y: oy + 16 | 0,
          w: 64,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: ox + 48 | 0,
          y: oy + 0 | 0,
          w: 16,
          h: 16
        }, undefined, 90.0, undefined, undefined);
  };
  var leftLegBase = function (ox, oy) {
    Generator.drawTextureLegacy("Skin", {
          x: 24,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: ox + 32 | 0,
          y: oy + 160 | 0,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 52,
          w: 12,
          h: 12
        }, {
          x: ox + 16 | 0,
          y: oy + 16 | 0,
          w: 48,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: ox + 0 | 0,
          y: oy + 16 | 0,
          w: 16,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: ox + 32 | 0,
          y: oy + 0 | 0,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
  };
  var drawTentacle = function (nx, ny, tentType) {
    switch (tentType) {
      case 1 :
          rightLegBase(nx, ny);
          Generator.drawTextureLegacy("Skin", {
                x: 8,
                y: 32,
                w: 4,
                h: 4
              }, {
                x: nx + 32 | 0,
                y: ny + 176 | 0,
                w: 16,
                h: 16
              }, "Vertical", 270.0, undefined, undefined);
          Generator.drawTextureLegacy("Skin", {
                x: 0,
                y: 36,
                w: 16,
                h: 12
              }, {
                x: nx + 0 | 0,
                y: ny + 16 | 0,
                w: 64,
                h: 144
              }, undefined, undefined, undefined, undefined);
          return Generator.drawTextureLegacy("Skin", {
                      x: 4,
                      y: 32,
                      w: 4,
                      h: 4
                    }, {
                      x: nx + 48 | 0,
                      y: ny + 0 | 0,
                      w: 16,
                      h: 16
                    }, undefined, 90.0, undefined, undefined);
      case 2 :
          return rightLegBase(nx, ny);
      case 3 :
          leftLegBase(nx, ny);
          Generator.drawTextureLegacy("Skin", {
                x: 8,
                y: 48,
                w: 4,
                h: 4
              }, {
                x: nx + 32 | 0,
                y: ny + 160 | 0,
                w: 16,
                h: 16
              }, "Vertical", undefined, undefined, undefined);
          Generator.drawTextureLegacy("Skin", {
                x: 0,
                y: 52,
                w: 12,
                h: 12
              }, {
                x: nx + 16 | 0,
                y: ny + 16 | 0,
                w: 48,
                h: 144
              }, undefined, undefined, undefined, undefined);
          Generator.drawTextureLegacy("Skin", {
                x: 12,
                y: 52,
                w: 4,
                h: 12
              }, {
                x: nx + 0 | 0,
                y: ny + 16 | 0,
                w: 16,
                h: 144
              }, undefined, undefined, undefined, undefined);
          return Generator.drawTextureLegacy("Skin", {
                      x: 4,
                      y: 48,
                      w: 4,
                      h: 4
                    }, {
                      x: nx + 32 | 0,
                      y: ny + 0 | 0,
                      w: 16,
                      h: 16
                    }, undefined, undefined, undefined, undefined);
      case 4 :
          return leftLegBase(nx, ny);
      case 5 :
          rightArmBase(nx, ny);
          if (alexModel) {
            Generator.drawTextureLegacy("Skin", {
                  x: 47,
                  y: 32,
                  w: 3,
                  h: 4
                }, {
                  x: nx + 32 | 0,
                  y: ny + 176 | 0,
                  w: 16,
                  h: 16
                }, "Vertical", 270.0, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 40,
                  y: 36,
                  w: 4,
                  h: 12
                }, {
                  x: nx + 0 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 44,
                  y: 36,
                  w: 3,
                  h: 12
                }, {
                  x: nx + 16 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 47,
                  y: 36,
                  w: 4,
                  h: 12
                }, {
                  x: nx + 32 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 51,
                  y: 36,
                  w: 3,
                  h: 12
                }, {
                  x: nx + 48 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            return Generator.drawTextureLegacy("Skin", {
                        x: 44,
                        y: 32,
                        w: 3,
                        h: 4
                      }, {
                        x: nx + 48 | 0,
                        y: ny + 0 | 0,
                        w: 16,
                        h: 16
                      }, undefined, 90.0, undefined, undefined);
          } else {
            Generator.drawTextureLegacy("Skin", {
                  x: 48,
                  y: 32,
                  w: 4,
                  h: 4
                }, {
                  x: nx + 32 | 0,
                  y: ny + 176 | 0,
                  w: 16,
                  h: 16
                }, "Vertical", 270.0, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 40,
                  y: 36,
                  w: 16,
                  h: 12
                }, {
                  x: nx + 0 | 0,
                  y: ny + 16 | 0,
                  w: 64,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            return Generator.drawTextureLegacy("Skin", {
                        x: 44,
                        y: 32,
                        w: 4,
                        h: 4
                      }, {
                        x: nx + 48 | 0,
                        y: ny + 0 | 0,
                        w: 16,
                        h: 16
                      }, undefined, 90.0, undefined, undefined);
          }
      case 6 :
          return rightArmBase(nx, ny);
      case 7 :
          leftArmBase(nx, ny);
          if (alexModel) {
            Generator.drawTextureLegacy("Skin", {
                  x: 55,
                  y: 48,
                  w: 3,
                  h: 4
                }, {
                  x: nx + 32 | 0,
                  y: ny + 160 | 0,
                  w: 16,
                  h: 16
                }, "Vertical", undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 48,
                  y: 52,
                  w: 4,
                  h: 12
                }, {
                  x: nx + 16 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 52,
                  y: 52,
                  w: 3,
                  h: 12
                }, {
                  x: nx + 32 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 55,
                  y: 52,
                  w: 4,
                  h: 12
                }, {
                  x: nx + 48 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 59,
                  y: 52,
                  w: 3,
                  h: 12
                }, {
                  x: nx + 0 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            return Generator.drawTextureLegacy("Skin", {
                        x: 52,
                        y: 48,
                        w: 3,
                        h: 4
                      }, {
                        x: nx + 32 | 0,
                        y: ny + 0 | 0,
                        w: 16,
                        h: 16
                      }, undefined, undefined, undefined, undefined);
          } else {
            Generator.drawTextureLegacy("Skin", {
                  x: 56,
                  y: 48,
                  w: 4,
                  h: 4
                }, {
                  x: nx + 32 | 0,
                  y: ny + 160 | 0,
                  w: 16,
                  h: 16
                }, "Vertical", undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 48,
                  y: 52,
                  w: 12,
                  h: 12
                }, {
                  x: nx + 16 | 0,
                  y: ny + 16 | 0,
                  w: 48,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            Generator.drawTextureLegacy("Skin", {
                  x: 60,
                  y: 52,
                  w: 4,
                  h: 12
                }, {
                  x: nx + 0 | 0,
                  y: ny + 16 | 0,
                  w: 16,
                  h: 144
                }, undefined, undefined, undefined, undefined);
            return Generator.drawTextureLegacy("Skin", {
                        x: 52,
                        y: 48,
                        w: 4,
                        h: 4
                      }, {
                        x: nx + 32 | 0,
                        y: ny + 0 | 0,
                        w: 16,
                        h: 16
                      }, undefined, undefined, undefined, undefined);
          }
      case 8 :
          return leftArmBase(nx, ny);
      default:
        return ;
    }
  };
  Generator.drawImage("Background", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 67,
        y: 145,
        w: 96,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 163,
        y: 145,
        w: 96,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 259,
        y: 145,
        w: 96,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 355,
        y: 145,
        w: 96,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 163,
        y: 49,
        w: 96,
        h: 96
      }, undefined, undefined, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 67,
          y: 145,
          w: 96,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 163,
          y: 145,
          w: 96,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 259,
          y: 145,
          w: 96,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 355,
          y: 145,
          w: 96,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 163,
          y: 49,
          w: 96,
          h: 96
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 67,
        y: 241,
        w: 96,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 163,
        y: 241,
        w: 96,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 259,
        y: 241,
        w: 96,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 355,
        y: 241,
        w: 96,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 163,
        y: 273,
        w: 96,
        h: 96
      }, "Vertical", undefined, undefined, undefined);
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 67,
          y: 241,
          w: 96,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 163,
          y: 241,
          w: 96,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 259,
          y: 241,
          w: 96,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 355,
          y: 241,
          w: 96,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 163,
          y: 273,
          w: 96,
          h: 96
        }, "Vertical", undefined, undefined, undefined);
  }
  Generator.defineRegionInput([
        471,
        16,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 1", cycleTentacleTypes(tent1));
        }));
  drawTentacle(471, 16, tent1);
  Generator.defineRegionInput([
        471,
        215,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 2", cycleTentacleTypes(tent2));
        }));
  drawTentacle(471, 215, tent2);
  Generator.defineRegionInput([
        470,
        416,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 3", cycleTentacleTypes(tent3));
        }));
  drawTentacle(470, 416, tent3);
  Generator.defineRegionInput([
        376,
        416,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 4", cycleTentacleTypes(tent4));
        }));
  drawTentacle(376, 416, tent4);
  Generator.defineRegionInput([
        280,
        416,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 5", cycleTentacleTypes(tent5));
        }));
  drawTentacle(280, 416, tent5);
  Generator.defineRegionInput([
        196,
        416,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 6", cycleTentacleTypes(tent6));
        }));
  drawTentacle(196, 416, tent6);
  Generator.defineRegionInput([
        109,
        416,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 7", cycleTentacleTypes(tent7));
        }));
  drawTentacle(109, 416, tent7);
  Generator.defineRegionInput([
        15,
        416,
        64,
        176
      ], (function (param) {
          Generator.setSelectInputValue("Tentacle 8", cycleTentacleTypes(tent8));
        }));
  drawTentacle(15, 416, tent8);
  Generator.drawTexture("Squid", [
        27,
        2,
        6,
        8
      ], [
        187,
        289,
        48,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Squid", [
        26,
        3,
        8,
        6
      ], [
        179,
        297,
        64,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  if (showFolds) {
    return Generator.drawImage("Folds", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
