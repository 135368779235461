// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-wolf-character";

var name = "Minecraft Wolf Character";

var history = [
  "Originally developed by dodecaphon.",
  "06 Feb 2015 lostminer - Add user variables.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "29 Sep 2020 NinjolasNJM - Various updates."
];

var thumbnail = {
  url: require("./thumbnail/v3-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Angry Wolf",
    url: requireTexture("wolf_angry"),
    standardWidth: 64,
    standardHeight: 32
  }
];

var steve = TextureMap.MinecraftCharacterLegacy.steve;

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  Generator.defineBooleanInput("Show Red Eyes", false);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var showRedEyes = Generator.getBooleanInputValue("Show Red Eyes");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideLeftSleeve = Generator.getBooleanInputValue("Hide Left Sleeve");
  var hideRightSleeve = Generator.getBooleanInputValue("Hide Right Sleeve");
  var hideLeftPant = Generator.getBooleanInputValue("Hide Left Pant");
  var hideRightPant = Generator.getBooleanInputValue("Hide Right Pant");
  var tailType = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tail Type")), 1);
  var cycleTailTypes = function (t) {
    return String(t === 4 ? 1 : t + 1 | 0);
  };
  Generator.defineRegionInput([
        341,
        312,
        160,
        112
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        85,
        198,
        240,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        346,
        575,
        64,
        88
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Sleeve", !hideLeftSleeve);
        }));
  Generator.defineRegionInput([
        259,
        575,
        64,
        88
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Sleeve", !hideRightSleeve);
        }));
  Generator.defineRegionInput([
        172,
        575,
        64,
        88
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Pant", !hideLeftPant);
        }));
  Generator.defineRegionInput([
        85,
        575,
        64,
        88
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Pant", !hideRightPant);
        }));
  Generator.defineRegionInput([
        407,
        518,
        88,
        64
      ], (function (param) {
          Generator.setSelectInputValue("Tail Type", cycleTailTypes(tailType));
        }));
  var drawLimb = function (sx, sy, dx, dy, isArm) {
    if (isArm && alexModel) {
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy,
            3,
            4
          ], [
            dx + 16 | 0,
            dy,
            16,
            16
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx,
            sy + 4 | 0,
            4,
            12
          ], [
            dx,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy + 4 | 0,
            3,
            12
          ], [
            dx + 16 | 0,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 7 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            dx + 32 | 0,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 11 | 0,
            sy + 4 | 0,
            3,
            12
          ], [
            dx + 48 | 0,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      return Generator.drawTexture("Skin", [
                  sx + 7 | 0,
                  sy,
                  3,
                  4
                ], [
                  dx + 16 | 0,
                  dy + 72 | 0,
                  16,
                  16
                ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    } else {
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy,
            4,
            4
          ], [
            dx + 16 | 0,
            dy,
            16,
            16
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx,
            sy + 4 | 0,
            4,
            12
          ], [
            dx,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            dx + 16 | 0,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 8 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            dx + 32 | 0,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 12 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            dx + 48 | 0,
            dy + 16 | 0,
            16,
            56
          ], undefined, undefined, undefined, undefined, undefined, undefined);
      return Generator.drawTexture("Skin", [
                  sx + 8 | 0,
                  sy,
                  4,
                  4
                ], [
                  dx + 16 | 0,
                  dy + 72 | 0,
                  16,
                  16
                ], "Vertical", undefined, undefined, undefined, undefined, undefined);
    }
  };
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 341,
        y: 344,
        w: 32,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 373,
        y: 392,
        w: 48,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 421,
        y: 344,
        w: 32,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 453,
        y: 344,
        w: 48,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 373,
        y: 312,
        w: 48,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 373,
        y: 344,
        w: 48,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 4,
        h: 3
      }, {
        x: 416,
        y: 232,
        w: 24,
        h: 24
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 1,
        h: 3
      }, {
        x: 392,
        y: 232,
        w: 24,
        h: 24
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 4,
        h: 1
      }, {
        x: 416,
        y: 208,
        w: 24,
        h: 24
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 15,
        w: 4,
        h: 1
      }, {
        x: 416,
        y: 256,
        w: 24,
        h: 24
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 13,
        y: 13,
        w: 1,
        h: 3
      }, {
        x: 440,
        y: 232,
        w: 24,
        h: 24
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 4,
        h: 3
      }, {
        x: 464,
        y: 232,
        w: 24,
        h: 24
      }, "Horizontal", undefined, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 341,
          y: 344,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 373,
          y: 392,
          w: 48,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 421,
          y: 344,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 453,
          y: 344,
          w: 48,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 373,
          y: 312,
          w: 48,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 373,
          y: 344,
          w: 48,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 4,
          h: 3
        }, {
          x: 416,
          y: 232,
          w: 24,
          h: 24
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 1,
          h: 3
        }, {
          x: 392,
          y: 232,
          w: 24,
          h: 24
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 4,
          h: 1
        }, {
          x: 416,
          y: 208,
          w: 24,
          h: 24
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 15,
          w: 4,
          h: 1
        }, {
          x: 416,
          y: 256,
          w: 24,
          h: 24
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 45,
          y: 13,
          w: 1,
          h: 3
        }, {
          x: 440,
          y: 232,
          w: 24,
          h: 24
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 4,
          h: 3
        }, {
          x: 464,
          y: 232,
          w: 24,
          h: 24
        }, "Horizontal", undefined, undefined, undefined);
  }
  drawLimb(0, 16, 85, 575, false);
  if (!hideRightPant) {
    drawLimb(0, 32, 85, 575, false);
  }
  drawLimb(16, 48, 172, 575, false);
  if (!hideLeftPant) {
    drawLimb(0, 48, 172, 575, false);
  }
  drawLimb(40, 16, 259, 575, true);
  if (!hideRightSleeve) {
    drawLimb(40, 32, 259, 575, true);
  }
  drawLimb(32, 48, 346, 575, true);
  if (!hideLeftSleeve) {
    drawLimb(48, 48, 346, 575, true);
  }
  var drawTail = function (sx, sy, isArm) {
    if (isArm && alexModel) {
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy,
            3,
            4
          ], [
            479,
            534,
            16,
            16
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx,
            sy + 4 | 0,
            4,
            12
          ], [
            443,
            498,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy + 4 | 0,
            3,
            12
          ], [
            443,
            514,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 7 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            443,
            530,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 11 | 0,
            sy + 4 | 0,
            3,
            12
          ], [
            443,
            546,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      return Generator.drawTexture("Skin", [
                  sx + 7 | 0,
                  sy,
                  3,
                  4
                ], [
                  407,
                  534,
                  16,
                  16
                ], "Vertical", undefined, undefined, 90.0, undefined, undefined);
    } else {
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy,
            4,
            4
          ], [
            479,
            534,
            16,
            16
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx,
            sy + 4 | 0,
            4,
            12
          ], [
            443,
            498,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 4 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            443,
            514,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 8 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            443,
            530,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      Generator.drawTexture("Skin", [
            sx + 12 | 0,
            sy + 4 | 0,
            4,
            12
          ], [
            443,
            546,
            16,
            56
          ], undefined, undefined, undefined, 90.0, undefined, undefined);
      return Generator.drawTexture("Skin", [
                  sx + 8 | 0,
                  sy,
                  4,
                  4
                ], [
                  407,
                  534,
                  16,
                  16
                ], "Vertical", undefined, undefined, 90.0, undefined, undefined);
    }
  };
  switch (tailType) {
    case 1 :
        drawTail(0, 16, false);
        if (!hideRightPant) {
          drawTail(0, 32, false);
        }
        break;
    case 2 :
        drawTail(16, 48, false);
        if (!hideLeftPant) {
          drawTail(0, 48, false);
        }
        break;
    case 3 :
        drawTail(40, 16, true);
        if (!hideRightSleeve) {
          drawTail(40, 32, true);
        }
        break;
    case 4 :
        drawTail(32, 48, true);
        if (!hideLeftSleeve) {
          drawTail(48, 48, true);
        }
        break;
    default:
      
  }
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 4
      }, {
        x: 141,
        y: 254,
        w: 64,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 4
      }, {
        x: 85,
        y: 254,
        w: 56,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 4
      }, {
        x: 205,
        y: 254,
        w: 56,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 4
      }, {
        x: 261,
        y: 254,
        w: 64,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 141,
        y: 198,
        w: 64,
        h: 56
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 141,
        y: 302,
        w: 64,
        h: 56
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 163,
        y: 427,
        w: 48,
        h: 72
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 24,
        w: 4,
        h: 8
      }, {
        x: 115,
        y: 427,
        w: 48,
        h: 72
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 24,
        w: 4,
        h: 8
      }, {
        x: 211,
        y: 427,
        w: 48,
        h: 72
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 259,
        y: 427,
        w: 48,
        h: 72
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 163,
        y: 379,
        w: 48,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 163,
        y: 499,
        w: 48,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 22,
        y: 17,
        w: 2,
        h: 2
      }, {
        x: 371,
        y: 459,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 25,
        y: 17,
        w: 2,
        h: 2
      }, {
        x: 395,
        y: 459,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 21,
        y: 17,
        w: 1,
        h: 2
      }, {
        x: 363,
        y: 459,
        w: 8,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 17,
        w: 1,
        h: 2
      }, {
        x: 387,
        y: 459,
        w: 8,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 22,
        y: 16,
        w: 2,
        h: 1
      }, {
        x: 371,
        y: 451,
        w: 16,
        h: 8
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 16,
        w: 2,
        h: 1
      }, {
        x: 371,
        y: 475,
        w: 16,
        h: 8
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 22,
        y: 17,
        w: 2,
        h: 2
      }, {
        x: 444,
        y: 459,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 25,
        y: 17,
        w: 2,
        h: 2
      }, {
        x: 468,
        y: 459,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 21,
        y: 17,
        w: 1,
        h: 2
      }, {
        x: 436,
        y: 459,
        w: 8,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 17,
        w: 1,
        h: 2
      }, {
        x: 460,
        y: 459,
        w: 8,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 22,
        y: 16,
        w: 2,
        h: 1
      }, {
        x: 444,
        y: 451,
        w: 16,
        h: 8
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 16,
        w: 2,
        h: 1
      }, {
        x: 444,
        y: 475,
        w: 16,
        h: 8
      }, "Vertical", undefined, undefined, undefined);
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 4
        }, {
          x: 141,
          y: 254,
          w: 64,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 4
        }, {
          x: 85,
          y: 254,
          w: 56,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 4
        }, {
          x: 205,
          y: 254,
          w: 56,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 4
        }, {
          x: 261,
          y: 254,
          w: 64,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 141,
          y: 198,
          w: 64,
          h: 56
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 141,
          y: 302,
          w: 64,
          h: 56
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 40,
          w: 8,
          h: 8
        }, {
          x: 163,
          y: 427,
          w: 48,
          h: 72
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 40,
          w: 4,
          h: 8
        }, {
          x: 115,
          y: 427,
          w: 48,
          h: 72
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 40,
          w: 4,
          h: 8
        }, {
          x: 211,
          y: 427,
          w: 48,
          h: 72
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 40,
          w: 8,
          h: 8
        }, {
          x: 259,
          y: 427,
          w: 48,
          h: 72
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 163,
          y: 379,
          w: 48,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 163,
          y: 499,
          w: 48,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 22,
          y: 33,
          w: 2,
          h: 2
        }, {
          x: 371,
          y: 459,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 25,
          y: 33,
          w: 2,
          h: 2
        }, {
          x: 395,
          y: 459,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 21,
          y: 33,
          w: 1,
          h: 2
        }, {
          x: 363,
          y: 459,
          w: 8,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 24,
          y: 33,
          w: 1,
          h: 2
        }, {
          x: 387,
          y: 459,
          w: 8,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 22,
          y: 32,
          w: 2,
          h: 1
        }, {
          x: 371,
          y: 451,
          w: 16,
          h: 8
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 24,
          y: 32,
          w: 2,
          h: 1
        }, {
          x: 371,
          y: 475,
          w: 16,
          h: 8
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 22,
          y: 33,
          w: 2,
          h: 2
        }, {
          x: 444,
          y: 459,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 25,
          y: 33,
          w: 2,
          h: 2
        }, {
          x: 468,
          y: 459,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 21,
          y: 33,
          w: 1,
          h: 2
        }, {
          x: 436,
          y: 459,
          w: 8,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 24,
          y: 33,
          w: 1,
          h: 2
        }, {
          x: 460,
          y: 459,
          w: 8,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 22,
          y: 32,
          w: 2,
          h: 1
        }, {
          x: 444,
          y: 451,
          w: 16,
          h: 8
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 24,
          y: 32,
          w: 2,
          h: 1
        }, {
          x: 444,
          y: 475,
          w: 16,
          h: 8
        }, "Vertical", undefined, undefined, undefined);
  }
  Generator.drawImage("Background", [
        0,
        0
      ]);
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    Generator.drawImage("Labels", [
          0,
          0
        ]);
  }
  if (showRedEyes) {
    Generator.drawTexture("Angry Wolf", [
          4,
          5,
          2,
          2
        ], [
          379,
          362,
          12,
          12
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Angry Wolf", [
          4,
          4,
          1,
          1
        ], [
          379,
          356,
          6,
          6
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Angry Wolf", [
          8,
          5,
          2,
          2
        ], [
          403,
          362,
          12,
          12
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    return Generator.drawTexture("Angry Wolf", [
                9,
                4,
                1,
                1
              ], [
                409,
                356,
                6,
                6
              ], undefined, undefined, undefined, undefined, undefined, undefined);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
