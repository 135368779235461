// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator_Builder = require("./Generator_Builder.bs.js");

var model = {
  contents: Generator_Builder.Model.make(undefined)
};

function setModel(newModel) {
  model.contents = newModel;
}

function getModel(param) {
  return model.contents;
}

function setStringVariable(id, value) {
  model.contents = Generator_Builder.setStringVariable(model.contents, id, value);
}

function getStringVariable(id) {
  return Generator_Builder.getStringVariable(model.contents, id);
}

function setIntegerVariable(id, value) {
  model.contents = Generator_Builder.setIntegerVariable(model.contents, id, value);
}

function getIntegerVariable(id) {
  return Generator_Builder.getIntegerVariable(model.contents, id);
}

function setFloatVariable(id, value) {
  model.contents = Generator_Builder.setFloatVariable(model.contents, id, value);
}

function getFloatVariable(id) {
  return Generator_Builder.getFloatVariable(model.contents, id);
}

function setBooleanVariable(id, value) {
  model.contents = Generator_Builder.setBooleanVariable(model.contents, id, value);
}

function getBooleanVariable(id) {
  return Generator_Builder.getBooleanVariable(model.contents, id);
}

function getPagePixelColor(id, x, y) {
  return Generator_Builder.getPagePixelColor(model.contents, id, x, y);
}

function getCurrentPagePixelColor(x, y) {
  return Generator_Builder.getCurrentPagePixelColor(model.contents, x, y);
}

function getTexturePixelColor(id, x, y) {
  return Generator_Builder.getTexturePixelColor(model.contents, id, x, y);
}

function getImagePixelColor(id, x, y) {
  return Generator_Builder.getImagePixelColor(model.contents, id, x, y);
}

function clearStringInputValues(param) {
  model.contents = Generator_Builder.clearStringInputValues(model.contents);
}

function defineButtonInput(id, onClick) {
  model.contents = Generator_Builder.defineButtonInput(model.contents, id, onClick);
}

function defineRegionInput(region, callback) {
  model.contents = Generator_Builder.defineRegionInput(model.contents, region, callback);
}

function defineCustomStringInput(id, fn) {
  model.contents = Generator_Builder.defineCustomStringInput(model.contents, id, fn);
}

function getStringInputValue(id) {
  return Generator_Builder.getStringInputValue(model.contents, id);
}

function setStringInputValue(id, value) {
  model.contents = Generator_Builder.setStringInputValue(model.contents, id, value);
}

function getBooleanInputValue(id) {
  return Generator_Builder.getBooleanInputValue(model.contents, id);
}

function setBooleanInputValue(id, value) {
  model.contents = Generator_Builder.setBooleanInputValue(model.contents, id, value);
}

function defineBooleanInput(id, initial) {
  model.contents = Generator_Builder.defineBooleanInput(model.contents, id, initial);
}

function defineAndGetBooleanInput(id, initial) {
  defineBooleanInput(id, initial);
  return Generator_Builder.getBooleanInputValue(model.contents, id);
}

function getBooleanInputValueWithDefault(id, $$default) {
  return Generator_Builder.getBooleanInputValueWithDefault(model.contents, id, $$default);
}

function setSelectInputValue(id, value) {
  model.contents = Generator_Builder.setSelectInputValue(model.contents, id, value);
}

function getSelectInputValue(id) {
  return Generator_Builder.getSelectInputValue(model.contents, id);
}

function defineSelectInput(id, options) {
  model.contents = Generator_Builder.defineSelectInput(model.contents, id, options);
}

function defineAndGetSelectInput(id, options) {
  defineSelectInput(id, options);
  return Generator_Builder.getSelectInputValue(model.contents, id);
}

function getRangeInputValue(id) {
  return Generator_Builder.getRangeInputValue(model.contents, id);
}

function defineRangeInput(id, options) {
  model.contents = Generator_Builder.defineRangeInput(model.contents, id, options);
}

function defineAndGetRangeInput(id, options) {
  defineRangeInput(id, options);
  return Generator_Builder.getRangeInputValue(model.contents, id);
}

function defineTextureInput(id, options) {
  model.contents = Generator_Builder.defineTextureInput(model.contents, id, options);
}

function defineText(text) {
  model.contents = Generator_Builder.defineText(model.contents, text);
}

function usePage(id) {
  model.contents = Generator_Builder.usePage(model.contents, id);
}

function fillBackgroundColor(color) {
  model.contents = Generator_Builder.fillBackgroundColor(model.contents, color);
}

function fillBackgroundColorWithWhite(param) {
  model.contents = Generator_Builder.fillBackgroundColor(model.contents, "#ffffff");
}

function fillRect(dest, color) {
  model.contents = Generator_Builder.fillRect(model.contents, dest, color);
}

function drawLine(from, to, colorOpt, widthOpt, param) {
  var color = colorOpt !== undefined ? colorOpt : "#000000";
  var width = widthOpt !== undefined ? widthOpt : 1;
  model.contents = Generator_Builder.drawLine(model.contents, from, to, color, width, [], 0);
}

function drawFoldLine(from, to) {
  model.contents = Generator_Builder.drawLine(model.contents, from, to, "#7b7b7b", 1, [
        2,
        2
      ], 3);
}

function drawTexture(id, source, dest, flipOpt, blendOpt, rotateLegacyOpt, rotateOpt, pixelateOpt, param) {
  var flip = flipOpt !== undefined ? flipOpt : "None";
  var blend = blendOpt !== undefined ? blendOpt : "None";
  var rotateLegacy = rotateLegacyOpt !== undefined ? rotateLegacyOpt : 0.0;
  var rotate = rotateOpt !== undefined ? rotateOpt : 0.0;
  var pixelate = pixelateOpt !== undefined ? pixelateOpt : false;
  var rotate$1 = rotateLegacy !== 0.0 ? ({
        NAME: "Corner",
        VAL: rotateLegacy
      }) : (
      rotate !== 0.0 ? ({
            NAME: "Center",
            VAL: rotate
          }) : "None"
    );
  model.contents = Generator_Builder.drawTexture(model.contents, id, source, dest, flip, rotate$1, blend, pixelate, undefined);
}

function drawTextureLegacy(id, source, dest, flipOpt, rotateLegacyOpt, pixelateOpt, param) {
  var flip = flipOpt !== undefined ? flipOpt : "None";
  var rotateLegacy = rotateLegacyOpt !== undefined ? rotateLegacyOpt : 0.0;
  var pixelate = pixelateOpt !== undefined ? pixelateOpt : false;
  drawTexture(id, [
        source.x,
        source.y,
        source.w,
        source.h
      ], [
        dest.x,
        dest.y,
        dest.w,
        dest.h
      ], flip, undefined, rotateLegacy, undefined, pixelate, undefined);
}

function drawImage(id, position) {
  model.contents = Generator_Builder.drawImage(model.contents, id, position);
}

function hasImage(id) {
  return Generator_Builder.hasImage(model.contents, id);
}

function hasTexture(id) {
  return Generator_Builder.hasTexture(model.contents, id);
}

function drawText(text, position, size) {
  model.contents = Generator_Builder.drawText(model.contents, text, position, size);
}

function toIntPoint(param) {
  return [
          Math.round(param[0]) | 0,
          Math.round(param[1]) | 0
        ];
}

function translate(param, dx, dy) {
  return [
          param[0] + dx,
          param[1] + dy
        ];
}

var Point = {
  toIntPoint: toIntPoint,
  translate: translate
};

function toRadians(degrees) {
  return degrees * (Math.PI / 180.0);
}

var Angle = {
  toRadians: toRadians
};

var Orientation = {};

function drawTab(rectangle, orientation, showFoldLineOpt, tabAngleOpt, param) {
  var showFoldLine = showFoldLineOpt !== undefined ? showFoldLineOpt : true;
  var tabAngle = tabAngleOpt !== undefined ? tabAngleOpt : 45.0;
  var h = rectangle[3];
  var w = rectangle[2];
  var y = rectangle[1];
  var x = rectangle[0];
  var tabAngleRad = toRadians(tabAngle);
  if (orientation === "East") {
    var maxInset = h / 2.0;
    var inset = w / Math.tan(tabAngleRad);
    var match = inset > maxInset ? [
        maxInset,
        Math.tan(tabAngleRad) * maxInset
      ] : [
        inset,
        w
      ];
    var tabHeight = match[1];
    var inset$1 = match[0];
    var p2_1 = 0.0 + inset$1;
    var p2 = [
      tabHeight,
      p2_1
    ];
    var p3_1 = h - inset$1;
    var p3 = [
      tabHeight,
      p3_1
    ];
    var p4 = [
      0.0,
      h
    ];
    var p1 = toIntPoint(translate([
              0.0,
              0.0
            ], x, y));
    var p2$1 = toIntPoint(translate(p2, x, y));
    var p3$1 = toIntPoint(translate(p3, x, y));
    var p4$1 = toIntPoint(translate(p4, x, y));
    drawLine(p1, p2$1, undefined, undefined, undefined);
    drawLine(p3$1, p2$1, undefined, undefined, undefined);
    drawLine(p3$1, p4$1, undefined, undefined, undefined);
    if (showFoldLine) {
      return drawFoldLine(p1, p4$1);
    } else {
      return ;
    }
  }
  if (orientation === "North") {
    var maxInset$1 = w / 2.0;
    var inset$2 = h / Math.tan(tabAngleRad);
    var match$1 = inset$2 > maxInset$1 ? [
        maxInset$1,
        Math.tan(tabAngleRad) * maxInset$1
      ] : [
        inset$2,
        h
      ];
    var tabHeight$1 = match$1[1];
    var inset$3 = match$1[0];
    var p1$1 = [
      0.0,
      h
    ];
    var p2_0 = 0.0 + inset$3;
    var p2_1$1 = h - tabHeight$1;
    var p2$2 = [
      p2_0,
      p2_1$1
    ];
    var p3_0 = w - inset$3;
    var p3_1$1 = h - tabHeight$1;
    var p3$2 = [
      p3_0,
      p3_1$1
    ];
    var p4$2 = [
      w,
      h
    ];
    var p1$2 = toIntPoint(translate(p1$1, x, y));
    var p2$3 = toIntPoint(translate(p2$2, x, y));
    var p3$3 = toIntPoint(translate(p3$2, x, y));
    var p4$3 = toIntPoint(translate(p4$2, x, y));
    drawLine(p2$3, p1$2, undefined, undefined, undefined);
    drawLine(p2$3, p3$3, undefined, undefined, undefined);
    drawLine(p4$3, p3$3, undefined, undefined, undefined);
    if (showFoldLine) {
      return drawFoldLine(p4$3, p1$2);
    } else {
      return ;
    }
  }
  if (orientation === "West") {
    var maxInset$2 = h / 2.0;
    var inset$4 = w / Math.tan(tabAngleRad);
    var match$2 = inset$4 > maxInset$2 ? [
        maxInset$2,
        Math.tan(tabAngleRad) * maxInset$2
      ] : [
        inset$4,
        w
      ];
    var tabHeight$2 = match$2[1];
    var inset$5 = match$2[0];
    var p1$3 = [
      w,
      h
    ];
    var p2_0$1 = w - tabHeight$2;
    var p2_1$2 = h - inset$5;
    var p2$4 = [
      p2_0$1,
      p2_1$2
    ];
    var p3_0$1 = w - tabHeight$2;
    var p3_1$2 = 0.0 + inset$5;
    var p3$4 = [
      p3_0$1,
      p3_1$2
    ];
    var p4$4 = [
      w,
      0.0
    ];
    var p1$4 = toIntPoint(translate(p1$3, x, y));
    var p2$5 = toIntPoint(translate(p2$4, x, y));
    var p3$5 = toIntPoint(translate(p3$4, x, y));
    var p4$5 = toIntPoint(translate(p4$4, x, y));
    drawLine(p1$4, p2$5, undefined, undefined, undefined);
    drawLine(p3$5, p2$5, undefined, undefined, undefined);
    drawLine(p3$5, p4$5, undefined, undefined, undefined);
    if (showFoldLine) {
      return drawFoldLine(p1$4, p4$5);
    } else {
      return ;
    }
  }
  var maxInset$3 = w / 2.0;
  var inset$6 = h / Math.tan(tabAngleRad);
  var match$3 = inset$6 > maxInset$3 ? [
      maxInset$3,
      Math.tan(tabAngleRad) * maxInset$3
    ] : [
      inset$6,
      h
    ];
  var tabHeight$3 = match$3[1];
  var inset$7 = match$3[0];
  var p1$5 = [
    w,
    0.0
  ];
  var p2_0$2 = w - inset$7;
  var p2$6 = [
    p2_0$2,
    tabHeight$3
  ];
  var p3_0$2 = 0.0 + inset$7;
  var p3$6 = [
    p3_0$2,
    tabHeight$3
  ];
  var p1$6 = toIntPoint(translate(p1$5, x, y));
  var p2$7 = toIntPoint(translate(p2$6, x, y));
  var p3$7 = toIntPoint(translate(p3$6, x, y));
  var p4$6 = toIntPoint(translate([
            0.0,
            0.0
          ], x, y));
  drawLine(p2$7, p1$6, undefined, undefined, undefined);
  drawLine(p2$7, p3$7, undefined, undefined, undefined);
  drawLine(p4$6, p3$7, undefined, undefined, undefined);
  if (showFoldLine) {
    return drawFoldLine(p4$6, p1$6);
  }
  
}

var Builder;

var PageSize;

var Markdown;

exports.Builder = Builder;
exports.PageSize = PageSize;
exports.Markdown = Markdown;
exports.model = model;
exports.setModel = setModel;
exports.getModel = getModel;
exports.setStringVariable = setStringVariable;
exports.getStringVariable = getStringVariable;
exports.setIntegerVariable = setIntegerVariable;
exports.getIntegerVariable = getIntegerVariable;
exports.setFloatVariable = setFloatVariable;
exports.getFloatVariable = getFloatVariable;
exports.setBooleanVariable = setBooleanVariable;
exports.getBooleanVariable = getBooleanVariable;
exports.getPagePixelColor = getPagePixelColor;
exports.getCurrentPagePixelColor = getCurrentPagePixelColor;
exports.getTexturePixelColor = getTexturePixelColor;
exports.getImagePixelColor = getImagePixelColor;
exports.clearStringInputValues = clearStringInputValues;
exports.defineButtonInput = defineButtonInput;
exports.defineRegionInput = defineRegionInput;
exports.defineCustomStringInput = defineCustomStringInput;
exports.getStringInputValue = getStringInputValue;
exports.setStringInputValue = setStringInputValue;
exports.getBooleanInputValue = getBooleanInputValue;
exports.setBooleanInputValue = setBooleanInputValue;
exports.defineBooleanInput = defineBooleanInput;
exports.defineAndGetBooleanInput = defineAndGetBooleanInput;
exports.getBooleanInputValueWithDefault = getBooleanInputValueWithDefault;
exports.setSelectInputValue = setSelectInputValue;
exports.getSelectInputValue = getSelectInputValue;
exports.defineSelectInput = defineSelectInput;
exports.defineAndGetSelectInput = defineAndGetSelectInput;
exports.getRangeInputValue = getRangeInputValue;
exports.defineRangeInput = defineRangeInput;
exports.defineAndGetRangeInput = defineAndGetRangeInput;
exports.defineTextureInput = defineTextureInput;
exports.defineText = defineText;
exports.usePage = usePage;
exports.fillBackgroundColor = fillBackgroundColor;
exports.fillBackgroundColorWithWhite = fillBackgroundColorWithWhite;
exports.fillRect = fillRect;
exports.drawLine = drawLine;
exports.drawFoldLine = drawFoldLine;
exports.drawTexture = drawTexture;
exports.drawTextureLegacy = drawTextureLegacy;
exports.drawImage = drawImage;
exports.hasImage = hasImage;
exports.hasTexture = hasTexture;
exports.drawText = drawText;
exports.Point = Point;
exports.Angle = Angle;
exports.Orientation = Orientation;
exports.drawTab = drawTab;
/* model Not a pure module */
