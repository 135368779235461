// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Generator = require("../../builder/modules/Generator.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Markdown = require("../../builder/views/Generator_Markdown.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "dalek";

var name = "Doctor Who Dalek";

var history = [
  "gootube2000 - First release.",
  "13 Feb 2015 lostminer - Update to use new version of generator."
];

var thumbnail = {
  url: require("./thumbnail/thumbnail.jpeg")
};

var dalek = require("./instructions/dalek.jpeg");

var dalekSkin = require("./instructions/60sDalek.png");

var instructions = "\nCreate your own Dalek papercraft, thanks to the team who make the [Dalek Mod](https://swdteam.com/p/dalek-mod).\n\n## What is a Dalek?\n\n<div class=\"not-prose\" style=\"float:right;max-width:150px;\">\n  <img src=\"" + dalek + "\" />\n</div>\n\nDaleks are a main enemy of a character called The Doctor in the TV show Doctor Who.\n\nDaleks are armoured, mutant creatures who are intensely xenophobic and bent on universal domination.\n\nDaleks are hated and feared throughout time and space. They are the oldest and most frequent foes of The Doctor.\n\n[More about Daleks](http://tardis.wikia.com/wiki/Dalek)\n\n[More about Doctor Who](http://www.thedoctorwhosite.co.uk/doctorwho/information-about-doctor-who)\n\n## How to use the Dalek generator\n\n### Option 1: Use an existing Dalek skin\n\n* Select one of the Dalek skins from the generator.\n* Download and print your Dalek papercraft.\n\n### Option 2: Create your own texture\n\n* Download a sample Dalek skin (Right click and save):\n  ![Dalek](" + dalekSkin + ")\n* Edit this skin in your favourite graphics program.\n* Choose this file in the generator.\n* Download and print your Dalek papercraft.\n";

var images = [
  {
    id: "Background1",
    url: requireImage("Background1")
  },
  {
    id: "Colors1",
    url: requireImage("Colors1")
  },
  {
    id: "Background2",
    url: requireImage("Background2")
  },
  {
    id: "Colors2",
    url: requireImage("Colors2")
  }
];

var daleks = [
  {
    file: "60sDalek",
    id: "60's Dalek"
  },
  {
    file: "80sDalek",
    id: "80's Dalek"
  },
  {
    file: "RedDalek",
    id: "Red Dalek"
  },
  {
    file: "YellowDalek",
    id: "Yellow Dalek"
  },
  {
    file: "MovieDalekGold",
    id: "Gold Dalek"
  },
  {
    file: "EntityGoldDalek",
    id: "Gold Entity Dalek"
  },
  {
    file: "EntityMDalekBlk",
    id: "Black Entity Dalek"
  },
  {
    file: "EntityMDalekBlu",
    id: "Blue Entity Dalek"
  },
  {
    file: "EntityMDalekRed",
    id: "Red Entity Dalek"
  },
  {
    file: "EnderDalek",
    id: "Ender Dalek"
  },
  {
    file: "ClassicSupreme",
    id: "Classic Supreme Dalek"
  },
  {
    file: "ImperialDalek",
    id: "Imperial Dalek"
  },
  {
    file: "InvasionDalek",
    id: "Invasion Dalek"
  },
  {
    file: "Ironside",
    id: "Ironside Dalek"
  },
  {
    file: "MarineDalek",
    id: "Marine Dalek"
  },
  {
    file: "PilotDalek",
    id: "Pilot Dalek"
  },
  {
    file: "RenegadeDalek",
    id: "Renegade Dalek"
  },
  {
    file: "ScientistDalek",
    id: "Scientist Dalek"
  },
  {
    file: "StoneDalek",
    id: "Stone Dalek"
  },
  {
    file: "Strategist",
    id: "Strategist Dalek"
  },
  {
    file: "SuicideDalek",
    id: "Suicide Dalek"
  }
];

var dalekTextures = daleks.map(function (dalek) {
      return {
              id: dalek.id,
              url: requireTexture("daleks/" + dalek.file),
              standardWidth: 64,
              standardHeight: 64
            };
    });

function script(param) {
  Generator.defineTextureInput("Skin", {
        standardWidth: 128,
        standardHeight: 128,
        choices: daleks.map(function (dalek) {
              return dalek.id;
            })
      });
  Generator.defineBooleanInput("Show Colors", false);
  var showColors = Generator.getBooleanInputValue("Show Colors");
  Generator.usePage("Head and Body");
  Generator.drawImage("Background1", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 5,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 27,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 6,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 32,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 7,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 37,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 22,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 6,
        y: 45,
        w: 1,
        h: 1
      }, {
        x: 32,
        y: 105,
        w: 5,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 5,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 55,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 6,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 60,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 7,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 65,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 44,
        w: 1,
        h: 3
      }, {
        x: 70,
        y: 110,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 6,
        y: 45,
        w: 1,
        h: 1
      }, {
        x: 60,
        y: 105,
        w: 5,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 58,
        y: 110,
        w: 30,
        h: 2
      }, {
        x: 95,
        y: 70,
        w: 150,
        h: 10
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 66,
        y: 102,
        w: 7,
        h: 8
      }, {
        x: 135,
        y: 30,
        w: 35,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 58,
        y: 124,
        w: 38,
        h: 4
      }, {
        x: 95,
        y: 175,
        w: 190,
        h: 20
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 68,
        y: 114,
        w: 9,
        h: 10
      }, {
        x: 190,
        y: 125,
        w: 45,
        h: 50
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 77,
        y: 114,
        w: 9,
        h: 10
      }, {
        x: 190,
        y: 195,
        w: 45,
        h: 50
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 28,
        h: 1
      }, {
        x: 211,
        y: 263,
        w: 140,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 40,
        w: 38,
        h: 1
      }, {
        x: 34,
        y: 288,
        w: 190,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 68,
        y: 114,
        w: 9,
        h: 10
      }, {
        x: 79,
        y: 243,
        w: 50,
        h: 45
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 77,
        y: 114,
        w: 9,
        h: 10
      }, {
        x: 79,
        y: 293,
        w: 50,
        h: 45
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 28,
        h: 1
      }, {
        x: 176,
        y: 342,
        w: 140,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 40,
        w: 38,
        h: 1
      }, {
        x: 100,
        y: 406,
        w: 190,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 68,
        y: 114,
        w: 9,
        h: 10
      }, {
        x: 145,
        y: 361,
        w: 50,
        h: 45
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 109,
        w: 46,
        h: 5
      }, {
        x: 15,
        y: 483,
        w: 230,
        h: 25
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 68,
        y: 114,
        w: 9,
        h: 10
      }, {
        x: 70,
        y: 428,
        w: 60,
        h: 55
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 1,
        w: 1,
        h: 3
      }, {
        x: 20,
        y: 180,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 1,
        y: 1,
        w: 3,
        h: 3
      }, {
        x: 25,
        y: 180,
        w: 15,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 1,
        w: 1,
        h: 3
      }, {
        x: 40,
        y: 180,
        w: 5,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 5,
        y: 1,
        w: 3,
        h: 3
      }, {
        x: 45,
        y: 180,
        w: 15,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 1,
        y: 0,
        w: 3,
        h: 1
      }, {
        x: 25,
        y: 175,
        w: 15,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 0,
        w: 3,
        h: 1
      }, {
        x: 25,
        y: 195,
        w: 15,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 42,
        w: 1,
        h: 1
      }, {
        x: 30,
        y: 185,
        w: 5,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 2,
        y: 56,
        w: 5,
        h: 4
      }, {
        x: 310,
        y: 433,
        w: 30,
        h: 20
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 1,
        y: 59,
        w: 1,
        h: 1
      }, {
        x: 305,
        y: 438,
        w: 5,
        h: 5
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 1,
        y: 48,
        w: 7,
        h: 1
      }, {
        x: 270,
        y: 483,
        w: 30,
        h: 20
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 48,
        w: 1,
        h: 1
      }, {
        x: 265,
        y: 488,
        w: 5,
        h: 5
      }, undefined, undefined, undefined, undefined);
  if (showColors) {
    Generator.drawImage("Colors1", [
          0,
          0
        ]);
  }
  Generator.usePage("Skirt");
  Generator.drawImage("Background2", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", {
        x: 1,
        y: 93,
        w: 47,
        h: 5
      }, {
        x: 120,
        y: 116,
        w: 235,
        h: 25
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 14,
        y: 60,
        w: 11,
        h: 14
      }, {
        x: 180,
        y: 56,
        w: 55,
        h: 60
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 74,
        w: 50,
        h: 6
      }, {
        x: 5,
        y: 256,
        w: 250,
        h: 30
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 13,
        y: 80,
        w: 11,
        h: 14
      }, {
        x: 75,
        y: 186,
        w: 55,
        h: 70
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 16,
        w: 58,
        h: 4
      }, {
        x: 35,
        y: 460,
        w: 290,
        h: 15
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 13,
        h: 16
      }, {
        x: 115,
        y: 380,
        w: 65,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 29,
        y: 0,
        w: 13,
        h: 16
      }, {
        x: 115,
        y: 380,
        w: 65,
        h: 80
      }, undefined, undefined, undefined, undefined);
  if (showColors) {
    return Generator.drawImage("Colors2", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator_instructions = Caml_option.some(React.createElement(Generator_Markdown.make, {
          children: instructions
        }));

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: generator_instructions,
  images: images,
  textures: dalekTextures,
  script: script
};

var textures = dalekTextures;

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.instructions = instructions;
exports.images = images;
exports.daleks = daleks;
exports.dalekTextures = dalekTextures;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
