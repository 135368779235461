// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Page = require("./Generator_Page.bs.js");
var Generator_Texture = require("./Generator_Texture.bs.js");
var Generator_ImageWithCanvas = require("./Generator_ImageWithCanvas.bs.js");
var Generator_CanvasWithContext = require("./Generator_CanvasWithContext.bs.js");

var Input = {};

function toString(value) {
  if (typeof value === "object" && value.NAME === "String") {
    return value.VAL;
  }
  
}

function toInteger(value) {
  if (typeof value === "object" && value.NAME === "Integer") {
    return value.VAL;
  }
  
}

function toFloat(value) {
  if (typeof value === "object" && value.NAME === "Float") {
    return value.VAL;
  }
  
}

function toBoolean(value) {
  if (typeof value === "object" && value.NAME === "Boolean") {
    return value.VAL;
  }
  
}

var Variable = {
  toString: toString,
  toInteger: toInteger,
  toFloat: toFloat,
  toBoolean: toBoolean
};

function make(param) {
  return {
          inputs: [],
          pages: [],
          currentPage: undefined,
          values: {
            images: {},
            textures: {},
            booleans: {},
            selects: {},
            ranges: {},
            strings: {},
            variables: {}
          }
        };
}

var Model = {
  Variable: Variable,
  make: make
};

function findPage(model, id) {
  return Caml_option.undefined_to_opt(model.pages.find(function (page) {
                  return page.id === id;
                }));
}

function getCanvasWithContextPixelColor(canvasWithContext, x, y) {
  var width = canvasWithContext.width;
  var data = canvasWithContext.contextWithAlpha.getImageData(0, 0, width, canvasWithContext.height).data;
  var pixelIndex = Math.imul(y, width) + x | 0;
  var arrayIndex = (pixelIndex << 2);
  var r = Belt_Array.get(data, arrayIndex);
  var g = Belt_Array.get(data, arrayIndex + 1 | 0);
  var b = Belt_Array.get(data, arrayIndex + 2 | 0);
  var a = Belt_Array.get(data, arrayIndex + 3 | 0);
  if (r !== undefined && g !== undefined && b !== undefined && a !== undefined) {
    return [
            r,
            g,
            b,
            a
          ];
  }
  
}

function getTexturePixelColor(model, textureId, x, y) {
  var texture = Js_dict.get(model.values.textures, textureId);
  if (texture !== undefined) {
    return getCanvasWithContextPixelColor(texture.imageWithCanvas.canvasWithContext, x, y);
  }
  
}

function getImagePixelColor(model, imageId, x, y) {
  var imageWithCanvas = Js_dict.get(model.values.images, imageId);
  if (imageWithCanvas !== undefined) {
    return getCanvasWithContextPixelColor(imageWithCanvas.canvasWithContext, x, y);
  }
  
}

function getPagePixelColor(model, pageId, x, y) {
  var page = findPage(model, pageId);
  if (page !== undefined) {
    return getCanvasWithContextPixelColor(page.canvasWithContext, x, y);
  }
  
}

function getCurrentPagePixelColor(model, x, y) {
  var page = model.currentPage;
  if (page !== undefined) {
    return getCanvasWithContextPixelColor(page.canvasWithContext, x, y);
  }
  
}

function setVariable(model, id, value) {
  var variables = Js_dict.fromArray(Js_dict.entries(model.values.variables));
  variables[id] = value;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: init.textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: init.strings,
            variables: variables
          }
        };
}

function getVariable(model, id) {
  return Js_dict.get(model.values.variables, id);
}

function getVariableMap(model, id, fn) {
  var value = getVariable(model, id);
  if (value !== undefined) {
    return Curry._1(fn, value);
  }
  
}

function setStringVariable(model, id, value) {
  return setVariable(model, id, {
              NAME: "String",
              VAL: value
            });
}

function getStringVariable(model, id) {
  return getVariableMap(model, id, toString);
}

function setIntegerVariable(model, id, value) {
  return setVariable(model, id, {
              NAME: "Integer",
              VAL: value
            });
}

function getFloatVariable(model, id) {
  return getVariableMap(model, id, toFloat);
}

function setFloatVariable(model, id, value) {
  return setVariable(model, id, {
              NAME: "Float",
              VAL: value
            });
}

function getIntegerVariable(model, id) {
  return getVariableMap(model, id, toInteger);
}

function setBooleanVariable(model, id, value) {
  return setVariable(model, id, {
              NAME: "Boolean",
              VAL: value
            });
}

function getBooleanVariable(model, id) {
  return getVariableMap(model, id, toBoolean);
}

function hasInput(model, idToFind) {
  return Caml_option.undefined_to_opt(model.inputs.find(function (input) {
                  var id;
                  id = input.TAG === /* RegionInput */2 ? "" : input._0;
                  return id === idToFind;
                }));
}

function clearStringInputValues(model) {
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: init.textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: {},
            variables: init.variables
          }
        };
}

function setStringInputValue(model, id, value) {
  var strings = Js_dict.fromArray(Js_dict.entries(model.values.strings));
  strings[id] = value;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: init.textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: strings,
            variables: init.variables
          }
        };
}

function getStringInputValue(model, id) {
  var value = Js_dict.get(model.values.strings, id);
  if (value !== undefined) {
    return value;
  } else {
    return "";
  }
}

function setBooleanInputValue(model, id, value) {
  var booleans = Js_dict.fromArray(Js_dict.entries(model.values.booleans));
  booleans[id] = value;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: init.textures,
            booleans: booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: init.strings,
            variables: init.variables
          }
        };
}

function getBooleanInputValue(model, id) {
  var value = Js_dict.get(model.values.booleans, id);
  if (value !== undefined) {
    return value;
  } else {
    return false;
  }
}

function getBooleanInputValueWithDefault(model, id, $$default) {
  var value = Js_dict.get(model.values.booleans, id);
  if (value !== undefined) {
    return value;
  } else {
    return $$default;
  }
}

function setSelectInputValue(model, id, value) {
  var selects = Js_dict.fromArray(Js_dict.entries(model.values.selects));
  selects[id] = value;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: init.textures,
            booleans: init.booleans,
            selects: selects,
            ranges: init.ranges,
            strings: init.strings,
            variables: init.variables
          }
        };
}

function getSelectInputValue(model, id) {
  var value = Js_dict.get(model.values.selects, id);
  if (value !== undefined) {
    return value;
  } else {
    return "";
  }
}

function setRangeInputValue(model, id, value) {
  var ranges = Js_dict.fromArray(Js_dict.entries(model.values.ranges));
  ranges[id] = value;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: init.textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: ranges,
            strings: init.strings,
            variables: init.variables
          }
        };
}

function getRangeInputValue(model, id) {
  var value = Js_dict.get(model.values.ranges, id);
  if (value !== undefined) {
    return value;
  } else {
    return 0;
  }
}

function hasBooleanValue(model, id) {
  return Js_dict.get(model.values.booleans, id) !== undefined;
}

function hasSelectValue(model, id) {
  return Js_dict.get(model.values.selects, id) !== undefined;
}

function hasRangeValue(model, id) {
  return Js_dict.get(model.values.ranges, id) !== undefined;
}

function usePage(model, id) {
  var page = findPage(model, id);
  if (page !== undefined) {
    return {
            inputs: model.inputs,
            pages: model.pages,
            currentPage: page,
            values: model.values
          };
  }
  var page$1 = Generator_Page.make(id);
  var pages = model.pages.concat([page$1]);
  return {
          inputs: model.inputs,
          pages: pages,
          currentPage: page$1,
          values: model.values
        };
}

function getDefaultPageId(param) {
  return "Page";
}

function getCurrentPageId(model) {
  var page = model.currentPage;
  if (page !== undefined) {
    return page.id;
  } else {
    return "Page";
  }
}

function ensureCurrentPage(model) {
  var match = model.currentPage;
  if (match !== undefined) {
    return model;
  } else {
    return usePage(model, "Page");
  }
}

function defineRegionInput(model, region, callback) {
  var pageId = getCurrentPageId(model);
  var inputs = model.inputs.concat([{
          TAG: /* RegionInput */2,
          _0: pageId,
          _1: region,
          _2: callback
        }]);
  return {
          inputs: inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: model.values
        };
}

function defineCustomStringInput(model, id, fn) {
  var inputs = model.inputs.concat([{
          TAG: /* CustomStringInput */1,
          _0: id,
          _1: fn
        }]);
  return {
          inputs: inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: model.values
        };
}

function defineBooleanInput(model, id, initial) {
  var inputs = model.inputs.concat([{
          TAG: /* BooleanInput */4,
          _0: id
        }]);
  var newModel_pages = model.pages;
  var newModel_currentPage = model.currentPage;
  var newModel_values = model.values;
  var newModel = {
    inputs: inputs,
    pages: newModel_pages,
    currentPage: newModel_currentPage,
    values: newModel_values
  };
  if (hasBooleanValue(model, id)) {
    return newModel;
  } else {
    return setBooleanInputValue(newModel, id, initial);
  }
}

function defineButtonInput(model, id, onClick) {
  var inputs = model.inputs.concat([{
          TAG: /* ButtonInput */7,
          _0: id,
          _1: onClick
        }]);
  return {
          inputs: inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: model.values
        };
}

function defineSelectInput(model, id, options) {
  var inputs = model.inputs.concat([{
          TAG: /* SelectInput */5,
          _0: id,
          _1: options
        }]);
  var newModel_pages = model.pages;
  var newModel_currentPage = model.currentPage;
  var newModel_values = model.values;
  var newModel = {
    inputs: inputs,
    pages: newModel_pages,
    currentPage: newModel_currentPage,
    values: newModel_values
  };
  if (hasSelectValue(model, id)) {
    return newModel;
  }
  var value = Belt_Option.getWithDefault(options[0], "");
  return setSelectInputValue(newModel, id, value);
}

function defineRangeInput(model, id, rangeArgs) {
  var inputs = model.inputs.concat([{
          TAG: /* RangeInput */6,
          _0: id,
          _1: rangeArgs
        }]);
  var newModel_pages = model.pages;
  var newModel_currentPage = model.currentPage;
  var newModel_values = model.values;
  var newModel = {
    inputs: inputs,
    pages: newModel_pages,
    currentPage: newModel_currentPage,
    values: newModel_values
  };
  if (hasRangeValue(model, id)) {
    return newModel;
  } else {
    return setRangeInputValue(newModel, id, rangeArgs.value);
  }
}

function defineTextureInput(model, id, options) {
  var input = {
    TAG: /* TextureInput */3,
    _0: id,
    _1: options
  };
  var inputs = model.inputs.concat([input]);
  return {
          inputs: inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: model.values
        };
}

function defineText(model, text) {
  var isText = function (input) {
    if (input.TAG === /* Text */0) {
      return true;
    } else {
      return false;
    }
  };
  var textCount = model.inputs.filter(isText).length;
  var id = "text-" + (textCount + 1 | 0).toString();
  var input = {
    TAG: /* Text */0,
    _0: id,
    _1: text
  };
  var inputs = model.inputs.concat([input]);
  return {
          inputs: inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: model.values
        };
}

function fillBackgroundColor(model, color) {
  var currentPage = model.currentPage;
  if (currentPage === undefined) {
    return model;
  }
  var currentPage$1 = findPage(model, currentPage.id);
  if (currentPage$1 === undefined) {
    return model;
  }
  var match = currentPage$1.canvasWithContext;
  var height = match.height;
  var width = match.width;
  var newCanvas = Generator_CanvasWithContext.make(width, height);
  var previousFillStyle = newCanvas.context.fillStyle;
  newCanvas.context.fillStyle = color;
  newCanvas.context.fillRect(0, 0, width, height);
  newCanvas.context.drawImage(currentPage$1.canvasWithContext.canvas, 0, 0);
  newCanvas.context.fillStyle = previousFillStyle;
  var newCurrentPage_id = currentPage$1.id;
  var newCurrentPage = {
    id: newCurrentPage_id,
    canvasWithContext: newCanvas
  };
  var newPages = Belt_Array.map(model.pages, (function (page) {
          if (page.id === newCurrentPage_id) {
            return newCurrentPage;
          } else {
            return page;
          }
        }));
  return {
          inputs: model.inputs,
          pages: newPages,
          currentPage: newCurrentPage,
          values: model.values
        };
}

function fillRect(model, dest, color) {
  var currentPage = model.currentPage;
  if (currentPage === undefined) {
    return model;
  }
  var context = currentPage.canvasWithContext.context;
  context.fillStyle = color;
  context.fillRect(dest[0], dest[1], dest[2], dest[3]);
  return model;
}

function getOffset(param, param$1) {
  var w = param$1[0] - param[0];
  var h = param$1[1] - param[1];
  var angle = Math.atan2(h, w);
  var ox = Math.sin(angle) * 0.5;
  var oy = Math.cos(angle) * 0.5;
  return [
          ox,
          oy
        ];
}

function drawLine(model, param, param$1, color, width, pattern, offset) {
  var y2 = param$1[1];
  var x2 = param$1[0];
  var y1 = param[1];
  var x1 = param[0];
  var match = getOffset([
        x1,
        y1
      ], [
        x2,
        y2
      ]);
  var oy = match[1];
  var ox = match[0];
  var currentPage = model.currentPage;
  if (currentPage === undefined) {
    return model;
  }
  var context = currentPage.canvasWithContext.context;
  context.beginPath();
  context.strokeStyle = color;
  context.lineWidth = width;
  context.setLineDash(pattern);
  context.lineDashOffset = offset;
  context.moveTo(x1 + ox, y1 + oy);
  context.lineTo(x2 + ox, y2 + oy);
  context.stroke();
  return model;
}

function drawImage(model, id, param) {
  var model$1 = ensureCurrentPage(model);
  var currentPage = model$1.currentPage;
  var image = Js_dict.get(model$1.values.images, id);
  if (currentPage !== undefined && image !== undefined) {
    currentPage.canvasWithContext.context.drawImage(image.image, param[0], param[1]);
  }
  return model$1;
}

function addImage(model, id, image) {
  var imageWithCanvas = Generator_ImageWithCanvas.makeFromImage(image);
  var images = Js_dict.fromArray(Js_dict.entries(model.values.images));
  images[id] = imageWithCanvas;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: images,
            textures: init.textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: init.strings,
            variables: init.variables
          }
        };
}

function addTexture(model, id, texture) {
  var textures = Js_dict.fromArray(Js_dict.entries(model.values.textures));
  textures[id] = texture;
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: init.strings,
            variables: init.variables
          }
        };
}

function clearTexture(model, id) {
  var entries = Js_dict.entries(model.values.textures).filter(function (param) {
        return param[0] !== id;
      });
  var textures = Js_dict.fromArray(entries);
  var init = model.values;
  return {
          inputs: model.inputs,
          pages: model.pages,
          currentPage: model.currentPage,
          values: {
            images: init.images,
            textures: textures,
            booleans: init.booleans,
            selects: init.selects,
            ranges: init.ranges,
            strings: init.strings,
            variables: init.variables
          }
        };
}

function drawTexture(model, id, param, param$1, flip, rotate, blend, pixelate, param$2) {
  var model$1 = ensureCurrentPage(model);
  var currentPage = model$1.currentPage;
  var texture = Js_dict.get(model$1.values.textures, id);
  if (currentPage !== undefined && texture !== undefined) {
    Generator_Texture.draw(texture, currentPage, param[0], param[1], param[2], param[3], param$1[0], param$1[1], param$1[2], param$1[3], flip, rotate, blend, pixelate, undefined);
  }
  return model$1;
}

function hasImage(model, id) {
  return Js_dict.get(model.values.images, id) !== undefined;
}

function hasTexture(model, id) {
  return Js_dict.get(model.values.textures, id) !== undefined;
}

function drawText(model, text, position, size) {
  var model$1 = ensureCurrentPage(model);
  var currentPage = model$1.currentPage;
  if (currentPage !== undefined) {
    var font = String(size) + "px sans-serif";
    currentPage.canvasWithContext.context.font = font;
    currentPage.canvasWithContext.context.fillText(text, position[0], position[1]);
  }
  return model$1;
}

exports.Input = Input;
exports.Model = Model;
exports.findPage = findPage;
exports.getCanvasWithContextPixelColor = getCanvasWithContextPixelColor;
exports.getTexturePixelColor = getTexturePixelColor;
exports.getImagePixelColor = getImagePixelColor;
exports.getPagePixelColor = getPagePixelColor;
exports.getCurrentPagePixelColor = getCurrentPagePixelColor;
exports.setVariable = setVariable;
exports.getVariable = getVariable;
exports.getVariableMap = getVariableMap;
exports.setStringVariable = setStringVariable;
exports.getStringVariable = getStringVariable;
exports.setIntegerVariable = setIntegerVariable;
exports.getFloatVariable = getFloatVariable;
exports.setFloatVariable = setFloatVariable;
exports.getIntegerVariable = getIntegerVariable;
exports.setBooleanVariable = setBooleanVariable;
exports.getBooleanVariable = getBooleanVariable;
exports.hasInput = hasInput;
exports.clearStringInputValues = clearStringInputValues;
exports.setStringInputValue = setStringInputValue;
exports.getStringInputValue = getStringInputValue;
exports.setBooleanInputValue = setBooleanInputValue;
exports.getBooleanInputValue = getBooleanInputValue;
exports.getBooleanInputValueWithDefault = getBooleanInputValueWithDefault;
exports.setSelectInputValue = setSelectInputValue;
exports.getSelectInputValue = getSelectInputValue;
exports.setRangeInputValue = setRangeInputValue;
exports.getRangeInputValue = getRangeInputValue;
exports.hasBooleanValue = hasBooleanValue;
exports.hasSelectValue = hasSelectValue;
exports.hasRangeValue = hasRangeValue;
exports.usePage = usePage;
exports.getDefaultPageId = getDefaultPageId;
exports.getCurrentPageId = getCurrentPageId;
exports.ensureCurrentPage = ensureCurrentPage;
exports.defineRegionInput = defineRegionInput;
exports.defineCustomStringInput = defineCustomStringInput;
exports.defineBooleanInput = defineBooleanInput;
exports.defineButtonInput = defineButtonInput;
exports.defineSelectInput = defineSelectInput;
exports.defineRangeInput = defineRangeInput;
exports.defineTextureInput = defineTextureInput;
exports.defineText = defineText;
exports.fillBackgroundColor = fillBackgroundColor;
exports.fillRect = fillRect;
exports.getOffset = getOffset;
exports.drawLine = drawLine;
exports.drawImage = drawImage;
exports.addImage = addImage;
exports.addTexture = addTexture;
exports.clearTexture = clearTexture;
exports.drawTexture = drawTexture;
exports.hasImage = hasImage;
exports.hasTexture = hasTexture;
exports.drawText = drawText;
/* No side effect */
