// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Generator_TexturePicker = require("../../builder/views/Generator_TexturePicker.bs.js");

function MinecraftItem_TexturePicker(Props) {
  var textureVersion = Props.textureVersion;
  var onSelect = Props.onSelect;
  if (textureVersion !== undefined) {
    return React.createElement("div", {
                className: "mb-4"
              }, React.createElement(Generator_TexturePicker.make, {
                    textureDef: textureVersion.textureDef,
                    frames: textureVersion.frames,
                    onSelect: onSelect
                  }));
  } else {
    return null;
  }
}

var TextureVersions;

var TexturePicker;

var SelectedTexture;

var Rotation;

var make = MinecraftItem_TexturePicker;

exports.TextureVersions = TextureVersions;
exports.TexturePicker = TexturePicker;
exports.SelectedTexture = SelectedTexture;
exports.Rotation = Rotation;
exports.make = make;
/* react Not a pure module */
