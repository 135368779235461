// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "minecraft-bee-character";

var name = "Minecraft Bee Character";

var video = {
  url: "https://www.youtube.com/embed/vG-mXWu0OlA?rel=0"
};

var history = ["1 May 2022 PaperDoggy - Initial script developed."];

var thumbnail = {
  url: require("./thumbnail/thumbnail-256.jpeg")
};

var images = [{
    id: "OverlayBee",
    url: require("./images/OverlayBee.png")
  }];

var textures = [
  {
    id: "Skin1",
    url: require("./textures/Skin1.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Skin2",
    url: require("./textures/Skin2.png"),
    standardWidth: 64,
    standardHeight: 64
  }
];

var steve = TextureMap.MinecraftCharacter.steve;

var alex = TextureMap.MinecraftCharacter.alex;

function drawHead(ox, oy, k, texture) {
  Generator.drawTexture(texture, steve.base.head.front, [
        ox,
        oy,
        56,
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.right, [
        (ox - (k << 3) | 0) - 1 | 0,
        oy,
        (k << 3),
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.left, [
        ox + 57 | 0,
        oy,
        (k << 3),
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.top, [
        ox,
        (oy - (k << 3) | 0) - 1 | 0,
        56,
        (k << 3)
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.bottom, [
        ox,
        oy + 57 | 0,
        56,
        (k << 3)
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.front, [
        ox,
        oy,
        56,
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.right, [
        (ox - (k << 3) | 0) - 1 | 0,
        oy,
        (k << 3),
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.left, [
        ox + 57 | 0,
        oy,
        (k << 3),
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.top, [
        ox,
        (oy - (k << 3) | 0) - 1 | 0,
        56,
        (k << 3)
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.bottom, [
        ox,
        oy + 57 | 0,
        56,
        (k << 3)
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
}

function drawBody(ox, oy, k, texture) {
  Generator.drawTexture(texture, steve.base.body.front, [
        ox,
        (oy + 57 | 0) + (k << 3) | 0,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.right, [
        (ox - 1 | 0) - (k << 3) | 0,
        oy,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, 90.0, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.left, [
        (ox + 57 | 0) + (k << 3) | 0,
        oy + 56 | 0,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, -90.0, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.bottom, [
        (ox + 57 | 0) + 81 | 0,
        oy,
        56,
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.back, [
        ox,
        oy - 81 | 0,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.front, [
        ox,
        (oy + 57 | 0) + (k << 3) | 0,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.right, [
        (ox - 1 | 0) - (k << 3) | 0,
        oy,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, 90.0, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.left, [
        (ox + 57 | 0) + (k << 3) | 0,
        oy + 56 | 0,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, -90.0, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.bottom, [
        (ox + 57 | 0) + 81 | 0,
        oy,
        56,
        56
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.back, [
        ox,
        oy - 81 | 0,
        56,
        ((10 - k | 0) << 3)
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
}

function drawRightArm(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.rightArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawLeftArm(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.leftArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawRightArmAlex(ox, oy, texture) {
  Generator.drawTexture(texture, alex.base.rightArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawLeftArmAlex(ox, oy, texture) {
  Generator.drawTexture(texture, alex.base.leftArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawRightLeg(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.rightLeg.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawLeftLeg(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.rightLeg.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.front, [
        ox,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.back, [
        ox + 9 | 0,
        oy,
        8,
        16
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawPlan(ox, oy, k, texture) {
  Generator.drawTexture(texture, steve.base.head.front, [
        ox,
        oy - 32 | 0,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.front, [
        ox,
        oy,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.front, [
        ox,
        oy + 48 | 0,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.front, [
        ox + 16 | 0,
        oy + 48 | 0,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.front, [
        ox - 36 | 0,
        oy + 131 | 0,
        28,
        28
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.head.left, [
        ox + 44 | 0,
        oy + 131 | 0,
        (k << 2),
        28
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.left, [
        (ox + 44 | 0) + (k << 2) | 0,
        (oy + 131 | 0) + 28 | 0,
        28,
        ((10 - k | 0) << 2)
      ], undefined, undefined, -90.0, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.body.back, [
        ox + 5 | 0,
        oy + 174 | 0,
        28,
        ((10 - k | 0) << 2)
      ], undefined, undefined, undefined, -180.0, undefined, undefined);
  Generator.drawTexture(texture, steve.base.head.top, [
        ox + 5 | 0,
        (oy + 174 | 0) + ((10 - k | 0) << 2) | 0,
        28,
        (k << 2)
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightLeg.front, [
        ox - 33 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.leftLeg.front, [
        ox - 14 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.head.front, [
        ox,
        oy - 32 | 0,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.front, [
        ox,
        oy,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.front, [
        ox,
        oy + 48 | 0,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.front, [
        ox + 16 | 0,
        oy + 48 | 0,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.front, [
        ox - 36 | 0,
        oy + 131 | 0,
        28,
        28
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.head.left, [
        ox + 44 | 0,
        oy + 131 | 0,
        (k << 2),
        28
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.left, [
        (ox + 44 | 0) + (k << 2) | 0,
        (oy + 131 | 0) + 28 | 0,
        28,
        ((10 - k | 0) << 2)
      ], undefined, undefined, -90.0, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.body.back, [
        ox + 5 | 0,
        oy + 174 | 0,
        28,
        ((10 - k | 0) << 2)
      ], undefined, undefined, undefined, -180.0, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.head.top, [
        ox + 5 | 0,
        (oy + 174 | 0) + ((10 - k | 0) << 2) | 0,
        28,
        (k << 2)
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightLeg.front, [
        ox - 33 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.leftLeg.front, [
        ox - 14 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawPlanArms(ox, oy, texture) {
  Generator.drawTexture(texture, steve.base.rightArm.front, [
        ox - 16 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.front, [
        ox + 32 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.base.rightArm.front, [
        ox - 27 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.base.leftArm.front, [
        ox - 20 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.front, [
        ox - 16 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.front, [
        ox + 32 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, steve.overlay.rightArm.front, [
        ox - 27 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, steve.overlay.leftArm.front, [
        ox - 20 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
}

function drawPlanArmsAlex(ox, oy, texture) {
  Generator.drawTexture(texture, alex.base.rightArm.front, [
        ox - 16 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.front, [
        ox + 32 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.base.rightArm.front, [
        ox - 27 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.base.leftArm.front, [
        ox - 20 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.front, [
        ox - 16 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.front, [
        ox + 32 | 0,
        oy,
        16,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, alex.overlay.rightArm.front, [
        ox - 27 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
  Generator.drawTexture(texture, alex.overlay.leftArm.front, [
        ox - 20 | 0,
        oy + 160 | 0,
        3,
        8
      ], undefined, undefined, undefined, undefined, true, undefined);
}

var Drawing = {
  drawHead: drawHead,
  drawBody: drawBody,
  drawRightArm: drawRightArm,
  drawLeftArm: drawLeftArm,
  drawRightArmAlex: drawRightArmAlex,
  drawLeftArmAlex: drawLeftArmAlex,
  drawRightLeg: drawRightLeg,
  drawLeftLeg: drawLeftLeg,
  drawPlan: drawPlan,
  drawPlanArms: drawPlanArms,
  drawPlanArmsAlex: drawPlanArmsAlex
};

function script(param) {
  Generator.defineSelectInput("Character 1 skin model type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin1", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  var alexModel1 = Generator.getSelectInputValue("Character 1 skin model type") === "Alex";
  Generator.defineRangeInput("Head size 1", {
        min: 0,
        max: 10,
        value: 0,
        step: 1
      });
  var headmultiplier1 = Generator.getRangeInputValue("Head size 1");
  Generator.defineSelectInput("Character 2 skin model type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin2", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  var alexModel2 = Generator.getSelectInputValue("Character 2 skin model type") === "Alex";
  Generator.defineRangeInput("Head size 2", {
        min: 0,
        max: 10,
        value: 0,
        step: 1
      });
  var headmultiplier2 = Generator.getRangeInputValue("Head size 2");
  Generator.drawImage("OverlayBee", [
        0,
        0
      ]);
  drawHead(108, 103, headmultiplier1, "Skin1");
  drawBody(108, 103, headmultiplier1, "Skin1");
  if (alexModel1) {
    drawRightArmAlex(329, 50, "Skin1");
    drawLeftArmAlex(353, 50, "Skin1");
  } else {
    drawRightArm(329, 50, "Skin1");
    drawLeftArm(353, 50, "Skin1");
  }
  drawRightLeg(329, 83, "Skin1");
  drawLeftLeg(353, 83, "Skin1");
  drawRightLeg(329, 115, "Skin1");
  drawLeftLeg(353, 115, "Skin1");
  drawPlan(429, 55, headmultiplier1, "Skin1");
  if (alexModel1) {
    drawPlanArmsAlex(429, 55, "Skin1");
  } else {
    drawPlanArms(429, 55, "Skin1");
  }
  drawHead(108, 416, headmultiplier2, "Skin2");
  drawBody(108, 416, headmultiplier2, "Skin2");
  if (alexModel2) {
    drawRightArmAlex(329, 363, "Skin2");
    drawLeftArmAlex(353, 363, "Skin2");
  } else {
    drawRightArm(329, 363, "Skin2");
    drawLeftArm(353, 363, "Skin2");
  }
  drawRightLeg(329, 396, "Skin2");
  drawLeftLeg(353, 396, "Skin2");
  drawRightLeg(329, 428, "Skin2");
  drawLeftLeg(353, 428, "Skin2");
  drawPlan(429, 368, headmultiplier2, "Skin2");
  if (alexModel2) {
    drawPlanArmsAlex(429, 368, "Skin2");
  } else {
    drawPlanArms(429, 368, "Skin2");
  }
  Generator.drawImage("OverlayBee", [
        0,
        0
      ]);
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.video = video;
exports.history = history;
exports.thumbnail = thumbnail;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.Drawing = Drawing;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
