// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Generator = require("../modules/Generator.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Pages = require("./Generator_Pages.bs.js");
var Generator_Inputs = require("./Generator_Inputs.bs.js");
var Generator_Builder = require("../modules/Generator_Builder.bs.js");
var Generator_ScriptRunner = require("../modules/Generator_ScriptRunner.bs.js");
var Generator_ResourceLoader = require("../modules/Generator_ResourceLoader.bs.js");

function Generator_View$Thumbnail(Props) {
  var thumbnail = Props.thumbnail;
  return React.createElement("div", {
              className: "border bg-gray-100 p-4 w-72 h-72"
            }, React.createElement("img", {
                  height: "256",
                  src: thumbnail.url,
                  width: "256"
                }));
}

var Thumbnail = {
  make: Generator_View$Thumbnail
};

function Generator_View$Video(Props) {
  var video = Props.video;
  return React.createElement("div", {
              style: {
                maxWidth: "640px"
              }
            }, React.createElement("div", {
                  style: {
                    maxWidth: "640px",
                    paddingTop: "56.25%",
                    position: "relative",
                    width: "100%"
                  }
                }, React.createElement("iframe", {
                      style: {
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        width: "100%"
                      },
                      allowFullScreen: true,
                      src: video.url
                    })));
}

var Video = {
  make: Generator_View$Video
};

function Generator_View$Instructions(Props) {
  var instructions = Props.instructions;
  return React.createElement("div", {
              className: "mb-8"
            }, instructions);
}

var Instructions = {
  make: Generator_View$Instructions
};

function Generator_View$GeneratorPhotoOrVideo(Props) {
  var generatorDef = Props.generatorDef;
  var video = generatorDef.video;
  var tmp;
  if (video !== undefined) {
    tmp = React.createElement(Generator_View$Video, {
          video: video
        });
  } else {
    var thumbnail = generatorDef.thumbnail;
    tmp = thumbnail !== undefined ? React.createElement(Generator_View$Thumbnail, {
            thumbnail: thumbnail
          }) : null;
  }
  return React.createElement("div", undefined, tmp);
}

var GeneratorPhotoOrVideo = {
  make: Generator_View$GeneratorPhotoOrVideo
};

function Generator_View$GeneratorInstructions(Props) {
  var generatorDef = Props.generatorDef;
  var instructions = generatorDef.instructions;
  return React.createElement("div", undefined, instructions !== undefined ? React.createElement(Generator_View$Instructions, {
                    instructions: Caml_option.valFromOption(instructions)
                  }) : null);
}

var GeneratorInstructions = {
  make: Generator_View$GeneratorInstructions
};

function Generator_View$GeneratorHistory(Props) {
  var generatorDef = Props.generatorDef;
  if (generatorDef.history.length !== 0) {
    return React.createElement("div", {
                className: "pt-16 text-gray-500"
              }, React.createElement("h1", {
                    className: "font-bold text-2xl mb-4"
                  }, "Updates"), React.createElement("ul", {
                    className: "list-disc list-outside ml-4"
                  }, Belt_Array.mapWithIndex(generatorDef.history, (function (index, history) {
                          return React.createElement("li", {
                                      key: String(index),
                                      className: "mb-2"
                                    }, history);
                        }))));
  } else {
    return null;
  }
}

var GeneratorHistory = {
  make: Generator_View$GeneratorHistory
};

function Generator_View(Props) {
  var generatorDef = Props.generatorDef;
  var afterInfo = Props.afterInfo;
  var afterInputs = Props.afterInputs;
  var match = React.useState(function () {
        
      });
  var setModel = match[1];
  var model = match[0];
  console.log(Generator_ResourceLoader.imageDefToImage);
  console.log(Generator_Builder.defineText);
  ((React.useEffect(
    function () {
      Generator_ResourceLoader.loadResources(generatorDef).then(function (
        results
      ) {
        if (results.TAG === /* Ok */ 0) {
          var match = results._0;
          const urlSearchParams = new URLSearchParams(window.location.search);
          const skin = urlSearchParams.get("skin"); // BASE64
          const skinModel = urlSearchParams.get("model") || "Steve"; // or 'Alex'
          const textureDef = Generator_ResourceLoader.textureDefToTexture({
            id: "Skin",
            url: skin,
            standardWidth: 64,
            standardHeight: 64,
          }).then((texture) => {
            if (!texture) {
              var model = Generator_Builder.Model.make(undefined);
              var model$1 = match[0].reduce(function (acc, param) {
                return Generator_Builder.addImage(acc, param[0], param[1]);
              }, model);
              var model$2 = match[1].reduce(function (acc, param) {
                return Generator_Builder.addTexture(acc, param[0], param[1]);
              }, model$1);
              Generator_ScriptRunner.run(generatorDef, model$2).then(function (
                model
              ) {
                Curry._1(setModel, function (param) {
                  return model;
                });
              });
              return;
            }

            var model = Generator_Builder.Model.make(undefined);
            var model$1 = match[0].reduce(function (acc, param) {
              return Generator_Builder.addImage(acc, param[0], param[1]);
            }, model);
            var model$2 = match[1].reduce(function (acc, param) {
              return Generator_Builder.addTexture(acc, texture[0], texture[1]);
            }, model$1);
            model$2.values.selects["Skin Model"] = skinModel;
            console.log(match, model, model$1, model$2);
            Generator_ScriptRunner.run(generatorDef, model$2).then(function (
              model
            ) {
              Curry._1(setModel, function (param) {
                return model;
              });
            });
            return;
          });
        }
        console.log(results._0);
      });
    },
    [generatorDef]
  )));
  var runScript = function (model) {
    Generator_ScriptRunner.run(generatorDef, model).then(function (model) {
          Curry._1(setModel, (function (param) {
                  return model;
                }));
        });
  };
  var onInputsChange = runScript;
  var onPagesInputsChange = function (param) {
    runScript(Generator.getModel(undefined));
  };
  return React.createElement("div", undefined, model !== undefined ? React.createElement("div", undefined, React.createElement("div", {
                        className: "md:flex md:items-center mb-8"
                      }, React.createElement("div", {
                            className: "w-full mb-8 md:mb-0"
                          }, React.createElement(Generator_View$GeneratorPhotoOrVideo, {
                                generatorDef: generatorDef
                              })), afterInfo !== undefined ? React.createElement("div", undefined, Caml_option.valFromOption(afterInfo)) : null), React.createElement(Generator_View$GeneratorInstructions, {
                        generatorDef: generatorDef
                      }), React.createElement(Generator_Inputs.make, {
                        model: model,
                        onChange: onInputsChange
                      }), React.createElement("div", undefined, React.createElement("div", {
                            className: "lg:flex lg:justify-between"
                          }, React.createElement("div", {
                                className: "mb-16 lg:mb-0"
                              }, React.createElement(Generator_Pages.make, {
                                    generatorDef: generatorDef,
                                    model: model,
                                    onChange: onPagesInputsChange
                                  })), afterInputs !== undefined ? React.createElement("div", undefined, Caml_option.valFromOption(afterInputs)) : null), React.createElement(Generator_View$GeneratorHistory, {
                            generatorDef: generatorDef
                          }))) : null);
}

var Builder;

var ResourceLoader;

var ScriptRunner;

var GeneratorInputs;

var GeneratorPages;

var make = Generator_View;

var $$default = Generator_View;

exports.Builder = Builder;
exports.ResourceLoader = ResourceLoader;
exports.ScriptRunner = ScriptRunner;
exports.GeneratorInputs = GeneratorInputs;
exports.GeneratorPages = GeneratorPages;
exports.Thumbnail = Thumbnail;
exports.Video = Video;
exports.Instructions = Instructions;
exports.GeneratorPhotoOrVideo = GeneratorPhotoOrVideo;
exports.GeneratorInstructions = GeneratorInstructions;
exports.GeneratorHistory = GeneratorHistory;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* react Not a pure module */
