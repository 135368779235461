// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../builder/modules/Generator.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");

function make(width, height, depth) {
  return {
          top: {
            x: depth,
            y: 0,
            w: width,
            h: depth
          },
          bottom: {
            x: depth + width | 0,
            y: 0,
            w: width,
            h: depth
          },
          front: {
            x: depth,
            y: depth,
            w: width,
            h: height
          },
          right: {
            x: 0,
            y: depth,
            w: depth,
            h: height
          },
          left: {
            x: depth + width | 0,
            y: depth,
            w: depth,
            h: height
          },
          back: {
            x: (depth << 1) + width | 0,
            y: depth,
            w: width,
            h: height
          }
        };
}

function translateRectangle(rectangle, xTranslate, yTranslate) {
  return {
          x: rectangle.x + xTranslate | 0,
          y: rectangle.y + yTranslate | 0,
          w: rectangle.w,
          h: rectangle.h
        };
}

function translate(cuboid, xTranslate, yTranslate) {
  return {
          top: translateRectangle(cuboid.top, xTranslate, yTranslate),
          bottom: translateRectangle(cuboid.bottom, xTranslate, yTranslate),
          front: translateRectangle(cuboid.front, xTranslate, yTranslate),
          right: translateRectangle(cuboid.right, xTranslate, yTranslate),
          left: translateRectangle(cuboid.left, xTranslate, yTranslate),
          back: translateRectangle(cuboid.back, xTranslate, yTranslate)
        };
}

var CuboidLegacy = {
  make: make,
  translateRectangle: translateRectangle,
  translate: translate
};

function translateRectangle$1(rectangle, param) {
  return [
          rectangle[0] + param[0] | 0,
          rectangle[1] + param[1] | 0,
          rectangle[2],
          rectangle[3]
        ];
}

function make$1(param) {
  var d = param[2];
  var h = param[1];
  var w = param[0];
  return {
          front: [
            d,
            d,
            w,
            h
          ],
          back: [
            (d << 1) + w | 0,
            d,
            w,
            h
          ],
          top: [
            d,
            0,
            w,
            d
          ],
          bottom: [
            d + w | 0,
            0,
            w,
            d
          ],
          right: [
            0,
            d,
            d,
            h
          ],
          left: [
            d + w | 0,
            d,
            d,
            h
          ]
        };
}

function translate$1(source, position) {
  return {
          front: translateRectangle$1(source.front, position),
          back: translateRectangle$1(source.back, position),
          top: translateRectangle$1(source.top, position),
          bottom: translateRectangle$1(source.bottom, position),
          right: translateRectangle$1(source.right, position),
          left: translateRectangle$1(source.left, position)
        };
}

var Source = {
  make: make$1,
  translate: translate$1
};

function make$2(rect) {
  return {
          rectangle: rect,
          flip: "None",
          rotate: 0.0
        };
}

function flip(face, flip$1) {
  var match = face.flip;
  var match$1 = match === "Horizontal" ? (
      flip$1 === "Horizontal" ? [
          "None",
          face.rotate
        ] : (
          flip$1 === "None" ? [
              "Horizontal",
              face.rotate
            ] : [
              "None",
              face.rotate + 180.0
            ]
        )
    ) : (
      match === "None" ? (
          flip$1 === "Horizontal" ? [
              "Horizontal",
              face.rotate
            ] : (
              flip$1 === "None" ? [
                  "None",
                  face.rotate
                ] : [
                  "Vertical",
                  face.rotate
                ]
            )
        ) : (
          flip$1 === "Horizontal" ? [
              "None",
              face.rotate + 180.0
            ] : (
              flip$1 === "None" ? [
                  "Vertical",
                  face.rotate
                ] : [
                  "None",
                  face.rotate
                ]
            )
        )
    );
  return {
          rectangle: face.rectangle,
          flip: match$1[0],
          rotate: match$1[1]
        };
}

function rotate(param, r) {
  var rectangle = param.rectangle;
  var h = rectangle[3];
  var w = rectangle[2];
  var y = rectangle[1];
  var x = rectangle[0];
  return {
          rectangle: (r + 360.0) % 180.0 === 90.0 ? [
              x + ((w - h | 0) / 2 | 0) | 0,
              y - ((w - h | 0) / 2 | 0) | 0,
              h,
              w
            ] : [
              x,
              y,
              w,
              h
            ],
          flip: param.flip,
          rotate: param.rotate + r
        };
}

function translate$2(param, position) {
  return {
          rectangle: translateRectangle$1(param.rectangle, position),
          flip: param.flip,
          rotate: param.rotate
        };
}

function draw(textureId, source, dest) {
  Generator.drawTexture(textureId, source, dest.rectangle, dest.flip, undefined, undefined, dest.rotate, undefined, undefined);
}

var Face = {
  make: make$2,
  flip: flip,
  rotate: rotate,
  translate: translate$2,
  draw: draw
};

function translate$3(dest, position) {
  return {
          front: translate$2(dest.front, position),
          back: translate$2(dest.back, position),
          top: translate$2(dest.top, position),
          bottom: translate$2(dest.bottom, position),
          right: translate$2(dest.right, position),
          left: translate$2(dest.left, position)
        };
}

function make$3(param, direction) {
  var d = param[2];
  var h = param[1];
  var w = param[0];
  if (direction === "East") {
    return {
            front: {
              rectangle: [
                d,
                d,
                w,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            back: {
              rectangle: [
                (d + w | 0) + d | 0,
                d,
                w,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            top: {
              rectangle: [
                d,
                0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            bottom: {
              rectangle: [
                d,
                d + h | 0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            right: {
              rectangle: [
                0,
                d,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            left: {
              rectangle: [
                d + w | 0,
                d,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            }
          };
  } else if (direction === "North") {
    return {
            front: {
              rectangle: [
                d,
                h + d | 0,
                w,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            back: rotate({
                  rectangle: [
                    d,
                    0,
                    w,
                    h
                  ],
                  flip: "None",
                  rotate: 0.0
                }, 180.0),
            top: {
              rectangle: [
                d,
                h,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            bottom: {
              rectangle: [
                d,
                (h << 1) + d | 0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            right: {
              rectangle: [
                0,
                h + d | 0,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            left: {
              rectangle: [
                d + w | 0,
                h + d | 0,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            }
          };
  } else if (direction === "West") {
    return {
            front: {
              rectangle: [
                w + d | 0,
                d,
                w,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            back: {
              rectangle: [
                0,
                d,
                w,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            top: {
              rectangle: [
                w + d | 0,
                0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            bottom: {
              rectangle: [
                w + d | 0,
                d + h | 0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            right: {
              rectangle: [
                w,
                d,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            left: {
              rectangle: [
                (w + d | 0) + w | 0,
                d,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            }
          };
  } else {
    return {
            front: {
              rectangle: [
                d,
                d,
                w,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            back: rotate({
                  rectangle: [
                    d,
                    (d + h | 0) + d | 0,
                    w,
                    h
                  ],
                  flip: "None",
                  rotate: 0.0
                }, 180.0),
            top: {
              rectangle: [
                d,
                0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            bottom: {
              rectangle: [
                d,
                d + h | 0,
                w,
                d
              ],
              flip: "None",
              rotate: 0.0
            },
            right: {
              rectangle: [
                0,
                d,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            },
            left: {
              rectangle: [
                d + w | 0,
                d,
                d,
                h
              ],
              flip: "None",
              rotate: 0.0
            }
          };
  }
}

function setLayout(scale, direction, center) {
  var d = scale[2];
  var h = scale[1];
  var w = scale[0];
  var scale$1 = center === "Left" || center === "Right" ? [
      d,
      h,
      w
    ] : (
      center === "Bottom" || center === "Top" ? [
          w,
          d,
          h
        ] : [
          w,
          h,
          d
        ]
    );
  var dest = make$3(scale$1, direction);
  if (center === "Top") {
    return {
            front: dest.bottom,
            back: rotate(dest.top, 180.0),
            top: dest.front,
            bottom: rotate(flip(dest.back, "Vertical"), 180.0),
            right: rotate(dest.right, 90.0),
            left: rotate(dest.left, -90.0)
          };
  } else if (center === "Front") {
    return {
            front: dest.front,
            back: dest.back,
            top: dest.top,
            bottom: flip(dest.bottom, "Vertical"),
            right: dest.right,
            left: dest.left
          };
  } else if (center === "Bottom") {
    return {
            front: dest.top,
            back: rotate(dest.bottom, 180.0),
            top: rotate(dest.back, 180.0),
            bottom: flip(dest.front, "Vertical"),
            right: rotate(dest.right, -90.0),
            left: rotate(dest.left, 90.0)
          };
  } else if (center === "Back") {
    return {
            front: dest.back,
            back: dest.front,
            top: rotate(dest.top, 180.0),
            bottom: flip(rotate(dest.bottom, 180.0), "Vertical"),
            right: dest.left,
            left: dest.right
          };
  } else if (center === "Left") {
    return {
            front: dest.right,
            back: dest.left,
            top: rotate(dest.top, 90.0),
            bottom: flip(rotate(dest.bottom, -90.0), "Vertical"),
            right: dest.back,
            left: dest.front
          };
  } else {
    return {
            front: dest.left,
            back: dest.right,
            top: rotate(dest.top, -90.0),
            bottom: flip(rotate(dest.bottom, 90.0), "Vertical"),
            right: dest.front,
            left: dest.back
          };
  }
}

var Dest = {
  translate: translate$3,
  make: make$3,
  setLayout: setLayout
};

function draw$1(textureId, source, position, scale, directionOpt, centerOpt, param) {
  var direction = directionOpt !== undefined ? directionOpt : "East";
  var center = centerOpt !== undefined ? centerOpt : "Front";
  var dest = translate$3(setLayout(scale, direction, center), position);
  draw(textureId, source.front, dest.front);
  draw(textureId, source.back, dest.back);
  draw(textureId, source.top, dest.top);
  draw(textureId, source.bottom, dest.bottom);
  draw(textureId, source.left, dest.left);
  draw(textureId, source.right, dest.right);
}

var Cuboid = {
  Source: Source,
  Face: Face,
  Dest: Dest,
  draw: draw$1
};

function drawCuboid(textureId, source, position, scale, directionOpt, centerOpt, param) {
  var direction = directionOpt !== undefined ? directionOpt : "East";
  var center = centerOpt !== undefined ? centerOpt : "Front";
  draw$1(textureId, source, position, scale, direction, center, undefined);
}

var Layer = {};

var steve_base = {
  head: translate(make(8, 8, 8), 0, 0),
  rightArm: translate(make(4, 12, 4), 40, 16),
  leftArm: translate(make(4, 12, 4), 32, 48),
  body: translate(make(8, 12, 4), 16, 16),
  rightLeg: translate(make(4, 12, 4), 0, 16),
  leftLeg: translate(make(4, 12, 4), 16, 48)
};

var steve_overlay = {
  head: translate(make(8, 8, 8), 32, 0),
  rightArm: translate(make(4, 12, 4), 40, 32),
  leftArm: translate(make(4, 12, 4), 48, 48),
  body: translate(make(8, 12, 4), 16, 32),
  rightLeg: translate(make(4, 12, 4), 0, 32),
  leftLeg: translate(make(4, 12, 4), 0, 48)
};

var steve = {
  base: steve_base,
  overlay: steve_overlay
};

var alex_base = {
  head: steve_base.head,
  rightArm: translate(make(3, 12, 4), 40, 16),
  leftArm: translate(make(3, 12, 4), 32, 48),
  body: steve_base.body,
  rightLeg: steve_base.rightLeg,
  leftLeg: steve_base.leftLeg
};

var alex_overlay = {
  head: steve_overlay.head,
  rightArm: translate(make(3, 12, 4), 40, 32),
  leftArm: translate(make(3, 12, 4), 48, 48),
  body: steve_overlay.body,
  rightLeg: steve_overlay.rightLeg,
  leftLeg: steve_overlay.leftLeg
};

var alex = {
  base: alex_base,
  overlay: alex_overlay
};

var CharacterLegacy = {
  Layer: Layer,
  make: make,
  translate: translate,
  steve: steve,
  alex: alex
};

var Layer$1 = {};

var steve_base$1 = {
  head: translate$1(make$1([
            8,
            8,
            8
          ]), [
        0,
        0
      ]),
  rightArm: translate$1(make$1([
            4,
            12,
            4
          ]), [
        40,
        16
      ]),
  leftArm: translate$1(make$1([
            4,
            12,
            4
          ]), [
        32,
        48
      ]),
  body: translate$1(make$1([
            8,
            12,
            4
          ]), [
        16,
        16
      ]),
  rightLeg: translate$1(make$1([
            4,
            12,
            4
          ]), [
        0,
        16
      ]),
  leftLeg: translate$1(make$1([
            4,
            12,
            4
          ]), [
        16,
        48
      ])
};

var steve_overlay$1 = {
  head: translate$1(make$1([
            8,
            8,
            8
          ]), [
        32,
        0
      ]),
  rightArm: translate$1(make$1([
            4,
            12,
            4
          ]), [
        40,
        32
      ]),
  leftArm: translate$1(make$1([
            4,
            12,
            4
          ]), [
        48,
        48
      ]),
  body: translate$1(make$1([
            8,
            12,
            4
          ]), [
        16,
        32
      ]),
  rightLeg: translate$1(make$1([
            4,
            12,
            4
          ]), [
        0,
        32
      ]),
  leftLeg: translate$1(make$1([
            4,
            12,
            4
          ]), [
        0,
        48
      ])
};

var steve$1 = {
  base: steve_base$1,
  overlay: steve_overlay$1
};

var alex_base$1 = {
  head: steve_base$1.head,
  rightArm: translate$1(make$1([
            3,
            12,
            4
          ]), [
        40,
        16
      ]),
  leftArm: translate$1(make$1([
            3,
            12,
            4
          ]), [
        32,
        48
      ]),
  body: steve_base$1.body,
  rightLeg: steve_base$1.rightLeg,
  leftLeg: steve_base$1.leftLeg
};

var alex_overlay$1 = {
  head: steve_overlay$1.head,
  rightArm: translate$1(make$1([
            3,
            12,
            4
          ]), [
        40,
        32
      ]),
  leftArm: translate$1(make$1([
            3,
            12,
            4
          ]), [
        48,
        48
      ]),
  body: steve_overlay$1.body,
  rightLeg: steve_overlay$1.rightLeg,
  leftLeg: steve_overlay$1.leftLeg
};

var alex$1 = {
  base: alex_base$1,
  overlay: alex_overlay$1
};

var Character = {
  Layer: Layer$1,
  make: make$1,
  translate: translate$1,
  steve: steve$1,
  alex: alex$1
};

function setTabSize(tabSize) {
  Generator.setIntegerVariable("tabSize", tabSize);
}

function getTabSize(param) {
  var value = Generator.getIntegerVariable("tabSize");
  if (value !== undefined) {
    return value;
  } else {
    return 24;
  }
}

function drawFaceTab(face, side, showFoldLineOpt, tabAngleOpt, size, param) {
  var showFoldLine = showFoldLineOpt !== undefined ? showFoldLineOpt : true;
  var tabAngle = tabAngleOpt !== undefined ? tabAngleOpt : 45.0;
  var size$1 = size !== undefined ? size : getTabSize(undefined);
  var h = face[3];
  var w = face[2];
  var y = face[1];
  var x = face[0];
  var tabRect = side === "East" ? [
      x + w | 0,
      y,
      size$1,
      h
    ] : (
      side === "North" ? [
          x,
          y - size$1 | 0,
          w,
          size$1
        ] : (
          side === "West" ? [
              x - size$1 | 0,
              y,
              size$1,
              h
            ] : [
              x,
              y + h | 0,
              w,
              size$1
            ]
        )
    );
  Generator.drawTab(tabRect, side, showFoldLine, tabAngle, undefined);
}

function drawFaceTabs(face, sides, showFoldLineOpt, tabAngleOpt, size, param) {
  var showFoldLine = showFoldLineOpt !== undefined ? showFoldLineOpt : true;
  var tabAngle = tabAngleOpt !== undefined ? tabAngleOpt : 45.0;
  var size$1 = size !== undefined ? size : getTabSize(undefined);
  Belt_Array.forEach(sides, (function (side) {
          drawFaceTab(face, side, showFoldLine, tabAngle, size$1, undefined);
        }));
}

var Builder;

var defaultTabSize = 24;

exports.Builder = Builder;
exports.CuboidLegacy = CuboidLegacy;
exports.translateRectangle = translateRectangle$1;
exports.Cuboid = Cuboid;
exports.drawCuboid = drawCuboid;
exports.CharacterLegacy = CharacterLegacy;
exports.Character = Character;
exports.setTabSize = setTabSize;
exports.defaultTabSize = defaultTabSize;
exports.getTabSize = getTabSize;
exports.drawFaceTab = drawFaceTab;
exports.drawFaceTabs = drawFaceTabs;
/* steve Not a pure module */
