// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


function makeFromUrl(url) {
  return new Promise((function (resolve, reject) {
                var image = new Image();
                image.onload = (function (param) {
                    resolve(image);
                  });
                image.onerror = (function (error) {
                    reject(error);
                  });
                image.src = url;
              }));
}

exports.makeFromUrl = makeFromUrl;
/* No side effect */
