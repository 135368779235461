// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var MinecraftBlock_Types = require("./MinecraftBlock_Types.bs.js");
var MinecraftBlock_Textures = require("./MinecraftBlock_Textures.bs.js");
var MinecraftBlock_Constants = require("./MinecraftBlock_Constants.bs.js");
var MinecraftBlock_TexturePicker = require("./MinecraftBlock_TexturePicker.bs.js");

var id = "minecraft-block";

var name = "Minecraft Block";

var history = [
  "Dec 2021 lostminer - Block generator rewrite.",
  "Dec 2021 NinjolasNJM - Add Stairs, Fence, Door, Trapdoor and Snow.",
  "Jan 2022 NinjolasNJM - Add Cake Block type."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var images = [
  {
    id: "Background",
    url: require("./images/Background.png")
  },
  {
    id: "Title",
    url: require("./images/Title.png")
  },
  {
    id: "Folds-Block",
    url: require("./images/Folds-Block.png")
  },
  {
    id: "Tabs-Block",
    url: require("./images/Tabs-Block.png")
  },
  {
    id: "Folds-Slab",
    url: require("./images/Folds-Slab.png")
  },
  {
    id: "Tabs-Slab",
    url: require("./images/Tabs-Slab.png")
  },
  {
    id: "Folds-Stair",
    url: require("./images/Folds-Stair.png")
  },
  {
    id: "Tabs-Stair",
    url: require("./images/Tabs-Stair.png")
  },
  {
    id: "Folds-Fence",
    url: require("./images/Folds-Fence.png")
  },
  {
    id: "Tabs-Fence",
    url: require("./images/Tabs-Fence.png")
  },
  {
    id: "Folds-Door",
    url: require("./images/Folds-Door.png")
  },
  {
    id: "Tabs-Door",
    url: require("./images/Tabs-Door.png")
  },
  {
    id: "Folds-Trapdoor",
    url: require("./images/Folds-Trapdoor.png")
  },
  {
    id: "Tabs-Trapdoor",
    url: require("./images/Tabs-Trapdoor.png")
  },
  {
    id: "Folds-Snow-Top",
    url: require("./images/Folds-Snow-Top.png")
  },
  {
    id: "Folds-Snow-Bottom",
    url: require("./images/Folds-Snow-Bottom.png")
  },
  {
    id: "Tabs-Snow-Top",
    url: require("./images/Tabs-Snow-Top.png")
  },
  {
    id: "Tabs-Snow-Middle",
    url: require("./images/Tabs-Snow-Middle.png")
  },
  {
    id: "Tabs-Snow-Bottom",
    url: require("./images/Tabs-Snow-Bottom.png")
  },
  {
    id: "Folds-Cake-Left",
    url: require("./images/Folds-Cake-Left.png")
  },
  {
    id: "Folds-Cake-Middle",
    url: require("./images/Folds-Cake-Middle.png")
  },
  {
    id: "Folds-Cake-Right",
    url: require("./images/Folds-Cake-Right.png")
  },
  {
    id: "Tabs-Cake-Left",
    url: require("./images/Tabs-Cake-Left.png")
  },
  {
    id: "Tabs-Cake-Middle",
    url: require("./images/Tabs-Cake-Middle.png")
  },
  {
    id: "Tabs-Cake-Corner",
    url: require("./images/Tabs-Cake-Corner.png")
  },
  {
    id: "Tabs-Cake-Right",
    url: require("./images/Tabs-Cake-Right.png")
  }
];

function script(param) {
  Generator.defineSelectInput("Version", MinecraftBlock_Textures.versionIds);
  var versionId = Generator.getSelectInputValue("Version");
  Generator.defineCustomStringInput(MinecraftBlock_Constants.currentBlockTextureId, (function (onChange) {
          return React.createElement(MinecraftBlock_TexturePicker.make, {
                      versionId: versionId,
                      onChange: onChange
                    });
        }));
  Generator.defineSelectInput("Number of Blocks", [
        "1",
        "2"
      ]);
  var numberOfBlocks = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Number of Blocks")), 1);
  Generator.defineBooleanInput("Show Folds", true);
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  Generator.drawImage("Background", [
        0,
        0
      ]);
  for(var i = 1; i <= numberOfBlocks; ++i){
    var blockId = i.toString();
    var typeName = "Block " + blockId + " Type";
    Generator.defineSelectInput(typeName, [
          "Block",
          "Slab",
          "Stair",
          "Fence",
          "Door",
          "Trapdoor",
          "Snow Layers",
          "Cake"
        ]);
    var blockType = Generator.getSelectInputValue(typeName);
    var oy = 16 + Math.imul(400, i - 1 | 0) | 0;
    switch (blockType) {
      case "Block" :
          MinecraftBlock_Types.Block.draw(blockId, 57, oy, showFolds);
          break;
      case "Cake" :
          MinecraftBlock_Types.Cake.draw(blockId, 57, oy, showFolds);
          break;
      case "Door" :
          MinecraftBlock_Types.Door.draw(blockId, 57, oy, showFolds);
          break;
      case "Fence" :
          MinecraftBlock_Types.Fence.draw(blockId, 57, oy, showFolds);
          break;
      case "Slab" :
          MinecraftBlock_Types.Slab.draw(blockId, 57, oy, showFolds);
          break;
      case "Snow Layers" :
          MinecraftBlock_Types.Snow.draw(blockId, 57, oy, showFolds);
          break;
      case "Stair" :
          MinecraftBlock_Types.Stair.draw(blockId, 57, oy, showFolds);
          break;
      case "Trapdoor" :
          MinecraftBlock_Types.Trapdoor.draw(blockId, 57, oy, showFolds);
          break;
      default:
        
    }
  }
  Generator.defineButtonInput("Clear", (function (param) {
          var currentTextureChoice = Generator.getStringInputValue(MinecraftBlock_Constants.currentBlockTextureId);
          Generator.clearStringInputValues(undefined);
          Generator.setStringInputValue(MinecraftBlock_Constants.currentBlockTextureId, currentTextureChoice);
        }));
  Generator.drawImage("Title", [
        0,
        0
      ]);
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: MinecraftBlock_Textures.textures,
  script: script
};

var TexturePicker;

var Textures;

var Face;

var Types;

var textures = MinecraftBlock_Textures.textures;

exports.TexturePicker = TexturePicker;
exports.Textures = Textures;
exports.Face = Face;
exports.Types = Types;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
