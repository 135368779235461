// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator_ImageFactory = require("./Generator_ImageFactory.bs.js");
var Generator_CanvasWithContext = require("./Generator_CanvasWithContext.bs.js");

function makeFromImage(image) {
  var width = image.width;
  var height = image.height;
  var canvasWithContext = Generator_CanvasWithContext.make(width, height);
  canvasWithContext.context.drawImage(image, 0, 0);
  return {
          image: image,
          width: image.width,
          height: image.height,
          canvasWithContext: canvasWithContext
        };
}

function makeFromImageUrl(url) {
  return Generator_ImageFactory.makeFromUrl(url).then(makeFromImage);
}

exports.makeFromImage = makeFromImage;
exports.makeFromImageUrl = makeFromImageUrl;
/* No side effect */
