// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var texture_url = require("./minecraft-1.13.2.png");

var texture = {
  id: "minecraft-1.13.2",
  url: texture_url,
  standardWidth: 16,
  standardHeight: 13056
};

var version = {
  id: "minecraft-1.13.2",
  textures: [
    {
      id: "acacia_door_bottom",
      frames: 1,
      index: 0
    },
    {
      id: "acacia_door_top",
      frames: 1,
      index: 16
    },
    {
      id: "acacia_leaves",
      frames: 1,
      index: 32
    },
    {
      id: "acacia_log",
      frames: 1,
      index: 48
    },
    {
      id: "acacia_log_top",
      frames: 1,
      index: 64
    },
    {
      id: "acacia_planks",
      frames: 1,
      index: 80
    },
    {
      id: "acacia_sapling",
      frames: 1,
      index: 96
    },
    {
      id: "acacia_trapdoor",
      frames: 1,
      index: 112
    },
    {
      id: "activator_rail",
      frames: 1,
      index: 128
    },
    {
      id: "activator_rail_on",
      frames: 1,
      index: 144
    },
    {
      id: "allium",
      frames: 1,
      index: 160
    },
    {
      id: "andesite",
      frames: 1,
      index: 176
    },
    {
      id: "anvil",
      frames: 1,
      index: 192
    },
    {
      id: "anvil_top",
      frames: 1,
      index: 208
    },
    {
      id: "attached_melon_stem",
      frames: 1,
      index: 224
    },
    {
      id: "attached_pumpkin_stem",
      frames: 1,
      index: 240
    },
    {
      id: "azure_bluet",
      frames: 1,
      index: 256
    },
    {
      id: "beacon",
      frames: 1,
      index: 272
    },
    {
      id: "bedrock",
      frames: 1,
      index: 288
    },
    {
      id: "beetroots_stage0",
      frames: 1,
      index: 304
    },
    {
      id: "beetroots_stage1",
      frames: 1,
      index: 320
    },
    {
      id: "beetroots_stage2",
      frames: 1,
      index: 336
    },
    {
      id: "beetroots_stage3",
      frames: 1,
      index: 352
    },
    {
      id: "birch_door_bottom",
      frames: 1,
      index: 368
    },
    {
      id: "birch_door_top",
      frames: 1,
      index: 384
    },
    {
      id: "birch_leaves",
      frames: 1,
      index: 400
    },
    {
      id: "birch_log",
      frames: 1,
      index: 416
    },
    {
      id: "birch_log_top",
      frames: 1,
      index: 432
    },
    {
      id: "birch_planks",
      frames: 1,
      index: 448
    },
    {
      id: "birch_sapling",
      frames: 1,
      index: 464
    },
    {
      id: "birch_trapdoor",
      frames: 1,
      index: 480
    },
    {
      id: "black_concrete",
      frames: 1,
      index: 496
    },
    {
      id: "black_concrete_powder",
      frames: 1,
      index: 512
    },
    {
      id: "black_glazed_terracotta",
      frames: 1,
      index: 528
    },
    {
      id: "black_shulker_box",
      frames: 1,
      index: 544
    },
    {
      id: "black_stained_glass",
      frames: 1,
      index: 560
    },
    {
      id: "black_stained_glass_pane_top",
      frames: 1,
      index: 576
    },
    {
      id: "black_terracotta",
      frames: 1,
      index: 592
    },
    {
      id: "black_wool",
      frames: 1,
      index: 608
    },
    {
      id: "blue_concrete",
      frames: 1,
      index: 624
    },
    {
      id: "blue_concrete_powder",
      frames: 1,
      index: 640
    },
    {
      id: "blue_glazed_terracotta",
      frames: 1,
      index: 656
    },
    {
      id: "blue_ice",
      frames: 1,
      index: 672
    },
    {
      id: "blue_orchid",
      frames: 1,
      index: 688
    },
    {
      id: "blue_shulker_box",
      frames: 1,
      index: 704
    },
    {
      id: "blue_stained_glass",
      frames: 1,
      index: 720
    },
    {
      id: "blue_stained_glass_pane_top",
      frames: 1,
      index: 736
    },
    {
      id: "blue_terracotta",
      frames: 1,
      index: 752
    },
    {
      id: "blue_wool",
      frames: 1,
      index: 768
    },
    {
      id: "bone_block_side",
      frames: 1,
      index: 784
    },
    {
      id: "bone_block_top",
      frames: 1,
      index: 800
    },
    {
      id: "bookshelf",
      frames: 1,
      index: 816
    },
    {
      id: "brain_coral",
      frames: 1,
      index: 832
    },
    {
      id: "brain_coral_block",
      frames: 1,
      index: 848
    },
    {
      id: "brain_coral_fan",
      frames: 1,
      index: 864
    },
    {
      id: "brewing_stand",
      frames: 1,
      index: 880
    },
    {
      id: "brewing_stand_base",
      frames: 1,
      index: 896
    },
    {
      id: "bricks",
      frames: 1,
      index: 912
    },
    {
      id: "brown_concrete",
      frames: 1,
      index: 928
    },
    {
      id: "brown_concrete_powder",
      frames: 1,
      index: 944
    },
    {
      id: "brown_glazed_terracotta",
      frames: 1,
      index: 960
    },
    {
      id: "brown_mushroom",
      frames: 1,
      index: 976
    },
    {
      id: "brown_mushroom_block",
      frames: 1,
      index: 992
    },
    {
      id: "brown_shulker_box",
      frames: 1,
      index: 1008
    },
    {
      id: "brown_stained_glass",
      frames: 1,
      index: 1024
    },
    {
      id: "brown_stained_glass_pane_top",
      frames: 1,
      index: 1040
    },
    {
      id: "brown_terracotta",
      frames: 1,
      index: 1056
    },
    {
      id: "brown_wool",
      frames: 1,
      index: 1072
    },
    {
      id: "bubble_coral",
      frames: 1,
      index: 1088
    },
    {
      id: "bubble_coral_block",
      frames: 1,
      index: 1104
    },
    {
      id: "bubble_coral_fan",
      frames: 1,
      index: 1120
    },
    {
      id: "cactus_bottom",
      frames: 1,
      index: 1136
    },
    {
      id: "cactus_side",
      frames: 1,
      index: 1152
    },
    {
      id: "cactus_top",
      frames: 1,
      index: 1168
    },
    {
      id: "cake_bottom",
      frames: 1,
      index: 1184
    },
    {
      id: "cake_inner",
      frames: 1,
      index: 1200
    },
    {
      id: "cake_side",
      frames: 1,
      index: 1216
    },
    {
      id: "cake_top",
      frames: 1,
      index: 1232
    },
    {
      id: "carrots_stage0",
      frames: 1,
      index: 1248
    },
    {
      id: "carrots_stage1",
      frames: 1,
      index: 1264
    },
    {
      id: "carrots_stage2",
      frames: 1,
      index: 1280
    },
    {
      id: "carrots_stage3",
      frames: 1,
      index: 1296
    },
    {
      id: "carved_pumpkin",
      frames: 1,
      index: 1312
    },
    {
      id: "cauldron_bottom",
      frames: 1,
      index: 1328
    },
    {
      id: "cauldron_inner",
      frames: 1,
      index: 1344
    },
    {
      id: "cauldron_side",
      frames: 1,
      index: 1360
    },
    {
      id: "cauldron_top",
      frames: 1,
      index: 1376
    },
    {
      id: "chain_command_block_back",
      frames: 4,
      index: 1392
    },
    {
      id: "chain_command_block_conditional",
      frames: 4,
      index: 1456
    },
    {
      id: "chain_command_block_front",
      frames: 4,
      index: 1520
    },
    {
      id: "chain_command_block_side",
      frames: 4,
      index: 1584
    },
    {
      id: "chipped_anvil_top",
      frames: 1,
      index: 1648
    },
    {
      id: "chiseled_quartz_block",
      frames: 1,
      index: 1664
    },
    {
      id: "chiseled_quartz_block_top",
      frames: 1,
      index: 1680
    },
    {
      id: "chiseled_red_sandstone",
      frames: 1,
      index: 1696
    },
    {
      id: "chiseled_sandstone",
      frames: 1,
      index: 1712
    },
    {
      id: "chiseled_stone_bricks",
      frames: 1,
      index: 1728
    },
    {
      id: "chorus_flower",
      frames: 1,
      index: 1744
    },
    {
      id: "chorus_flower_dead",
      frames: 1,
      index: 1760
    },
    {
      id: "chorus_plant",
      frames: 1,
      index: 1776
    },
    {
      id: "clay",
      frames: 1,
      index: 1792
    },
    {
      id: "coal_block",
      frames: 1,
      index: 1808
    },
    {
      id: "coal_ore",
      frames: 1,
      index: 1824
    },
    {
      id: "coarse_dirt",
      frames: 1,
      index: 1840
    },
    {
      id: "cobblestone",
      frames: 1,
      index: 1856
    },
    {
      id: "cobweb",
      frames: 1,
      index: 1872
    },
    {
      id: "cocoa_stage0",
      frames: 1,
      index: 1888
    },
    {
      id: "cocoa_stage1",
      frames: 1,
      index: 1904
    },
    {
      id: "cocoa_stage2",
      frames: 1,
      index: 1920
    },
    {
      id: "command_block_back",
      frames: 4,
      index: 1936
    },
    {
      id: "command_block_conditional",
      frames: 4,
      index: 2000
    },
    {
      id: "command_block_front",
      frames: 4,
      index: 2064
    },
    {
      id: "command_block_side",
      frames: 4,
      index: 2128
    },
    {
      id: "comparator",
      frames: 1,
      index: 2192
    },
    {
      id: "comparator_on",
      frames: 1,
      index: 2208
    },
    {
      id: "conduit",
      frames: 1,
      index: 2224
    },
    {
      id: "cracked_stone_bricks",
      frames: 1,
      index: 2240
    },
    {
      id: "crafting_table_front",
      frames: 1,
      index: 2256
    },
    {
      id: "crafting_table_side",
      frames: 1,
      index: 2272
    },
    {
      id: "crafting_table_top",
      frames: 1,
      index: 2288
    },
    {
      id: "cut_red_sandstone",
      frames: 1,
      index: 2304
    },
    {
      id: "cut_sandstone",
      frames: 1,
      index: 2320
    },
    {
      id: "cyan_concrete",
      frames: 1,
      index: 2336
    },
    {
      id: "cyan_concrete_powder",
      frames: 1,
      index: 2352
    },
    {
      id: "cyan_glazed_terracotta",
      frames: 1,
      index: 2368
    },
    {
      id: "cyan_shulker_box",
      frames: 1,
      index: 2384
    },
    {
      id: "cyan_stained_glass",
      frames: 1,
      index: 2400
    },
    {
      id: "cyan_stained_glass_pane_top",
      frames: 1,
      index: 2416
    },
    {
      id: "cyan_terracotta",
      frames: 1,
      index: 2432
    },
    {
      id: "cyan_wool",
      frames: 1,
      index: 2448
    },
    {
      id: "damaged_anvil_top",
      frames: 1,
      index: 2464
    },
    {
      id: "dandelion",
      frames: 1,
      index: 2480
    },
    {
      id: "dark_oak_door_bottom",
      frames: 1,
      index: 2496
    },
    {
      id: "dark_oak_door_top",
      frames: 1,
      index: 2512
    },
    {
      id: "dark_oak_leaves",
      frames: 1,
      index: 2528
    },
    {
      id: "dark_oak_log",
      frames: 1,
      index: 2544
    },
    {
      id: "dark_oak_log_top",
      frames: 1,
      index: 2560
    },
    {
      id: "dark_oak_planks",
      frames: 1,
      index: 2576
    },
    {
      id: "dark_oak_sapling",
      frames: 1,
      index: 2592
    },
    {
      id: "dark_oak_trapdoor",
      frames: 1,
      index: 2608
    },
    {
      id: "dark_prismarine",
      frames: 1,
      index: 2624
    },
    {
      id: "daylight_detector_inverted_top",
      frames: 1,
      index: 2640
    },
    {
      id: "daylight_detector_side",
      frames: 1,
      index: 2656
    },
    {
      id: "daylight_detector_top",
      frames: 1,
      index: 2672
    },
    {
      id: "dead_brain_coral",
      frames: 1,
      index: 2688
    },
    {
      id: "dead_brain_coral_block",
      frames: 1,
      index: 2704
    },
    {
      id: "dead_brain_coral_fan",
      frames: 1,
      index: 2720
    },
    {
      id: "dead_bubble_coral",
      frames: 1,
      index: 2736
    },
    {
      id: "dead_bubble_coral_block",
      frames: 1,
      index: 2752
    },
    {
      id: "dead_bubble_coral_fan",
      frames: 1,
      index: 2768
    },
    {
      id: "dead_bush",
      frames: 1,
      index: 2784
    },
    {
      id: "dead_fire_coral",
      frames: 1,
      index: 2800
    },
    {
      id: "dead_fire_coral_block",
      frames: 1,
      index: 2816
    },
    {
      id: "dead_fire_coral_fan",
      frames: 1,
      index: 2832
    },
    {
      id: "dead_horn_coral",
      frames: 1,
      index: 2848
    },
    {
      id: "dead_horn_coral_block",
      frames: 1,
      index: 2864
    },
    {
      id: "dead_horn_coral_fan",
      frames: 1,
      index: 2880
    },
    {
      id: "dead_tube_coral",
      frames: 1,
      index: 2896
    },
    {
      id: "dead_tube_coral_block",
      frames: 1,
      index: 2912
    },
    {
      id: "dead_tube_coral_fan",
      frames: 1,
      index: 2928
    },
    {
      id: "debug",
      frames: 1,
      index: 2944
    },
    {
      id: "debug2",
      frames: 1,
      index: 2960
    },
    {
      id: "destroy_stage_0",
      frames: 1,
      index: 2976
    },
    {
      id: "destroy_stage_1",
      frames: 1,
      index: 2992
    },
    {
      id: "destroy_stage_2",
      frames: 1,
      index: 3008
    },
    {
      id: "destroy_stage_3",
      frames: 1,
      index: 3024
    },
    {
      id: "destroy_stage_4",
      frames: 1,
      index: 3040
    },
    {
      id: "destroy_stage_5",
      frames: 1,
      index: 3056
    },
    {
      id: "destroy_stage_6",
      frames: 1,
      index: 3072
    },
    {
      id: "destroy_stage_7",
      frames: 1,
      index: 3088
    },
    {
      id: "destroy_stage_8",
      frames: 1,
      index: 3104
    },
    {
      id: "destroy_stage_9",
      frames: 1,
      index: 3120
    },
    {
      id: "detector_rail",
      frames: 1,
      index: 3136
    },
    {
      id: "detector_rail_on",
      frames: 1,
      index: 3152
    },
    {
      id: "diamond_block",
      frames: 1,
      index: 3168
    },
    {
      id: "diamond_ore",
      frames: 1,
      index: 3184
    },
    {
      id: "diorite",
      frames: 1,
      index: 3200
    },
    {
      id: "dirt",
      frames: 1,
      index: 3216
    },
    {
      id: "dispenser_front",
      frames: 1,
      index: 3232
    },
    {
      id: "dispenser_front_vertical",
      frames: 1,
      index: 3248
    },
    {
      id: "dragon_egg",
      frames: 1,
      index: 3264
    },
    {
      id: "dried_kelp_bottom",
      frames: 1,
      index: 3280
    },
    {
      id: "dried_kelp_side",
      frames: 1,
      index: 3296
    },
    {
      id: "dried_kelp_top",
      frames: 1,
      index: 3312
    },
    {
      id: "dropper_front",
      frames: 1,
      index: 3328
    },
    {
      id: "dropper_front_vertical",
      frames: 1,
      index: 3344
    },
    {
      id: "emerald_block",
      frames: 1,
      index: 3360
    },
    {
      id: "emerald_ore",
      frames: 1,
      index: 3376
    },
    {
      id: "enchanting_table_bottom",
      frames: 1,
      index: 3392
    },
    {
      id: "enchanting_table_side",
      frames: 1,
      index: 3408
    },
    {
      id: "enchanting_table_top",
      frames: 1,
      index: 3424
    },
    {
      id: "end_portal_frame_eye",
      frames: 1,
      index: 3440
    },
    {
      id: "end_portal_frame_side",
      frames: 1,
      index: 3456
    },
    {
      id: "end_portal_frame_top",
      frames: 1,
      index: 3472
    },
    {
      id: "end_rod",
      frames: 1,
      index: 3488
    },
    {
      id: "end_stone",
      frames: 1,
      index: 3504
    },
    {
      id: "end_stone_bricks",
      frames: 1,
      index: 3520
    },
    {
      id: "farmland",
      frames: 1,
      index: 3536
    },
    {
      id: "farmland_moist",
      frames: 1,
      index: 3552
    },
    {
      id: "fern",
      frames: 1,
      index: 3568
    },
    {
      id: "fire_0",
      frames: 32,
      index: 3584
    },
    {
      id: "fire_1",
      frames: 32,
      index: 4096
    },
    {
      id: "fire_coral",
      frames: 1,
      index: 4608
    },
    {
      id: "fire_coral_block",
      frames: 1,
      index: 4624
    },
    {
      id: "fire_coral_fan",
      frames: 1,
      index: 4640
    },
    {
      id: "flower_pot",
      frames: 1,
      index: 4656
    },
    {
      id: "frosted_ice_0",
      frames: 1,
      index: 4672
    },
    {
      id: "frosted_ice_1",
      frames: 1,
      index: 4688
    },
    {
      id: "frosted_ice_2",
      frames: 1,
      index: 4704
    },
    {
      id: "frosted_ice_3",
      frames: 1,
      index: 4720
    },
    {
      id: "furnace_front",
      frames: 1,
      index: 4736
    },
    {
      id: "furnace_front_on",
      frames: 1,
      index: 4752
    },
    {
      id: "furnace_side",
      frames: 1,
      index: 4768
    },
    {
      id: "furnace_top",
      frames: 1,
      index: 4784
    },
    {
      id: "glass",
      frames: 1,
      index: 4800
    },
    {
      id: "glass_pane_top",
      frames: 1,
      index: 4816
    },
    {
      id: "glowstone",
      frames: 1,
      index: 4832
    },
    {
      id: "gold_block",
      frames: 1,
      index: 4848
    },
    {
      id: "gold_ore",
      frames: 1,
      index: 4864
    },
    {
      id: "granite",
      frames: 1,
      index: 4880
    },
    {
      id: "grass",
      frames: 1,
      index: 4896
    },
    {
      id: "grass_block_side",
      frames: 1,
      index: 4912
    },
    {
      id: "grass_block_side_overlay",
      frames: 1,
      index: 4928
    },
    {
      id: "grass_block_snow",
      frames: 1,
      index: 4944
    },
    {
      id: "grass_block_top",
      frames: 1,
      index: 4960
    },
    {
      id: "grass_path_side",
      frames: 1,
      index: 4976
    },
    {
      id: "grass_path_top",
      frames: 1,
      index: 4992
    },
    {
      id: "gravel",
      frames: 1,
      index: 5008
    },
    {
      id: "gray_concrete",
      frames: 1,
      index: 5024
    },
    {
      id: "gray_concrete_powder",
      frames: 1,
      index: 5040
    },
    {
      id: "gray_glazed_terracotta",
      frames: 1,
      index: 5056
    },
    {
      id: "gray_shulker_box",
      frames: 1,
      index: 5072
    },
    {
      id: "gray_stained_glass",
      frames: 1,
      index: 5088
    },
    {
      id: "gray_stained_glass_pane_top",
      frames: 1,
      index: 5104
    },
    {
      id: "gray_terracotta",
      frames: 1,
      index: 5120
    },
    {
      id: "gray_wool",
      frames: 1,
      index: 5136
    },
    {
      id: "green_concrete",
      frames: 1,
      index: 5152
    },
    {
      id: "green_concrete_powder",
      frames: 1,
      index: 5168
    },
    {
      id: "green_glazed_terracotta",
      frames: 1,
      index: 5184
    },
    {
      id: "green_shulker_box",
      frames: 1,
      index: 5200
    },
    {
      id: "green_stained_glass",
      frames: 1,
      index: 5216
    },
    {
      id: "green_stained_glass_pane_top",
      frames: 1,
      index: 5232
    },
    {
      id: "green_terracotta",
      frames: 1,
      index: 5248
    },
    {
      id: "green_wool",
      frames: 1,
      index: 5264
    },
    {
      id: "hay_block_side",
      frames: 1,
      index: 5280
    },
    {
      id: "hay_block_top",
      frames: 1,
      index: 5296
    },
    {
      id: "hopper_inside",
      frames: 1,
      index: 5312
    },
    {
      id: "hopper_outside",
      frames: 1,
      index: 5328
    },
    {
      id: "hopper_top",
      frames: 1,
      index: 5344
    },
    {
      id: "horn_coral",
      frames: 1,
      index: 5360
    },
    {
      id: "horn_coral_block",
      frames: 1,
      index: 5376
    },
    {
      id: "horn_coral_fan",
      frames: 1,
      index: 5392
    },
    {
      id: "ice",
      frames: 1,
      index: 5408
    },
    {
      id: "iron_bars",
      frames: 1,
      index: 5424
    },
    {
      id: "iron_block",
      frames: 1,
      index: 5440
    },
    {
      id: "iron_door_bottom",
      frames: 1,
      index: 5456
    },
    {
      id: "iron_door_top",
      frames: 1,
      index: 5472
    },
    {
      id: "iron_ore",
      frames: 1,
      index: 5488
    },
    {
      id: "iron_trapdoor",
      frames: 1,
      index: 5504
    },
    {
      id: "item_frame",
      frames: 1,
      index: 5520
    },
    {
      id: "jack_o_lantern",
      frames: 1,
      index: 5536
    },
    {
      id: "jukebox_side",
      frames: 1,
      index: 5552
    },
    {
      id: "jukebox_top",
      frames: 1,
      index: 5568
    },
    {
      id: "jungle_door_bottom",
      frames: 1,
      index: 5584
    },
    {
      id: "jungle_door_top",
      frames: 1,
      index: 5600
    },
    {
      id: "jungle_leaves",
      frames: 1,
      index: 5616
    },
    {
      id: "jungle_log",
      frames: 1,
      index: 5632
    },
    {
      id: "jungle_log_top",
      frames: 1,
      index: 5648
    },
    {
      id: "jungle_planks",
      frames: 1,
      index: 5664
    },
    {
      id: "jungle_sapling",
      frames: 1,
      index: 5680
    },
    {
      id: "jungle_trapdoor",
      frames: 1,
      index: 5696
    },
    {
      id: "kelp",
      frames: 20,
      index: 5712
    },
    {
      id: "kelp_plant",
      frames: 20,
      index: 6032
    },
    {
      id: "ladder",
      frames: 1,
      index: 6352
    },
    {
      id: "lapis_block",
      frames: 1,
      index: 6368
    },
    {
      id: "lapis_ore",
      frames: 1,
      index: 6384
    },
    {
      id: "large_fern_bottom",
      frames: 1,
      index: 6400
    },
    {
      id: "large_fern_top",
      frames: 1,
      index: 6416
    },
    {
      id: "lava_still",
      frames: 20,
      index: 6432
    },
    {
      id: "lever",
      frames: 1,
      index: 6752
    },
    {
      id: "light_blue_concrete",
      frames: 1,
      index: 6768
    },
    {
      id: "light_blue_concrete_powder",
      frames: 1,
      index: 6784
    },
    {
      id: "light_blue_glazed_terracotta",
      frames: 1,
      index: 6800
    },
    {
      id: "light_blue_shulker_box",
      frames: 1,
      index: 6816
    },
    {
      id: "light_blue_stained_glass",
      frames: 1,
      index: 6832
    },
    {
      id: "light_blue_stained_glass_pane_top",
      frames: 1,
      index: 6848
    },
    {
      id: "light_blue_terracotta",
      frames: 1,
      index: 6864
    },
    {
      id: "light_blue_wool",
      frames: 1,
      index: 6880
    },
    {
      id: "light_gray_concrete",
      frames: 1,
      index: 6896
    },
    {
      id: "light_gray_concrete_powder",
      frames: 1,
      index: 6912
    },
    {
      id: "light_gray_glazed_terracotta",
      frames: 1,
      index: 6928
    },
    {
      id: "light_gray_shulker_box",
      frames: 1,
      index: 6944
    },
    {
      id: "light_gray_stained_glass",
      frames: 1,
      index: 6960
    },
    {
      id: "light_gray_stained_glass_pane_top",
      frames: 1,
      index: 6976
    },
    {
      id: "light_gray_terracotta",
      frames: 1,
      index: 6992
    },
    {
      id: "light_gray_wool",
      frames: 1,
      index: 7008
    },
    {
      id: "lilac_bottom",
      frames: 1,
      index: 7024
    },
    {
      id: "lilac_top",
      frames: 1,
      index: 7040
    },
    {
      id: "lily_pad",
      frames: 1,
      index: 7056
    },
    {
      id: "lime_concrete",
      frames: 1,
      index: 7072
    },
    {
      id: "lime_concrete_powder",
      frames: 1,
      index: 7088
    },
    {
      id: "lime_glazed_terracotta",
      frames: 1,
      index: 7104
    },
    {
      id: "lime_shulker_box",
      frames: 1,
      index: 7120
    },
    {
      id: "lime_stained_glass",
      frames: 1,
      index: 7136
    },
    {
      id: "lime_stained_glass_pane_top",
      frames: 1,
      index: 7152
    },
    {
      id: "lime_terracotta",
      frames: 1,
      index: 7168
    },
    {
      id: "lime_wool",
      frames: 1,
      index: 7184
    },
    {
      id: "magenta_concrete",
      frames: 1,
      index: 7200
    },
    {
      id: "magenta_concrete_powder",
      frames: 1,
      index: 7216
    },
    {
      id: "magenta_glazed_terracotta",
      frames: 1,
      index: 7232
    },
    {
      id: "magenta_shulker_box",
      frames: 1,
      index: 7248
    },
    {
      id: "magenta_stained_glass",
      frames: 1,
      index: 7264
    },
    {
      id: "magenta_stained_glass_pane_top",
      frames: 1,
      index: 7280
    },
    {
      id: "magenta_terracotta",
      frames: 1,
      index: 7296
    },
    {
      id: "magenta_wool",
      frames: 1,
      index: 7312
    },
    {
      id: "magma",
      frames: 3,
      index: 7328
    },
    {
      id: "melon_side",
      frames: 1,
      index: 7376
    },
    {
      id: "melon_stem",
      frames: 1,
      index: 7392
    },
    {
      id: "melon_top",
      frames: 1,
      index: 7408
    },
    {
      id: "mossy_cobblestone",
      frames: 1,
      index: 7424
    },
    {
      id: "mossy_stone_bricks",
      frames: 1,
      index: 7440
    },
    {
      id: "mushroom_block_inside",
      frames: 1,
      index: 7456
    },
    {
      id: "mushroom_stem",
      frames: 1,
      index: 7472
    },
    {
      id: "mycelium_side",
      frames: 1,
      index: 7488
    },
    {
      id: "mycelium_top",
      frames: 1,
      index: 7504
    },
    {
      id: "nether_bricks",
      frames: 1,
      index: 7520
    },
    {
      id: "nether_portal",
      frames: 32,
      index: 7536
    },
    {
      id: "nether_quartz_ore",
      frames: 1,
      index: 8048
    },
    {
      id: "nether_wart_block",
      frames: 1,
      index: 8064
    },
    {
      id: "nether_wart_stage0",
      frames: 1,
      index: 8080
    },
    {
      id: "nether_wart_stage1",
      frames: 1,
      index: 8096
    },
    {
      id: "nether_wart_stage2",
      frames: 1,
      index: 8112
    },
    {
      id: "netherrack",
      frames: 1,
      index: 8128
    },
    {
      id: "note_block",
      frames: 1,
      index: 8144
    },
    {
      id: "oak_door_bottom",
      frames: 1,
      index: 8160
    },
    {
      id: "oak_door_top",
      frames: 1,
      index: 8176
    },
    {
      id: "oak_leaves",
      frames: 1,
      index: 8192
    },
    {
      id: "oak_log",
      frames: 1,
      index: 8208
    },
    {
      id: "oak_log_top",
      frames: 1,
      index: 8224
    },
    {
      id: "oak_planks",
      frames: 1,
      index: 8240
    },
    {
      id: "oak_sapling",
      frames: 1,
      index: 8256
    },
    {
      id: "oak_trapdoor",
      frames: 1,
      index: 8272
    },
    {
      id: "observer_back",
      frames: 1,
      index: 8288
    },
    {
      id: "observer_back_on",
      frames: 1,
      index: 8304
    },
    {
      id: "observer_front",
      frames: 1,
      index: 8320
    },
    {
      id: "observer_side",
      frames: 1,
      index: 8336
    },
    {
      id: "observer_top",
      frames: 1,
      index: 8352
    },
    {
      id: "obsidian",
      frames: 1,
      index: 8368
    },
    {
      id: "orange_concrete",
      frames: 1,
      index: 8384
    },
    {
      id: "orange_concrete_powder",
      frames: 1,
      index: 8400
    },
    {
      id: "orange_glazed_terracotta",
      frames: 1,
      index: 8416
    },
    {
      id: "orange_shulker_box",
      frames: 1,
      index: 8432
    },
    {
      id: "orange_stained_glass",
      frames: 1,
      index: 8448
    },
    {
      id: "orange_stained_glass_pane_top",
      frames: 1,
      index: 8464
    },
    {
      id: "orange_terracotta",
      frames: 1,
      index: 8480
    },
    {
      id: "orange_tulip",
      frames: 1,
      index: 8496
    },
    {
      id: "orange_wool",
      frames: 1,
      index: 8512
    },
    {
      id: "oxeye_daisy",
      frames: 1,
      index: 8528
    },
    {
      id: "packed_ice",
      frames: 1,
      index: 8544
    },
    {
      id: "peony_bottom",
      frames: 1,
      index: 8560
    },
    {
      id: "peony_top",
      frames: 1,
      index: 8576
    },
    {
      id: "pink_concrete",
      frames: 1,
      index: 8592
    },
    {
      id: "pink_concrete_powder",
      frames: 1,
      index: 8608
    },
    {
      id: "pink_glazed_terracotta",
      frames: 1,
      index: 8624
    },
    {
      id: "pink_shulker_box",
      frames: 1,
      index: 8640
    },
    {
      id: "pink_stained_glass",
      frames: 1,
      index: 8656
    },
    {
      id: "pink_stained_glass_pane_top",
      frames: 1,
      index: 8672
    },
    {
      id: "pink_terracotta",
      frames: 1,
      index: 8688
    },
    {
      id: "pink_tulip",
      frames: 1,
      index: 8704
    },
    {
      id: "pink_wool",
      frames: 1,
      index: 8720
    },
    {
      id: "piston_bottom",
      frames: 1,
      index: 8736
    },
    {
      id: "piston_inner",
      frames: 1,
      index: 8752
    },
    {
      id: "piston_side",
      frames: 1,
      index: 8768
    },
    {
      id: "piston_top",
      frames: 1,
      index: 8784
    },
    {
      id: "piston_top_sticky",
      frames: 1,
      index: 8800
    },
    {
      id: "podzol_side",
      frames: 1,
      index: 8816
    },
    {
      id: "podzol_top",
      frames: 1,
      index: 8832
    },
    {
      id: "polished_andesite",
      frames: 1,
      index: 8848
    },
    {
      id: "polished_diorite",
      frames: 1,
      index: 8864
    },
    {
      id: "polished_granite",
      frames: 1,
      index: 8880
    },
    {
      id: "poppy",
      frames: 1,
      index: 8896
    },
    {
      id: "potatoes_stage0",
      frames: 1,
      index: 8912
    },
    {
      id: "potatoes_stage1",
      frames: 1,
      index: 8928
    },
    {
      id: "potatoes_stage2",
      frames: 1,
      index: 8944
    },
    {
      id: "potatoes_stage3",
      frames: 1,
      index: 8960
    },
    {
      id: "powered_rail",
      frames: 1,
      index: 8976
    },
    {
      id: "powered_rail_on",
      frames: 1,
      index: 8992
    },
    {
      id: "prismarine",
      frames: 4,
      index: 9008
    },
    {
      id: "prismarine_bricks",
      frames: 1,
      index: 9072
    },
    {
      id: "pumpkin_side",
      frames: 1,
      index: 9088
    },
    {
      id: "pumpkin_stem",
      frames: 1,
      index: 9104
    },
    {
      id: "pumpkin_top",
      frames: 1,
      index: 9120
    },
    {
      id: "purple_concrete",
      frames: 1,
      index: 9136
    },
    {
      id: "purple_concrete_powder",
      frames: 1,
      index: 9152
    },
    {
      id: "purple_glazed_terracotta",
      frames: 1,
      index: 9168
    },
    {
      id: "purple_shulker_box",
      frames: 1,
      index: 9184
    },
    {
      id: "purple_stained_glass",
      frames: 1,
      index: 9200
    },
    {
      id: "purple_stained_glass_pane_top",
      frames: 1,
      index: 9216
    },
    {
      id: "purple_terracotta",
      frames: 1,
      index: 9232
    },
    {
      id: "purple_wool",
      frames: 1,
      index: 9248
    },
    {
      id: "purpur_block",
      frames: 1,
      index: 9264
    },
    {
      id: "purpur_pillar",
      frames: 1,
      index: 9280
    },
    {
      id: "purpur_pillar_top",
      frames: 1,
      index: 9296
    },
    {
      id: "quartz_block_bottom",
      frames: 1,
      index: 9312
    },
    {
      id: "quartz_block_side",
      frames: 1,
      index: 9328
    },
    {
      id: "quartz_block_top",
      frames: 1,
      index: 9344
    },
    {
      id: "quartz_pillar",
      frames: 1,
      index: 9360
    },
    {
      id: "quartz_pillar_top",
      frames: 1,
      index: 9376
    },
    {
      id: "rail",
      frames: 1,
      index: 9392
    },
    {
      id: "rail_corner",
      frames: 1,
      index: 9408
    },
    {
      id: "red_concrete",
      frames: 1,
      index: 9424
    },
    {
      id: "red_concrete_powder",
      frames: 1,
      index: 9440
    },
    {
      id: "red_glazed_terracotta",
      frames: 1,
      index: 9456
    },
    {
      id: "red_mushroom",
      frames: 1,
      index: 9472
    },
    {
      id: "red_mushroom_block",
      frames: 1,
      index: 9488
    },
    {
      id: "red_nether_bricks",
      frames: 1,
      index: 9504
    },
    {
      id: "red_sand",
      frames: 1,
      index: 9520
    },
    {
      id: "red_sandstone",
      frames: 1,
      index: 9536
    },
    {
      id: "red_sandstone_bottom",
      frames: 1,
      index: 9552
    },
    {
      id: "red_sandstone_top",
      frames: 1,
      index: 9568
    },
    {
      id: "red_shulker_box",
      frames: 1,
      index: 9584
    },
    {
      id: "red_stained_glass",
      frames: 1,
      index: 9600
    },
    {
      id: "red_stained_glass_pane_top",
      frames: 1,
      index: 9616
    },
    {
      id: "red_terracotta",
      frames: 1,
      index: 9632
    },
    {
      id: "red_tulip",
      frames: 1,
      index: 9648
    },
    {
      id: "red_wool",
      frames: 1,
      index: 9664
    },
    {
      id: "redstone_block",
      frames: 1,
      index: 9680
    },
    {
      id: "redstone_dust_dot",
      frames: 1,
      index: 9696
    },
    {
      id: "redstone_dust_line0",
      frames: 1,
      index: 9712
    },
    {
      id: "redstone_dust_line1",
      frames: 1,
      index: 9728
    },
    {
      id: "redstone_dust_overlay",
      frames: 1,
      index: 9744
    },
    {
      id: "redstone_lamp",
      frames: 1,
      index: 9760
    },
    {
      id: "redstone_lamp_on",
      frames: 1,
      index: 9776
    },
    {
      id: "redstone_ore",
      frames: 1,
      index: 9792
    },
    {
      id: "redstone_torch",
      frames: 1,
      index: 9808
    },
    {
      id: "redstone_torch_off",
      frames: 1,
      index: 9824
    },
    {
      id: "repeater",
      frames: 1,
      index: 9840
    },
    {
      id: "repeater_on",
      frames: 1,
      index: 9856
    },
    {
      id: "repeating_command_block_back",
      frames: 4,
      index: 9872
    },
    {
      id: "repeating_command_block_conditional",
      frames: 4,
      index: 9936
    },
    {
      id: "repeating_command_block_front",
      frames: 4,
      index: 10000
    },
    {
      id: "repeating_command_block_side",
      frames: 4,
      index: 10064
    },
    {
      id: "rose_bush_bottom",
      frames: 1,
      index: 10128
    },
    {
      id: "rose_bush_top",
      frames: 1,
      index: 10144
    },
    {
      id: "sand",
      frames: 1,
      index: 10160
    },
    {
      id: "sandstone",
      frames: 1,
      index: 10176
    },
    {
      id: "sandstone_bottom",
      frames: 1,
      index: 10192
    },
    {
      id: "sandstone_top",
      frames: 1,
      index: 10208
    },
    {
      id: "sea_lantern",
      frames: 5,
      index: 10224
    },
    {
      id: "sea_pickle",
      frames: 1,
      index: 10304
    },
    {
      id: "seagrass",
      frames: 18,
      index: 10320
    },
    {
      id: "shulker_box",
      frames: 1,
      index: 10608
    },
    {
      id: "slime_block",
      frames: 1,
      index: 10624
    },
    {
      id: "snow",
      frames: 1,
      index: 10640
    },
    {
      id: "soul_sand",
      frames: 1,
      index: 10656
    },
    {
      id: "spawner",
      frames: 1,
      index: 10672
    },
    {
      id: "sponge",
      frames: 1,
      index: 10688
    },
    {
      id: "spruce_door_bottom",
      frames: 1,
      index: 10704
    },
    {
      id: "spruce_door_top",
      frames: 1,
      index: 10720
    },
    {
      id: "spruce_leaves",
      frames: 1,
      index: 10736
    },
    {
      id: "spruce_log",
      frames: 1,
      index: 10752
    },
    {
      id: "spruce_log_top",
      frames: 1,
      index: 10768
    },
    {
      id: "spruce_planks",
      frames: 1,
      index: 10784
    },
    {
      id: "spruce_sapling",
      frames: 1,
      index: 10800
    },
    {
      id: "spruce_trapdoor",
      frames: 1,
      index: 10816
    },
    {
      id: "stone",
      frames: 1,
      index: 10832
    },
    {
      id: "stone_bricks",
      frames: 1,
      index: 10848
    },
    {
      id: "stone_slab_side",
      frames: 1,
      index: 10864
    },
    {
      id: "stone_slab_top",
      frames: 1,
      index: 10880
    },
    {
      id: "stripped_acacia_log",
      frames: 1,
      index: 10896
    },
    {
      id: "stripped_acacia_log_top",
      frames: 1,
      index: 10912
    },
    {
      id: "stripped_birch_log",
      frames: 1,
      index: 10928
    },
    {
      id: "stripped_birch_log_top",
      frames: 1,
      index: 10944
    },
    {
      id: "stripped_dark_oak_log",
      frames: 1,
      index: 10960
    },
    {
      id: "stripped_dark_oak_log_top",
      frames: 1,
      index: 10976
    },
    {
      id: "stripped_jungle_log",
      frames: 1,
      index: 10992
    },
    {
      id: "stripped_jungle_log_top",
      frames: 1,
      index: 11008
    },
    {
      id: "stripped_oak_log",
      frames: 1,
      index: 11024
    },
    {
      id: "stripped_oak_log_top",
      frames: 1,
      index: 11040
    },
    {
      id: "stripped_spruce_log",
      frames: 1,
      index: 11056
    },
    {
      id: "stripped_spruce_log_top",
      frames: 1,
      index: 11072
    },
    {
      id: "structure_block",
      frames: 1,
      index: 11088
    },
    {
      id: "structure_block_corner",
      frames: 1,
      index: 11104
    },
    {
      id: "structure_block_data",
      frames: 1,
      index: 11120
    },
    {
      id: "structure_block_load",
      frames: 1,
      index: 11136
    },
    {
      id: "structure_block_save",
      frames: 1,
      index: 11152
    },
    {
      id: "sugar_cane",
      frames: 1,
      index: 11168
    },
    {
      id: "sunflower_back",
      frames: 1,
      index: 11184
    },
    {
      id: "sunflower_bottom",
      frames: 1,
      index: 11200
    },
    {
      id: "sunflower_front",
      frames: 1,
      index: 11216
    },
    {
      id: "sunflower_top",
      frames: 1,
      index: 11232
    },
    {
      id: "tall_grass_bottom",
      frames: 1,
      index: 11248
    },
    {
      id: "tall_grass_top",
      frames: 1,
      index: 11264
    },
    {
      id: "tall_seagrass_bottom",
      frames: 19,
      index: 11280
    },
    {
      id: "tall_seagrass_top",
      frames: 19,
      index: 11584
    },
    {
      id: "terracotta",
      frames: 1,
      index: 11888
    },
    {
      id: "tnt_bottom",
      frames: 1,
      index: 11904
    },
    {
      id: "tnt_side",
      frames: 1,
      index: 11920
    },
    {
      id: "tnt_top",
      frames: 1,
      index: 11936
    },
    {
      id: "torch",
      frames: 1,
      index: 11952
    },
    {
      id: "tripwire",
      frames: 1,
      index: 11968
    },
    {
      id: "tripwire_hook",
      frames: 1,
      index: 11984
    },
    {
      id: "tube_coral",
      frames: 1,
      index: 12000
    },
    {
      id: "tube_coral_block",
      frames: 1,
      index: 12016
    },
    {
      id: "tube_coral_fan",
      frames: 1,
      index: 12032
    },
    {
      id: "turtle_egg",
      frames: 1,
      index: 12048
    },
    {
      id: "turtle_egg_slightly_cracked",
      frames: 1,
      index: 12064
    },
    {
      id: "turtle_egg_very_cracked",
      frames: 1,
      index: 12080
    },
    {
      id: "vine",
      frames: 1,
      index: 12096
    },
    {
      id: "water_overlay",
      frames: 1,
      index: 12112
    },
    {
      id: "water_still",
      frames: 32,
      index: 12128
    },
    {
      id: "wet_sponge",
      frames: 1,
      index: 12640
    },
    {
      id: "wheat_stage0",
      frames: 1,
      index: 12656
    },
    {
      id: "wheat_stage1",
      frames: 1,
      index: 12672
    },
    {
      id: "wheat_stage2",
      frames: 1,
      index: 12688
    },
    {
      id: "wheat_stage3",
      frames: 1,
      index: 12704
    },
    {
      id: "wheat_stage4",
      frames: 1,
      index: 12720
    },
    {
      id: "wheat_stage5",
      frames: 1,
      index: 12736
    },
    {
      id: "wheat_stage6",
      frames: 1,
      index: 12752
    },
    {
      id: "wheat_stage7",
      frames: 1,
      index: 12768
    },
    {
      id: "white_concrete",
      frames: 1,
      index: 12784
    },
    {
      id: "white_concrete_powder",
      frames: 1,
      index: 12800
    },
    {
      id: "white_glazed_terracotta",
      frames: 1,
      index: 12816
    },
    {
      id: "white_shulker_box",
      frames: 1,
      index: 12832
    },
    {
      id: "white_stained_glass",
      frames: 1,
      index: 12848
    },
    {
      id: "white_stained_glass_pane_top",
      frames: 1,
      index: 12864
    },
    {
      id: "white_terracotta",
      frames: 1,
      index: 12880
    },
    {
      id: "white_tulip",
      frames: 1,
      index: 12896
    },
    {
      id: "white_wool",
      frames: 1,
      index: 12912
    },
    {
      id: "yellow_concrete",
      frames: 1,
      index: 12928
    },
    {
      id: "yellow_concrete_powder",
      frames: 1,
      index: 12944
    },
    {
      id: "yellow_glazed_terracotta",
      frames: 1,
      index: 12960
    },
    {
      id: "yellow_shulker_box",
      frames: 1,
      index: 12976
    },
    {
      id: "yellow_stained_glass",
      frames: 1,
      index: 12992
    },
    {
      id: "yellow_stained_glass_pane_top",
      frames: 1,
      index: 13008
    },
    {
      id: "yellow_terracotta",
      frames: 1,
      index: 13024
    },
    {
      id: "yellow_wool",
      frames: 1,
      index: 13040
    }
  ]
};

var data = [
  texture,
  version
];

exports.texture = texture;
exports.version = version;
exports.data = data;
/* texture Not a pure module */
