// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-creeper-character";

var name = "Minecraft Creeper Character";

var history = [
  "Created by CanadaCraft, template by BrickyBoy99.",
  "13 Sep 2020 NinjolasNJM - Updated to work with 1.8+ Skins.",
  "17 Jul 2021 M16 - Updated generator photo."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Tabs",
  "Folds",
  "Labels",
  "Action-Figure",
  "Action-Figure-Folds",
  "Action-Figure-Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [{
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  }];

var steve = TextureMap.MinecraftCharacter.steve;

function script(param) {
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  Generator.defineBooleanInput("Action Figure", false);
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var actionFigure = Generator.getBooleanInputValue("Action Figure");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideFrontRightPant = Generator.getBooleanInputValue("Hide Front Right Pant");
  var hideFrontLeftPant = Generator.getBooleanInputValue("Hide Front Left Pant");
  var hideBackRightPant = Generator.getBooleanInputValue("Hide Back Right Pant");
  var hideBackLeftPant = Generator.getBooleanInputValue("Hide Back Left Pant");
  Generator.defineRegionInput([
        164,
        110,
        260,
        196
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        196,
        340,
        196,
        196
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        62,
        471,
        134,
        116
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Front Right Pant", !hideFrontRightPant);
        }));
  Generator.defineRegionInput([
        121,
        589,
        134,
        116
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Front Left Pant", !hideFrontLeftPant);
        }));
  Generator.defineRegionInput([
        419,
        471,
        134,
        116
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Back Right Pant", !hideBackRightPant);
        }));
  Generator.defineRegionInput([
        367,
        589,
        134,
        116
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Back Left Pant", !hideBackLeftPant);
        }));
  Generator.drawImage("Background", [
        0,
        0
      ]);
  Generator.drawImage("Tabs", [
        0,
        0
      ]);
  Generator.drawTexture("Skin", steve.base.head.right, [
        164,
        174,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.head.front, [
        228,
        174,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.head.left, [
        292,
        174,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.head.back, [
        356,
        174,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.head.top, [
        228,
        110,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.head.bottom, [
        228,
        238,
        64,
        64
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.body.right, [
        196,
        372,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.body.front, [
        228,
        372,
        64,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.body.left, [
        292,
        372,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.body.back, [
        324,
        372,
        64,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.body.top, [
        228,
        340,
        64,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.body.bottom, [
        276,
        484,
        64,
        32
      ], "Vertical", undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.back, [
        62,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.right, [
        94,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.front, [
        126,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.left, [
        158,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.top, [
        126,
        471,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.bottom, [
        126,
        551,
        32,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.back, [
        121,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.right, [
        153,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.front, [
        185,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.left, [
        217,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.top, [
        185,
        589,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.bottom, [
        185,
        669,
        32,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.right, [
        483,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.front, [
        515,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.left, [
        419,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.back, [
        451,
        503,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.top, [
        451,
        471,
        32,
        32
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.rightLeg.bottom, [
        451,
        551,
        32,
        32
      ], "Vertical", undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.right, [
        431,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.front, [
        463,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.left, [
        367,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.back, [
        399,
        621,
        32,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.top, [
        399,
        589,
        32,
        32
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture("Skin", steve.base.leftLeg.bottom, [
        399,
        669,
        32,
        32
      ], "Vertical", undefined, undefined, 180.0, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTexture("Skin", steve.overlay.head.right, [
          164,
          174,
          64,
          64
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.head.front, [
          228,
          174,
          64,
          64
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.head.left, [
          292,
          174,
          64,
          64
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.head.back, [
          356,
          174,
          64,
          64
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.head.top, [
          228,
          110,
          64,
          64
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.head.bottom, [
          228,
          238,
          64,
          64
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  }
  if (!hideJacket) {
    Generator.drawTexture("Skin", steve.overlay.body.right, [
          196,
          372,
          32,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.body.front, [
          228,
          372,
          64,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.body.left, [
          292,
          372,
          32,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.body.back, [
          324,
          372,
          64,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.body.top, [
          228,
          340,
          64,
          32
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.body.bottom, [
          276,
          484,
          64,
          32
        ], "Vertical", undefined, undefined, 90.0, undefined, undefined);
  }
  if (!hideFrontRightPant) {
    Generator.drawTexture("Skin", steve.overlay.rightLeg.back, [
          62,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.right, [
          94,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.front, [
          126,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.left, [
          158,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.top, [
          126,
          471,
          32,
          32
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.bottom, [
          126,
          551,
          32,
          32
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  }
  if (!hideFrontLeftPant) {
    Generator.drawTexture("Skin", steve.overlay.leftLeg.back, [
          121,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.right, [
          153,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.front, [
          185,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.left, [
          217,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.top, [
          185,
          589,
          32,
          32
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.bottom, [
          185,
          669,
          32,
          32
        ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  }
  if (!hideBackRightPant) {
    Generator.drawTexture("Skin", steve.overlay.rightLeg.right, [
          483,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.front, [
          515,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.left, [
          419,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.back, [
          451,
          503,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.top, [
          451,
          471,
          32,
          32
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.rightLeg.bottom, [
          451,
          551,
          32,
          32
        ], "Vertical", undefined, undefined, 180.0, undefined, undefined);
  }
  if (!hideBackLeftPant) {
    Generator.drawTexture("Skin", steve.overlay.leftLeg.left, [
          431,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.front, [
          463,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.right, [
          367,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.back, [
          399,
          621,
          32,
          48
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.top, [
          399,
          589,
          32,
          32
        ], undefined, undefined, undefined, 180.0, undefined, undefined);
    Generator.drawTexture("Skin", steve.overlay.leftLeg.bottom, [
          399,
          669,
          32,
          32
        ], "Vertical", undefined, undefined, 180.0, undefined, undefined);
  }
  if (actionFigure) {
    Generator.drawTexture("Skin", steve.base.head.bottom, [
          44,
          254,
          64,
          96
        ], undefined, undefined, undefined, undefined, undefined, undefined);
    if (!hideHelmet) {
      Generator.drawTexture("Skin", steve.overlay.head.bottom, [
            44,
            254,
            64,
            96
          ], undefined, undefined, undefined, undefined, undefined, undefined);
    }
    Generator.drawImage("Action-Figure", [
          0,
          0
        ]);
    if (showFolds) {
      Generator.drawImage("Action-Figure-Folds", [
            0,
            0
          ]);
    }
    if (showLabels) {
      Generator.drawImage("Action-Figure-Labels", [
            0,
            0
          ]);
    }
    
  }
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
