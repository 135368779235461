// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-cat-character";

var name = "Minecraft Cat Character";

var history = [
  "Originally developed by dodecaphon.",
  "06 Feb 2015 lostminer - Add user variables.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "17 Sep 2020 NinjolasNJM - Updated to use 1.8+ skins."
];

var thumbnail = {
  url: require("./thumbnail/thumbnail.jpeg")
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [{
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  }];

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideLeftSleeve = Generator.getBooleanInputValue("Hide Left Sleeve");
  var hideRightSleeve = Generator.getBooleanInputValue("Hide Right Sleeve");
  var hideLeftPant = Generator.getBooleanInputValue("Hide Left Pant");
  var hideRightPant = Generator.getBooleanInputValue("Hide Right Pant");
  var tailType = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Tail Type")), 1);
  var cycleTailTypes = function (t) {
    return String(t === 4 ? 1 : t + 1 | 0);
  };
  Generator.defineRegionInput([
        40,
        33,
        160,
        112
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        40,
        193,
        160,
        224
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        340,
        232,
        64,
        64
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Sleeve", !hideLeftSleeve);
        }));
  Generator.defineRegionInput([
        251,
        232,
        64,
        64
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Sleeve", !hideRightSleeve);
        }));
  Generator.defineRegionInput([
        340,
        320,
        64,
        64
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Pant", !hideLeftPant);
        }));
  Generator.defineRegionInput([
        251,
        320,
        64,
        64
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Pant", !hideRightPant);
        }));
  Generator.defineRegionInput([
        469,
        283,
        104,
        83
      ], (function (param) {
          Generator.setSelectInputValue("Tail Type", cycleTailTypes(tailType));
        }));
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 40,
        y: 73,
        w: 40,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 80,
        y: 105,
        w: 40,
        h: 40
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 120,
        y: 73,
        w: 40,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 160,
        y: 73,
        w: 40,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 80,
        y: 33,
        w: 40,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 80,
        y: 73,
        w: 40,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 4,
        h: 3
      }, {
        x: 256,
        y: 84,
        w: 24,
        h: 12
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 4,
        h: 1
      }, {
        x: 256,
        y: 76,
        w: 24,
        h: 8
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 13,
        y: 13,
        w: 1,
        h: 3
      }, {
        x: 280,
        y: 84,
        w: 8,
        h: 12
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 15,
        w: 4,
        h: 1
      }, {
        x: 256,
        y: 96,
        w: 24,
        h: 8
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 10,
        y: 13,
        w: 1,
        h: 3
      }, {
        x: 248,
        y: 84,
        w: 8,
        h: 12
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 9,
        y: 1,
        w: 1,
        h: 1
      }, {
        x: 168,
        y: 168,
        w: 24,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 9,
        y: 0,
        w: 1,
        h: 1
      }, {
        x: 168,
        y: 152,
        w: 24,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 14,
        y: 1,
        w: 1,
        h: 1
      }, {
        x: 245,
        y: 169,
        w: 24,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 14,
        y: 0,
        w: 1,
        h: 1
      }, {
        x: 245,
        y: 153,
        w: 24,
        h: 16
      }, undefined, undefined, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 40,
          y: 73,
          w: 40,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 80,
          y: 105,
          w: 40,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 120,
          y: 73,
          w: 40,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 56,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 160,
          y: 73,
          w: 40,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 80,
          y: 33,
          w: 40,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 8,
          w: 8,
          h: 8
        }, {
          x: 80,
          y: 73,
          w: 40,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 4,
          h: 3
        }, {
          x: 256,
          y: 84,
          w: 24,
          h: 12
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 4,
          h: 1
        }, {
          x: 256,
          y: 76,
          w: 24,
          h: 8
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 45,
          y: 13,
          w: 1,
          h: 3
        }, {
          x: 280,
          y: 84,
          w: 8,
          h: 12
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 15,
          w: 4,
          h: 1
        }, {
          x: 256,
          y: 96,
          w: 24,
          h: 8
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 42,
          y: 13,
          w: 1,
          h: 3
        }, {
          x: 248,
          y: 84,
          w: 8,
          h: 12
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 41,
          y: 1,
          w: 1,
          h: 1
        }, {
          x: 168,
          y: 168,
          w: 24,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 41,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 168,
          y: 152,
          w: 24,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 46,
          y: 1,
          w: 1,
          h: 1
        }, {
          x: 245,
          y: 169,
          w: 24,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 46,
          y: 0,
          w: 1,
          h: 1
        }, {
          x: 245,
          y: 153,
          w: 24,
          h: 16
        }, undefined, undefined, undefined, undefined);
  }
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 251,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 20,
          w: 3,
          h: 12
        }, {
          x: 267,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 20,
          w: 4,
          h: 12
        }, {
          x: 283,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 20,
          w: 3,
          h: 12
        }, {
          x: 299,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 267,
          y: 232,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 267,
          y: 280,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 16,
          h: 12
        }, {
          x: 251,
          y: 248,
          w: 64,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 267,
          y: 232,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 267,
          y: 280,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideRightSleeve) {
    if (alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 251,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 36,
            w: 3,
            h: 12
          }, {
            x: 267,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 36,
            w: 4,
            h: 12
          }, {
            x: 283,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 36,
            w: 3,
            h: 12
          }, {
            x: 299,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 267,
            y: 232,
            w: 16,
            h: 16
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 267,
            y: 280,
            w: 16,
            h: 16
          }, "Vertical", undefined, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 16,
            h: 12
          }, {
            x: 251,
            y: 248,
            w: 64,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 4,
            h: 4
          }, {
            x: 267,
            y: 232,
            w: 16,
            h: 16
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 32,
            w: 4,
            h: 4
          }, {
            x: 267,
            y: 280,
            w: 16,
            h: 16
          }, "Vertical", undefined, undefined, undefined);
    }
  }
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 340,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 52,
          w: 3,
          h: 12
        }, {
          x: 356,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 52,
          w: 4,
          h: 12
        }, {
          x: 372,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 52,
          w: 3,
          h: 12
        }, {
          x: 388,
          y: 248,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 356,
          y: 232,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 356,
          y: 280,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 16,
          h: 12
        }, {
          x: 340,
          y: 248,
          w: 64,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 356,
          y: 232,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 356,
          y: 280,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideLeftSleeve) {
    if (alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 340,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: 356,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 52,
            w: 4,
            h: 12
          }, {
            x: 372,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 59,
            y: 52,
            w: 3,
            h: 12
          }, {
            x: 388,
            y: 248,
            w: 16,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 356,
            y: 232,
            w: 16,
            h: 16
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 356,
            y: 280,
            w: 16,
            h: 16
          }, "Vertical", undefined, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 16,
            h: 12
          }, {
            x: 340,
            y: 248,
            w: 64,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 4,
            h: 4
          }, {
            x: 356,
            y: 232,
            w: 16,
            h: 16
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 56,
            y: 48,
            w: 4,
            h: 4
          }, {
            x: 356,
            y: 280,
            w: 16,
            h: 16
          }, "Vertical", undefined, undefined, undefined);
    }
  }
  var ob = 88;
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 20,
        w: 16,
        h: 12
      }, {
        x: 251,
        y: ob + 248 | 0,
        w: 64,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 267,
        y: ob + 232 | 0,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 267,
        y: ob + 280 | 0,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  if (!hideRightPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 36,
          w: 16,
          h: 12
        }, {
          x: 251,
          y: ob + 248 | 0,
          w: 64,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 267,
          y: ob + 232 | 0,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 267,
          y: ob + 280 | 0,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 52,
        w: 16,
        h: 12
      }, {
        x: 340,
        y: ob + 248 | 0,
        w: 64,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 356,
        y: ob + 232 | 0,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 356,
        y: ob + 280 | 0,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  if (!hideLeftPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 52,
          w: 16,
          h: 12
        }, {
          x: 340,
          y: ob + 248 | 0,
          w: 64,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 356,
          y: ob + 232 | 0,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 356,
          y: ob + 280 | 0,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  var drawTail = function (sx, sy, isArm) {
    if (isArm && alexModel) {
      Generator.drawTextureLegacy("Skin", {
            x: sx,
            y: sy + 4 | 0,
            w: 4,
            h: 6
          }, {
            x: 469,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 4 | 0,
            y: sy + 4 | 0,
            w: 3,
            h: 6
          }, {
            x: 477,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 7 | 0,
            y: sy + 4 | 0,
            w: 4,
            h: 6
          }, {
            x: 485,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 11 | 0,
            y: sy + 4 | 0,
            w: 3,
            h: 6
          }, {
            x: 493,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx,
            y: sy + 10 | 0,
            w: 4,
            h: 6
          }, {
            x: 541,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 4 | 0,
            y: sy + 10 | 0,
            w: 3,
            h: 6
          }, {
            x: 549,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 7 | 0,
            y: sy + 10 | 0,
            w: 4,
            h: 6
          }, {
            x: 557,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 11 | 0,
            y: sy + 10 | 0,
            w: 3,
            h: 6
          }, {
            x: 565,
            y: 294,
            w: 8,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 5 | 0,
            y: sy + 1 | 0,
            w: 1,
            h: 1
          }, {
            x: 477,
            y: 358,
            w: 8,
            h: 8
          }, undefined, undefined, undefined, undefined);
      return Generator.drawTextureLegacy("Skin", {
                  x: sx + 9 | 0,
                  y: sy + 1 | 0,
                  w: 1,
                  h: 1
                }, {
                  x: 549,
                  y: 358,
                  w: 8,
                  h: 8
                }, undefined, undefined, undefined, undefined);
    } else {
      Generator.drawTextureLegacy("Skin", {
            x: sx,
            y: sy + 4 | 0,
            w: 16,
            h: 6
          }, {
            x: 469,
            y: 294,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx,
            y: sy + 10 | 0,
            w: 16,
            h: 6
          }, {
            x: 541,
            y: 294,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: sx + 5 | 0,
            y: sy + 1 | 0,
            w: 1,
            h: 1
          }, {
            x: 477,
            y: 358,
            w: 8,
            h: 8
          }, undefined, undefined, undefined, undefined);
      return Generator.drawTextureLegacy("Skin", {
                  x: sx + 9 | 0,
                  y: sy + 1 | 0,
                  w: 1,
                  h: 1
                }, {
                  x: 549,
                  y: 358,
                  w: 8,
                  h: 8
                }, undefined, undefined, undefined, undefined);
    }
  };
  switch (tailType) {
    case 1 :
        drawTail(0, 16, false);
        if (!hideRightPant) {
          drawTail(0, 32, false);
        }
        break;
    case 2 :
        drawTail(16, 48, false);
        if (!hideLeftPant) {
          drawTail(0, 48, false);
        }
        break;
    case 3 :
        drawTail(40, 16, true);
        if (!hideRightSleeve) {
          drawTail(40, 32, true);
        }
        break;
    case 4 :
        drawTail(32, 48, true);
        if (!hideLeftSleeve) {
          drawTail(48, 48, true);
        }
        break;
    default:
      
  }
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 40,
        y: 241,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 88,
        y: 241,
        w: 32,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 120,
        y: 241,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 168,
        y: 241,
        w: 32,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 88,
        y: 193,
        w: 32,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 88,
        y: 369,
        w: 32,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 40,
          y: 241,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 88,
          y: 241,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 120,
          y: 241,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 168,
          y: 241,
          w: 32,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 88,
          y: 193,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 88,
          y: 369,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
  }
  Generator.drawImage("Background", [
        0,
        0
      ]);
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
