// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Generator_Icon = require("./Generator_Icon.bs.js");
var Generator_ButtonStyles = require("./Generator_ButtonStyles.bs.js");

function getDisabled(state) {
  return state !== "Ready";
}

function getContent(state, children) {
  if (state === "Ready" || state === "Disabled") {
    return children;
  } else {
    return React.createElement(Generator_Icon.ProgressSpin.make, {
                color: "White"
              });
  }
}

function Generator_Buttons$Button(Props) {
  var state = Props.state;
  var onClick = Props.onClick;
  var colorOpt = Props.color;
  var sizeOpt = Props.size;
  var fullOpt = Props.full;
  var titleOpt = Props.title;
  var children = Props.children;
  var idOpt = Props.id;
  var color = colorOpt !== undefined ? colorOpt : "Gray";
  var size = sizeOpt !== undefined ? sizeOpt : "Base";
  var full = fullOpt !== undefined ? fullOpt : false;
  var title = titleOpt !== undefined ? titleOpt : "";
  var id = idOpt !== undefined ? idOpt : "";
  var className = Generator_ButtonStyles.makeClassName(state, color, size, full);
  return React.createElement("button", {
              className: className,
              id: id,
              title: title,
              disabled: state !== "Ready",
              type: "button",
              onClick: onClick
            }, getContent(state, children));
}

var Button = {
  make: Generator_Buttons$Button
};

function makeIcon(icon) {
  if (icon === "ChevronDown") {
    return React.createElement(Generator_Icon.ChevronDown.make, {
                color: "White"
              });
  } else if (icon === "ChevronUp") {
    return React.createElement(Generator_Icon.ChevronUp.make, {
                color: "White"
              });
  } else if (icon === "X") {
    return React.createElement(Generator_Icon.X.make, {
                color: "White"
              });
  } else if (icon === "ChevronDoubleUp") {
    return React.createElement(Generator_Icon.ChevronDoubleUp.make, {
                color: "White"
              });
  } else if (icon === "Trash") {
    return React.createElement(Generator_Icon.Trash.make, {
                color: "White"
              });
  } else if (icon === "Menu") {
    return React.createElement(Generator_Icon.Menu.make, {
                color: "White"
              });
  } else {
    return React.createElement(Generator_Icon.ChevronDoubleDown.make, {
                color: "White"
              });
  }
}

function Generator_Buttons$IconButton(Props) {
  var title = Props.title;
  var onClick = Props.onClick;
  var icon = Props.icon;
  var color = Props.color;
  var state = Props.state;
  return React.createElement(Generator_Buttons$Button, {
              state: state,
              onClick: onClick,
              color: color,
              size: "Small",
              title: title,
              children: makeIcon(icon)
            });
}

var IconButton = {
  makeIcon: makeIcon,
  make: Generator_Buttons$IconButton
};

var Icon;

var ButtonStyles;

exports.Icon = Icon;
exports.ButtonStyles = ButtonStyles;
exports.getDisabled = getDisabled;
exports.getContent = getContent;
exports.Button = Button;
exports.IconButton = IconButton;
/* react Not a pure module */
