// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-cow-character";

var name = "Minecraft Cow Character";

var history = [
  "Originally created by Poekoko.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "29 Sep 2020 NinjolasNJM - Updated to work with 1.8+ Skins.",
  "17 Jul 2021 M16 - Updated generator photo."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [{
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  }];

var steve = TextureMap.MinecraftCharacterLegacy.steve;

var alex = TextureMap.MinecraftCharacterLegacy.alex;

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideLeftSleeve = Generator.getBooleanInputValue("Hide Left Sleeve");
  var hideRightSleeve = Generator.getBooleanInputValue("Hide Right Sleeve");
  var hideLeftPant = Generator.getBooleanInputValue("Hide Left Pant");
  var hideRightPant = Generator.getBooleanInputValue("Hide Right Pant");
  Generator.defineRegionInput([
        25,
        24,
        224,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        194,
        115,
        352,
        304
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        421,
        394,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Sleeve", !hideLeftSleeve);
        }));
  Generator.defineRegionInput([
        46,
        394,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Sleeve", !hideRightSleeve);
        }));
  Generator.defineRegionInput([
        421,
        586,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Pant", !hideLeftPant);
        }));
  Generator.defineRegionInput([
        46,
        586,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Pant", !hideRightPant);
        }));
  Generator.drawImage("Background", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Skin", steve.base.head.right, {
        x: 25,
        y: 72,
        w: 48,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.head.front, {
        x: 73,
        y: 72,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.head.left, {
        x: 137,
        y: 72,
        w: 48,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.head.back, {
        x: 185,
        y: 72,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.head.top, {
        x: 73,
        y: 24,
        w: 64,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.head.bottom, {
        x: 73,
        y: 136,
        w: 64,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", alex.base.rightArm.right, {
          x: 46,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.rightArm.front, {
          x: 78,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.rightArm.left, {
          x: 110,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.rightArm.back, {
          x: 142,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.rightArm.top, {
          x: 78,
          y: 394,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.rightArm.bottom, {
          x: 78,
          y: 522,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.leftArm.right, {
          x: 453,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.leftArm.front, {
          x: 485,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.leftArm.left, {
          x: 517,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.leftArm.back, {
          x: 421,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.leftArm.top, {
          x: 485,
          y: 394,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", alex.base.leftArm.bottom, {
          x: 485,
          y: 522,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
  } else {
    Generator.drawTextureLegacy("Skin", steve.base.rightArm.right, {
          x: 46,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.rightArm.front, {
          x: 78,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.rightArm.left, {
          x: 110,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.rightArm.back, {
          x: 142,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.rightArm.top, {
          x: 78,
          y: 394,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.rightArm.bottom, {
          x: 78,
          y: 522,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.leftArm.right, {
          x: 453,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.leftArm.front, {
          x: 485,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.leftArm.left, {
          x: 517,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.leftArm.back, {
          x: 421,
          y: 426,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.leftArm.top, {
          x: 485,
          y: 394,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.base.leftArm.bottom, {
          x: 485,
          y: 522,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", steve.base.body.right, {
        x: 194,
        y: 195,
        w: 80,
        h: 144
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.body.front, {
        x: 274,
        y: 195,
        w: 96,
        h: 144
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.body.left, {
        x: 370,
        y: 195,
        w: 80,
        h: 144
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.body.back, {
        x: 450,
        y: 195,
        w: 96,
        h: 144
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.body.top, {
        x: 274,
        y: 115,
        w: 96,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.body.bottom, {
        x: 274,
        y: 339,
        w: 96,
        h: 80
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.rightLeg.right, {
        x: 46,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.rightLeg.front, {
        x: 78,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.rightLeg.left, {
        x: 110,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.rightLeg.back, {
        x: 142,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.rightLeg.top, {
        x: 78,
        y: 586,
        w: 32,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.rightLeg.bottom, {
        x: 78,
        y: 714,
        w: 32,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.leftLeg.right, {
        x: 453,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.leftLeg.front, {
        x: 485,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.leftLeg.left, {
        x: 517,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.leftLeg.back, {
        x: 421,
        y: 618,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.leftLeg.top, {
        x: 485,
        y: 586,
        w: 32,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", steve.base.leftLeg.bottom, {
        x: 485,
        y: 714,
        w: 32,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", steve.overlay.head.right, {
          x: 25,
          y: 72,
          w: 48,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.head.front, {
          x: 73,
          y: 72,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.head.left, {
          x: 137,
          y: 72,
          w: 48,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.head.back, {
          x: 185,
          y: 72,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.head.top, {
          x: 73,
          y: 24,
          w: 64,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.head.bottom, {
          x: 73,
          y: 136,
          w: 64,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
  }
  if (alexModel) {
    if (!hideRightSleeve) {
      Generator.drawTextureLegacy("Skin", alex.overlay.rightArm.right, {
            x: 46,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.rightArm.front, {
            x: 78,
            y: 426,
            w: 24,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.rightArm.left, {
            x: 102,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.rightArm.back, {
            x: 134,
            y: 426,
            w: 24,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.rightArm.top, {
            x: 78,
            y: 394,
            w: 24,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.rightArm.bottom, {
            x: 78,
            y: 522,
            w: 24,
            h: 32
          }, undefined, undefined, undefined, undefined);
    }
    if (!hideLeftSleeve) {
      Generator.drawTextureLegacy("Skin", alex.overlay.leftArm.right, {
            x: 453,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.leftArm.front, {
            x: 485,
            y: 426,
            w: 24,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.leftArm.left, {
            x: 509,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.leftArm.back, {
            x: 429,
            y: 426,
            w: 24,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.leftArm.top, {
            x: 485,
            y: 394,
            w: 24,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", alex.overlay.leftArm.bottom, {
            x: 485,
            y: 522,
            w: 24,
            h: 32
          }, undefined, undefined, undefined, undefined);
    }
    
  } else {
    if (!hideRightSleeve) {
      Generator.drawTextureLegacy("Skin", steve.overlay.rightArm.right, {
            x: 46,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.rightArm.front, {
            x: 78,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.rightArm.left, {
            x: 110,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.rightArm.back, {
            x: 142,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.rightArm.top, {
            x: 78,
            y: 394,
            w: 32,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.rightArm.bottom, {
            x: 78,
            y: 522,
            w: 32,
            h: 32
          }, "Vertical", undefined, undefined, undefined);
    }
    if (!hideLeftSleeve) {
      Generator.drawTextureLegacy("Skin", steve.overlay.leftArm.right, {
            x: 453,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.leftArm.front, {
            x: 485,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.leftArm.left, {
            x: 517,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.leftArm.back, {
            x: 421,
            y: 426,
            w: 32,
            h: 96
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.leftArm.top, {
            x: 485,
            y: 394,
            w: 32,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", steve.overlay.leftArm.bottom, {
            x: 485,
            y: 522,
            w: 32,
            h: 32
          }, "Vertical", undefined, undefined, undefined);
    }
    
  }
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", steve.overlay.body.right, {
          x: 194,
          y: 195,
          w: 80,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.body.front, {
          x: 274,
          y: 195,
          w: 96,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.body.left, {
          x: 370,
          y: 195,
          w: 80,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.body.back, {
          x: 450,
          y: 195,
          w: 96,
          h: 144
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.body.top, {
          x: 274,
          y: 115,
          w: 96,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.body.bottom, {
          x: 274,
          y: 339,
          w: 96,
          h: 80
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideRightPant) {
    Generator.drawTextureLegacy("Skin", steve.overlay.rightLeg.right, {
          x: 46,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.rightLeg.front, {
          x: 78,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.rightLeg.left, {
          x: 110,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.rightLeg.back, {
          x: 142,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.rightLeg.top, {
          x: 78,
          y: 586,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.rightLeg.bottom, {
          x: 78,
          y: 714,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
  }
  if (!hideLeftPant) {
    Generator.drawTextureLegacy("Skin", steve.overlay.leftLeg.right, {
          x: 453,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.leftLeg.front, {
          x: 485,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.leftLeg.left, {
          x: 517,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.leftLeg.back, {
          x: 421,
          y: 618,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.leftLeg.top, {
          x: 485,
          y: 586,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", steve.overlay.leftLeg.bottom, {
          x: 485,
          y: 714,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
  }
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
