// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var texture_url = require("./Texture_minecraft_1_7_10_items.png");

var texture = {
  id: "minecraft-1.7.10-items",
  url: texture_url,
  standardWidth: 512,
  standardHeight: 1120
};

var tiles = [
  {
    name: "apple",
    x: 0,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "apple_golden",
    x: 16,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "arrow",
    x: 32,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bed",
    x: 48,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beef_cooked",
    x: 64,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beef_raw",
    x: 80,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "blaze_powder",
    x: 96,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "blaze_rod",
    x: 112,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "boat",
    x: 128,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bone",
    x: 144,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "book_enchanted",
    x: 160,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "book_normal",
    x: 176,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "book_writable",
    x: 192,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "book_written",
    x: 208,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_pulling_0",
    x: 224,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_pulling_1",
    x: 240,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_pulling_2",
    x: 256,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_standby",
    x: 272,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bowl",
    x: 288,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bread",
    x: 304,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brewing_stand",
    x: 320,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brick",
    x: 336,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bucket_empty",
    x: 352,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bucket_lava",
    x: 368,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bucket_milk",
    x: 384,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bucket_water",
    x: 400,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cake",
    x: 416,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrot",
    x: 432,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrot_golden",
    x: 448,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrot_on_a_stick",
    x: 464,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cauldron",
    x: 480,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_boots",
    x: 496,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_chestplate",
    x: 0,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_helmet",
    x: 16,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_leggings",
    x: 32,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "charcoal",
    x: 48,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chicken_cooked",
    x: 64,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chicken_raw",
    x: 80,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clay_ball",
    x: 96,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock",
    x: 336,
    y: 96,
    width: 16,
    height: 1024
  },
  {
    name: "coal",
    x: 112,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "comparator",
    x: 128,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "compass",
    x: 320,
    y: 96,
    width: 16,
    height: 512
  },
  {
    name: "cookie",
    x: 144,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond",
    x: 160,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_axe",
    x: 176,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_boots",
    x: 192,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_chestplate",
    x: 208,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_helmet",
    x: 224,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_hoe",
    x: 240,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_horse_armor",
    x: 256,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_leggings",
    x: 272,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_pickaxe",
    x: 288,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_shovel",
    x: 304,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "diamond_sword",
    x: 320,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "door_iron",
    x: 336,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "door_wood",
    x: 352,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_black",
    x: 368,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_blue",
    x: 384,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_brown",
    x: 400,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_cyan",
    x: 416,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_gray",
    x: 432,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_green",
    x: 448,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_light_blue",
    x: 464,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_lime",
    x: 480,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_magenta",
    x: 496,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_orange",
    x: 0,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_pink",
    x: 16,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_purple",
    x: 32,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_red",
    x: 48,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_silver",
    x: 64,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_white",
    x: 80,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "dye_powder_yellow",
    x: 96,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "egg",
    x: 112,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "emerald",
    x: 128,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_boots",
    x: 144,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_chestplate",
    x: 160,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_helmet",
    x: 176,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_leggings",
    x: 192,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "ender_eye",
    x: 208,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "ender_pearl",
    x: 224,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "experience_bottle",
    x: 240,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "feather",
    x: 256,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fireball",
    x: 272,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fireworks",
    x: 288,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fireworks_charge",
    x: 304,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fireworks_charge_overlay",
    x: 320,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fish_clownfish_raw",
    x: 336,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fish_cod_cooked",
    x: 352,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fish_cod_raw",
    x: 368,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fish_pufferfish_raw",
    x: 384,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fish_salmon_cooked",
    x: 400,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fish_salmon_raw",
    x: 416,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fishing_rod_cast",
    x: 432,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "fishing_rod_uncast",
    x: 448,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "flint",
    x: 464,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "flint_and_steel",
    x: 480,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "flower_pot",
    x: 496,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "ghast_tear",
    x: 0,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "glowstone_dust",
    x: 16,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_axe",
    x: 32,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_boots",
    x: 48,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_chestplate",
    x: 64,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_helmet",
    x: 80,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_hoe",
    x: 96,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_horse_armor",
    x: 112,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_ingot",
    x: 128,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_leggings",
    x: 144,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_nugget",
    x: 160,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_pickaxe",
    x: 176,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_shovel",
    x: 192,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gold_sword",
    x: 208,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "gunpowder",
    x: 224,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "hopper",
    x: 240,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_axe",
    x: 256,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_boots",
    x: 272,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_chestplate",
    x: 288,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_helmet",
    x: 304,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_hoe",
    x: 320,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_horse_armor",
    x: 336,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_ingot",
    x: 352,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_leggings",
    x: 368,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_pickaxe",
    x: 384,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_shovel",
    x: 400,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "iron_sword",
    x: 416,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "item_frame",
    x: 432,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "lead",
    x: 448,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "leather",
    x: 464,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "leather_boots",
    x: 480,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "leather_boots_overlay",
    x: 496,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "leather_chestplate",
    x: 0,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "leather_chestplate_overlay",
    x: 16,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "leather_helmet",
    x: 32,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "leather_helmet_overlay",
    x: 48,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "leather_leggings",
    x: 64,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "leather_leggings_overlay",
    x: 80,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "magma_cream",
    x: 96,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "map_empty",
    x: 112,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "map_filled",
    x: 128,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "melon",
    x: 144,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "melon_speckled",
    x: 160,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "minecart_chest",
    x: 176,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "minecart_command_block",
    x: 192,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "minecart_furnace",
    x: 208,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "minecart_hopper",
    x: 224,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "minecart_normal",
    x: 240,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "minecart_tnt",
    x: 256,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_stew",
    x: 272,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "name_tag",
    x: 288,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "nether_star",
    x: 304,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart",
    x: 320,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "netherbrick",
    x: 336,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "painting",
    x: 352,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "paper",
    x: 368,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "porkchop_cooked",
    x: 384,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "porkchop_raw",
    x: 400,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potato",
    x: 416,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potato_baked",
    x: 432,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potato_poisonous",
    x: 448,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potion_bottle_drinkable",
    x: 464,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potion_bottle_empty",
    x: 480,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potion_bottle_splash",
    x: 496,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "potion_overlay",
    x: 0,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_pie",
    x: 16,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "quartz",
    x: 32,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "quiver",
    x: 48,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_11",
    x: 64,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_13",
    x: 80,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_blocks",
    x: 96,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_cat",
    x: 112,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_chirp",
    x: 128,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_far",
    x: 144,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_mall",
    x: 160,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_mellohi",
    x: 176,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_stal",
    x: 192,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_strad",
    x: 208,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_wait",
    x: 224,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "record_ward",
    x: 240,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "redstone_dust",
    x: 256,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "reeds",
    x: 272,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "repeater",
    x: 288,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "rotten_flesh",
    x: 304,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "ruby",
    x: 320,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "saddle",
    x: 336,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "seeds_melon",
    x: 352,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "seeds_pumpkin",
    x: 368,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "seeds_wheat",
    x: 384,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "shears",
    x: 400,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "sign",
    x: 416,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "skull_creeper",
    x: 432,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "skull_skeleton",
    x: 448,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "skull_steve",
    x: 464,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "skull_wither",
    x: 480,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "skull_zombie",
    x: 496,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "slimeball",
    x: 0,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "snowball",
    x: 16,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "spawn_egg",
    x: 32,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "spawn_egg_overlay",
    x: 48,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "spider_eye",
    x: 64,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "spider_eye_fermented",
    x: 80,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "stick",
    x: 96,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "stone_axe",
    x: 112,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "stone_hoe",
    x: 128,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "stone_pickaxe",
    x: 144,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "stone_shovel",
    x: 160,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "stone_sword",
    x: 176,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "string",
    x: 192,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "sugar",
    x: 208,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "wheat",
    x: 224,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "wood_axe",
    x: 240,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "wood_hoe",
    x: 256,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "wood_pickaxe",
    x: 272,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "wood_shovel",
    x: 288,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "wood_sword",
    x: 304,
    y: 96,
    width: 16,
    height: 16
  }
];

var data = [
  texture,
  tiles
];

exports.texture = texture;
exports.tiles = tiles;
exports.data = data;
/* texture Not a pure module */
