// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Generator_TextureFrame = require("../../builder/modules/Generator_TextureFrame.bs.js");
var Texture_minecraft_1_13_2_items = require("../../textures/Texture_minecraft_1_13_2_items.bs.js");
var Texture_minecraft_1_18_1_items = require("../../textures/Texture_minecraft_1_18_1_items.bs.js");
var Texture_minecraft_1_7_10_items = require("../../textures/Texture_minecraft_1_7_10_items.bs.js");
var Texture_minecraft_1_13_2_blocks = require("../../textures/Texture_minecraft_1_13_2_blocks.bs.js");
var Texture_minecraft_1_18_1_blocks = require("../../textures/Texture_minecraft_1_18_1_blocks.bs.js");
var Texture_minecraft_1_7_10_blocks = require("../../textures/Texture_minecraft_1_7_10_blocks.bs.js");

var definitions = [
  [
    Texture_minecraft_1_7_10_blocks.data,
    16
  ],
  [
    Texture_minecraft_1_7_10_items.data,
    16
  ],
  [
    Texture_minecraft_1_13_2_blocks.data,
    16
  ],
  [
    Texture_minecraft_1_13_2_items.data,
    16
  ],
  [
    Texture_minecraft_1_18_1_blocks.data,
    16
  ],
  [
    Texture_minecraft_1_18_1_items.data,
    16
  ]
];

var textureVersions = Belt_Array.map(definitions, (function (definition) {
        var data = definition[0];
        var frames = Generator_TextureFrame.tilesToFrames(data[1], definition[1]);
        return {
                textureDef: data[0],
                frames: frames
              };
      }));

var allTextureDefs = Belt_Array.map(textureVersions, (function (param) {
        return param.textureDef;
      }));

var versionIds = Belt_Array.reverse(Belt_Array.map(textureVersions, (function (param) {
            return param.textureDef.id;
          })));

function findVersion(versionId) {
  return Belt_Array.getBy(textureVersions, (function (param) {
                return param.textureDef.id === versionId;
              }));
}

exports.definitions = definitions;
exports.textureVersions = textureVersions;
exports.allTextureDefs = allTextureDefs;
exports.versionIds = versionIds;
exports.findVersion = findVersion;
/* textureVersions Not a pure module */
