// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Core__Promise = require("@rescript/core/src/Core__Promise.bs.js");
var Generator_Texture = require("./Generator_Texture.bs.js");
var Generator_ImageFactory = require("./Generator_ImageFactory.bs.js");

function imageDefToImage(imageDef) {
  return Generator_ImageFactory.makeFromUrl(imageDef.url).then(function (image) {
              return [
                      imageDef.id,
                      image
                    ];
            });
}

function textureDefToTexture(textureDef) {
  return Generator_Texture.makeFromUrl(textureDef.url, textureDef.standardWidth, textureDef.standardHeight).then(function (texture) {
              return [
                      textureDef.id,
                      texture
                    ];
            });
}

function loadResources(generatorDef) {
  var imagePromises = Promise.all(generatorDef.images.map(imageDefToImage));
  var texturePromises = Promise.all(generatorDef.textures.map(textureDefToTexture));
  return Core__Promise.$$catch(imagePromises.then(function (images) {
                  return texturePromises.then(function (textures) {
                              return {
                                      TAG: /* Ok */0,
                                      _0: [
                                        images,
                                        textures
                                      ]
                                    };
                            });
                }), (function (exn) {
                console.log(exn);
                return Promise.resolve({
                            TAG: /* Error */1,
                            _0: "Failed to load resources"
                          });
              }));
}

var Builder;

exports.Builder = Builder;
exports.imageDefToImage = imageDefToImage;
exports.textureDefToTexture = textureDefToTexture;
exports.loadResources = loadResources;
/* No side effect */
