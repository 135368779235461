// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-villager-character";

var name = "Minecraft Villager Character";

var history = [
  "Originally developed by Boe6Eod7Nty.",
  "06 Feb 2015 lostminer: Add user variables.",
  "13 Feb 2015 lostminer: Update to use new version of generator.",
  "18 Sep 2020 NinjolasNJM: Updated to use 1.8+ skins."
];

var thumbnail = {
  url: require("./thumbnail/v3-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [{
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  }];

var steve = TextureMap.MinecraftCharacterLegacy.steve;

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var hideHelmet = Generator.getBooleanInputValue("Hide Helmet");
  var hideJacket = Generator.getBooleanInputValue("Hide Jacket");
  var hideLeftSleeve = Generator.getBooleanInputValue("Hide Left Sleeve");
  var hideRightSleeve = Generator.getBooleanInputValue("Hide Right Sleeve");
  var hideLeftPant = Generator.getBooleanInputValue("Hide Left Pant");
  var hideRightPant = Generator.getBooleanInputValue("Hide Right Pant");
  Generator.defineRegionInput([
        22,
        10,
        256,
        208
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Helmet", !hideHelmet);
        }));
  Generator.defineRegionInput([
        325,
        26,
        224,
        192
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Jacket", !hideJacket);
        }));
  Generator.defineRegionInput([
        173,
        419,
        128,
        128
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Sleeve", !hideLeftSleeve);
        }));
  Generator.defineRegionInput([
        21,
        419,
        128,
        128
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Sleeve", !hideRightSleeve);
        }));
  Generator.defineRegionInput([
        178,
        240,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Left Pant", !hideLeftPant);
        }));
  Generator.defineRegionInput([
        22,
        240,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Hide Right Pant", !hideRightPant);
        }));
  Generator.drawTextureLegacy("Skin", {
        x: 11,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 381,
        y: 446,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 11,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 365,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 11,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 397,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 381,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 413,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 13,
        w: 1,
        h: 1
      }, {
        x: 381,
        y: 494,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 11,
        y: 14,
        w: 1,
        h: 1
      }, {
        x: 483,
        y: 446,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 11,
        y: 14,
        w: 1,
        h: 1
      }, {
        x: 467,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 11,
        y: 14,
        w: 1,
        h: 1
      }, {
        x: 499,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 14,
        w: 1,
        h: 1
      }, {
        x: 483,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 14,
        w: 1,
        h: 1
      }, {
        x: 515,
        y: 462,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 14,
        w: 1,
        h: 1
      }, {
        x: 483,
        y: 494,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 8,
        w: 32,
        h: 8
      }, {
        x: 22,
        y: 74,
        w: 256,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 86,
        y: 10,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 86,
        y: 154,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  if (!hideHelmet) {
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 381,
          y: 446,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 365,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 397,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 381,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 413,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 13,
          w: 1,
          h: 1
        }, {
          x: 381,
          y: 494,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 14,
          w: 1,
          h: 1
        }, {
          x: 483,
          y: 446,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 14,
          w: 1,
          h: 1
        }, {
          x: 467,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 14,
          w: 1,
          h: 1
        }, {
          x: 499,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 14,
          w: 1,
          h: 1
        }, {
          x: 483,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 14,
          w: 1,
          h: 1
        }, {
          x: 515,
          y: 462,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 14,
          w: 1,
          h: 1
        }, {
          x: 483,
          y: 494,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 8,
          w: 32,
          h: 8
        }, {
          x: 22,
          y: 74,
          w: 256,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 86,
          y: 10,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 86,
          y: 154,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 373,
        y: 26,
        w: 64,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 373,
        y: 170,
        w: 64,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 325,
        y: 74,
        w: 48,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 437,
        y: 74,
        w: 48,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 373,
        y: 74,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 485,
        y: 74,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 373,
          y: 26,
          w: 64,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 32,
          w: 8,
          h: 4
        }, {
          x: 373,
          y: 170,
          w: 64,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 325,
          y: 74,
          w: 48,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 437,
          y: 74,
          w: 48,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 373,
          y: 74,
          w: 64,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 485,
          y: 74,
          w: 64,
          h: 96
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 0,
        y: 20,
        w: 12,
        h: 12
      }, {
        x: 54,
        y: 272,
        w: 96,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 12,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 22,
        y: 272,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 4,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 86,
        y: 240,
        w: 32,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 8,
        y: 16,
        w: 4,
        h: 4
      }, {
        x: 86,
        y: 368,
        w: 32,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  if (!hideRightPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 36,
          w: 12,
          h: 12
        }, {
          x: 54,
          y: 272,
          w: 96,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 12,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 22,
          y: 272,
          w: 32,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 86,
          y: 240,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 32,
          w: 4,
          h: 4
        }, {
          x: 86,
          y: 368,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 52,
        w: 16,
        h: 12
      }, {
        x: 178,
        y: 272,
        w: 128,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 210,
        y: 240,
        w: 32,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 24,
        y: 48,
        w: 4,
        h: 4
      }, {
        x: 210,
        y: 368,
        w: 32,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  if (!hideLeftPant) {
    Generator.drawTextureLegacy("Skin", {
          x: 0,
          y: 52,
          w: 16,
          h: 12
        }, {
          x: 178,
          y: 272,
          w: 128,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 4,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 210,
          y: 240,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 8,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 210,
          y: 368,
          w: 32,
          h: 32
        }, "Vertical", undefined, undefined, undefined);
  }
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 28,
          w: 3,
          h: 4
        }, {
          x: 373,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 3,
          h: 4
        }, {
          x: 341,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 28,
          w: 4,
          h: 4
        }, {
          x: 373,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 28,
          w: 4,
          h: 4
        }, {
          x: 373,
          y: 364,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 28,
          w: 3,
          h: 4
        }, {
          x: 533,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 21,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 20,
          w: 3,
          h: 6
        }, {
          x: 53,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 47,
          y: 20,
          w: 4,
          h: 6
        }, {
          x: 85,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 51,
          y: 20,
          w: 3,
          h: 6
        }, {
          x: 117,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 3,
          h: 4
        }, {
          x: 53,
          y: 419,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 3,
          h: 3
        }, {
          x: 53,
          y: 547,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    if (!hideRightSleeve) {
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 44,
            w: 3,
            h: 4
          }, {
            x: 373,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 40,
            w: 3,
            h: 4
          }, {
            x: 341,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 44,
            w: 4,
            h: 4
          }, {
            x: 373,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 44,
            w: 4,
            h: 4
          }, {
            x: 373,
            y: 364,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 44,
            w: 3,
            h: 4
          }, {
            x: 533,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 4,
            h: 6
          }, {
            x: 21,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 36,
            w: 3,
            h: 6
          }, {
            x: 53,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 47,
            y: 36,
            w: 4,
            h: 6
          }, {
            x: 85,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 51,
            y: 36,
            w: 3,
            h: 6
          }, {
            x: 117,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 3,
            h: 4
          }, {
            x: 53,
            y: 419,
            w: 32,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 40,
            w: 3,
            h: 3
          }, {
            x: 53,
            y: 547,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
    }
    
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 28,
          w: 4,
          h: 4
        }, {
          x: 373,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 4,
          h: 4
        }, {
          x: 341,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 48,
          y: 28,
          w: 4,
          h: 4
        }, {
          x: 373,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 28,
          w: 4,
          h: 4
        }, {
          x: 373,
          y: 364,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 52,
          y: 28,
          w: 4,
          h: 4
        }, {
          x: 533,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 20,
          w: 16,
          h: 6
        }, {
          x: 21,
          y: 451,
          w: 128,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 16,
          w: 4,
          h: 4
        }, {
          x: 53,
          y: 419,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 24,
          w: 4,
          h: 3
        }, {
          x: 53,
          y: 547,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    if (!hideRightSleeve) {
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 36,
            w: 16,
            h: 6
          }, {
            x: 21,
            y: 451,
            w: 128,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 32,
            w: 4,
            h: 4
          }, {
            x: 53,
            y: 419,
            w: 32,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 40,
            w: 4,
            h: 3
          }, {
            x: 53,
            y: 547,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 44,
            w: 4,
            h: 4
          }, {
            x: 373,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 44,
            y: 40,
            w: 4,
            h: 4
          }, {
            x: 341,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 44,
            w: 4,
            h: 4
          }, {
            x: 373,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 40,
            y: 44,
            w: 4,
            h: 4
          }, {
            x: 373,
            y: 364,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 44,
            w: 4,
            h: 4
          }, {
            x: 533,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
    }
    
  }
  if (alexModel) {
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 60,
          w: 3,
          h: 4
        }, {
          x: 437,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 3,
          h: 4
        }, {
          x: 469,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 60,
          w: 4,
          h: 4
        }, {
          x: 437,
          y: 268,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 60,
          w: 4,
          h: 4
        }, {
          x: 437,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 60,
          w: 3,
          h: 4
        }, {
          x: 469,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 205,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 52,
          w: 3,
          h: 6
        }, {
          x: 237,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 39,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 269,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 43,
          y: 52,
          w: 3,
          h: 6
        }, {
          x: 173,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 3,
          h: 4
        }, {
          x: 237,
          y: 419,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 3,
          h: 3
        }, {
          x: 269,
          y: 515,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    if (!hideLeftSleeve) {
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 60,
            w: 3,
            h: 4
          }, {
            x: 437,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 56,
            w: 3,
            h: 4
          }, {
            x: 469,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 60,
            w: 4,
            h: 4
          }, {
            x: 437,
            y: 268,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 60,
            w: 4,
            h: 4
          }, {
            x: 437,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 59,
            y: 60,
            w: 3,
            h: 4
          }, {
            x: 469,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 4,
            h: 6
          }, {
            x: 205,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 52,
            w: 3,
            h: 6
          }, {
            x: 237,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 55,
            y: 52,
            w: 4,
            h: 6
          }, {
            x: 269,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 59,
            y: 52,
            w: 3,
            h: 6
          }, {
            x: 173,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 3,
            h: 4
          }, {
            x: 237,
            y: 419,
            w: 32,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 56,
            w: 3,
            h: 3
          }, {
            x: 269,
            y: 515,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
    }
    
  } else {
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 60,
          w: 4,
          h: 4
        }, {
          x: 437,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 4,
          h: 4
        }, {
          x: 469,
          y: 300,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 60,
          w: 4,
          h: 4
        }, {
          x: 437,
          y: 268,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 40,
          y: 60,
          w: 4,
          h: 4
        }, {
          x: 437,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 60,
          w: 4,
          h: 4
        }, {
          x: 469,
          y: 332,
          w: 32,
          h: 32
        }, undefined, 270.0, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 52,
          w: 12,
          h: 6
        }, {
          x: 205,
          y: 451,
          w: 96,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 44,
          y: 52,
          w: 4,
          h: 6
        }, {
          x: 173,
          y: 451,
          w: 32,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 48,
          w: 4,
          h: 4
        }, {
          x: 237,
          y: 419,
          w: 32,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 36,
          y: 56,
          w: 4,
          h: 3
        }, {
          x: 269,
          y: 515,
          w: 32,
          h: 32
        }, undefined, 90.0, undefined, undefined);
    if (!hideLeftSleeve) {
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 52,
            w: 12,
            h: 6
          }, {
            x: 205,
            y: 451,
            w: 96,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 60,
            y: 52,
            w: 4,
            h: 6
          }, {
            x: 173,
            y: 451,
            w: 32,
            h: 64
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 48,
            w: 4,
            h: 4
          }, {
            x: 237,
            y: 419,
            w: 32,
            h: 32
          }, undefined, undefined, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 56,
            w: 4,
            h: 3
          }, {
            x: 269,
            y: 515,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 60,
            w: 4,
            h: 4
          }, {
            x: 437,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 52,
            y: 56,
            w: 4,
            h: 4
          }, {
            x: 469,
            y: 300,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 48,
            y: 60,
            w: 4,
            h: 4
          }, {
            x: 437,
            y: 268,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 56,
            y: 60,
            w: 4,
            h: 4
          }, {
            x: 437,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 90.0, undefined, undefined);
      Generator.drawTextureLegacy("Skin", {
            x: 60,
            y: 60,
            w: 4,
            h: 4
          }, {
            x: 469,
            y: 332,
            w: 32,
            h: 32
          }, undefined, 270.0, undefined, undefined);
    }
    
  }
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 71,
        y: 583,
        w: 64,
        h: 160
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 135,
        y: 583,
        w: 48,
        h: 160
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 23,
        y: 583,
        w: 48,
        h: 160
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 183,
        y: 583,
        w: 64,
        h: 160
      }, undefined, undefined, undefined, undefined);
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 71,
          y: 583,
          w: 64,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 135,
          y: 583,
          w: 48,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 23,
          y: 583,
          w: 48,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 183,
          y: 583,
          w: 64,
          h: 160
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawTextureLegacy("Skin", {
        x: 28,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 407,
        y: 583,
        w: 48,
        h: 160
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 16,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 295,
        y: 583,
        w: 48,
        h: 160
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 20,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 343,
        y: 583,
        w: 64,
        h: 160
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Skin", {
        x: 32,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 455,
        y: 583,
        w: 64,
        h: 160
      }, undefined, undefined, undefined, undefined);
  if (!hideJacket) {
    Generator.drawTextureLegacy("Skin", {
          x: 28,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 407,
          y: 583,
          w: 48,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 16,
          y: 36,
          w: 4,
          h: 12
        }, {
          x: 295,
          y: 583,
          w: 48,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 20,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 343,
          y: 583,
          w: 64,
          h: 160
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Skin", {
          x: 32,
          y: 36,
          w: 8,
          h: 12
        }, {
          x: 455,
          y: 583,
          w: 64,
          h: 160
        }, undefined, undefined, undefined, undefined);
  }
  Generator.drawImage("Background", [
        0,
        0
      ]);
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
