// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "minecraft-villager";

var name = "Minecraft Villager";

var history = ["19 May 2022 PaperDoggy - Initial script developed."];

var thumbnail = {
  url: require("./thumbnail/thumbnail-v2-256.jpeg")
};

var video = {
  url: "https://www.youtube.com/embed/jcO0fe-pj9k?rel=0"
};

var images = [
  {
    id: "Villager",
    url: require("./images/Villager.png")
  },
  {
    id: "ZombieVillager",
    url: require("./images/ZombieVillager.png")
  },
  {
    id: "FarmerHat",
    url: require("./images/farmerhat.png")
  },
  {
    id: "ShepherdHat",
    url: require("./images/shepherdhat.png")
  },
  {
    id: "FishermanHat",
    url: require("./images/fishermanhat.png")
  }
];

var textures = [
  {
    id: "Desert",
    url: require("./textures/desert.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Plains",
    url: require("./textures/plains.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Taiga",
    url: require("./textures/taiga.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Snow",
    url: require("./textures/snow.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Savanna",
    url: require("./textures/savanna.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Jungle",
    url: require("./textures/jungle.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Swamp",
    url: require("./textures/swamp.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "DesertZombie",
    url: require("./textures/desertzombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "PlainsZombie",
    url: require("./textures/plainszombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "TaigaZombie",
    url: require("./textures/taigazombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "SnowZombie",
    url: require("./textures/snowzombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "SavannaZombie",
    url: require("./textures/savannazombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "JungleZombie",
    url: require("./textures/junglezombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "SwampZombie",
    url: require("./textures/swampzombie.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Armorer",
    url: require("./textures/armorer.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Butcher",
    url: require("./textures/butcher.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Cartographer",
    url: require("./textures/cartographer.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Cleric",
    url: require("./textures/cleric.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Farmer",
    url: require("./textures/farmer.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Fisherman",
    url: require("./textures/fisherman.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Fletcher",
    url: require("./textures/fletcher.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Leatherworker",
    url: require("./textures/leatherworker.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Librarian",
    url: require("./textures/librarian.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Mason",
    url: require("./textures/mason.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Nitwit",
    url: require("./textures/nitwit.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Shepherd",
    url: require("./textures/shepherd.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Toolsmith",
    url: require("./textures/toolsmith.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Weaponsmith",
    url: require("./textures/weaponsmith.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieArmorer",
    url: require("./textures/zombiearmorer.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieButcher",
    url: require("./textures/zombiebutcher.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieCartographer",
    url: require("./textures/zombiecartographer.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieCleric",
    url: require("./textures/zombiecleric.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieFarmer",
    url: require("./textures/zombiefarmer.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieFisherman",
    url: require("./textures/zombiefisherman.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieFletcher",
    url: require("./textures/zombiefletcher.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieLeatherworker",
    url: require("./textures/zombieleatherworker.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieLibrarian",
    url: require("./textures/zombielibrarian.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieMason",
    url: require("./textures/zombiemason.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieNitwit",
    url: require("./textures/zombienitwit.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieShepherd",
    url: require("./textures/zombieshepherd.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieToolsmith",
    url: require("./textures/zombietoolsmith.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "ZombieWeaponsmith",
    url: require("./textures/zombieweaponsmith.png"),
    standardWidth: 64,
    standardHeight: 64
  }
];

var steve = TextureMap.MinecraftCharacter.steve;

var alex = TextureMap.MinecraftCharacter.alex;

function drawHatOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        8,
        0,
        8,
        8
      ], [
        ox,
        oy - 65 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        8,
        8,
        8,
        10
      ], [
        ox,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        0,
        8,
        8,
        10
      ], [
        ox - 65 | 0,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        16,
        8,
        8,
        10
      ], [
        ox + 65 | 0,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        24,
        8,
        8,
        10
      ], [
        ox + 130 | 0,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        40,
        0,
        8,
        8
      ], [
        ox,
        oy - 65 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        40,
        8,
        8,
        10
      ], [
        ox,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        32,
        8,
        8,
        10
      ], [
        ox - 65 | 0,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        48,
        8,
        8,
        10
      ], [
        ox + 65 | 0,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        56,
        8,
        8,
        10
      ], [
        ox + 130 | 0,
        oy,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawClothes(ox, oy, texture) {
  Generator.drawTexture(texture, [
        6,
        38,
        8,
        6
      ], [
        ox,
        oy - 53 | 0,
        68,
        52
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        6,
        44,
        8,
        22
      ], [
        ox,
        oy,
        68,
        176
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        0,
        44,
        6,
        22
      ], [
        ox - 53 | 0,
        oy,
        52,
        176
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        14,
        44,
        6,
        22
      ], [
        ox + 69 | 0,
        oy,
        52,
        176
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        20,
        44,
        8,
        22
      ], [
        (ox + 69 | 0) + 53 | 0,
        oy,
        68,
        176
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawBodyOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        22,
        20,
        8,
        6
      ], [
        ox,
        oy - 49 | 0,
        64,
        48
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        30,
        20,
        8,
        6
      ], [
        ox,
        oy + 97 | 0,
        64,
        48
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        22,
        26,
        8,
        12
      ], [
        ox,
        oy,
        64,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        16,
        26,
        6,
        12
      ], [
        ox - 49 | 0,
        oy,
        48,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        30,
        26,
        6,
        12
      ], [
        ox + 65 | 0,
        oy,
        48,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        36,
        26,
        8,
        12
      ], [
        (ox + 65 | 0) + 49 | 0,
        oy,
        64,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawLeftLegOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        4,
        22,
        4,
        4
      ], [
        ox,
        oy - 33 | 0,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        8,
        22,
        4,
        4
      ], [
        ox,
        oy + 97 | 0,
        32,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        4,
        26,
        4,
        12
      ], [
        ox,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        0,
        26,
        4,
        12
      ], [
        ox - 33 | 0,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        8,
        26,
        4,
        12
      ], [
        ox + 33 | 0,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        12,
        26,
        4,
        12
      ], [
        (ox + 33 | 0) + 33 | 0,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawRightLegOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        4,
        22,
        4,
        4
      ], [
        ox,
        oy - 33 | 0,
        32,
        32
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        8,
        22,
        4,
        4
      ], [
        ox,
        oy + 97 | 0,
        32,
        32
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, [
        4,
        26,
        4,
        12
      ], [
        ox,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        8,
        26,
        4,
        12
      ], [
        ox - 33 | 0,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        0,
        26,
        4,
        12
      ], [
        ox + 33 | 0,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        12,
        26,
        4,
        12
      ], [
        (ox - 33 | 0) - 33 | 0,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawLeftArmOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        48,
        22,
        4,
        4
      ], [
        ox,
        oy - 33 | 0,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        22,
        4,
        4
      ], [
        ox,
        oy + 65 | 0,
        32,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        48,
        26,
        4,
        8
      ], [
        ox,
        oy,
        32,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        44,
        26,
        4,
        8
      ], [
        ox - 33 | 0,
        oy,
        32,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        26,
        4,
        8
      ], [
        ox + 33 | 0,
        oy,
        32,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        56,
        26,
        4,
        8
      ], [
        (ox + 33 | 0) + 33 | 0,
        oy,
        32,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawRightArmOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        48,
        22,
        4,
        4
      ], [
        ox,
        oy - 33 | 0,
        32,
        32
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        22,
        4,
        4
      ], [
        ox,
        oy + 65 | 0,
        32,
        32
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, [
        48,
        26,
        4,
        8
      ], [
        ox,
        oy,
        32,
        64
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        26,
        4,
        8
      ], [
        ox - 33 | 0,
        oy,
        32,
        64
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        44,
        26,
        4,
        8
      ], [
        ox + 33 | 0,
        oy,
        32,
        64
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        56,
        26,
        4,
        8
      ], [
        (ox - 33 | 0) - 33 | 0,
        oy,
        32,
        64
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

function drawMiddleArmOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        44,
        38,
        8,
        4
      ], [
        ox,
        oy - 33 | 0,
        64,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        38,
        8,
        4
      ], [
        ox,
        oy + 33 | 0,
        64,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        44,
        42,
        8,
        4
      ], [
        ox,
        oy,
        64,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        40,
        42,
        4,
        4
      ], [
        ox - 33 | 0,
        oy,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        42,
        4,
        4
      ], [
        ox + 65 | 0,
        oy,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        56,
        42,
        8,
        4
      ], [
        (ox + 33 | 0) + 65 | 0,
        oy,
        64,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawZombieLeftArmOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        48,
        22,
        4,
        4
      ], [
        ox,
        oy - 33 | 0,
        32,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        22,
        4,
        4
      ], [
        ox,
        oy + 97 | 0,
        32,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        48,
        26,
        4,
        12
      ], [
        ox,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        44,
        26,
        4,
        12
      ], [
        ox - 33 | 0,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        26,
        4,
        12
      ], [
        ox + 33 | 0,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        56,
        26,
        4,
        12
      ], [
        (ox + 33 | 0) + 33 | 0,
        oy,
        32,
        96
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawZombieRightArmOverlay(ox, oy, texture) {
  Generator.drawTexture(texture, [
        48,
        22,
        4,
        4
      ], [
        ox,
        oy - 33 | 0,
        32,
        32
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        22,
        4,
        4
      ], [
        ox,
        oy + 97 | 0,
        32,
        32
      ], undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, [
        48,
        26,
        4,
        12
      ], [
        ox,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        52,
        26,
        4,
        12
      ], [
        ox - 33 | 0,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        44,
        26,
        4,
        12
      ], [
        ox + 33 | 0,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, [
        56,
        26,
        4,
        12
      ], [
        (ox - 33 | 0) - 33 | 0,
        oy,
        32,
        96
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
}

var Drawing = {
  drawHatOverlay: drawHatOverlay,
  drawClothes: drawClothes,
  drawBodyOverlay: drawBodyOverlay,
  drawLeftLegOverlay: drawLeftLegOverlay,
  drawRightLegOverlay: drawRightLegOverlay,
  drawLeftArmOverlay: drawLeftArmOverlay,
  drawRightArmOverlay: drawRightArmOverlay,
  drawMiddleArmOverlay: drawMiddleArmOverlay,
  drawZombieLeftArmOverlay: drawZombieLeftArmOverlay,
  drawZombieRightArmOverlay: drawZombieRightArmOverlay
};

function script(param) {
  Generator.defineSelectInput("Type", [
        "Normal",
        "Zombie"
      ]);
  Generator.defineSelectInput("Biome", [
        "Plains",
        "Desert",
        "Jungle",
        "Savanna",
        "Snow",
        "Swamp",
        "Taiga"
      ]);
  Generator.defineSelectInput("Profession", [
        "None",
        "Armorer",
        "Butcher",
        "Cartographer",
        "Cleric",
        "Farmer",
        "Fisherman",
        "Fletcher",
        "Leatherworker",
        "Librarian",
        "Mason",
        "Nitwit",
        "Shepherd",
        "Toolsmith",
        "Weaponsmith"
      ]);
  var villagertype = Generator.getSelectInputValue("Type") === "Normal";
  var zombietype = Generator.getSelectInputValue("Type") === "Zombie";
  var professionArmorer = Generator.getSelectInputValue("Profession") === "Armorer";
  var professionButcher = Generator.getSelectInputValue("Profession") === "Butcher";
  var professionCartographer = Generator.getSelectInputValue("Profession") === "Cartographer";
  var professionCleric = Generator.getSelectInputValue("Profession") === "Cleric";
  var professionFarmer = Generator.getSelectInputValue("Profession") === "Farmer";
  var professionFisherman = Generator.getSelectInputValue("Profession") === "Fisherman";
  var professionFletcher = Generator.getSelectInputValue("Profession") === "Fletcher";
  var professionLeatherworker = Generator.getSelectInputValue("Profession") === "Leatherworker";
  var professionLibrarian = Generator.getSelectInputValue("Profession") === "Librarian";
  var professionMason = Generator.getSelectInputValue("Profession") === "Mason";
  var professionNitwit = Generator.getSelectInputValue("Profession") === "Nitwit";
  var professionShepherd = Generator.getSelectInputValue("Profession") === "Shepherd";
  var professionToolsmith = Generator.getSelectInputValue("Profession") === "Toolsmith";
  var professionWeaponsmith = Generator.getSelectInputValue("Profession") === "Weaponsmith";
  var plains = Generator.getSelectInputValue("Biome") === "Plains";
  var desert = Generator.getSelectInputValue("Biome") === "Desert";
  var jungle = Generator.getSelectInputValue("Biome") === "Jungle";
  var savanna = Generator.getSelectInputValue("Biome") === "Savanna";
  var snow = Generator.getSelectInputValue("Biome") === "Snow";
  var swamp = Generator.getSelectInputValue("Biome") === "Swamp";
  var taiga = Generator.getSelectInputValue("Biome") === "Taiga";
  if (villagertype) {
    Generator.drawImage("Villager", [
          0,
          0
        ]);
    if (!(professionButcher || professionFarmer || professionFisherman || professionFletcher || professionLibrarian || professionShepherd)) {
      if (plains) {
        drawHatOverlay(82, 83, "Plains");
      }
      if (desert) {
        drawHatOverlay(82, 83, "Desert");
      }
      if (jungle) {
        drawHatOverlay(82, 83, "Jungle");
      }
      if (savanna) {
        drawHatOverlay(82, 83, "Savanna");
      }
      if (snow) {
        drawHatOverlay(82, 83, "Snow");
      }
      if (swamp) {
        drawHatOverlay(82, 83, "Swamp");
      }
      if (taiga) {
        drawHatOverlay(82, 83, "Taiga");
      }
      
    }
    if (plains) {
      drawClothes(330, 550, "Plains");
      drawBodyOverlay(361, 216, "Plains");
      drawLeftLegOverlay(62, 290, "Plains");
      drawRightLegOverlay(267, 370, "Plains");
      drawLeftArmOverlay(56, 476, "Plains");
      drawRightArmOverlay(517, 379, "Plains");
      drawMiddleArmOverlay(46, 643, "Plains");
    }
    if (desert) {
      drawClothes(330, 550, "Desert");
      drawBodyOverlay(361, 216, "Desert");
      drawLeftLegOverlay(62, 290, "Desert");
      drawRightLegOverlay(267, 370, "Desert");
      drawLeftArmOverlay(56, 476, "Desert");
      drawRightArmOverlay(517, 379, "Desert");
      drawMiddleArmOverlay(46, 643, "Desert");
    }
    if (jungle) {
      drawClothes(330, 550, "Jungle");
      drawBodyOverlay(361, 216, "Jungle");
      drawLeftLegOverlay(62, 290, "Jungle");
      drawRightLegOverlay(267, 370, "Jungle");
      drawLeftArmOverlay(56, 476, "Jungle");
      drawRightArmOverlay(517, 379, "Jungle");
      drawMiddleArmOverlay(46, 643, "Jungle");
    }
    if (savanna) {
      drawClothes(330, 550, "Savanna");
      drawBodyOverlay(361, 216, "Savanna");
      drawLeftLegOverlay(62, 290, "Savanna");
      drawRightLegOverlay(267, 370, "Savanna");
      drawLeftArmOverlay(56, 476, "Savanna");
      drawRightArmOverlay(517, 379, "Savanna");
      drawMiddleArmOverlay(46, 643, "Savanna");
    }
    if (snow) {
      drawClothes(330, 550, "Snow");
      drawBodyOverlay(361, 216, "Snow");
      drawLeftLegOverlay(62, 290, "Snow");
      drawRightLegOverlay(267, 370, "Snow");
      drawLeftArmOverlay(56, 476, "Snow");
      drawRightArmOverlay(517, 379, "Snow");
      drawMiddleArmOverlay(46, 643, "Snow");
    }
    if (swamp) {
      drawClothes(330, 550, "Swamp");
      drawBodyOverlay(361, 216, "Swamp");
      drawLeftLegOverlay(62, 290, "Swamp");
      drawRightLegOverlay(267, 370, "Swamp");
      drawLeftArmOverlay(56, 476, "Swamp");
      drawRightArmOverlay(517, 379, "Swamp");
      drawMiddleArmOverlay(46, 643, "Swamp");
    }
    if (taiga) {
      drawClothes(330, 550, "Taiga");
      drawBodyOverlay(361, 216, "Taiga");
      drawLeftLegOverlay(62, 290, "Taiga");
      drawRightLegOverlay(267, 370, "Taiga");
      drawLeftArmOverlay(56, 476, "Taiga");
      drawRightArmOverlay(517, 379, "Taiga");
      drawMiddleArmOverlay(46, 643, "Taiga");
    }
    if (professionArmorer) {
      drawHatOverlay(82, 83, "Armorer");
      drawClothes(330, 550, "Armorer");
      drawLeftArmOverlay(56, 476, "Armorer");
      drawRightArmOverlay(517, 379, "Armorer");
      drawMiddleArmOverlay(46, 643, "Armorer");
    }
    if (professionButcher) {
      drawHatOverlay(82, 83, "Buther");
      drawClothes(330, 550, "Butcher");
      drawLeftArmOverlay(56, 476, "Butcher");
      drawRightArmOverlay(517, 379, "Butcher");
      drawMiddleArmOverlay(46, 643, "Butcher");
    }
    if (professionCartographer) {
      drawHatOverlay(82, 83, "Cartographer");
      drawClothes(330, 550, "Cartographer");
      drawLeftArmOverlay(56, 476, "Cartographer");
      drawRightArmOverlay(517, 379, "Cartographer");
      drawMiddleArmOverlay(46, 643, "Cartographer");
    }
    if (professionCleric) {
      drawHatOverlay(82, 83, "Cleric");
      drawClothes(330, 550, "Cleric");
      drawLeftArmOverlay(56, 476, "Cleric");
      drawRightArmOverlay(517, 379, "Cleric");
      drawMiddleArmOverlay(46, 643, "Cleric");
    }
    if (professionFarmer) {
      drawHatOverlay(82, 83, "Farmer");
      drawClothes(330, 550, "Farmer");
      drawLeftArmOverlay(56, 476, "Farmer");
      drawRightArmOverlay(517, 379, "Farmer");
      drawMiddleArmOverlay(46, 643, "Farmer");
      Generator.drawImage("FarmerHat", [
            316,
            12
          ]);
    }
    if (professionFisherman) {
      drawHatOverlay(82, 83, "Fisherman");
      drawClothes(330, 550, "Fisherman");
      drawLeftLegOverlay(62, 290, "Fisherman");
      drawRightLegOverlay(267, 370, "Fisherman");
      drawLeftArmOverlay(56, 476, "Fisherman");
      drawRightArmOverlay(517, 379, "Fisherman");
      drawMiddleArmOverlay(46, 643, "Fisherman");
      Generator.drawImage("FishermanHat", [
            316,
            12
          ]);
    }
    if (professionFletcher) {
      drawHatOverlay(82, 83, "Fletcher");
      drawClothes(330, 550, "Fletcher");
      drawLeftArmOverlay(56, 476, "Fletcher");
      drawRightArmOverlay(517, 379, "Fletcher");
      drawMiddleArmOverlay(46, 643, "Fletcher");
    }
    if (professionLeatherworker) {
      drawHatOverlay(82, 83, "Leatherworker");
      drawClothes(330, 550, "Leatherworker");
      drawLeftArmOverlay(56, 476, "Leatherworker");
      drawRightArmOverlay(517, 379, "Leatherworker");
      drawMiddleArmOverlay(46, 643, "Leatherworker");
    }
    if (professionLibrarian) {
      drawHatOverlay(82, 83, "Librarian");
      drawClothes(330, 550, "Librarian");
      drawLeftArmOverlay(56, 476, "Librarian");
      drawRightArmOverlay(517, 379, "Librarian");
      drawMiddleArmOverlay(46, 643, "Library");
    }
    if (professionMason) {
      drawHatOverlay(82, 83, "Mason");
      drawClothes(330, 550, "Mason");
      drawLeftArmOverlay(56, 476, "Mason");
      drawRightArmOverlay(517, 379, "Mason");
      drawMiddleArmOverlay(46, 643, "Mason");
    }
    if (professionNitwit) {
      drawHatOverlay(82, 83, "Nitwit");
      drawClothes(330, 550, "Nitwit");
      drawLeftArmOverlay(56, 476, "Nitwit");
      drawRightArmOverlay(517, 379, "Nitwit");
      drawMiddleArmOverlay(46, 643, "Nitwit");
    }
    if (professionShepherd) {
      drawHatOverlay(82, 83, "Shepherd");
      drawClothes(330, 550, "Shepherd");
      drawLeftArmOverlay(56, 476, "Shepherd");
      drawRightArmOverlay(517, 379, "Shepherd");
      drawMiddleArmOverlay(46, 643, "Shepherd");
      Generator.drawImage("ShepherdHat", [
            316,
            12
          ]);
    }
    if (professionToolsmith) {
      drawHatOverlay(82, 83, "Toolsmith");
      drawClothes(330, 550, "Toolsmith");
      drawLeftArmOverlay(56, 476, "Toolsmith");
      drawRightArmOverlay(517, 379, "Toolsmith");
      drawMiddleArmOverlay(46, 643, "Toolsmith");
    }
    if (professionWeaponsmith) {
      drawHatOverlay(82, 83, "Weaponsmith");
      drawClothes(330, 550, "Weaponsmith");
      drawLeftArmOverlay(56, 476, "Weaponsmith");
      drawRightArmOverlay(517, 379, "Weaponsmith");
      drawMiddleArmOverlay(46, 643, "Weaponsmith");
    }
    
  }
  if (zombietype) {
    Generator.drawImage("ZombieVillager", [
          0,
          0
        ]);
    if (!(professionButcher || professionFarmer || professionFisherman || professionFletcher || professionLibrarian || professionShepherd)) {
      if (plains) {
        drawHatOverlay(82, 83, "PlainsZombie");
      }
      if (desert) {
        drawHatOverlay(82, 83, "DesertZombie");
      }
      if (jungle) {
        drawHatOverlay(82, 83, "JungleZombie");
      }
      if (savanna) {
        drawHatOverlay(82, 83, "SavannaZombie");
      }
      if (snow) {
        drawHatOverlay(82, 83, "SnowZombie");
      }
      if (swamp) {
        drawHatOverlay(82, 83, "SwampZombie");
      }
      if (taiga) {
        drawHatOverlay(82, 83, "TaigaZombie");
      }
      
    }
    if (plains) {
      drawClothes(330, 550, "PlainsZombie");
      drawBodyOverlay(361, 216, "PlainsZombie");
      drawLeftLegOverlay(62, 290, "PlainsZombie");
      drawRightLegOverlay(267, 370, "PlainsZombie");
      drawZombieLeftArmOverlay(56, 476, "PlainsZombie");
      drawZombieRightArmOverlay(517, 379, "PlainsZombie");
    }
    if (desert) {
      drawClothes(330, 550, "DesertZombie");
      drawBodyOverlay(361, 216, "DesertZombie");
      drawLeftLegOverlay(62, 290, "DesertZombie");
      drawRightLegOverlay(267, 370, "DesertZombie");
      drawZombieLeftArmOverlay(56, 476, "DesertZombie");
      drawZombieRightArmOverlay(517, 379, "DesertZombie");
    }
    if (jungle) {
      drawClothes(330, 550, "JungleZombie");
      drawBodyOverlay(361, 216, "JungleZombie");
      drawLeftLegOverlay(62, 290, "JungleZombie");
      drawRightLegOverlay(267, 370, "JungleZombie");
      drawZombieLeftArmOverlay(56, 476, "JungleZombie");
      drawZombieRightArmOverlay(517, 379, "JungleZombie");
    }
    if (savanna) {
      drawClothes(330, 550, "SavannaZombie");
      drawBodyOverlay(361, 216, "SavannaZombie");
      drawLeftLegOverlay(62, 290, "SavannaZombie");
      drawRightLegOverlay(267, 370, "SavannaZombie");
      drawZombieLeftArmOverlay(56, 476, "SavannaZombie");
      drawZombieRightArmOverlay(517, 379, "SavannaZombie");
    }
    if (snow) {
      drawClothes(330, 550, "SnowZombie");
      drawBodyOverlay(361, 216, "SnowZombie");
      drawLeftLegOverlay(62, 290, "SnowZombie");
      drawRightLegOverlay(267, 370, "SnowZombie");
      drawZombieLeftArmOverlay(56, 476, "SnowZombie");
      drawZombieRightArmOverlay(517, 379, "SnowZombie");
    }
    if (swamp) {
      drawClothes(330, 550, "SwampZombie");
      drawBodyOverlay(361, 216, "SwampZombie");
      drawLeftLegOverlay(62, 290, "SwampZombie");
      drawRightLegOverlay(267, 370, "SwampZombie");
      drawZombieLeftArmOverlay(56, 476, "SwampZombie");
      drawZombieRightArmOverlay(517, 379, "SwampZombie");
    }
    if (taiga) {
      drawClothes(330, 550, "TaigaZombie");
      drawBodyOverlay(361, 216, "TaigaZombie");
      drawLeftLegOverlay(62, 290, "TaigaZombie");
      drawRightLegOverlay(267, 370, "TaigaZombie");
      drawZombieLeftArmOverlay(56, 476, "TaigaZombie");
      drawZombieRightArmOverlay(517, 379, "TaigaZombie");
    }
    if (professionArmorer) {
      drawHatOverlay(82, 83, "ZombieArmorer");
      drawClothes(330, 550, "ZombieArmorer");
      drawLeftArmOverlay(56, 476, "ZombieArmorer");
      drawRightArmOverlay(517, 379, "ZombieArmorer");
    }
    if (professionButcher) {
      drawHatOverlay(82, 83, "ZombieButher");
      drawClothes(330, 550, "ZombieButcher");
      drawLeftArmOverlay(56, 476, "ZombieButcher");
      drawRightArmOverlay(517, 379, "ZombieButcher");
    }
    if (professionCartographer) {
      drawHatOverlay(82, 83, "ZombieCartographer");
      drawClothes(330, 550, "ZombieCartographer");
      drawLeftArmOverlay(56, 476, "ZombieCartographer");
      drawRightArmOverlay(517, 379, "ZombieCartographer");
    }
    if (professionCleric) {
      drawHatOverlay(82, 83, "ZombieCleric");
      drawClothes(330, 550, "ZombieCleric");
      drawLeftArmOverlay(56, 476, "ZombieCleric");
      drawRightArmOverlay(517, 379, "ZombieCleric");
    }
    if (professionFarmer) {
      drawHatOverlay(82, 83, "ZombieFarmer");
      drawClothes(330, 550, "ZombieFarmer");
      drawLeftArmOverlay(56, 476, "ZombieFarmer");
      drawRightArmOverlay(517, 379, "ZombieFarmer");
      Generator.drawImage("FarmerHat", [
            316,
            12
          ]);
    }
    if (professionFisherman) {
      drawHatOverlay(82, 83, "ZombieFisherman");
      drawClothes(330, 550, "ZombieFisherman");
      drawLeftLegOverlay(62, 290, "ZombieFisherman");
      drawRightLegOverlay(267, 370, "ZombieFisherman");
      drawLeftArmOverlay(56, 476, "ZombieFisherman");
      drawRightArmOverlay(517, 379, "ZombieFisherman");
      Generator.drawImage("FishermanHat", [
            316,
            12
          ]);
    }
    if (professionFletcher) {
      drawHatOverlay(82, 83, "ZombieFletcher");
      drawClothes(330, 550, "ZombieFletcher");
      drawLeftArmOverlay(56, 476, "ZombieFletcher");
      drawRightArmOverlay(517, 379, "ZombieFletcher");
    }
    if (professionLeatherworker) {
      drawHatOverlay(82, 83, "ZombieLeatherworker");
      drawClothes(330, 550, "ZombieLeatherworker");
      drawLeftArmOverlay(56, 476, "ZombieLeatherworker");
      drawRightArmOverlay(517, 379, "ZombieLeatherworker");
    }
    if (professionLibrarian) {
      drawHatOverlay(82, 83, "ZombieLibrarian");
      drawClothes(330, 550, "ZombieLibrarian");
      drawLeftArmOverlay(56, 476, "ZombieLibrarian");
      drawRightArmOverlay(517, 379, "ZombieLibrarian");
    }
    if (professionMason) {
      drawHatOverlay(82, 83, "ZombieMason");
      drawClothes(330, 550, "ZombieMason");
      drawLeftArmOverlay(56, 476, "ZombieMason");
      drawRightArmOverlay(517, 379, "ZombieMason");
    }
    if (professionNitwit) {
      drawHatOverlay(82, 83, "ZombieNitwit");
      drawClothes(330, 550, "ZombieNitwit");
      drawLeftArmOverlay(56, 476, "ZombieNitwit");
      drawRightArmOverlay(517, 379, "ZombieNitwit");
    }
    if (professionShepherd) {
      drawHatOverlay(82, 83, "ZombieShepherd");
      drawClothes(330, 550, "ZombieShepherd");
      drawLeftArmOverlay(56, 476, "ZombieShepherd");
      drawRightArmOverlay(517, 379, "ZombieShepherd");
      Generator.drawImage("ShepherdHat", [
            316,
            12
          ]);
    }
    if (professionToolsmith) {
      drawHatOverlay(82, 83, "ZombieToolsmith");
      drawClothes(330, 550, "ZombieToolsmith");
      drawLeftArmOverlay(56, 476, "ZombieToolsmith");
      drawRightArmOverlay(517, 379, "ZombieToolsmith");
    }
    if (professionWeaponsmith) {
      drawHatOverlay(82, 83, "ZombieWeaponsmith");
      drawClothes(330, 550, "ZombieWeaponsmith");
      drawLeftArmOverlay(56, 476, "ZombieWeaponsmith");
      return drawRightArmOverlay(517, 379, "ZombieWeaponsmith");
    } else {
      return ;
    }
  }
  
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.video = video;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.Drawing = Drawing;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
