// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-enderman";

var name = "Minecraft Enderman";

var history = [
  "Originally developed by ODF.",
  "06 Feb 2015 lostminer - Add user variables.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "19 Sep 2020 NinjolasNJM - Fixed orientations of limbs.",
  "07 Jun 2021 NinjolasNJM - Converted to ReScript generator.",
  "17 Jul 2021 M16 - Updated generator photo."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Labels",
  "Folds"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Enderman",
    url: requireTexture("enderman"),
    standardWidth: 64,
    standardHeight: 32
  },
  {
    id: "Enderman Eyes",
    url: requireTexture("enderman_eyes"),
    standardWidth: 64,
    standardHeight: 32
  }
];

function script(param) {
  Generator.defineTextureInput("Enderman", {
        standardWidth: 64,
        standardHeight: 32,
        choices: []
      });
  Generator.defineTextureInput("Enderman Eyes", {
        standardWidth: 64,
        standardHeight: 32,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  Generator.drawImage("Background", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Enderman", {
        x: 0,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 8,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 16,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 202,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 24,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 266,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 8,
        y: 16,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 25,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 16,
        y: 16,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 153,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 202,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 266,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 25,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 153,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 32,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 268,
        y: 233,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 36,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 300,
        y: 233,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 44,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 364,
        y: 233,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 48,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 396,
        y: 233,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 36,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 300,
        y: 201,
        w: 64,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 44,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 300,
        y: 329,
        w: 64,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 96,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 112,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 128,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 144,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 112,
        y: 383,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 112,
        y: 639,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 222,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 206,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 190,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 174,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 206,
        y: 383,
        w: 16,
        h: 16
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 222,
        y: 655,
        w: 16,
        h: 16
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 276,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 292,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 308,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 324,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 292,
        y: 383,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 292,
        y: 639,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 401,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 385,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 369,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 353,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 385,
        y: 383,
        w: 16,
        h: 16
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 401,
        y: 655,
        w: 16,
        h: 16
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 0,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 8,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 16,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 202,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 24,
        y: 24,
        w: 8,
        h: 8
      }, {
        x: 266,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 8,
        y: 16,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 25,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 16,
        y: 16,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 153,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 0,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 74,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 8,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 16,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 202,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 24,
        y: 8,
        w: 8,
        h: 8
      }, {
        x: 266,
        y: 89,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 25,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 138,
        y: 153,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 32,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 268,
        y: 233,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 36,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 300,
        y: 233,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 44,
        y: 20,
        w: 4,
        h: 12
      }, {
        x: 364,
        y: 233,
        w: 32,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 48,
        y: 20,
        w: 8,
        h: 12
      }, {
        x: 396,
        y: 233,
        w: 64,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 36,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 300,
        y: 201,
        w: 64,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 44,
        y: 16,
        w: 8,
        h: 4
      }, {
        x: 300,
        y: 329,
        w: 64,
        h: 32
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 96,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 112,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 128,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 144,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 112,
        y: 383,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 112,
        y: 639,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 222,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 206,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 190,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 174,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 206,
        y: 383,
        w: 16,
        h: 16
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 222,
        y: 655,
        w: 16,
        h: 16
      }, undefined, 180.0, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 276,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 292,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 308,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 324,
        y: 399,
        w: 16,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 292,
        y: 383,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 292,
        y: 639,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 56,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 401,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 385,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 369,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 62,
        y: 2,
        w: 2,
        h: 30
      }, {
        x: 353,
        y: 399,
        w: 16,
        h: 240
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 58,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 385,
        y: 383,
        w: 16,
        h: 16
      }, "Horizontal", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Enderman Eyes", {
        x: 60,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 401,
        y: 655,
        w: 16,
        h: 16
      }, undefined, 180.0, undefined, undefined);
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
