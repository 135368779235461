// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Caml_int32 = require("rescript/lib/js/caml_int32.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Markdown = require("../../builder/views/Generator_Markdown.bs.js");
var Generator_TexturePicker = require("../../builder/views/Generator_TexturePicker.bs.js");
var MinecraftItem_TexturePicker = require("./MinecraftItem_TexturePicker.bs.js");
var MinecraftItem_TextureVersions = require("./MinecraftItem_TextureVersions.bs.js");

var id = "minecraft-item";

var name = "Minecraft Item";

var history = [
  "26 Jan 2022 lostminer - First release.",
  "05 Feb 2022 NinjolasNJM - Added fold lines and gap removal feature."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var instructions = "\n## Item Sizes\n\nThe generator supports four standard sizes:\n\n* **Medium** - Good for general items (400% scale)\n* **Large** - Good for weapons and tools (700% scale)\n* **Small** - Good for blocks as items (200% scale)\n* **Full Page** - For fun large size items\n";

var images = [
  {
    id: "Background",
    url: require("./images/Background.png")
  },
  {
    id: "Title",
    url: require("./images/Title.png")
  }
];

var textures = Js_array.concat(MinecraftItem_TextureVersions.allTextureDefs, [{
        id: "CenterFold",
        url: require("./textures/CenterFold.png"),
        standardWidth: 2,
        standardHeight: 128
      }]);

function cycleTextureOffset(t, tileWidth) {
  return String(t === tileWidth ? 0 : t + 1 | 0);
}

function makeRegionId(textureId, rectangle) {
  return textureId + "-" + rectangle[0].toString() + "-" + rectangle[1].toString();
}

function getTileWidth(rectangle) {
  return rectangle[2];
}

function drawItem(textureId, rectangle, x, y, size, showFolds) {
  var tileWidth = getTileWidth(rectangle);
  var regionId = makeRegionId(textureId, rectangle);
  var textureOffset = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue(regionId)), 0);
  Generator.defineRegionInput([
        x,
        y,
        size,
        size
      ], (function (param) {
          Generator.setSelectInputValue(regionId, cycleTextureOffset(textureOffset, tileWidth));
        }));
  var offset = Caml_int32.div(Math.imul(textureOffset, size), tileWidth);
  Generator.drawTexture(textureId, rectangle, [
        x + offset | 0,
        y,
        size,
        size
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(textureId, rectangle, [
        (x + size | 0) - offset | 0,
        y,
        size,
        size
      ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
  if (showFolds) {
    return Generator.drawTexture("CenterFold", [
                0,
                0,
                2,
                size
              ], [
                (x + size | 0) - 1 | 0,
                y,
                2,
                size
              ], undefined, undefined, undefined, undefined, undefined, undefined);
  }
  
}

function drawItems(selectedTextureFrames, size, border, maxCols, maxRows, showFolds) {
  var maxItems = Math.imul(maxCols, maxRows);
  var addedCount = selectedTextureFrames.length;
  var pageCount = addedCount > 0 ? Caml_int32.div(addedCount - 1 | 0, maxItems) + 1 | 0 : 0;
  for(var page = 1; page <= pageCount; ++page){
    Generator.usePage("Page " + String(page));
    Generator.drawImage("Background", [
          0,
          0
        ]);
    Belt_Array.forEachWithIndex(selectedTextureFrames, (function (index, selectedTextureFrame) {
            var page = Caml_int32.div(index, maxItems) + 1 | 0;
            var pageId = "Page " + String(page);
            var col = Caml_int32.mod_(index, maxCols);
            var row = Caml_int32.mod_(Caml_int32.div(index, maxCols), maxRows);
            var x = (Math.imul(col, size) << 1);
            var x$1 = col > 0 ? x + Math.imul(border, col) | 0 : x;
            var x$2 = border + x$1 | 0;
            var y = Math.imul(row, size);
            var y$1 = row > 0 ? y + Math.imul(border, row) | 0 : y;
            var y$2 = border + y$1 | 0;
            Generator.usePage(pageId);
            drawItem(selectedTextureFrame.textureDefId, selectedTextureFrame.frame.rectangle, x$2, y$2, size, showFolds);
            Generator.drawImage("Title", [
                  0,
                  0
                ]);
          }));
  }
}

function drawSmall(selectedTextureFrames, showFolds) {
  drawItems(selectedTextureFrames, 32, 25, 6, 13, showFolds);
}

function drawMedium(selectedTextureFrames, showFolds) {
  drawItems(selectedTextureFrames, 64, 15, 4, 10, showFolds);
}

function drawLarge(selectedTextureFrames, showFolds) {
  drawItems(selectedTextureFrames, 112, 20, 2, 6, showFolds);
}

function drawFullPage(selectedTextureFrames) {
  var addedCount = selectedTextureFrames.length;
  var pageCount = (addedCount << 1);
  for(var page = 1; page <= pageCount; ++page){
    Generator.usePage("Page " + String(page));
    Generator.drawImage("Background", [
          0,
          0
        ]);
  }
  Belt_Array.forEachWithIndex(selectedTextureFrames, (function (index, selectedTextureFrame) {
          var frame = selectedTextureFrame.frame;
          var textureDefId = selectedTextureFrame.textureDefId;
          var page1 = (index << 1) + 1 | 0;
          var page1Id = "Page " + String(page1);
          var page2 = (index << 1) + 2 | 0;
          var page2Id = "Page " + String(page2);
          Generator.usePage(page1Id);
          Generator.drawTexture(textureDefId, frame.rectangle, [
                30,
                30,
                512,
                512
              ], undefined, undefined, undefined, undefined, undefined, undefined);
          Generator.drawImage("Title", [
                0,
                0
              ]);
          Generator.usePage(page2Id);
          Generator.drawTexture(textureDefId, frame.rectangle, [
                30,
                30,
                512,
                512
              ], "Horizontal", undefined, undefined, undefined, undefined, undefined);
          Generator.drawImage("Title", [
                0,
                0
              ]);
        }));
}

var sizeSmall = "Small (200%)";

var sizeMedium = "Medium (400%)";

var sizeLarge = "Large (700%)";

var sizeFullPage = "Full Page";

var sizes = [
  sizeMedium,
  sizeLarge,
  sizeSmall,
  sizeFullPage
];

function script(param) {
  Generator.defineSelectInput("Version", MinecraftItem_TextureVersions.versionIds);
  var versionId = Generator.getSelectInputValue("Version");
  var textureVersion = MinecraftItem_TextureVersions.findVersion(versionId);
  Generator.defineSelectInput("Size", sizes);
  var size = Generator.getSelectInputValue("Size");
  Generator.defineCustomStringInput("SelectedTextureFrame", (function (onChange) {
          return React.createElement(MinecraftItem_TexturePicker.make, {
                      textureVersion: textureVersion,
                      onSelect: (function (selectedTexture) {
                          Curry._1(onChange, Generator_TexturePicker.SelectedTexture.encode(selectedTexture));
                        })
                    });
        }));
  Generator.defineBooleanInput("Show Folds", true);
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var selectedTextureFrame = Generator_TexturePicker.SelectedTexture.decode(Generator.getStringInputValue("SelectedTextureFrame"));
  var selectedTextureFrames = Generator_TexturePicker.SelectedTexture.decodeArray(Generator.getStringInputValue("SelectedTextureFrames"));
  Generator.defineButtonInput("Add Item", (function (param) {
          if (selectedTextureFrame === undefined) {
            return ;
          }
          var selectedTextureFrames$1 = Belt_Array.concat(selectedTextureFrames, [selectedTextureFrame]);
          Generator.setStringInputValue("SelectedTextureFrames", Generator_TexturePicker.SelectedTexture.encodeArray(selectedTextureFrames$1));
        }));
  Generator.defineButtonInput("Clear", (function (param) {
          Generator.setStringInputValue("SelectedTextureFrames", Generator_TexturePicker.SelectedTexture.encodeArray([]));
        }));
  if (selectedTextureFrames.length === 0) {
    Generator.usePage("Page 1");
    Generator.drawImage("Background", [
          0,
          0
        ]);
    Generator.drawImage("Title", [
          0,
          0
        ]);
  }
  if (size === sizeSmall) {
    return drawSmall(selectedTextureFrames, showFolds);
  } else if (size === sizeMedium) {
    return drawMedium(selectedTextureFrames, showFolds);
  } else if (size === sizeLarge) {
    return drawLarge(selectedTextureFrames, showFolds);
  } else if (size === sizeFullPage) {
    return drawFullPage(selectedTextureFrames);
  } else {
    return drawMedium(selectedTextureFrames, showFolds);
  }
}

var generator_thumbnail = thumbnail;

var generator_instructions = Caml_option.some(React.createElement(Generator_Markdown.make, {
          children: instructions
        }));

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: generator_instructions,
  images: images,
  textures: textures,
  script: script
};

var Markdown;

var TexturePicker;

var TextureVersions;

exports.Markdown = Markdown;
exports.TexturePicker = TexturePicker;
exports.TextureVersions = TextureVersions;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.instructions = instructions;
exports.images = images;
exports.textures = textures;
exports.cycleTextureOffset = cycleTextureOffset;
exports.makeRegionId = makeRegionId;
exports.getTileWidth = getTileWidth;
exports.drawItem = drawItem;
exports.drawItems = drawItems;
exports.drawSmall = drawSmall;
exports.drawMedium = drawMedium;
exports.drawLarge = drawLarge;
exports.drawFullPage = drawFullPage;
exports.sizeSmall = sizeSmall;
exports.sizeMedium = sizeMedium;
exports.sizeLarge = sizeLarge;
exports.sizeFullPage = sizeFullPage;
exports.sizes = sizes;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
