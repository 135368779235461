// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Belt_Int = require("rescript/lib/js/belt_Int.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var Minecraft = require("../Minecraft.bs.js");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var TextureMap = require("../TextureMap.bs.js");
var Belt_Option = require("rescript/lib/js/belt_Option.js");
var Generator_PageSize = require("../../builder/modules/Generator_PageSize.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "testing";

var name = "Testing";

var history = [];

var images = [
  {
    id: "Grid",
    url: require("./images/Grid.png")
  },
  {
    id: "ImageColors4x4",
    url: require("./images/Colors4x4.png")
  },
  {
    id: "ImageColors64x64",
    url: require("./images/Colors64x64.png")
  }
];

var textures = [
  {
    id: "TextureColors4x4",
    url: require("./textures/Colors4x4.png"),
    standardWidth: 4,
    standardHeight: 4
  },
  {
    id: "TextureColors64x64",
    url: require("./textures/Colors64x64.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Creeper",
    url: require("./textures/Creeper.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Steve",
    url: require("./textures/Steve.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Steve-Corners",
    url: require("./textures/Steve-Corners.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Steve-Faces",
    url: require("./textures/Steve-Faces.png"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Steve256",
    url: require("./textures/Steve256.png"),
    standardWidth: 256,
    standardHeight: 256
  },
  {
    id: "GrassTop",
    url: require("./textures/GrassTop.png"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "GrassSide",
    url: require("./textures/GrassSide.png"),
    standardWidth: 16,
    standardHeight: 16
  }
];

var gridWidth = 512;

var gridHeight = 768;

var offsetX = (Generator_PageSize.A4.px.width - gridWidth | 0) / 2 | 0;

var offsetY = (Generator_PageSize.A4.px.height - gridHeight | 0) / 2 | 0;

var Config = {
  colCount: 4,
  rowCount: 6,
  gridCellSize: 128,
  gridWidth: gridWidth,
  gridHeight: gridHeight,
  offsetX: offsetX,
  offsetY: offsetY
};

function assertEquals(actual, expected) {
  Generator.defineText(actual === expected ? "" + actual + " === " + expected + ": PASS" : "" + actual + " === " + expected + ": FAIL");
}

function drawGrid(param) {
  for(var y = 0; y <= 5; ++y){
    for(var x = 0; x <= 3; ++x){
      var xpos = offsetX + (x << 7) | 0;
      var ypos = offsetY + (y << 7) | 0;
      Generator.drawImage("Grid", [
            xpos,
            ypos
          ]);
    }
  }
}

function drawSteveBodyCuboid(x, y, scale, direction, center) {
  Minecraft.drawCuboid("Steve-Faces", Minecraft.Character.steve.base.body, [
        x,
        y
      ], [
        (scale << 1),
        Math.imul(3, scale),
        scale
      ], direction, center, undefined);
}

function drawCuboidTestPage3(param) {
  Generator.usePage("Cuboid 3");
  Generator.fillBackgroundColorWithWhite(undefined);
  drawSteveBodyCuboid(10, 10, 16, "North", "Front");
  drawSteveBodyCuboid(80, 10, 16, "North", "Back");
  drawSteveBodyCuboid(150, 10, 16, "North", "Right");
  drawSteveBodyCuboid(240, 10, 16, "North", "Left");
  drawSteveBodyCuboid(330, 10, 16, "North", "Top");
  drawSteveBodyCuboid(460, 10, 16, "North", "Bottom");
  var y = 190;
  drawSteveBodyCuboid(10, y, 16, "South", "Front");
  drawSteveBodyCuboid(80, y, 16, "South", "Back");
  drawSteveBodyCuboid(150, y, 16, "South", "Right");
  drawSteveBodyCuboid(240, y, 16, "South", "Left");
  drawSteveBodyCuboid(330, y, 16, "South", "Top");
  drawSteveBodyCuboid(460, y, 16, "South", "Bottom");
  var y$1 = y + 180 | 0;
  drawSteveBodyCuboid(10, y$1, 16, "West", "Front");
  drawSteveBodyCuboid(110, y$1, 16, "West", "Back");
  drawSteveBodyCuboid(210, y$1, 16, "West", "Right");
  drawSteveBodyCuboid(310, y$1, 16, "West", "Left");
  drawSteveBodyCuboid(380, y$1 - 50 | 0, 16, "West", "Top");
  drawSteveBodyCuboid(420, y$1 + 20 | 0, 16, "West", "Bottom");
  var y$2 = y$1 + 180 | 0;
  drawSteveBodyCuboid(10, y$2, 16, "East", "Front");
  drawSteveBodyCuboid(110, y$2, 16, "East", "Back");
  drawSteveBodyCuboid(210, y$2, 16, "East", "Right");
  drawSteveBodyCuboid(310, y$2, 16, "East", "Left");
  drawSteveBodyCuboid(380, y$2 - 50 | 0, 16, "East", "Top");
  drawSteveBodyCuboid(420, y$2 + 20 | 0, 16, "East", "Bottom");
}

function drawSteveHeadCuboid2(x, y, center) {
  var x$1 = x - 64 | 0;
  var y$1 = y - 64 | 0;
  Minecraft.drawCuboid("Steve-Faces", Minecraft.Character.steve.base.head, [
        x$1,
        y$1
      ], [
        64,
        64,
        64
      ], "East", center, undefined);
}

function drawCuboidTestPage2(param) {
  Generator.usePage("Cuboid 2");
  Generator.fillBackgroundColorWithWhite(undefined);
  Generator.defineText("Answer: " + String(2));
  drawSteveHeadCuboid2(99, 79, "Right");
  drawSteveHeadCuboid2(387, 79, "Front");
  drawSteveHeadCuboid2(99, 279, "Left");
  drawSteveHeadCuboid2(387, 279, "Back");
  drawSteveHeadCuboid2(99, 479, "Top");
  drawSteveHeadCuboid2(387, 479, "Bottom");
  drawSteveHeadCuboid2(99, 679, "Front");
  drawSteveHeadCuboid2(387, 679, "Front");
}

function drawSteveHeadCuboid(x, y, size, direction) {
  var match = direction === "West" || direction === "East" ? [
      (size << 2),
      Math.imul(size, 3)
    ] : [
      Math.imul(size, 3),
      (size << 2)
    ];
  Generator.drawTexture("TextureColors4x4", [
        2,
        0,
        1,
        1
      ], [
        x,
        y,
        match[0],
        match[1]
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawCuboid("Steve-Corners", Minecraft.Character.steve.base.head, [
        x,
        y
      ], [
        size,
        size,
        size
      ], direction, undefined, undefined);
}

function drawCuboidTestPage(param) {
  Generator.usePage("Cuboid");
  Generator.fillBackgroundColorWithWhite(undefined);
  drawSteveHeadCuboid(10, 10, 10, "East");
  drawSteveHeadCuboid(100, 10, 10, "West");
  drawSteveHeadCuboid(10, 100, 10, "North");
  drawSteveHeadCuboid(100, 100, 10, "South");
  drawSteveHeadCuboid(200, 10, 33, "East");
  drawSteveHeadCuboid(400, 10, 33, "West");
  drawSteveHeadCuboid(200, 150, 33, "North");
  drawSteveHeadCuboid(400, 150, 33, "South");
  drawSteveHeadCuboid(10, 300, 64, "East");
  drawSteveHeadCuboid(330, 300, 64, "West");
  drawSteveHeadCuboid(10, 550, 64, "North");
  drawSteveHeadCuboid(330, 550, 64, "South");
}

function drawSteveHead(texture, ox, oy, size) {
  var head = TextureMap.MinecraftCharacter.steve.base.head;
  Generator.drawTexture(texture, head.front, [
        ox + size | 0,
        oy + size | 0,
        size,
        size
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, head.right, [
        ox,
        oy + size | 0,
        size,
        size
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, head.left, [
        ox + (size << 1) | 0,
        oy + size | 0,
        size,
        size
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, head.top, [
        ox + size | 0,
        oy,
        size,
        size
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, head.back, [
        ox + Math.imul(size, 3) | 0,
        oy + size | 0,
        size,
        size
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(texture, head.bottom, [
        ox + size | 0,
        oy + (size << 1) | 0,
        size,
        size
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
}

function drawFoldBox(x, y, size, direction) {
  var p1 = [
    x,
    y
  ];
  var p2_0 = x + size | 0;
  var p2 = [
    p2_0,
    y
  ];
  var p3_0 = x + size | 0;
  var p3_1 = y + size | 0;
  var p3 = [
    p3_0,
    p3_1
  ];
  var p4_1 = y + size | 0;
  var p4 = [
    x,
    p4_1
  ];
  if (direction === "clockwise") {
    Generator.drawFoldLine(p1, p2);
    Generator.drawFoldLine(p2, p3);
    Generator.drawFoldLine(p3, p4);
    return Generator.drawFoldLine(p4, p1);
  } else {
    Generator.drawFoldLine(p2, p1);
    Generator.drawFoldLine(p3, p2);
    Generator.drawFoldLine(p4, p3);
    return Generator.drawFoldLine(p1, p4);
  }
}

function drawFoldsSun(ox, oy, size) {
  var angles = [
    0.0,
    15.0,
    30.0,
    45.0,
    60.0,
    75.0,
    90.0,
    105.0,
    120.0,
    135.0,
    150.0,
    165.0,
    180.0,
    195.0,
    210.0,
    225.0,
    240.0,
    255.0,
    270.0,
    285.0,
    300.0,
    315.0,
    330.0,
    345.0
  ];
  var outerRadius = 20.0 + size;
  Belt_Array.forEach(angles, (function (angle) {
          var radians = angle * Math.PI / 180.0;
          var x1 = 20.0 * Math.cos(radians) | 0;
          var y1 = 20.0 * Math.sin(radians) | 0;
          var x2 = outerRadius * Math.cos(radians) | 0;
          var y2 = outerRadius * Math.sin(radians) | 0;
          Generator.drawFoldLine([
                ox + x1 | 0,
                oy + y1 | 0
              ], [
                ox + x2 | 0,
                oy + y2 | 0
              ]);
        }));
}

function drawFoldLinesTestPage(param) {
  Generator.usePage("Fold Lines");
  Generator.drawText("Clockwise", [
        40,
        50
      ], 16);
  drawSteveHead("Steve", 40, 70, 64);
  drawFoldBox(104, 134, 64, "clockwise");
  Generator.drawText("Anti-Clockwise", [
        300,
        50
      ], 16);
  drawSteveHead("Steve", 300, 70, 64);
  drawFoldBox(364, 134, 64, "anticlockwise");
  Generator.drawText("Arbitrary Sizes", [
        40,
        320
      ], 16);
  for(var index = 0; index <= 19; ++index){
    var size = 4 + index | 0;
    var x = 40 + Math.imul(index, 27) | 0;
    drawFoldBox(x, 340, size, "clockwise");
  }
  for(var index$1 = 0; index$1 <= 12; ++index$1){
    var size$1 = 24 + index$1 | 0;
    var x$1 = 40 + Math.imul(index$1, 40) | 0;
    drawFoldBox(x$1, 380, size$1, "clockwise");
  }
  Generator.drawText("Power of 2 Sizes", [
        40,
        440
      ], 16);
  for(var index$2 = 0; index$2 <= 4; ++index$2){
    var exp = index$2 + 2.0;
    var size$2 = Math.pow(2.0, exp) | 0;
    var x$2 = 40 + Math.imul(index$2, 50) | 0;
    drawFoldBox(x$2, 460, size$2, "clockwise");
  }
  Generator.drawText("Contrast", [
        40,
        560
      ], 16);
  Generator.drawTexture("TextureColors64x64", [
        0,
        0,
        64,
        64
      ], [
        40,
        580,
        128,
        128
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  drawFoldBox(56, 596, 96, "clockwise");
  drawFoldBox(88, 628, 32, "clockwise");
  Generator.drawText("Angles", [
        280,
        560
      ], 16);
  drawFoldsSun(420, 660, 100.0);
}

function drawSun(ox, oy, color, width) {
  var angles = [
    0.0,
    15.0,
    30.0,
    45.0,
    60.0,
    75.0,
    90.0,
    105.0,
    120.0,
    135.0,
    150.0,
    165.0,
    180.0,
    195.0,
    210.0,
    225.0,
    240.0,
    255.0,
    270.0,
    285.0,
    300.0,
    315.0,
    330.0,
    345.0
  ];
  Belt_Array.forEach(angles, (function (angle) {
          var radians = angle * Math.PI / 180.0;
          var x1 = 20.0 * Math.cos(radians) | 0;
          var y1 = 20.0 * Math.sin(radians) | 0;
          var x2 = 50.0 * Math.cos(radians) | 0;
          var y2 = 50.0 * Math.sin(radians) | 0;
          Generator.drawLine([
                ox + x1 | 0,
                oy + y1 | 0
              ], [
                ox + x2 | 0,
                oy + y2 | 0
              ], color, width, undefined);
        }));
}

function drawSquare(ox, oy, color, width) {
  var ox$1 = ox - 25 | 0;
  var oy$1 = oy - 25 | 0;
  var p1 = [
    ox$1,
    oy$1
  ];
  var p2_0 = ox$1 + 50 | 0;
  var p2 = [
    p2_0,
    oy$1
  ];
  var p3_0 = ox$1 + 50 | 0;
  var p3_1 = oy$1 + 50 | 0;
  var p3 = [
    p3_0,
    p3_1
  ];
  var p4_1 = oy$1 + 50 | 0;
  var p4 = [
    ox$1,
    p4_1
  ];
  Generator.drawLine(p1, p2, color, width, undefined);
  Generator.drawLine(p2, p3, color, width, undefined);
  Generator.drawLine(p3, p4, color, width, undefined);
  Generator.drawLine(p4, p1, color, width, undefined);
}

function drawLinesTestPage(param) {
  Generator.usePage("Lines");
  drawSun(100, 100, "#000000", 1);
  drawSun(250, 100, "#000000", 2);
  drawSun(400, 100, "#000000", 3);
  drawSun(100, 250, "#ff0000", 1);
  drawSun(250, 250, "#00ff00", 2);
  drawSun(400, 250, "#0000ff", 3);
  drawSquare(100, 400, "#000000", 1);
  drawSquare(250, 400, "#000000", 2);
  drawSquare(400, 400, "#000000", 3);
  drawSquare(100, 550, "#ff0000", 1);
  drawSquare(250, 550, "#00ff00", 2);
  drawSquare(400, 550, "#0000ff", 3);
}

var rgbaToHex = (function rgbaToHex(rgba) {
    let r = rgba[0].toString(16);
    let g = rgba[1].toString(16);
    let b = rgba[2].toString(16);
    let a = rgba[3].toString(16);

    if (r.length == 1)
      r = "0" + r;
    if (g.length == 1)
      g = "0" + g;
    if (b.length == 1)
      b = "0" + b;
    if (a.length == 1)
      a = "0" + a;

    return "#" + r + g + b + a;
  });

function drawTextureImagePageColorTestPage(param) {
  Generator.usePage("Texture Image Page Color Test");
  var makeAssertText = function (actual, expected) {
    var label = "Actual: " + actual + "; Expected: " + expected + ";";
    var result = actual === expected ? "SUCCESS" : "FAIL";
    return "" + label + " Result: " + result + "";
  };
  var getTexturePixelColor = function (id, x, y) {
    var color = Generator.getTexturePixelColor(id, x, y);
    if (color !== undefined) {
      return rgbaToHex(color);
    } else {
      return "Unknown";
    }
  };
  var getImagePixelColor = function (id, x, y) {
    var color = Generator.getImagePixelColor(id, x, y);
    if (color !== undefined) {
      return rgbaToHex(color);
    } else {
      return "Unknown";
    }
  };
  var getPagePixelColor = function (x, y) {
    var color = Generator.getCurrentPagePixelColor(x, y);
    if (color !== undefined) {
      return rgbaToHex(color);
    } else {
      return "Unknown";
    }
  };
  Generator.drawText("Texture Color Test", [
        20,
        40
      ], 24);
  Generator.drawTexture("TextureColors64x64", [
        0,
        0,
        64,
        64
      ], [
        500,
        20,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  var textureColor00 = getTexturePixelColor("TextureColors64x64", 0, 0);
  var textureColor11 = getTexturePixelColor("TextureColors64x64", 16, 16);
  var textureColor22 = getTexturePixelColor("TextureColors64x64", 32, 32);
  var textureColor33 = getTexturePixelColor("TextureColors64x64", 48, 48);
  Generator.drawText(makeAssertText(textureColor00, "#000000ff"), [
        20,
        80
      ], 16);
  Generator.drawText(makeAssertText(textureColor11, "#777777ff"), [
        20,
        120
      ], 16);
  Generator.drawText(makeAssertText(textureColor22, "#ff000080"), [
        20,
        160
      ], 16);
  Generator.drawText(makeAssertText(textureColor33, "#ffffff80"), [
        20,
        200
      ], 16);
  Generator.drawText("Image Color Test", [
        20,
        260
      ], 24);
  Generator.drawImage("ImageColors64x64", [
        500,
        240
      ]);
  var imageColor00 = getImagePixelColor("ImageColors64x64", 0, 0);
  var imageColor11 = getImagePixelColor("ImageColors64x64", 16, 16);
  var imageColor22 = getImagePixelColor("ImageColors64x64", 32, 32);
  var imageColor33 = getImagePixelColor("ImageColors64x64", 48, 48);
  Generator.drawText(makeAssertText(imageColor00, "#000000ff"), [
        20,
        300
      ], 16);
  Generator.drawText(makeAssertText(imageColor11, "#777777ff"), [
        20,
        340
      ], 16);
  Generator.drawText(makeAssertText(imageColor22, "#ff000080"), [
        20,
        380
      ], 16);
  Generator.drawText(makeAssertText(imageColor33, "#ffffff80"), [
        20,
        420
      ], 16);
  Generator.drawText("Page Color Test", [
        20,
        480
      ], 24);
  Generator.drawImage("ImageColors64x64", [
        500,
        460
      ]);
  Generator.fillBackgroundColor("#ffffff");
  var pageColor00 = getPagePixelColor(500, 460);
  var pageColor11 = getPagePixelColor(516, 476);
  var pageColor22 = getPagePixelColor(532, 492);
  var pageColor33 = getPagePixelColor(548, 508);
  Generator.drawText(makeAssertText(pageColor00, "#000000ff"), [
        20,
        520
      ], 16);
  Generator.drawText(makeAssertText(pageColor11, "#777777ff"), [
        20,
        560
      ], 16);
  Generator.drawText(makeAssertText(pageColor22, "#ff7f7fff"), [
        20,
        600
      ], 16);
  Generator.drawText(makeAssertText(pageColor33, "#ffffffff"), [
        20,
        640
      ], 16);
}

function drawButtonInputTest(param) {
  var counter = Belt_Option.getWithDefault(Belt_Int.fromString(Generator.getSelectInputValue("Counter")), 1);
  Generator.defineText("Counter is " + String(counter));
  Generator.defineButtonInput("Increment Counter", (function (param) {
          var nextCounter = counter >= 6 ? 1 : counter + 1 | 0;
          var nextCounterString = String(nextCounter);
          Generator.setSelectInputValue("Counter", nextCounterString);
        }));
}

function drawTextureRotationTest(texture, src) {
  drawGrid(undefined);
  var dst = function (gridx, gridy) {
    return [
            (offsetX + (gridx << 7) | 0) + 32 | 0,
            (offsetY + (gridy << 7) | 0) + 32 | 0,
            64,
            64
          ];
  };
  Generator.drawTexture(texture, src, dst(0, 0), undefined, undefined, undefined, 0.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(1, 0), undefined, undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(2, 0), undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(3, 0), undefined, undefined, undefined, 270.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(0, 1), "Horizontal", undefined, undefined, 0.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(1, 1), "Horizontal", undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(2, 1), "Horizontal", undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(3, 1), "Horizontal", undefined, undefined, 270.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(0, 2), "Vertical", undefined, undefined, 0.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(1, 2), "Vertical", undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(2, 2), "Vertical", undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(3, 2), "Vertical", undefined, undefined, 270.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(0, 3), undefined, undefined, undefined, 45.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(1, 3), undefined, undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(2, 3), undefined, undefined, undefined, 135.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(3, 3), undefined, undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(0, 4), "Horizontal", undefined, undefined, 45.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(1, 4), "Horizontal", undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(2, 4), "Horizontal", undefined, undefined, 135.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(3, 4), "Horizontal", undefined, undefined, 180.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(0, 5), "Vertical", undefined, undefined, 45.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(1, 5), "Vertical", undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(2, 5), "Vertical", undefined, undefined, 135.0, undefined, undefined);
  Generator.drawTexture(texture, src, dst(3, 5), "Vertical", undefined, undefined, 180.0, undefined, undefined);
}

function drawTextureRotation64Test(param) {
  Generator.usePage("Texture Rotation 64");
  drawTextureRotationTest("Steve", [
        8,
        8,
        8,
        8
      ]);
}

function drawTextureRotation256Test(param) {
  Generator.usePage("Texture Rotation 256");
  drawTextureRotationTest("Steve256", [
        32,
        32,
        32,
        32
      ]);
}

function drawTextureCropRotationTest(param) {
  Generator.usePage("Texture Crop Rotation");
  drawGrid(undefined);
  var dw = 64;
  var dh = 24;
  var src = [
    8,
    11,
    8,
    3
  ];
  var dst = function (gridx, gridy) {
    return [
            ((offsetX + (gridx << 7) | 0) + 64 | 0) - (dw / 2 | 0) | 0,
            ((offsetY + (gridy << 7) | 0) + 64 | 0) - (dh / 2 | 0) | 0,
            dw,
            dh
          ];
  };
  var deg = 360.0 / 24;
  for(var row = 0; row <= 5; ++row){
    for(var col = 0; col <= 3; ++col){
      var factor = (row << 2) + col | 0;
      var rotate = deg * factor;
      Generator.drawTexture("Steve", src, dst(col, row), undefined, undefined, undefined, rotate, undefined, undefined);
    }
  }
}

function drawTextureTintTest(param) {
  Generator.usePage("Texture Tint");
  drawGrid(undefined);
  var size = 96;
  var indent = 16;
  var tints = [
    "#90814D",
    "#BFB755",
    "#59C93C",
    "#64C73F",
    "#79C05A",
    "#88BB67",
    "#507A32",
    "#6A7039",
    "#4C763C",
    "#91BD59",
    "#8EB971",
    "#55C93F",
    "#8AB689",
    "#83B593",
    "#86B87F",
    "#86B783",
    "#80B497"
  ];
  for(var row = 1; row <= 6; ++row){
    for(var col = 1; col <= 4; ++col){
      var x = (offsetX + ((col - 1 | 0) << 7) | 0) + indent | 0;
      var y = (offsetY + ((row - 1 | 0) << 7) | 0) + indent | 0;
      var index = (col - 1 | 0) + ((row - 1 | 0) << 2) | 0;
      var tint = Belt_Array.get(tints, index);
      if (tint !== undefined) {
        Generator.drawTexture("GrassTop", [
              0,
              0,
              16,
              16
            ], [
              x,
              y,
              size,
              size
            ], undefined, {
              NAME: "MultiplyHex",
              VAL: tint
            }, undefined, undefined, undefined, undefined);
      } else {
        Generator.drawTexture("Steve", [
              8,
              8,
              8,
              8
            ], [
              x,
              y,
              size,
              size
            ], undefined, undefined, undefined, undefined, undefined, undefined);
        Generator.drawTexture("GrassSide", [
              0,
              0,
              16,
              16
            ], [
              x,
              y,
              size,
              size
            ], undefined, {
              NAME: "MultiplyHex",
              VAL: "#59C93C"
            }, undefined, undefined, undefined, undefined);
      }
    }
  }
}

function drawTextureCropTest(param) {
  Generator.usePage("Texture Crop");
  var size = 96;
  var indent = 16;
  var tests = [
    [
      8,
      8,
      8,
      8,
      size,
      size,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size / 2 | 0,
      size,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size / 2 | 0,
      size,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size / 4 | 0,
      size,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size / 4 | 0,
      size,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size / 8 | 0,
      size,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size / 8 | 0,
      size,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size / 2 | 0,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size / 2 | 0,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size / 4 | 0,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size / 4 | 0,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size / 8 | 0,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size,
      size / 8 | 0,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size / 2 | 0,
      size / 2 | 0,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size / 2 | 0,
      size / 2 | 0,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size / 4 | 0,
      size / 4 | 0,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size / 4 | 0,
      size / 4 | 0,
      true
    ],
    [
      8,
      8,
      8,
      8,
      size / 8 | 0,
      size / 8 | 0,
      false
    ],
    [
      8,
      8,
      8,
      8,
      size / 8 | 0,
      size / 8 | 0,
      true
    ],
    [
      8,
      8,
      8,
      8,
      8,
      8,
      true
    ],
    [
      8,
      8,
      8,
      8,
      4,
      4,
      true
    ],
    [
      8,
      8,
      8,
      8,
      2,
      2,
      true
    ],
    [
      8,
      8,
      8,
      8,
      1,
      1,
      true
    ]
  ];
  drawGrid(undefined);
  for(var row = 1; row <= 6; ++row){
    for(var col = 1; col <= 4; ++col){
      var x = (offsetX + ((col - 1 | 0) << 7) | 0) + indent | 0;
      var y = (offsetY + ((row - 1 | 0) << 7) | 0) + indent | 0;
      var index = (col - 1 | 0) + ((row - 1 | 0) << 2) | 0;
      if (index < tests.length) {
        var test = tests[index];
        if (test !== undefined) {
          Generator.drawTexture("Steve", [
                test[0],
                test[1],
                test[2],
                test[3]
              ], [
                x,
                y,
                test[4],
                test[5]
              ], undefined, undefined, undefined, undefined, test[6], undefined);
        }
        
      }
      
    }
  }
}

function drawRectTab(rectangle, orientation, tabAngle) {
  Generator.fillRect(rectangle, "#ff000020");
  Generator.drawTab(rectangle, orientation, undefined, tabAngle, undefined);
}

function drawTabsTestPage(param) {
  Generator.usePage("Tabs");
  Generator.fillBackgroundColorWithWhite(undefined);
  drawRectTab([
        10,
        10,
        100,
        30
      ], "North", 45.0);
  drawRectTab([
        150,
        10,
        100,
        30
      ], "South", 45.0);
  drawRectTab([
        300,
        10,
        100,
        30
      ], "East", 45.0);
  drawRectTab([
        450,
        10,
        100,
        30
      ], "West", 45.0);
  drawRectTab([
        10,
        80,
        100,
        100
      ], "North", 45.0);
  drawRectTab([
        150,
        80,
        100,
        100
      ], "South", 45.0);
  drawRectTab([
        300,
        80,
        100,
        100
      ], "East", 45.0);
  drawRectTab([
        450,
        80,
        100,
        100
      ], "West", 45.0);
  drawRectTab([
        10,
        220,
        30,
        100
      ], "North", 45.0);
  drawRectTab([
        150,
        220,
        30,
        100
      ], "South", 45.0);
  drawRectTab([
        300,
        220,
        30,
        100
      ], "East", 45.0);
  drawRectTab([
        450,
        220,
        30,
        100
      ], "West", 45.0);
  drawRectTab([
        10,
        400,
        100,
        50
      ], "North", 80.0);
  drawRectTab([
        150,
        400,
        100,
        50
      ], "South", 80.0);
  drawRectTab([
        300,
        400,
        100,
        50
      ], "East", 80.0);
  drawRectTab([
        450,
        400,
        100,
        50
      ], "West", 80.0);
  drawRectTab([
        10,
        500,
        100,
        100
      ], "North", 80.0);
  drawRectTab([
        150,
        500,
        100,
        100
      ], "South", 80.0);
  drawRectTab([
        300,
        500,
        100,
        100
      ], "East", 80.0);
  drawRectTab([
        450,
        500,
        100,
        100
      ], "West", 80.0);
  drawRectTab([
        10,
        650,
        50,
        100
      ], "North", 80.0);
  drawRectTab([
        150,
        650,
        50,
        100
      ], "South", 80.0);
  drawRectTab([
        300,
        650,
        50,
        100
      ], "East", 80.0);
  drawRectTab([
        450,
        650,
        50,
        100
      ], "West", 80.0);
}

function drawSteveHeadWithTabs(texture, ox, oy, size) {
  var head = Minecraft.Character.steve.base.head;
  var frontFace_0 = ox + size | 0;
  var frontFace_1 = oy + size | 0;
  var frontFace = [
    frontFace_0,
    frontFace_1,
    size,
    size
  ];
  Generator.drawTexture(texture, head.front, frontFace, undefined, undefined, undefined, undefined, undefined, undefined);
  var rightFace_1 = oy + size | 0;
  var rightFace = [
    ox,
    rightFace_1,
    size,
    size
  ];
  Generator.drawTexture(texture, head.right, rightFace, undefined, undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawFaceTabs(rightFace, [
        "West",
        "North",
        "South"
      ], undefined, undefined, undefined, undefined);
  var leftFace_0 = ox + (size << 1) | 0;
  var leftFace_1 = oy + size | 0;
  var leftFace = [
    leftFace_0,
    leftFace_1,
    size,
    size
  ];
  Generator.drawTexture(texture, head.left, leftFace, undefined, undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawFaceTabs(leftFace, [
        "North",
        "South"
      ], undefined, undefined, undefined, undefined);
  var topFace_0 = ox + size | 0;
  var topFace = [
    topFace_0,
    oy,
    size,
    size
  ];
  Generator.drawTexture(texture, head.top, topFace, undefined, undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawFaceTabs(topFace, [
        "North",
        "West",
        "East"
      ], undefined, undefined, undefined, undefined);
  var backFace_0 = ox + Math.imul(size, 3) | 0;
  var backFace_1 = oy + size | 0;
  var backFace = [
    backFace_0,
    backFace_1,
    size,
    size
  ];
  Generator.drawTexture(texture, head.back, backFace, undefined, undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawFaceTabs(backFace, [
        "North",
        "South",
        "East"
      ], undefined, undefined, undefined, undefined);
  var bottomFace_0 = ox + size | 0;
  var bottomFace_1 = oy + (size << 1) | 0;
  var bottomFace = [
    bottomFace_0,
    bottomFace_1,
    size,
    size
  ];
  Generator.drawTexture(texture, head.bottom, bottomFace, "Vertical", undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawFaceTabs(bottomFace, [
        "West",
        "East",
        "South"
      ], undefined, undefined, undefined, undefined);
}

function drawSteveFaceWithTabs(x, y, w, h, size, tabAngle, param) {
  var size$1 = size !== undefined ? size : Minecraft.getTabSize(undefined);
  var head = Minecraft.Character.steve.base.head;
  var face = [
    x,
    y,
    w,
    h
  ];
  Generator.drawTexture("Steve", head.front, face, undefined, undefined, undefined, undefined, undefined, undefined);
  Minecraft.drawFaceTabs(face, [
        "North",
        "East",
        "South",
        "West"
      ], undefined, tabAngle, size$1, undefined);
}

function drawFaceTabsTestPage(param) {
  Generator.usePage("Face Tabs");
  Generator.fillBackgroundColorWithWhite(undefined);
  Minecraft.setTabSize(24);
  drawSteveFaceWithTabs(50, 50, 64, 64, undefined, 45.0, undefined);
  drawSteveFaceWithTabs(200, 50, 64, 32, undefined, 45.0, undefined);
  drawSteveFaceWithTabs(350, 50, 32, 64, undefined, 45.0, undefined);
  drawSteveFaceWithTabs(500, 50, 32, 32, undefined, 45.0, undefined);
  Minecraft.setTabSize(48);
  drawSteveFaceWithTabs(50, 200, 64, 64, undefined, 45.0, undefined);
  drawSteveFaceWithTabs(200, 200, 64, 32, undefined, 45.0, undefined);
  drawSteveFaceWithTabs(350, 200, 32, 64, undefined, 45.0, undefined);
  drawSteveFaceWithTabs(500, 200, 32, 32, undefined, 45.0, undefined);
  Minecraft.setTabSize(12);
  drawSteveFaceWithTabs(50, 350, 64, 64, undefined, 60.0, undefined);
  drawSteveFaceWithTabs(200, 350, 64, 32, undefined, 60.0, undefined);
  drawSteveFaceWithTabs(350, 350, 32, 64, undefined, 60.0, undefined);
  drawSteveFaceWithTabs(500, 350, 32, 32, undefined, 60.0, undefined);
  Minecraft.setTabSize(12);
  drawSteveFaceWithTabs(50, 500, 64, 64, undefined, 30.0, undefined);
  drawSteveFaceWithTabs(200, 500, 64, 32, undefined, 30.0, undefined);
  drawSteveFaceWithTabs(350, 500, 32, 64, undefined, 30.0, undefined);
  drawSteveFaceWithTabs(500, 500, 32, 32, undefined, 30.0, undefined);
  drawSteveFaceWithTabs(400, 650, 16, 16, 8, 45.0, undefined);
  drawSteveFaceWithTabs(450, 650, 16, 8, 8, 45.0, undefined);
  drawSteveFaceWithTabs(400, 700, 8, 16, 8, 45.0, undefined);
  drawSteveFaceWithTabs(450, 700, 8, 8, 4, 45.0, undefined);
  Minecraft.setTabSize(24);
  drawSteveHeadWithTabs("Steve", 50, 600, 64);
}

function script(param) {
  drawCuboidTestPage3(undefined);
  drawCuboidTestPage2(undefined);
  drawFaceTabsTestPage(undefined);
  drawTabsTestPage(undefined);
  drawCuboidTestPage(undefined);
  drawFoldLinesTestPage(undefined);
  drawLinesTestPage(undefined);
  drawTextureImagePageColorTestPage(undefined);
  drawButtonInputTest(undefined);
  drawTextureRotation64Test(undefined);
  drawTextureRotation256Test(undefined);
  drawTextureCropRotationTest(undefined);
  drawTextureTintTest(undefined);
  drawTextureCropTest(undefined);
}

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: undefined,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

var PageSize;

exports.PageSize = PageSize;
exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.images = images;
exports.textures = textures;
exports.Config = Config;
exports.assertEquals = assertEquals;
exports.drawGrid = drawGrid;
exports.drawSteveBodyCuboid = drawSteveBodyCuboid;
exports.drawCuboidTestPage3 = drawCuboidTestPage3;
exports.drawSteveHeadCuboid2 = drawSteveHeadCuboid2;
exports.drawCuboidTestPage2 = drawCuboidTestPage2;
exports.drawSteveHeadCuboid = drawSteveHeadCuboid;
exports.drawCuboidTestPage = drawCuboidTestPage;
exports.drawSteveHead = drawSteveHead;
exports.drawFoldBox = drawFoldBox;
exports.drawFoldsSun = drawFoldsSun;
exports.drawFoldLinesTestPage = drawFoldLinesTestPage;
exports.drawSun = drawSun;
exports.drawSquare = drawSquare;
exports.drawLinesTestPage = drawLinesTestPage;
exports.rgbaToHex = rgbaToHex;
exports.drawTextureImagePageColorTestPage = drawTextureImagePageColorTestPage;
exports.drawButtonInputTest = drawButtonInputTest;
exports.drawTextureRotationTest = drawTextureRotationTest;
exports.drawTextureRotation64Test = drawTextureRotation64Test;
exports.drawTextureRotation256Test = drawTextureRotation256Test;
exports.drawTextureCropRotationTest = drawTextureCropRotationTest;
exports.drawTextureTintTest = drawTextureTintTest;
exports.drawTextureCropTest = drawTextureCropTest;
exports.drawRectTab = drawRectTab;
exports.drawTabsTestPage = drawTabsTestPage;
exports.drawSteveHeadWithTabs = drawSteveHeadWithTabs;
exports.drawSteveFaceWithTabs = drawSteveFaceWithTabs;
exports.drawFaceTabsTestPage = drawFaceTabsTestPage;
exports.script = script;
exports.generator = generator;
/* images Not a pure module */
