// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var Style = {};

var $$Element = {};

var $$Window = {};

function setSrcDocAttribute(iframe, value) {
  iframe.setAttribute("srcdoc", value);
}

var Iframe = {
  setSrcDocAttribute: setSrcDocAttribute
};

var $$Image = {};

function setFillStyleRGBA(context, r, g, b, a) {
  var value = "rgba(" + r.toString() + "," + g.toString() + "," + b.toString() + "," + a.toString() + ")";
  context.fillStyle = value;
}

var Context2d = {
  setFillStyleRGBA: setFillStyleRGBA
};

var Body = {};

function createCanvasElement($$document) {
  return $$document.createElement("canvas");
}

function createIframeElement($$document) {
  return $$document.createElement("iframe");
}

var $$Document = {
  createCanvasElement: createCanvasElement,
  createIframeElement: createIframeElement
};

function make(width, height) {
  var canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

function getContext2d(canvas) {
  return canvas.getContext("2d");
}

function getContext2dWithAlpha(canvas) {
  return canvas.getContext("2d", {
              alpha: true
            });
}

function toDataUrlAsPng(canvas) {
  return canvas.toDataURL("image/png");
}

var Canvas = {
  make: make,
  getContext2d: getContext2d,
  getContext2dWithAlpha: getContext2dWithAlpha,
  toDataUrlAsPng: toDataUrlAsPng
};

var $$File = {};

var $$FileReader = {};

var $$FileList = {};

function currentTarget(prim) {
  return prim.currentTarget;
}

function currentTargetFiles($$event) {
  return $$event.currentTarget.files;
}

var $$Event = {
  currentTarget: currentTarget,
  currentTargetFiles: currentTargetFiles
};

var $$FormData = {};

exports.Style = Style;
exports.$$Element = $$Element;
exports.$$Window = $$Window;
exports.Iframe = Iframe;
exports.$$Image = $$Image;
exports.Context2d = Context2d;
exports.Body = Body;
exports.$$Document = $$Document;
exports.Canvas = Canvas;
exports.$$File = $$File;
exports.$$FileReader = $$FileReader;
exports.$$FileList = $$FileList;
exports.$$Event = $$Event;
exports.$$FormData = $$FormData;
/* No side effect */
