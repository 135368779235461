// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var ReactDom = require("react-dom");
var Components = require("./Components.bs.js");

require('tailwindcss/tailwind.css');
;

var root = document.getElementById("root");

ReactDom.render(React.createElement(Components.App.make, {}), root);

exports.root = root;
/*  Not a pure module */
