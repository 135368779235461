// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Generator = require("./Generator.bs.js");
var Generator_Builder = require("./Generator_Builder.bs.js");

function run(generatorDef, model) {
  return new Promise((function (resolve, _reject) {
                setTimeout((function (param) {
                        var init = Generator_Builder.Model.make(undefined);
                        var model_inputs = init.inputs;
                        var model_pages = init.pages;
                        var model_currentPage = init.currentPage;
                        var model_values = model.values;
                        var model$1 = {
                          inputs: model_inputs,
                          pages: model_pages,
                          currentPage: model_currentPage,
                          values: model_values
                        };
                        Generator.setModel(model$1);
                        Curry._1(generatorDef.script, undefined);
                        resolve(Generator.getModel(undefined));
                      }), 1);
              }));
}

var Builder;

exports.Builder = Builder;
exports.run = run;
/* Generator Not a pure module */
