// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var A4_px = {
  width: 595,
  height: 842
};

var A4_mm = {
  width: 210,
  height: 297
};

var A4 = {
  px: A4_px,
  mm: A4_mm
};

exports.A4 = A4;
/* No side effect */
