// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-golem";

var name = "Minecraft Golem";

var history = [
  "Originally Developed by Wajy.",
  "06 Feb 2015 lostminer - Add user variables.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "20 Feb 2015 lostminer - Make the nose optional.",
  "19 Sep 2020 NinjolasNJM - Fixed bottom textures and allowed for multiple types of flowers and damage cracks.",
  "07 Jun 2021 NinjolasNJM - Converted to ReScript generator. The ability to select between several default flower and damage textures still needs to be implemented."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Golem",
    url: requireTexture("iron_golem"),
    standardWidth: 128,
    standardHeight: 128
  },
  {
    id: "Poppy Flower",
    url: requireTexture("flower_poppy"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "Rose Flower",
    url: requireTexture("flower_rose"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "Cyan Flower",
    url: requireTexture("flower_cyan"),
    standardWidth: 16,
    standardHeight: 16
  },
  {
    id: "Low Damage",
    url: requireTexture("damage_low"),
    standardWidth: 128,
    standardHeight: 128
  },
  {
    id: "Medium Damage",
    url: requireTexture("damage_medium"),
    standardWidth: 128,
    standardHeight: 128
  },
  {
    id: "High Damage",
    url: requireTexture("damage_high"),
    standardWidth: 128,
    standardHeight: 128
  }
];

function script(param) {
  Generator.defineTextureInput("Golem", {
        standardWidth: 128,
        standardHeight: 128,
        choices: []
      });
  Generator.defineTextureInput("Flower", {
        standardWidth: 16,
        standardHeight: 16,
        choices: [
          "Poppy Flower",
          "Rose Flower",
          "Cyan Flower"
        ]
      });
  Generator.defineTextureInput("Damage", {
        standardWidth: 128,
        standardHeight: 128,
        choices: [
          "Low Damage",
          "Medium Damage",
          "High Damage"
        ]
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  Generator.drawImage("Background", [
        0,
        0
      ]);
  Generator.drawTextureLegacy("Golem", {
        x: 0,
        y: 8,
        w: 8,
        h: 10
      }, {
        x: 39,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 8,
        y: 8,
        w: 8,
        h: 10
      }, {
        x: 103,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 16,
        y: 8,
        w: 8,
        h: 10
      }, {
        x: 167,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 24,
        y: 8,
        w: 8,
        h: 10
      }, {
        x: 231,
        y: 83,
        w: 64,
        h: 80
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 8,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 103,
        y: 19,
        w: 64,
        h: 64
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 16,
        y: 0,
        w: 8,
        h: 8
      }, {
        x: 103,
        y: 163,
        w: 64,
        h: 64
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 6,
        y: 76,
        w: 9,
        h: 5
      }, {
        x: 373,
        y: 125,
        w: 72,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 15,
        y: 76,
        w: 6,
        h: 5
      }, {
        x: 445,
        y: 125,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 0,
        y: 76,
        w: 6,
        h: 5
      }, {
        x: 325,
        y: 125,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 21,
        y: 76,
        w: 9,
        h: 5
      }, {
        x: 493,
        y: 125,
        w: 72,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 6,
        y: 70,
        w: 9,
        h: 6
      }, {
        x: 373,
        y: 77,
        w: 72,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 15,
        y: 70,
        w: 9,
        h: 6
      }, {
        x: 373,
        y: 165,
        w: 72,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 11,
        y: 51,
        w: 18,
        h: 12
      }, {
        x: 208,
        y: 286,
        w: 144,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 29,
        y: 51,
        w: 11,
        h: 12
      }, {
        x: 352,
        y: 286,
        w: 88,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 40,
        y: 51,
        w: 18,
        h: 12
      }, {
        x: 440,
        y: 286,
        w: 144,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 0,
        y: 51,
        w: 11,
        h: 12
      }, {
        x: 120,
        y: 286,
        w: 89,
        h: 96
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 11,
        y: 40,
        w: 18,
        h: 11
      }, {
        x: 208,
        y: 198,
        w: 144,
        h: 88
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 29,
        y: 40,
        w: 18,
        h: 11
      }, {
        x: 208,
        y: 382,
        w: 144,
        h: 88
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 42,
        y: 5,
        w: 6,
        h: 16
      }, {
        x: 438,
        y: 448,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 48,
        y: 5,
        w: 6,
        h: 16
      }, {
        x: 486,
        y: 448,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 53,
        y: 5,
        w: 6,
        h: 16
      }, {
        x: 526,
        y: 448,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 37,
        y: 5,
        w: 5,
        h: 16
      }, {
        x: 398,
        y: 448,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 42,
        y: 0,
        w: 6,
        h: 5
      }, {
        x: 438,
        y: 408,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 48,
        y: 0,
        w: 6,
        h: 5
      }, {
        x: 438,
        y: 576,
        w: 48,
        h: 40
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 65,
        y: 5,
        w: 6,
        h: 16
      }, {
        x: 438,
        y: 662,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 71,
        y: 5,
        w: 5,
        h: 16
      }, {
        x: 486,
        y: 662,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 76,
        y: 5,
        w: 6,
        h: 16
      }, {
        x: 526,
        y: 662,
        w: 48,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 60,
        y: 5,
        w: 5,
        h: 16
      }, {
        x: 398,
        y: 662,
        w: 40,
        h: 128
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 65,
        y: 0,
        w: 6,
        h: 5
      }, {
        x: 438,
        y: 622,
        w: 48,
        h: 40
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 71,
        y: 0,
        w: 6,
        h: 5
      }, {
        x: 438,
        y: 790,
        w: 48,
        h: 40
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 66,
        y: 27,
        w: 4,
        h: 30
      }, {
        x: 77,
        y: 541,
        w: 32,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 70,
        y: 27,
        w: 6,
        h: 30
      }, {
        x: 109,
        y: 541,
        w: 48,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 76,
        y: 27,
        w: 4,
        h: 30
      }, {
        x: 157,
        y: 541,
        w: 32,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 60,
        y: 27,
        w: 6,
        h: 30
      }, {
        x: 29,
        y: 541,
        w: 48,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 66,
        y: 21,
        w: 4,
        h: 6
      }, {
        x: 77,
        y: 493,
        w: 32,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 70,
        y: 21,
        w: 4,
        h: 6
      }, {
        x: 77,
        y: 781,
        w: 32,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 66,
        y: 64,
        w: 4,
        h: 30
      }, {
        x: 264,
        y: 541,
        w: 32,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 70,
        y: 64,
        w: 6,
        h: 30
      }, {
        x: 296,
        y: 541,
        w: 48,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 76,
        y: 64,
        w: 4,
        h: 30
      }, {
        x: 344,
        y: 541,
        w: 32,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 60,
        y: 64,
        w: 6,
        h: 30
      }, {
        x: 216,
        y: 541,
        w: 48,
        h: 240
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 66,
        y: 58,
        w: 4,
        h: 6
      }, {
        x: 264,
        y: 493,
        w: 32,
        h: 48
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 70,
        y: 21,
        w: 4,
        h: 6
      }, {
        x: 264,
        y: 781,
        w: 32,
        h: 48
      }, "Vertical", undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 28,
        y: 2,
        w: 2,
        h: 4
      }, {
        x: 57,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 26,
        y: 2,
        w: 2,
        h: 4
      }, {
        x: 73,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 24,
        y: 2,
        w: 2,
        h: 4
      }, {
        x: 89,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 30,
        y: 2,
        w: 2,
        h: 4
      }, {
        x: 105,
        y: 416,
        w: 16,
        h: 32
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 26,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 73,
        y: 400,
        w: 16,
        h: 16
      }, undefined, undefined, undefined, undefined);
  Generator.drawTextureLegacy("Golem", {
        x: 28,
        y: 0,
        w: 2,
        h: 2
      }, {
        x: 73,
        y: 448,
        w: 16,
        h: 16
      }, "Vertical", undefined, undefined, undefined);
  var showFlower = Generator.hasTexture("Flower");
  if (showFlower) {
    Generator.drawTextureLegacy("Flower", {
          x: 5,
          y: 5,
          w: 8,
          h: 11
        }, {
          x: 95,
          y: 262,
          w: 64,
          h: 88
        }, undefined, 90.0, undefined, undefined);
    Generator.drawTextureLegacy("Flower", {
          x: 5,
          y: 5,
          w: 8,
          h: 11
        }, {
          x: 95,
          y: 198,
          w: 64,
          h: 88
        }, "Horizontal", 90.0, undefined, undefined);
  }
  var showDamage = Generator.hasTexture("Damage");
  if (showDamage) {
    Generator.drawTextureLegacy("Damage", {
          x: 0,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 39,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 8,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 103,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 16,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 167,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 24,
          y: 8,
          w: 8,
          h: 10
        }, {
          x: 231,
          y: 83,
          w: 64,
          h: 80
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 8,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 19,
          w: 64,
          h: 64
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 16,
          y: 0,
          w: 8,
          h: 8
        }, {
          x: 103,
          y: 163,
          w: 64,
          h: 64
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 6,
          y: 76,
          w: 9,
          h: 5
        }, {
          x: 373,
          y: 125,
          w: 72,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 15,
          y: 76,
          w: 6,
          h: 5
        }, {
          x: 445,
          y: 125,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 0,
          y: 76,
          w: 6,
          h: 5
        }, {
          x: 325,
          y: 125,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 21,
          y: 76,
          w: 9,
          h: 5
        }, {
          x: 493,
          y: 125,
          w: 72,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 6,
          y: 70,
          w: 9,
          h: 6
        }, {
          x: 373,
          y: 77,
          w: 72,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 15,
          y: 70,
          w: 9,
          h: 6
        }, {
          x: 373,
          y: 165,
          w: 72,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 11,
          y: 51,
          w: 18,
          h: 12
        }, {
          x: 208,
          y: 286,
          w: 144,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 29,
          y: 51,
          w: 11,
          h: 12
        }, {
          x: 352,
          y: 286,
          w: 88,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 40,
          y: 51,
          w: 18,
          h: 12
        }, {
          x: 440,
          y: 286,
          w: 144,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 0,
          y: 51,
          w: 11,
          h: 12
        }, {
          x: 120,
          y: 286,
          w: 89,
          h: 96
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 11,
          y: 40,
          w: 18,
          h: 11
        }, {
          x: 208,
          y: 198,
          w: 144,
          h: 88
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 29,
          y: 40,
          w: 18,
          h: 11
        }, {
          x: 208,
          y: 382,
          w: 144,
          h: 88
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 42,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 438,
          y: 448,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 48,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 486,
          y: 448,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 53,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 526,
          y: 448,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 37,
          y: 5,
          w: 5,
          h: 16
        }, {
          x: 398,
          y: 448,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 42,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 408,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 48,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 576,
          w: 48,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 65,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 438,
          y: 662,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 71,
          y: 5,
          w: 5,
          h: 16
        }, {
          x: 486,
          y: 662,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 76,
          y: 5,
          w: 6,
          h: 16
        }, {
          x: 526,
          y: 662,
          w: 48,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 60,
          y: 5,
          w: 5,
          h: 16
        }, {
          x: 398,
          y: 662,
          w: 40,
          h: 128
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 65,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 622,
          w: 48,
          h: 40
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 71,
          y: 0,
          w: 6,
          h: 5
        }, {
          x: 438,
          y: 790,
          w: 48,
          h: 40
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 27,
          w: 4,
          h: 30
        }, {
          x: 77,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 27,
          w: 6,
          h: 30
        }, {
          x: 109,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 76,
          y: 27,
          w: 4,
          h: 30
        }, {
          x: 157,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 60,
          y: 27,
          w: 6,
          h: 30
        }, {
          x: 29,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 21,
          w: 4,
          h: 6
        }, {
          x: 77,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 21,
          w: 4,
          h: 6
        }, {
          x: 77,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 64,
          w: 4,
          h: 30
        }, {
          x: 264,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 64,
          w: 6,
          h: 30
        }, {
          x: 296,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 76,
          y: 64,
          w: 4,
          h: 30
        }, {
          x: 344,
          y: 541,
          w: 32,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 60,
          y: 64,
          w: 6,
          h: 30
        }, {
          x: 216,
          y: 541,
          w: 48,
          h: 240
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 66,
          y: 58,
          w: 4,
          h: 6
        }, {
          x: 264,
          y: 493,
          w: 32,
          h: 48
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 70,
          y: 21,
          w: 4,
          h: 6
        }, {
          x: 264,
          y: 781,
          w: 32,
          h: 48
        }, "Vertical", undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 28,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 57,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 26,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 73,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 24,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 89,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 30,
          y: 2,
          w: 2,
          h: 4
        }, {
          x: 105,
          y: 416,
          w: 16,
          h: 32
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 26,
          y: 0,
          w: 2,
          h: 2
        }, {
          x: 73,
          y: 400,
          w: 16,
          h: 16
        }, undefined, undefined, undefined, undefined);
    Generator.drawTextureLegacy("Damage", {
          x: 28,
          y: 0,
          w: 2,
          h: 2
        }, {
          x: 73,
          y: 448,
          w: 16,
          h: 16
        }, "Vertical", undefined, undefined, undefined);
  }
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
