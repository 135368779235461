// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Dom2 = require("../../bindings/Dom2.bs.js");

function make(width, height) {
  var canvas = Dom2.$$Document.createCanvasElement(document);
  var context = Dom2.Canvas.getContext2d(canvas);
  var contextWithAlpha = Dom2.Canvas.getContext2dWithAlpha(canvas);
  canvas.width = width;
  canvas.height = height;
  return {
          canvas: canvas,
          context: context,
          contextWithAlpha: contextWithAlpha,
          width: width,
          height: height
        };
}

exports.make = make;
/* No side effect */
