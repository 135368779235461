// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator_PageSize = require("./Generator_PageSize.bs.js");
var Generator_CanvasWithContext = require("./Generator_CanvasWithContext.bs.js");

function make(id) {
  var canvasWithContext = Generator_CanvasWithContext.make(Generator_PageSize.A4.px.width, Generator_PageSize.A4.px.height);
  return {
          id: id,
          canvasWithContext: canvasWithContext
        };
}

exports.make = make;
/* No side effect */
