// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-axolotl-character";

var name = "Minecraft Axolotl Character";

var history = [
  "Feb 2022 M16 - Initial script developed.",
  "6 Feb 2022 lostminer - Refactoring."
];

var thumbnail = {
  url: require("./thumbnail/thumbnail-256.jpeg")
};

var video = {
  url: "https://www.youtube.com/embed/QVRD8Bl_hjA?rel=0"
};

var imageIds = [
  "Background",
  "Folds",
  "Labels"
];

function toImageDef(id) {
  return {
          id: id,
          url: requireImage(id)
        };
}

var images = imageIds.map(toImageDef);

var textures = [
  {
    id: "Skin",
    url: requireTexture("Steve"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Blue",
    url: requireTexture("axolotl_blue"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Cyan",
    url: requireTexture("axolotl_cyan"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Pink",
    url: requireTexture("axolotl_lucy"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Gold",
    url: requireTexture("axolotl_gold"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Brown",
    url: requireTexture("axolotl_wild"),
    standardWidth: 64,
    standardHeight: 64
  }
];

var steve = TextureMap.MinecraftCharacter.steve;

var alex = TextureMap.MinecraftCharacter.alex;

function xOf(param) {
  return param[0];
}

function yOf(param) {
  return param[1];
}

function widthOf(param) {
  return param[2];
}

function heightOf(param) {
  return param[3];
}

function topOf(param, n) {
  return [
          param[0],
          param[1],
          param[2],
          n
        ];
}

function bottomOf(param, n) {
  return [
          param[0],
          (param[1] + param[3] | 0) - n | 0,
          param[2],
          n
        ];
}

function armOrLegPart(rectangle) {
  return topOf(rectangle, heightOf(rectangle) - 1 | 0);
}

function handOrFootPart(rectangle) {
  return bottomOf(rectangle, 1);
}

function drawHead(layer, faceStretch) {
  var head = layer.head;
  var offset;
  switch (faceStretch) {
    case 0 :
        offset = 0;
        break;
    case 1 :
        offset = 6;
        break;
    case 2 :
        offset = 12;
        break;
    case 3 :
        offset = 20;
        break;
    case 4 :
        offset = 32;
        break;
    default:
      offset = 40;
  }
  var pixelate = offset > 0;
  Generator.drawTexture("Skin", head.right, [
        189,
        214,
        40 - offset | 0,
        40
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture("Skin", head.front, [
        229 - offset | 0,
        214,
        64 + (offset << 1) | 0,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", head.left, [
        293 + offset | 0,
        214,
        40 - offset | 0,
        40
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture("Skin", head.back, [
        333,
        214,
        64,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", head.top, [
        229,
        174,
        64,
        40
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", head.bottom, [
        229,
        254,
        64,
        40
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
}

function drawBody(layer) {
  var body = layer.body;
  Generator.drawTexture("Skin", body.top, [
        234,
        405,
        64,
        32
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", body.back, [
        234,
        325,
        64,
        80
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", body.bottom, [
        378,
        405,
        64,
        32
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", body.front, [
        234,
        437,
        64,
        80
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", body.left, [
        298,
        437,
        32,
        80
      ], undefined, undefined, -90.00, undefined, undefined, undefined);
  Generator.drawTexture("Skin", body.right, [
        234,
        405,
        32,
        80
      ], undefined, undefined, 90.00, undefined, undefined, undefined);
}

function drawLimb(leg, ox, oy) {
  Generator.drawTexture("Skin", armOrLegPart(leg.left), [
        ox + 8 | 0,
        oy - 24 | 0,
        8,
        24
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", bottomOf(leg.left, 1), [
        ox,
        oy,
        24,
        16
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", armOrLegPart(leg.right), [
        ox - 16 | 0,
        oy - 24 | 0,
        8,
        24
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Skin", bottomOf(leg.right, 1), [
        ox - 24 | 0,
        oy,
        24,
        16
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawArms(layer) {
  drawLimb(layer.leftArm, 461, 495);
  drawLimb(layer.rightArm, 403, 495);
}

function drawLegs(layer) {
  drawLimb(layer.rightLeg, 403, 548);
  drawLimb(layer.leftLeg, 461, 548);
}

var topLeftBack = [
  249,
  7,
  24,
  24
];

var topLeftFront = [
  273,
  7,
  24,
  24
];

var topRightBack = [
  323,
  7,
  24,
  24
];

var topRightFront = [
  299,
  7,
  24,
  24
];

var middleLeftBack = [
  268,
  41,
  24,
  32
];

var middleLeftFront = [
  268,
  73,
  24,
  32
];

var middleRightBack = [
  304,
  41,
  24,
  32
];

var middleRightFront = [
  304,
  73,
  24,
  32
];

var bottomLeftBack = [
  268,
  110,
  24,
  24
];

var bottomLeftFront = [
  268,
  134,
  24,
  24
];

var bottomRightBack = [
  304,
  110,
  24,
  24
];

var bottomRightFront = [
  304,
  134,
  24,
  24
];

var HeadFins = {
  topLeftBack: topLeftBack,
  topLeftFront: topLeftFront,
  topRightBack: topRightBack,
  topRightFront: topRightFront,
  middleLeftBack: middleLeftBack,
  middleLeftFront: middleLeftFront,
  middleRightBack: middleRightBack,
  middleRightFront: middleRightFront,
  bottomLeftBack: bottomLeftBack,
  bottomLeftFront: bottomLeftFront,
  bottomRightBack: bottomRightBack,
  bottomRightFront: bottomRightFront
};

function drawHeadFins(layer) {
  var match = layer.head;
  var left = match.left;
  var right = match.right;
  var top = match.top;
  var topX = xOf(top);
  var topY = yOf(top);
  var rightX = xOf(right);
  var rightY = yOf(right);
  var leftX = xOf(left);
  var leftY = yOf(left);
  var r_0 = topX + 1 | 0;
  var r_1 = topY + 2 | 0;
  var r = [
    r_0,
    r_1,
    2,
    1
  ];
  Generator.drawTexture("Skin", r, topLeftBack, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$1 = topX + 1 | 0;
  var r_1$1 = topY + 1 | 0;
  var r$1 = [
    r_0$1,
    r_1$1,
    2,
    1
  ];
  Generator.drawTexture("Skin", r$1, topLeftFront, "Horizontal", undefined, undefined, undefined, undefined, undefined);
  var r_0$2 = topX + 5 | 0;
  var r_1$2 = topY + 1 | 0;
  var r$2 = [
    r_0$2,
    r_1$2,
    2,
    1
  ];
  Generator.drawTexture("Skin", r$2, topRightFront, "Horizontal", undefined, undefined, undefined, undefined, undefined);
  var r_0$3 = topX + 5 | 0;
  var r_1$3 = topY + 2 | 0;
  var r$3 = [
    r_0$3,
    r_1$3,
    2,
    1
  ];
  Generator.drawTexture("Skin", r$3, topRightBack, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$4 = rightX + 1 | 0;
  var r$4 = [
    r_0$4,
    rightY,
    1,
    3
  ];
  Generator.drawTexture("Skin", r$4, middleLeftBack, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$5 = rightX + 2 | 0;
  var r$5 = [
    r_0$5,
    rightY,
    1,
    3
  ];
  Generator.drawTexture("Skin", r$5, middleLeftFront, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$6 = leftX + 6 | 0;
  var r$6 = [
    r_0$6,
    leftY,
    1,
    3
  ];
  Generator.drawTexture("Skin", r$6, middleRightBack, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$7 = leftX + 5 | 0;
  var r$7 = [
    r_0$7,
    leftY,
    1,
    3
  ];
  Generator.drawTexture("Skin", r$7, middleRightFront, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$8 = rightX + 2 | 0;
  var r_1$4 = rightY + 6 | 0;
  var r$8 = [
    r_0$8,
    r_1$4,
    1,
    2
  ];
  Generator.drawTexture("Skin", r$8, bottomLeftBack, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$9 = rightX + 1 | 0;
  var r_1$5 = rightY + 6 | 0;
  var r$9 = [
    r_0$9,
    r_1$5,
    1,
    2
  ];
  Generator.drawTexture("Skin", r$9, bottomLeftFront, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$10 = leftX + 5 | 0;
  var r_1$6 = leftY + 6 | 0;
  var r$10 = [
    r_0$10,
    r_1$6,
    1,
    2
  ];
  Generator.drawTexture("Skin", r$10, bottomRightBack, undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$11 = leftX + 6 | 0;
  var r_1$7 = leftY + 6 | 0;
  var r$11 = [
    r_0$11,
    r_1$7,
    1,
    2
  ];
  Generator.drawTexture("Skin", r$11, bottomRightFront, undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawHeadFinsTexture(param) {
  Generator.drawTexture("Head Fins Texture", [
        3,
        37,
        3,
        3
      ], topLeftBack, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        8,
        37,
        3,
        3
      ], topLeftFront, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        3,
        37,
        3,
        3
      ], topRightFront, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        8,
        37,
        3,
        3
      ], topRightBack, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        0,
        40,
        3,
        4
      ], middleLeftBack, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        0,
        40,
        3,
        4
      ], middleLeftFront, "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        11,
        40,
        3,
        4
      ], middleRightBack, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        11,
        40,
        3,
        4
      ], middleRightFront, "Horizontal", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        0,
        44,
        3,
        3
      ], bottomLeftBack, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        0,
        44,
        3,
        3
      ], bottomLeftFront, "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        11,
        44,
        3,
        3
      ], bottomRightBack, undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture("Head Fins Texture", [
        11,
        44,
        3,
        3
      ], bottomRightFront, "Vertical", undefined, undefined, undefined, undefined, undefined);
}

function drawTailFins(layer) {
  var match = layer.body;
  var back = match.back;
  var backX = xOf(back);
  var backY = yOf(back);
  var backHeight = heightOf(back);
  var r_0 = backX + 3 | 0;
  var r = [
    r_0,
    backY,
    1,
    backHeight
  ];
  Generator.drawTexture("Skin", r, [
        258,
        533,
        40,
        168
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  var r_0$1 = backX + 4 | 0;
  var r$1 = [
    r_0$1,
    backY,
    1,
    backHeight
  ];
  Generator.drawTexture("Skin", r$1, [
        298,
        533,
        40,
        168
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawTailFinsTexture(param) {
  Generator.drawTexture("Tail Fins Texture", [
        2,
        26,
        9,
        1
      ], [
        226,
        565,
        72,
        8
      ], undefined, undefined, undefined, -90.00, undefined, undefined);
  Generator.drawTexture("Tail Fins Texture", [
        2,
        26,
        9,
        1
      ], [
        298,
        565,
        72,
        8
      ], "Vertical", undefined, undefined, -90.0, undefined, undefined);
  Generator.drawTexture("Tail Fins Texture", [
        2,
        31,
        12,
        5
      ], [
        230,
        633,
        96,
        40
      ], undefined, undefined, undefined, -90.0, undefined, undefined);
  Generator.drawTexture("Tail Fins Texture", [
        2,
        31,
        12,
        5
      ], [
        270,
        633,
        96,
        40
      ], "Vertical", undefined, undefined, -90.0, undefined, undefined);
}

function script(param) {
  Generator.defineSelectInput("Skin Model Type", [
        "Steve",
        "Alex"
      ]);
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineTextureInput("Head Fins Texture", {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Blue",
          "Cyan",
          "Pink",
          "Gold",
          "Brown"
        ]
      });
  Generator.defineTextureInput("Tail Fins Texture", {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Blue",
          "Cyan",
          "Pink",
          "Gold",
          "Brown"
        ]
      });
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  Generator.defineBooleanInput("Show Overlay", true);
  Generator.defineRangeInput("Axolotl Face", {
        min: 0,
        max: 5,
        value: 0,
        step: 1
      });
  var alexModel = Generator.getSelectInputValue("Skin Model Type") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var showOverlay = Generator.getBooleanInputValue("Show Overlay");
  var faceStretch = Generator.getRangeInputValue("Axolotl Face");
  drawHead(steve.base, faceStretch);
  drawBody(steve.base);
  if (alexModel) {
    drawArms(alex.base);
  } else {
    drawArms(steve.base);
  }
  drawLegs(steve.base);
  drawHeadFins(steve.base);
  drawTailFins(steve.base);
  if (showOverlay) {
    drawHead(steve.overlay, faceStretch);
    drawBody(steve.overlay);
    if (alexModel) {
      drawArms(alex.overlay);
    } else {
      drawArms(steve.overlay);
    }
    drawLegs(steve.overlay);
    drawHeadFins(steve.overlay);
    drawTailFins(steve.overlay);
  }
  var showHeadFinsTexture = Generator.hasTexture("Head Fins Texture");
  if (showHeadFinsTexture) {
    drawHeadFinsTexture(undefined);
  }
  var showTailFindTexture = Generator.hasTexture("Tail Fins Texture");
  if (showTailFindTexture) {
    drawTailFinsTexture(undefined);
  }
  Generator.drawImage("Background", [
        0,
        0
      ]);
  if (showFolds) {
    Generator.drawImage("Folds", [
          0,
          0
        ]);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator_video = video;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: generator_video,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.video = video;
exports.imageIds = imageIds;
exports.toImageDef = toImageDef;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.xOf = xOf;
exports.yOf = yOf;
exports.widthOf = widthOf;
exports.heightOf = heightOf;
exports.topOf = topOf;
exports.bottomOf = bottomOf;
exports.armOrLegPart = armOrLegPart;
exports.handOrFootPart = handOrFootPart;
exports.drawHead = drawHead;
exports.drawBody = drawBody;
exports.drawLimb = drawLimb;
exports.drawArms = drawArms;
exports.drawLegs = drawLegs;
exports.HeadFins = HeadFins;
exports.drawHeadFins = drawHeadFins;
exports.drawHeadFinsTexture = drawHeadFinsTexture;
exports.drawTailFins = drawTailFins;
exports.drawTailFinsTexture = drawTailFinsTexture;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
