// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");

function requireImage(fileName) {
  return require("./images/" + fileName);
}

function requireTexture(fileName) {
  return require("./textures/" + fileName);
}

var id = "example";

var name = "Example";

var history = [];

var images = [
  {
    id: "Background",
    url: require("./images/Background.png")
  },
  {
    id: "Folds",
    url: require("./images/Folds.png")
  }
];

var textures = [{
    id: "Skin",
    url: require("./textures/Skin.png"),
    standardWidth: 64,
    standardHeight: 64
  }];

function drawHead(name, x, y) {
  Generator.drawTexture(name, [
        0,
        8,
        8,
        8
      ], [
        x - 64 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        8,
        8,
        8,
        8
      ], [
        x + 0 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        16,
        8,
        8,
        8
      ], [
        x + 64 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        24,
        8,
        8,
        8
      ], [
        x + 128 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        8,
        0,
        8,
        8
      ], [
        x + 0 | 0,
        y - 64 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        16,
        0,
        8,
        8
      ], [
        x + 0 | 0,
        y + 64 | 0,
        64,
        64
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        32,
        8,
        8,
        8
      ], [
        x - 64 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        40,
        8,
        8,
        8
      ], [
        x + 0 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        48,
        8,
        8,
        8
      ], [
        x + 64 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        56,
        8,
        8,
        8
      ], [
        x + 128 | 0,
        y + 0 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        40,
        0,
        8,
        8
      ], [
        x + 0 | 0,
        y - 64 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(name, [
        48,
        0,
        8,
        8
      ], [
        x + 0 | 0,
        y + 64 | 0,
        64,
        64
      ], "Vertical", undefined, undefined, undefined, undefined, undefined);
}

function script(param) {
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: []
      });
  Generator.defineBooleanInput("Show Folds", true);
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  Generator.drawImage("Background", [
        0,
        0
      ]);
  drawHead("Skin", 185, 117);
  if (showFolds) {
    return Generator.drawImage("Folds", [
                0,
                0
              ]);
  }
  
}

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: undefined,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.images = images;
exports.textures = textures;
exports.drawHead = drawHead;
exports.script = script;
exports.generator = generator;
/* images Not a pure module */
