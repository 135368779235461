// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_obj = require("rescript/lib/js/caml_obj.js");
var Generator = require("../../builder/modules/Generator.bs.js");
var Minecraft = require("../Minecraft.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_Markdown = require("../../builder/views/Generator_Markdown.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-character";

var name = "Minecraft Character";

var history = [
  "01 Feb 2015 gootube2000 - First release.",
  "05 Feb 2015 gootube2000 - Fixed orientation of the hands, feet and under the head.",
  "13 Feb 2015 lostminer - Update to use new version of generator.",
  "20 Feb 2015 lostminer - Make background non-transparent.",
  "02 Oct 2020 NinjolasNJM - Combined Steve and Alex Generators into one.",
  "27 May 2021 lostminer - Convert to ReScript generator.",
  "17 Jul 2021 M16 - Updated generator photo.",
  "27 May 2022 NinjolasNJM - Made folds drawn using drawFolds, and parts drawn using drawCuboid, and added title",
  "12 Jun 2022 NinjolasNJM - Updated to use new Minecraft module"
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var instructions = "\n## How to use the Minecraft Character Generator?\n\n1. Select your Minecraft skin file.\n2. Choose the your Minecraft skin file model type.\n3. Download and print your character papercraft.\n";

var images = [
  {
    id: "SteveTabs",
    url: requireImage("SteveTabs")
  },
  {
    id: "SteveFolds",
    url: requireImage("SteveFolds")
  },
  {
    id: "AlexTabs",
    url: requireImage("AlexTabs")
  },
  {
    id: "AlexFolds",
    url: requireImage("AlexFolds")
  },
  {
    id: "Labels",
    url: requireImage("Labels")
  }
];

var textures = [
  {
    id: "Skin",
    url: requireTexture("SkinSteve64x64"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Steve",
    url: requireTexture("SkinSteve64x64"),
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Alex",
    url: requireTexture("SkinAlex64x64"),
    standardWidth: 64,
    standardHeight: 64
  }
];

var steve = Minecraft.Character.steve;

var alex = Minecraft.Character.alex;

function script(param) {
  Generator.defineTextureInput("Skin", {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Steve",
          "Alex"
        ]
      });
  Generator.defineSelectInput("Skin Model", [
        "Steve",
        "Alex"
      ]);
  Generator.defineBooleanInput("Show Folds", true);
  Generator.defineBooleanInput("Show Labels", true);
  Generator.defineText("Click in the papercraft template to turn on and off the overlay for each part.");
  var isAlexModel = Generator.getSelectInputValue("Skin Model") === "Alex";
  var showFolds = Generator.getBooleanInputValue("Show Folds");
  var showLabels = Generator.getBooleanInputValue("Show Labels");
  var showHeadOverlay = Generator.getBooleanInputValueWithDefault("Show Head Overlay", true);
  var showBodyOverlay = Generator.getBooleanInputValueWithDefault("Show Body Overlay", true);
  var showLeftArmOverlay = Generator.getBooleanInputValueWithDefault("Show Left Arm Overlay", true);
  var showRightArmOverlay = Generator.getBooleanInputValueWithDefault("Show Right Arm Overlay", true);
  var showLeftLegOverlay = Generator.getBooleanInputValueWithDefault("Show Left Leg Overlay", true);
  var showRightLegOverlay = Generator.getBooleanInputValueWithDefault("Show Right Leg Overlay", true);
  var $$char = isAlexModel ? alex : steve;
  var drawHead = function (param) {
    var oy = param[1];
    var ox = param[0];
    var scale = [
      64,
      64,
      64
    ];
    Minecraft.drawCuboid("Skin", $$char.base.head, [
          ox,
          oy
        ], scale, undefined, undefined, undefined);
    if (showHeadOverlay) {
      return Minecraft.drawCuboid("Skin", $$char.overlay.head, [
                  ox,
                  oy
                ], scale, undefined, undefined, undefined);
    }
    
  };
  var drawBody = function (param) {
    var oy = param[1];
    var ox = param[0];
    var scale = [
      64,
      96,
      32
    ];
    Minecraft.drawCuboid("Skin", $$char.base.body, [
          ox,
          oy
        ], scale, undefined, undefined, undefined);
    if (showBodyOverlay) {
      return Minecraft.drawCuboid("Skin", $$char.overlay.body, [
                  ox,
                  oy
                ], scale, undefined, undefined, undefined);
    }
    
  };
  var drawRightArm = function (param) {
    var oy = param[1];
    var ox = param[0];
    var scale = Caml_obj.equal($$char, alex) ? [
        24,
        96,
        32
      ] : [
        32,
        96,
        32
      ];
    Minecraft.drawCuboid("Skin", $$char.base.rightArm, [
          ox,
          oy
        ], scale, undefined, undefined, undefined);
    if (showRightArmOverlay) {
      return Minecraft.drawCuboid("Skin", $$char.overlay.rightArm, [
                  ox,
                  oy
                ], scale, undefined, undefined, undefined);
    }
    
  };
  var drawLeftArm = function (param) {
    var oy = param[1];
    var ox = param[0];
    var scale = Caml_obj.equal($$char, alex) ? [
        24,
        96,
        32
      ] : [
        32,
        96,
        32
      ];
    Minecraft.drawCuboid("Skin", $$char.base.leftArm, [
          ox,
          oy
        ], scale, "West", undefined, undefined);
    if (showLeftArmOverlay) {
      return Minecraft.drawCuboid("Skin", $$char.overlay.leftArm, [
                  ox,
                  oy
                ], scale, "West", undefined, undefined);
    }
    
  };
  var drawRightLeg = function (param) {
    var oy = param[1];
    var ox = param[0];
    var scale = [
      32,
      96,
      32
    ];
    Minecraft.drawCuboid("Skin", $$char.base.rightLeg, [
          ox,
          oy
        ], scale, undefined, undefined, undefined);
    if (showRightLegOverlay) {
      return Minecraft.drawCuboid("Skin", $$char.overlay.rightLeg, [
                  ox,
                  oy
                ], scale, undefined, undefined, undefined);
    }
    
  };
  var drawLeftLeg = function (param) {
    var oy = param[1];
    var ox = param[0];
    var scale = [
      32,
      96,
      32
    ];
    Minecraft.drawCuboid("Skin", $$char.base.leftLeg, [
          ox,
          oy
        ], scale, "West", undefined, undefined);
    if (showLeftLegOverlay) {
      return Minecraft.drawCuboid("Skin", $$char.overlay.leftLeg, [
                  ox,
                  oy
                ], scale, "West", undefined, undefined);
    }
    
  };
  var drawFolds = function (param) {
    if (isAlexModel) {
      return Generator.drawImage("AlexFolds", [
                  0,
                  0
                ]);
    } else {
      return Generator.drawImage("SteveFolds", [
                  0,
                  0
                ]);
    }
  };
  Generator.drawImage("Background", [
        0,
        0
      ]);
  if (isAlexModel) {
    Generator.drawImage("AlexTabs", [
          0,
          0
        ]);
  } else {
    Generator.drawImage("SteveTabs", [
          0,
          0
        ]);
  }
  drawHead([
        74,
        25
      ]);
  Generator.defineRegionInput([
        74,
        25,
        256,
        192
      ], (function (param) {
          Generator.setBooleanInputValue("Show Head Overlay", !showHeadOverlay);
        }));
  drawBody([
        268,
        201
      ]);
  Generator.defineRegionInput([
        268,
        201,
        192,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Show Body Overlay", !showBodyOverlay);
        }));
  var ox = isAlexModel ? 107 : 99;
  drawRightArm([
        ox,
        373
      ]);
  Generator.defineRegionInput([
        ox,
        373,
        isAlexModel ? 112 : 128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Show Right Arm Overlay", !showRightArmOverlay);
        }));
  var ox$1 = isAlexModel ? 391 : 383;
  drawLeftArm([
        ox$1,
        373
      ]);
  Generator.defineRegionInput([
        ox$1,
        373,
        isAlexModel ? 112 : 128,
        166
      ], (function (param) {
          Generator.setBooleanInputValue("Show Left Arm Overlay", !showLeftArmOverlay);
        }));
  drawRightLeg([
        99,
        587
      ]);
  Generator.defineRegionInput([
        99,
        587,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Show Right Leg Overlay", !showRightLegOverlay);
        }));
  drawLeftLeg([
        383,
        587
      ]);
  Generator.defineRegionInput([
        383,
        587,
        128,
        160
      ], (function (param) {
          Generator.setBooleanInputValue("Show Left Leg Overlay", !showLeftLegOverlay);
        }));
  if (showFolds) {
    drawFolds(undefined);
  }
  if (showLabels) {
    return Generator.drawImage("Labels", [
                0,
                0
              ]);
  }
  
}

var generator_thumbnail = thumbnail;

var generator_instructions = Caml_option.some(React.createElement(Generator_Markdown.make, {
          children: instructions
        }));

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: generator_instructions,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.instructions = instructions;
exports.images = images;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
