// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Generator = require("../../builder/modules/Generator.bs.js");
var TextureMap = require("../TextureMap.bs.js");

function requireImage(id) {
  return require("./images/" + id + ".png");
}

function requireTexture(id) {
  return require("./textures/" + id + ".png");
}

var id = "minecraft-character-mini";

var name = "Minecraft Character Mini";

var history = [
  "13 Sep 2015 Sandvich - First release using the generator builder.",
  "17 Sep 2020 NinjolasNJM - Added support for Alex skins and fixed bottom of legs.",
  "11 Feb 2022 LostMiner - Refactor. Add pixelate option."
];

var thumbnail = {
  url: require("./thumbnail/v2-thumbnail-256.jpeg")
};

var images = [
  {
    id: "Skin Overlay",
    url: requireImage("skin-overlay")
  },
  {
    id: "Skin Background",
    url: requireImage("skin-background")
  }
];

var steveSkin = requireTexture("SkinSteve64x64");

var alexSkin = requireTexture("SkinAlex64x64");

var textures = [
  {
    id: "Mini 1",
    url: steveSkin,
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Steve",
    url: steveSkin,
    standardWidth: 64,
    standardHeight: 64
  },
  {
    id: "Alex",
    url: alexSkin,
    standardWidth: 64,
    standardHeight: 64
  }
];

var steve = TextureMap.MinecraftCharacter.steve;

var alex = TextureMap.MinecraftCharacter.alex;

function drawHead(textureId, layer, ox, oy) {
  Generator.drawTexture(textureId, layer.head.right, [
        ox,
        oy,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(textureId, layer.head.front, [
        ox + 64 | 0,
        oy,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(textureId, layer.head.left, [
        ox + 128 | 0,
        oy,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(textureId, layer.head.back, [
        ox + 192 | 0,
        oy,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
  Generator.drawTexture(textureId, layer.head.top, [
        ox + 64 | 0,
        oy - 64 | 0,
        64,
        64
      ], undefined, undefined, undefined, undefined, undefined, undefined);
}

function drawHeadFlaps(textureId, layer, ox, oy) {
  Generator.drawTexture(textureId, layer.head.right, [
        ox,
        oy,
        64,
        64
      ], undefined, undefined, undefined, 90.0, undefined, undefined);
  Generator.drawTexture(textureId, layer.head.left, [
        ox + 128 | 0,
        oy,
        64,
        64
      ], undefined, undefined, undefined, -90.0, undefined, undefined);
}

function drawBody(textureId, layer, ox, oy, bodyHeight, pixelate) {
  Generator.drawTexture(textureId, layer.body.right, [
        ox,
        oy,
        64,
        bodyHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.body.front, [
        ox + 64 | 0,
        oy,
        64,
        bodyHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.body.left, [
        ox + 128 | 0,
        oy,
        64,
        bodyHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.body.back, [
        ox + 192 | 0,
        oy,
        64,
        bodyHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
}

function drawRightArm(textureId, layer, ox, oy, pixelate) {
  Generator.drawTexture(textureId, layer.rightArm.left, [
        ox,
        oy,
        32,
        48
      ], undefined, undefined, undefined, 90.0, pixelate, undefined);
  Generator.drawTexture(textureId, layer.rightArm.right, [
        ox,
        oy + 32 | 0,
        32,
        48
      ], undefined, undefined, undefined, 90.0, pixelate, undefined);
}

function drawLeftArm(textureId, layer, ox, oy, pixelate) {
  Generator.drawTexture(textureId, layer.leftArm.right, [
        ox,
        oy,
        32,
        48
      ], undefined, undefined, undefined, -90.0, pixelate, undefined);
  Generator.drawTexture(textureId, layer.leftArm.left, [
        ox,
        oy + 32 | 0,
        32,
        48
      ], undefined, undefined, undefined, -90.0, pixelate, undefined);
}

function drawRightLeg(textureId, layer, ox, oy, bodyHeight, pixelate) {
  var legHeight = 64 - bodyHeight | 0;
  Generator.drawTexture(textureId, layer.rightLeg.front, [
        ox + 64 | 0,
        oy + bodyHeight | 0,
        32,
        legHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.rightLeg.right, [
        ox,
        oy + bodyHeight | 0,
        64,
        legHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.rightLeg.back, [
        ox + 224 | 0,
        oy + bodyHeight | 0,
        32,
        legHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.rightLeg.bottom, [
        ox + 64 | 0,
        oy + 64 | 0,
        32,
        64
      ], "Vertical", undefined, undefined, undefined, pixelate, undefined);
}

function drawLeftLeg(textureId, layer, ox, oy, bodyHeight, pixelate) {
  var legHeight = 64 - bodyHeight | 0;
  Generator.drawTexture(textureId, layer.leftLeg.front, [
        ox + 96 | 0,
        oy + bodyHeight | 0,
        32,
        legHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.leftLeg.left, [
        ox + 128 | 0,
        oy + bodyHeight | 0,
        64,
        legHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.leftLeg.back, [
        ox + 192 | 0,
        oy + bodyHeight | 0,
        32,
        legHeight
      ], undefined, undefined, undefined, undefined, pixelate, undefined);
  Generator.drawTexture(textureId, layer.leftLeg.bottom, [
        ox + 96 | 0,
        oy + 64 | 0,
        32,
        64
      ], "Vertical", undefined, undefined, undefined, pixelate, undefined);
}

function drawMini(textureId, x, y) {
  Generator.defineTextureInput(textureId, {
        standardWidth: 64,
        standardHeight: 64,
        choices: [
          "Steve",
          "Alex"
        ]
      });
  if (!Generator.hasTexture(textureId)) {
    return ;
  }
  var modelTypeName = textureId + " Model Type";
  Generator.defineSelectInput(modelTypeName, [
        "Steve",
        "Alex"
      ]);
  var modelType = Generator.getSelectInputValue(modelTypeName);
  var showHeadOverlay = Generator.defineAndGetBooleanInput(textureId + " Head Overlay", true);
  var showBodyOverlay = Generator.defineAndGetBooleanInput(textureId + " Body Overlay", true);
  var showArmOverlay = Generator.defineAndGetBooleanInput(textureId + " Arm Overlay", true);
  var showLegOverlay = Generator.defineAndGetBooleanInput(textureId + " Leg Overlay", true);
  var bodyHeight = Generator.defineAndGetRangeInput(textureId + " Body Height", {
        min: 0,
        max: 64,
        value: 32,
        step: 1
      });
  var textureStyle = Generator.defineAndGetSelectInput(textureId + " Texture Style", [
        "Simple",
        "Detailed"
      ]);
  var isAlexModel = modelType === "Alex";
  var pixelate = textureStyle === "Simple";
  Generator.drawImage("Skin Background", [
        x,
        y
      ]);
  var ox = x + 49 | 0;
  var oy = y + 90 | 0;
  drawHead(textureId, steve.base, ox, oy);
  if (showHeadOverlay) {
    drawHead(textureId, steve.overlay, ox, oy);
  }
  var ox$1 = x + 49 | 0;
  var oy$1 = y + 26 | 0;
  drawHeadFlaps(textureId, steve.base, ox$1, oy$1);
  if (showHeadOverlay) {
    drawHeadFlaps(textureId, steve.overlay, ox$1, oy$1);
  }
  var ox$2 = x + 49 | 0;
  var oy$2 = y + 154 | 0;
  drawBody(textureId, steve.base, ox$2, oy$2, bodyHeight, pixelate);
  if (showBodyOverlay) {
    drawBody(textureId, steve.overlay, ox$2, oy$2, bodyHeight, pixelate);
  }
  var armTexture = isAlexModel ? alex : steve;
  var ox$3 = x + 9 | 0;
  var oy$3 = y + 2 | 0;
  drawRightArm(textureId, armTexture.base, ox$3, oy$3, pixelate);
  if (showArmOverlay) {
    drawRightArm(textureId, armTexture.overlay, ox$3, oy$3, pixelate);
  }
  var ox$4 = x + 249 | 0;
  var oy$4 = y + 2 | 0;
  drawLeftArm(textureId, armTexture.base, ox$4, oy$4, pixelate);
  if (showArmOverlay) {
    drawLeftArm(textureId, armTexture.overlay, ox$4, oy$4, pixelate);
  }
  var ox$5 = x + 49 | 0;
  var oy$5 = y + 154 | 0;
  drawRightLeg(textureId, steve.base, ox$5, oy$5, bodyHeight, pixelate);
  if (showLegOverlay) {
    drawRightLeg(textureId, steve.overlay, ox$5, oy$5, bodyHeight, pixelate);
  }
  drawLeftLeg(textureId, steve.base, ox$5, oy$5, bodyHeight, pixelate);
  if (showLegOverlay) {
    drawLeftLeg(textureId, steve.overlay, ox$5, oy$5, bodyHeight, pixelate);
  }
  Generator.drawImage("Skin Overlay", [
        x,
        y
      ]);
}

function script(param) {
  drawMini("Mini 1", 151, 108);
  drawMini("Mini 2", 151, 453);
}

var generator_thumbnail = thumbnail;

var generator = {
  id: id,
  name: name,
  history: history,
  thumbnail: generator_thumbnail,
  video: undefined,
  instructions: undefined,
  images: images,
  textures: textures,
  script: script
};

exports.requireImage = requireImage;
exports.requireTexture = requireTexture;
exports.id = id;
exports.name = name;
exports.history = history;
exports.thumbnail = thumbnail;
exports.images = images;
exports.steveSkin = steveSkin;
exports.alexSkin = alexSkin;
exports.textures = textures;
exports.steve = steve;
exports.alex = alex;
exports.drawHead = drawHead;
exports.drawHeadFlaps = drawHeadFlaps;
exports.drawBody = drawBody;
exports.drawRightArm = drawRightArm;
exports.drawLeftArm = drawLeftArm;
exports.drawRightLeg = drawRightLeg;
exports.drawLeftLeg = drawLeftLeg;
exports.drawMini = drawMini;
exports.script = script;
exports.generator = generator;
/* thumbnail Not a pure module */
