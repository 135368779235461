// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Dom2 = require("../../bindings/Dom2.bs.js");

function make(width, height) {
  var canvas = Dom2.$$Document.createCanvasElement(document);
  canvas.width = width;
  canvas.height = height;
  return canvas;
}

exports.make = make;
/* No side effect */
