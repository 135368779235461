// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';


var texture_url = require("./Texture_minecraft_1_13_2_items.png");

var texture = {
  id: "minecraft-1.13.2-items",
  url: texture_url,
  standardWidth: 512,
  standardHeight: 192
};

var tiles = [
  {
    name: "acacia_boat",
    x: 0,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "acacia_door",
    x: 16,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "apple",
    x: 32,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "armor_stand",
    x: 48,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "arrow",
    x: 64,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "baked_potato",
    x: 80,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "barrier",
    x: 96,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beef",
    x: 112,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beetroot",
    x: 128,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beetroot_seeds",
    x: 144,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "beetroot_soup",
    x: 160,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "birch_boat",
    x: 176,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "birch_door",
    x: 192,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "blaze_powder",
    x: 208,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "blaze_rod",
    x: 224,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bone",
    x: 240,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bone_meal",
    x: 256,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "book",
    x: 272,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow",
    x: 288,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_pulling_0",
    x: 304,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_pulling_1",
    x: 320,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bow_pulling_2",
    x: 336,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bowl",
    x: 352,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bread",
    x: 368,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brewing_stand",
    x: 384,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "brick",
    x: 400,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "broken_elytra",
    x: 416,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "bucket",
    x: 432,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cactus_green",
    x: 448,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cake",
    x: 464,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrot",
    x: 480,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "carrot_on_a_stick",
    x: 496,
    y: 0,
    width: 16,
    height: 16
  },
  {
    name: "cauldron",
    x: 0,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_boots",
    x: 16,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_chestplate",
    x: 32,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_helmet",
    x: 48,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chainmail_leggings",
    x: 64,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "charcoal",
    x: 80,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chest_minecart",
    x: 96,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chicken",
    x: 112,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "chorus_fruit",
    x: 128,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clay_ball",
    x: 144,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_00",
    x: 160,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_01",
    x: 176,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_02",
    x: 192,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_03",
    x: 208,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_04",
    x: 224,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_05",
    x: 240,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_06",
    x: 256,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_07",
    x: 272,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_08",
    x: 288,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_09",
    x: 304,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_10",
    x: 320,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_11",
    x: 336,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_12",
    x: 352,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_13",
    x: 368,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_14",
    x: 384,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_15",
    x: 400,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_16",
    x: 416,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_17",
    x: 432,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_18",
    x: 448,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_19",
    x: 464,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_20",
    x: 480,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_21",
    x: 496,
    y: 16,
    width: 16,
    height: 16
  },
  {
    name: "clock_22",
    x: 0,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_23",
    x: 16,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_24",
    x: 32,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_25",
    x: 48,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_26",
    x: 64,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_27",
    x: 80,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_28",
    x: 96,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_29",
    x: 112,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_30",
    x: 128,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_31",
    x: 144,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_32",
    x: 160,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_33",
    x: 176,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_34",
    x: 192,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_35",
    x: 208,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_36",
    x: 224,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_37",
    x: 240,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_38",
    x: 256,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_39",
    x: 272,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_40",
    x: 288,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_41",
    x: 304,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_42",
    x: 320,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_43",
    x: 336,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_44",
    x: 352,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_45",
    x: 368,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_46",
    x: 384,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_47",
    x: 400,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_48",
    x: 416,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_49",
    x: 432,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_50",
    x: 448,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_51",
    x: 464,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_52",
    x: 480,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_53",
    x: 496,
    y: 32,
    width: 16,
    height: 16
  },
  {
    name: "clock_54",
    x: 0,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_55",
    x: 16,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_56",
    x: 32,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_57",
    x: 48,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_58",
    x: 64,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_59",
    x: 80,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_60",
    x: 96,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_61",
    x: 112,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_62",
    x: 128,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "clock_63",
    x: 144,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "coal",
    x: 160,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cocoa_beans",
    x: 176,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cod",
    x: 192,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "cod_bucket",
    x: 208,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "command_block_minecart",
    x: 224,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "comparator",
    x: 240,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_00",
    x: 256,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_01",
    x: 272,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_02",
    x: 288,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_03",
    x: 304,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_04",
    x: 320,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_05",
    x: 336,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_06",
    x: 352,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_07",
    x: 368,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_08",
    x: 384,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_09",
    x: 400,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_10",
    x: 416,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_11",
    x: 432,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_12",
    x: 448,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_13",
    x: 464,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_14",
    x: 480,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_15",
    x: 496,
    y: 48,
    width: 16,
    height: 16
  },
  {
    name: "compass_16",
    x: 0,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_17",
    x: 16,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_18",
    x: 32,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_19",
    x: 48,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_20",
    x: 64,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_21",
    x: 80,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_22",
    x: 96,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_23",
    x: 112,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_24",
    x: 128,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_25",
    x: 144,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_26",
    x: 160,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_27",
    x: 176,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_28",
    x: 192,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_29",
    x: 208,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_30",
    x: 224,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "compass_31",
    x: 240,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_beef",
    x: 256,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_chicken",
    x: 272,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_cod",
    x: 288,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_mutton",
    x: 304,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_porkchop",
    x: 320,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_rabbit",
    x: 336,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cooked_salmon",
    x: 352,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cookie",
    x: 368,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "cyan_dye",
    x: 384,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "dandelion_yellow",
    x: 400,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_boat",
    x: 416,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "dark_oak_door",
    x: 432,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "diamond",
    x: 448,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "diamond_axe",
    x: 464,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "diamond_boots",
    x: 480,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "diamond_chestplate",
    x: 496,
    y: 64,
    width: 16,
    height: 16
  },
  {
    name: "diamond_helmet",
    x: 0,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "diamond_hoe",
    x: 16,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "diamond_horse_armor",
    x: 32,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "diamond_leggings",
    x: 48,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "diamond_pickaxe",
    x: 64,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "diamond_shovel",
    x: 80,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "diamond_sword",
    x: 96,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "dragon_breath",
    x: 112,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "dried_kelp",
    x: 128,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "egg",
    x: 144,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "elytra",
    x: 160,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "emerald",
    x: 176,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_boots",
    x: 192,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_chestplate",
    x: 208,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_helmet",
    x: 224,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_leggings",
    x: 240,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "empty_armor_slot_shield",
    x: 256,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "enchanted_book",
    x: 272,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "end_crystal",
    x: 288,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "ender_eye",
    x: 304,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "ender_pearl",
    x: 320,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "experience_bottle",
    x: 336,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "feather",
    x: 352,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "fermented_spider_eye",
    x: 368,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "filled_map",
    x: 384,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "filled_map_markings",
    x: 400,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "fire_charge",
    x: 416,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "firework_rocket",
    x: 432,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "firework_star",
    x: 448,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "firework_star_overlay",
    x: 464,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "fishing_rod",
    x: 480,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "fishing_rod_cast",
    x: 496,
    y: 80,
    width: 16,
    height: 16
  },
  {
    name: "flint",
    x: 0,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "flint_and_steel",
    x: 16,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "flower_pot",
    x: 32,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "furnace_minecart",
    x: 48,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "ghast_tear",
    x: 64,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "glass_bottle",
    x: 80,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "glistering_melon_slice",
    x: 96,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "glowstone_dust",
    x: 112,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "gold_ingot",
    x: 128,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "gold_nugget",
    x: 144,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_apple",
    x: 160,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_axe",
    x: 176,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_boots",
    x: 192,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_carrot",
    x: 208,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_chestplate",
    x: 224,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_helmet",
    x: 240,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_hoe",
    x: 256,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_horse_armor",
    x: 272,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_leggings",
    x: 288,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_pickaxe",
    x: 304,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_shovel",
    x: 320,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "golden_sword",
    x: 336,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "gray_dye",
    x: 352,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "gunpowder",
    x: 368,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "heart_of_the_sea",
    x: 384,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "hopper",
    x: 400,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "hopper_minecart",
    x: 416,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "ink_sac",
    x: 432,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "iron_axe",
    x: 448,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "iron_boots",
    x: 464,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "iron_chestplate",
    x: 480,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "iron_door",
    x: 496,
    y: 96,
    width: 16,
    height: 16
  },
  {
    name: "iron_helmet",
    x: 0,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_hoe",
    x: 16,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_horse_armor",
    x: 32,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_ingot",
    x: 48,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_leggings",
    x: 64,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_nugget",
    x: 80,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_pickaxe",
    x: 96,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_shovel",
    x: 112,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "iron_sword",
    x: 128,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "item_frame",
    x: 144,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "jungle_boat",
    x: 160,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "jungle_door",
    x: 176,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "kelp",
    x: 192,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "knowledge_book",
    x: 208,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "lapis_lazuli",
    x: 224,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "lava_bucket",
    x: 240,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "lead",
    x: 256,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather",
    x: 272,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_boots",
    x: 288,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_boots_overlay",
    x: 304,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_chestplate",
    x: 320,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_chestplate_overlay",
    x: 336,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_helmet",
    x: 352,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_helmet_overlay",
    x: 368,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_leggings",
    x: 384,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "leather_leggings_overlay",
    x: 400,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "light_blue_dye",
    x: 416,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "light_gray_dye",
    x: 432,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "lime_dye",
    x: 448,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "lingering_potion",
    x: 464,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "magenta_dye",
    x: 480,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "magma_cream",
    x: 496,
    y: 112,
    width: 16,
    height: 16
  },
  {
    name: "map",
    x: 0,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "melon_seeds",
    x: 16,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "melon_slice",
    x: 32,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "milk_bucket",
    x: 48,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "minecart",
    x: 64,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "mushroom_stew",
    x: 80,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_11",
    x: 96,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_13",
    x: 112,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_blocks",
    x: 128,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_cat",
    x: 144,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_chirp",
    x: 160,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_far",
    x: 176,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_mall",
    x: 192,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_mellohi",
    x: 208,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_stal",
    x: 224,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_strad",
    x: 240,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_wait",
    x: 256,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "music_disc_ward",
    x: 272,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "mutton",
    x: 288,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "name_tag",
    x: 304,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "nautilus_shell",
    x: 320,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "nether_brick",
    x: 336,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "nether_star",
    x: 352,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "nether_wart",
    x: 368,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "oak_boat",
    x: 384,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "oak_door",
    x: 400,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "orange_dye",
    x: 416,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "painting",
    x: 432,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "paper",
    x: 448,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "phantom_membrane",
    x: 464,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "pink_dye",
    x: 480,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "poisonous_potato",
    x: 496,
    y: 128,
    width: 16,
    height: 16
  },
  {
    name: "popped_chorus_fruit",
    x: 0,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "porkchop",
    x: 16,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "potato",
    x: 32,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "potion",
    x: 48,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "potion_overlay",
    x: 64,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "prismarine_crystals",
    x: 80,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "prismarine_shard",
    x: 96,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "pufferfish",
    x: 112,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "pufferfish_bucket",
    x: 128,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_pie",
    x: 144,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "pumpkin_seeds",
    x: 160,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "purple_dye",
    x: 176,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "quartz",
    x: 192,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "rabbit",
    x: 208,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "rabbit_foot",
    x: 224,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "rabbit_hide",
    x: 240,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "rabbit_stew",
    x: 256,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "redstone",
    x: 272,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "repeater",
    x: 288,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "rose_red",
    x: 304,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "rotten_flesh",
    x: 320,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "ruby",
    x: 336,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "saddle",
    x: 352,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "salmon",
    x: 368,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "salmon_bucket",
    x: 384,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "scute",
    x: 400,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sea_pickle",
    x: 416,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "seagrass",
    x: 432,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "shears",
    x: 448,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "shulker_shell",
    x: 464,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "sign",
    x: 480,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "slime_ball",
    x: 496,
    y: 144,
    width: 16,
    height: 16
  },
  {
    name: "snowball",
    x: 0,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "spawn_egg",
    x: 16,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "spawn_egg_overlay",
    x: 32,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "spectral_arrow",
    x: 48,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "spider_eye",
    x: 64,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "splash_potion",
    x: 80,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "spruce_boat",
    x: 96,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "spruce_door",
    x: 112,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "stick",
    x: 128,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "stone_axe",
    x: 144,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "stone_hoe",
    x: 160,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "stone_pickaxe",
    x: 176,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "stone_shovel",
    x: 192,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "stone_sword",
    x: 208,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "string",
    x: 224,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "structure_void",
    x: 240,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "sugar",
    x: 256,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "sugar_cane",
    x: 272,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "tipped_arrow_base",
    x: 288,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "tipped_arrow_head",
    x: 304,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "tnt_minecart",
    x: 320,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "totem_of_undying",
    x: 336,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "trident",
    x: 352,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "tropical_fish",
    x: 368,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "tropical_fish_bucket",
    x: 384,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "turtle_egg",
    x: 400,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "turtle_helmet",
    x: 416,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "water_bucket",
    x: 432,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat",
    x: 448,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wheat_seeds",
    x: 464,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wooden_axe",
    x: 480,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wooden_hoe",
    x: 496,
    y: 160,
    width: 16,
    height: 16
  },
  {
    name: "wooden_pickaxe",
    x: 0,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "wooden_shovel",
    x: 16,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "wooden_sword",
    x: 32,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "writable_book",
    x: 48,
    y: 176,
    width: 16,
    height: 16
  },
  {
    name: "written_book",
    x: 64,
    y: 176,
    width: 16,
    height: 16
  }
];

var data = [
  texture,
  tiles
];

exports.texture = texture;
exports.tiles = tiles;
exports.data = data;
/* texture Not a pure module */
