// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("rescript/lib/js/belt_Array.js");
var Generators = require("./generators/Generators.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Generator_View = require("./builder/views/Generator_View.bs.js");
var RescriptReactRouter = require("@rescript/react/src/RescriptReactRouter.bs.js");

var generatorGroups = [
  {
    label: "Characters",
    generators: Generators.character
  },
  {
    label: "Mob Characters",
    generators: Generators.mobCharacter
  },
  {
    label: "Mobs",
    generators: Generators.mob
  },
  {
    label: "Blocks, Items and Accessories",
    generators: Generators.utility
  },
  {
    label: "Mods",
    generators: Generators.mod
  },
  {
    label: "Other",
    generators: Generators.other
  },
  {
    label: "Development",
    generators: Generators.dev
  },
  {
    label: "Testing",
    generators: Generators.test
  }
];

function Components$Heading(Props) {
  var children = Props.children;
  return React.createElement("h1", {
              className: "text-2xl font-bold mb-2"
            }, children);
}

var Heading = {
  make: Components$Heading
};

function Components$GeneratorList(Props) {
  return Belt_Array.map(generatorGroups, (function (generatorGroup) {
                var label = generatorGroup.label;
                return React.createElement("div", {
                            key: label,
                            className: "mb-8"
                          }, React.createElement(Components$Heading, {
                                children: label
                              }), React.createElement("div", undefined, Belt_Array.map(generatorGroup.generators, (function (generator) {
                                      var thumbnail = generator.thumbnail;
                                      var id = generator.id;
                                      var url = "#" + id + "";
                                      return React.createElement("div", {
                                                  key: id
                                                }, React.createElement("a", {
                                                      className: "flex items-center p-2 hover:bg-gray-100 rounded",
                                                      href: url
                                                    }, thumbnail !== undefined ? React.createElement("img", {
                                                            className: "w-20 h-20 mr-2",
                                                            src: thumbnail.url
                                                          }) : React.createElement("div", {
                                                            className: "w-20 h-20 bg-gray-200 mr-2"
                                                          }), React.createElement("span", undefined, generator.name)));
                                    }))));
              }));
}

var GeneratorList = {
  make: Components$GeneratorList
};

function Components$BackLink(Props) {
  return React.createElement("a", {
              className: "text-green-600 hover:underline mb-4 block",
              href: "#"
            }, "← Back to generator list");
}

var BackLink = {
  make: Components$BackLink
};

function Components$ListView(Props) {
  return React.createElement(Components$GeneratorList, {});
}

var ListView = {
  make: Components$ListView
};

function Components$GeneratorView(Props) {
  var generatorDef = Props.generatorDef;
  return React.createElement(React.Fragment, undefined, React.createElement(Components$Heading, {
                  children: generatorDef.name
                }), React.createElement(Components$BackLink, {}), React.createElement(Generator_View.make, {
                  generatorDef: generatorDef
                }));
}

var GeneratorView = {
  make: Components$GeneratorView
};

function Components$App(Props) {
  var url = RescriptReactRouter.useUrl(undefined, undefined);
  var match = React.useState(function () {
        
      });
  var setGeneratorDef = match[1];
  var generatorDef = match[0];
  React.useEffect((function () {
          var generatorDef = Generators.all.find(function (generator) {
                return generator.id === url.hash;
              });
          var generatorDef$1 = generatorDef === undefined ? undefined : Caml_option.some(generatorDef);
          Curry._1(setGeneratorDef, (function (param) {
                  return generatorDef$1;
                }));
        }), [url.hash]);
  return React.createElement("div", {
              className: "p-8 pb-64"
            }, generatorDef !== undefined ? React.createElement(Components$GeneratorView, {
                    generatorDef: generatorDef
                  }) : React.createElement(Components$ListView, {}));
}

var App = {
  make: Components$App
};

exports.generatorGroups = generatorGroups;
exports.Heading = Heading;
exports.GeneratorList = GeneratorList;
exports.BackLink = BackLink;
exports.ListView = ListView;
exports.GeneratorView = GeneratorView;
exports.App = App;
/* react Not a pure module */
